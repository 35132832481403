<template>
    <div class="zte">
        <el-row type="flex">
            <el-col :span="24" v-for="item in zte" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="zhengte_which_num">
                        <el-radio-group v-model="radio" fill size="small" text-color="#fff" @change="zhengte_change_num">
                            <el-radio-button label="zte">正特码一</el-radio-button>
                            <el-radio-button label="zte2">正特码二</el-radio-button>
                            <el-radio-button label="zte3">正特码三</el-radio-button>
                            <el-radio-button label="zte4">正特码四</el-radio-button>
                            <el-radio-button label="zte5">正特码五</el-radio-button>
                            <el-radio-button label="zte6">正特码六</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="bet-items-one">
                        <div class="flex5">
                            <div class="flex5-item" v-for="i in item.betclass" :key="i.index">
                                <span v-if="i.color=='red'" class="lhc_zte_red">{{i.name}}</span>
                                <span v-if="i.color=='green'" class="lhc_zte_green">{{i.name}}</span>
                                <span v-if="i.color=='blue'" class="lhc_zte_blue">{{i.name}}</span>
                                <span class="bet-lose" style=" min-width: 50px;">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                zte: [
                    {
                        ballname: "正特",
                        betclass: [
                            { title: "正特", name: "1", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "2", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "3", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "4", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "5", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "6", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "7", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "8", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "9", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "10", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "11", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "12", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "13", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "14", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "15", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "16", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "17", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "18", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "19", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "20", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "21", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "22", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "23", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "24", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "25", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "26", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "27", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "28", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "29", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "30", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "31", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "32", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "33", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "34", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "35", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "36", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "37", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "38", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "39", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "40", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "41", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "42", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "43", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "44", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "正特", name: "45", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "46", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "正特", name: "47", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "48", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "正特", name: "49", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                        ]
                    }
                ],
                radio: 'zte',
            }
        },
        methods: {
            zhengte_change_num(label) {
                this.$emit('regainLoss', label)
                this.reset();
                this.get_lose();
            },

            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //正特
                for (let j = 0; j < this.zte[0].betclass.length; j++) {
                    if (this.radio == "zte") {
                        this.zte[0].betclass[j].title = "正特码一";
                        this.zte[0].betclass[j].subname = "4_0_0";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte2") {
                        this.zte[0].betclass[j].title = "正特码二";
                        this.zte[0].betclass[j].subname = "4_0_1";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte3") {
                        this.zte[0].betclass[j].title = "正特码三";
                        this.zte[0].betclass[j].subname = "4_0_2";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte4") {
                        this.zte[0].betclass[j].title = "正特码四";
                        this.zte[0].betclass[j].subname = "4_0_3";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte5") {
                        this.zte[0].betclass[j].title = "正特码五";
                        this.zte[0].betclass[j].subname = "4_0_4";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                    if (this.radio == "zte6") {
                        this.zte[0].betclass[j].title = "正特码六";
                        this.zte[0].betclass[j].subname = "4_0_5";
                        lose_data.forEach(item => {
                            if (item.title == this.zte[0].betclass[j].title && item.name == this.zte[0].betclass[j].name) {
                                this.zte[0].betclass[j].static_bonus = item.rate;
                                this.zte[0].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zte[0].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                // console.log(this.zte)
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //正特
                for (let i = 0; i < this.zte.length; i++) {
                    for (let j = 0; j < this.zte[i].betclass.length; j++) {
                        this.zte[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //正特
                for (let i = 0; i < this.zte.length; i++) {
                    for (let j = 0; j < this.zte[i].betclass.length; j++) {
                        if (this.zte[i].betclass[j].val && !isNaN(this.zte[i].betclass[j].val * 1)) {
                            list.push(this.zte[i].betclass[j]);
                        }
                    }
                }
                // console.log(list);return;
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //正特
                    for (let j = 0; j < this.zte.length; j++) {
                        for (let k = 0; k < this.zte[j].betclass.length; k++) {
                            if (this.zte[j].betclass[k].title == list[i].title && this.zte[j].betclass[k].name == list[i].name) {
                                this.zte[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .zhengte_which_num {
        text-align: center;
        padding-bottom: 10px;
    }
    .bet-page .flex5 {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .bet-page .flex5-item {
        width: 20%;
    }
    .lhc_zte_red,
    .lhc_zte_blue,
    .lhc_zte_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        margin-bottom: 3px;
        font-weight: bold;
    }
    .lhc_zte_red {
        background-color: #f56c6c;
    }
    .lhc_zte_blue {
        background-color: #0089ff;
    }
    .lhc_zte_green {
        background-color: #67c23a;
    }
</style>