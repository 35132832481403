<template>
    <div class="bet-page theme-border-half">
        <cqssc v-show="gid == 1|| gid == 7 || gid == 18|| gid == 38 || gid == 39|| gid == 44|| gid == 45|| gid == 46" :activeName="activeName" ref="cqssc"></cqssc>
        <bjpk10 v-show="gid == 3|| gid == 19|| gid == 24|| gid == 28|| gid == 37||gid == 40|| gid == 43|| gid == 47" :activeName="activeName" ref="bjpk10"></bjpk10>
        <can28 v-show="gid==22||gid==23" :activeName="activeName" ref="can28"></can28>
        <jslhc v-show="gid==34||gid==20||gid==48" :activeName="activeName" ref="jslhc" @regainLoss="regainLoss" @fastsub="fastsub"></jslhc>
        <k3 v-show="gid==6||gid==29||gid==30||gid==31||gid==32||gid==36" :activeName="activeName" ref="k3"></k3>
        <pl35 v-show="gid==10" :activeName="activeName" ref="pl35"></pl35>
        <fc3D v-show="gid==4" :activeName="activeName" ref="fc3D"></fc3D>
        <subBet ref="subBet" @reset="reset" @reset_fast_sub="reset_fast_sub"></subBet>
    </div>
</template>

<script>
    import subBet from "./subBet"
    import cqssc from "./cqssc/cqssc";
    import bjpk10 from "./bjpk10/bjpk10";
    import can28 from "./can28/can28";
    import jslhc from "./jslhc/jslhc";
    import k3 from "./k3/k3";
    import pl35 from "./pl35/pl35.vue";
    import fc3D from "./fc3D/fc3D.vue";
    export default {
        props: ["activeName"],
        components: {
            cqssc,
            bjpk10,
            can28,
            jslhc,
            k3,
            pl35,
            fc3D,
            subBet
        },
        data() {
            return {
                gid: '',
                themeColorhalf: '',
                themeColorLight: '',
            }
        },
        created() {
            this.gid = this.$store.state.checked_gid;
        },
        mounted() {
            // console.log(this.activeName)
            this.gid = this.$store.state.checked_gid;
        },
        methods: {
            reset_fast_sub() {
                this.$refs['jslhc'].reset_fast_sub();
            },
            //六合彩快速下注
            fastsub(list) {
                this.$refs['subBet'].fastsub(list);
            },
            //六合彩正特，连码，自选不中，根据小玩法重新获取赔率
            regainLoss(playStr) {
                this.$emit('regainLoss', playStr)
            },
            //停止下注
            stop_bet(state, text) {
                this.$refs.subBet.stop_bet(state, text);
                this.reset();
            },
            /**切换彩种 */
            choose_lottery() {
                this.gid = this.$store.state.checked_gid;
            },
            /**显示赔率 */
            set_lose() {
                let sid = setInterval(() => {
                    if (this.$store.state.lose_data&&this.$store.state.lose_data.length>0) {
                        window.clearInterval(sid);
                        // 重庆时时彩
                        this.gid == 1 || this.gid == 7 || this.gid == 18 || this.gid == 38 || this.gid == 39 || this.gid == 44 || this.gid == 45 || this.gid == 46 ? this.$refs.cqssc.set_lose() : "";
                        // 北京PK10
                        this.gid == 3 || this.gid == 19 || this.gid == 24 || this.gid == 28 || this.gid == 37 || this.gid == 40 || this.gid == 43 || this.gid == 47 ? this.$refs.bjpk10.set_lose() : "";
                        //加拿大28
                        this.gid == 22 || this.gid == 23 ? this.$refs.can28.set_lose() : "";
                        //极速六合彩
                        this.gid == 34 || this.gid == 20 || this.gid == 48 ? this.$refs.jslhc.set_lose() : "";
                        //快3
                        this.gid == 6 || this.gid == 29 || this.gid == 30 || this.gid == 31 || this.gid == 32 || this.gid == 36 ? this.$refs.k3.set_lose() : "";
                        this.gid == 10 ? this.$refs.pl35.set_lose() : "";
                        this.gid == 4 ? this.$refs.fc3D.set_lose() : "";
                    }
                }, 100);
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$refs.subBet.get_fast())
                return this.$refs.subBet.get_fast();
            },
            /**重填，重置 */
            reset() {
                this.$nextTick(() => {
                    this.gid == 1 || this.gid == 7 || this.gid == 18 || this.gid == 38 || this.gid == 39 || this.gid == 44 || this.gid == 45 || this.gid == 46 ? this.$refs.cqssc.reset() : "";
                    this.gid == 3 || this.gid == 19 || this.gid == 24 || this.gid == 28 || this.gid == 37 || this.gid == 40 || this.gid == 43 || this.gid == 47 ? this.$refs.bjpk10.reset() : "";
                    this.gid == 22 || this.gid == 23 ? this.$refs.can28.reset() : "";
                    this.gid == 34 || this.gid == 20 || this.gid == 48 ? this.$refs.jslhc.reset() : "";
                    this.gid == 6 || this.gid == 29 || this.gid == 30 || this.gid == 31 || this.gid == 32 || this.gid == 36 ? this.$refs.k3.reset() : "";
                    this.gid == 10 ? this.$refs.pl35.reset() : "";
                    this.gid == 4 ? this.$refs.fc3D.reset() : "";
                })
            },
            /**提交，下注 */
            sub_bet() {
                if (this.gid == 1 || this.gid == 7 || this.gid == 18 || this.gid == 38 || this.gid == 39 || this.gid == 44 || this.gid == 45 || this.gid == 46) {
                    return this.$refs.cqssc.sub_bet();
                }
                if (this.gid == 3 || this.gid == 19 || this.gid == 24 || this.gid == 28 || this.gid == 37 || this.gid == 40 || this.gid == 43 || this.gid == 47) {
                    return this.$refs.bjpk10.sub_bet();
                }
                if (this.gid == 22 || this.gid == 23) {
                    return this.$refs.can28.sub_bet();
                }
                if (this.gid == 34 || this.gid == 20 || this.gid == 48) {
                    return this.$refs.jslhc.sub_bet();
                }
                if (this.gid == 6 || this.gid == 29 || this.gid == 30 || this.gid == 31 || this.gid == 32 || this.gid == 36) {
                    return this.$refs.k3.sub_bet();
                }
                if (this.gid == 10) {
                    return this.$refs.pl35.sub_bet();
                }
                if (this.gid == 4) {
                    return this.$refs.fc3D.sub_bet();
                }
            },
            /**下注成功后刷新用余额 */
            get_user_balance() {
                this.$emit("get_user_balance", "")
            },
            /**重复上次下单 */
            repeat_last_bet(gid, playing, list) {
                gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46 ? this.$refs.cqssc.repeat_last_bet(playing, list) : "";
                gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47 ? this.$refs.bjpk10.repeat_last_bet(playing, list) : "";
                gid == 22 || gid == 23 ? this.$refs.can28.repeat_last_bet(playing, list) : "";
                gid == 34 || gid == 20 || gid == 48 ? this.$refs.jslhc.repeat_last_bet(playing, list) : "";
                gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36 ? this.$refs.k3.repeat_last_bet(playing, list) : "";
                gid == 10 ? this.$refs.pl35.repeat_last_bet(playing, list) : "";
                gid == 4 ? this.$refs.fc3D.repeat_last_bet(playing, list) : "";
            },
        },
    }
</script>

<style>
    /* 自定 flex 5 col */
    .bet-page .flex5 {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .bet-page .flex5-item {
        width: 19%;
        margin-bottom: 3px;
    }
    .bet-page .flex5-item:last-child {
        margin: 0;
    }
    .bet-page {
        position: relative;
        display: inline-block;
        padding: 5px;
        padding-bottom: 0;
        width: 880px;
        border: 1px solid;
        border-top: 2px solid;
        background-color: #fff;
    }
    .bet-page .bet-title {
        color: #fff;
        height: 28px;
        line-height: 28px;
        text-align: left;
    }
    .bet-page .bet-title span {
        padding-left: 10px;
    }
    .bet-page .bet-items {
        /* padding: 5px 0; */
        line-height: 0px;
    }

    .bet-page .bet-items .bet-name {
        display: inline-block;
        line-height: 28px;
        padding: 0 0 0 5px;
        width: 35px;
        text-align: center;
    }
    .bet-page .bet-items .bet-lose {
        display: inline-block;
        height: 28px;
        width: 60px;
        line-height: 28px;
        color: #f56c6c;
        font-weight: bold;
        text-align: center;
    }

    .bet-page .bet-items .el-input {
        width: 60px;
        float: right;
        margin-right: 3px;
    }
    /* 只有1列的 */
    .bet-page .bet-items-one .bet-name {
        line-height: 28px;
        padding: 0 0 0 10px;
        width: 20%;
        text-align: center;
        display: inline-block;
    }
    .bet-page .bet-items-one .bet-lose {
        display: inline-block;
        color: #f56c6c;
        font-weight: bold;
        height: 28px;
        width: 70px;
        line-height: 28px;
        text-align: center;
    }

    .bet-page .bet-items-one .el-input {
        width: 40%;
        float: right;
        margin-right: 3px;
    }
</style>
