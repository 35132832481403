<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-21 21:20:14
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-01 00:22:55
 * @FilePath: \pc\src\components\homePublic\lmcl.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="lmcl theme-border">
        <div class="tabs theme-border light-bg">
            <div :class="cl_class" @click="click_cl">两面长龙</div>
            <div :class="near_class" @click="click_near">近期下注</div>
        </div>
        <div class="data">
            <div class="cl-data" v-if="show_data==='cl'">
                <!-- 大小 -->
                <div v-if="!lmcl.nodata">
                    <div v-for="(item,index) in lmcl.list1" :key="item.index" :class="index%2==1?'theme-half-bg-light cl-item theme-border':' cl-item theme-border'">
                        <span class="item-tit">{{item.title}}-</span>
                        <span class="item-val">{{item.big_small}}</span>
                        <span class="item-sum">{{item.b_s_qnum}}期</span>
                    </div>
                    <!-- 单双 -->
                    <div v-for="(item,index) in lmcl.list2" :key="item.index" :class="index%2==1?'theme-half-bg-light cl-item theme-border':' cl-item theme-border'">
                        <span class="item-tit">{{item.title}}-</span>
                        <span class="item-val">{{item.odd_even}}</span>
                        <span class="item-sum">{{item.b_s_qnum}}期</span>
                    </div>
                </div>
                <div v-else class="no-data">暂无数据</div>
            </div>
            <div class="near-data" v-if="show_data==='near'">
                <div v-if="!nearBet.nodata">
                    <div  v-for="(item,index) in nearBet.list" :key="item.index" :class="index%2==1?'light-bg data-row theme-border':' data-row theme-border'">
                        <div class="game-name">
                            {{item.game_name}}
                            <el-tag v-if="item.order_staus==0" size="small" effect="dark" type="success">未结算</el-tag>
                            <el-tag v-if="item.order_staus==1" size="small" effect="dark" type="danger">已结算</el-tag>
                        </div>
                        <div class="qnum">期号：{{item.qnum}}</div>
                        <div class="betinfo">{{item.game_level}}</div>
                        <div class="lose">
                            <span class="redcolor">{{item.balance*1}}</span> @
                            <span>{{item.list_bonus*1}}</span>
                            <!-- <span class="bet-val">{{item.bonus}}</span> -->
                        </div>
                    </div>
                    <div style="margin:0 auto">
                        <el-pagination @current-change="get_near_bet" :current-page="nearBet.page" :page-size="5" :pager-count="5" :total="nearBet.nums" hide-on-single-page small layout="prev,pager,next" />
                    </div>
                </div>
                <div v-else class="no-data">暂无数据</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                show_data: "near",
                /**按钮样式 */
                near_class: 'tab theme-bg theme-border white-text',
                cl_class: 'tab theme-border black-text',
                /**两面长龙 */
                lmcl: {
                    list1: [],
                    list2: [],
                    nodata: false,
                },
                /**近期下注 */
                nearBet: {
                    list: [],
                    nodata: false, //是否有下注，默认没有
                    page: 1,
                    nums: 0,
                },
                loop_near_order_id: '',
            }
        },
        created() {

        },
        mounted() {
            this.get_data();
            this.loop_near_order_id = setInterval(() => {
                this.get_data();
            }, 20000);
        },
        beforeDestroy() {
            window, clearInterval(this.loop_near_order_id);
        },
        methods: {
            get_data() {
                this.show_data === 'near' ? this.get_near_bet(this.nearBet.page) : this.get_lmcl();
            },
            get_recently_data() {
                this.$refs.recently.getRecentlyLottery();
            },
            click_cl() {
                this.show_data = 'cl';
                this.cl_class = 'tab theme-bg theme-border white-text';
                this.near_class = 'tab theme-border black-text';
                this.get_lmcl();
            },
            click_near() {
                this.show_data = 'near'
                this.near_class = 'tab theme-bg theme-border white-text';
                this.cl_class = 'tab theme-border black-text';
                this.get_near_bet(1);
            },
            get_near_bet(page = 1) {
                this.nearBet.page = page;
                this.axios.get(`/order/near_order?limit=5&page=${page}`).then(result => {
                    if (result.data.status == 1 && result.data.msg === "请求成功！") {
                        if (result.data.res.list) {
                            if (result.data.res.list.length > 0) {
                                this.nearBet.nodata = false;
                                this.nearBet.list = [];
                                this.nearBet.nums = result.data.res.nums * 1;
                                for (let i = 0; i < result.data.res.list.length; i++) {
                                    if (result.data.res.list[i].order_staus !== -1) {
                                        this.nearBet.list.push(result.data.res.list[i]);
                                    }
                                }
                            }
                        } else {
                            this.nearBet.nodata = true
                        }
                    }
                })
            },
            get_lmcl() {
                this.axios.get("/order/index_right_lm?gid=" + this.$store.state.checked_gid).then(result => {
                    // console.log(result.data);
                    this.lmcl.list1 = [];
                    this.lmcl.list2 = [];
                    if (result.data.status == 1 && result.data.msg === "获取长龙成功") {
                        this.lmcl.nodata = false;
                        this.lmcl.list1 = result.data.res[0];
                        this.lmcl.list2 = result.data.res[1];
                    } else {
                        this.lmcl.nodata = true;
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .lmcl {
        width: 220px;
        height: 465px;
        border: 1px solid;
        position: absolute;
        top: 10px;
        left: 911px;
    }
    .tabs {
        float: left;
        width: 24px;
        height: 465px;
        border-right: 2px solid;
    }
    .tab {
        border-bottom: 1px solid;
        padding: 7px 0;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
    }
    .data {
        float: right;
        background-color: #fff;
        width: 194px;
        height: 465px;
    }
    .no-data {
        text-align: center;
        line-height: 400px;
    }
    .cl-item {
        height: 21px;
        border-bottom: 1px dotted;
        line-height: 21px;
        padding: 0 5px;
    }
    .item-val {
        color: red;
    }
    .item-sum {
        float: right;
    }
    .data-row {
        padding: 5px;
        border-bottom: 1px dotted;
    }
    .qnum,.betinfo,.lose{
        line-height: 19px;
    }
</style>
