<!--
 * @Author: your name
 * @Date: 2020-06-18 14:35:52
 * @LastEditTime: 2022-08-03 17:11:39
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \luck\src\components\betPage\bjpk10\bjpk10.vue
-->
<template>
    <div class="bjpk10">
        <lm v-show="activeName=='两面'" ref="lm"></lm>
        <gy v-show="activeName=='冠亚组合'" ref="gy"></gy>
        <all v-show="activeName=='全部'" ref="all"></all>
        <d1_d10 v-show="activeName=='1-10名'" ref="d1_d10"></d1_d10>
        <d3_d6 v-show="activeName=='三、四、五、六名'" ref="d3_d6"></d3_d6>
        <d7_d10 v-show="activeName=='七、八、九、十名'" ref="d7_d10"></d7_d10>
    </div>
</template>

<script>
    import lm from "./lm";
    import gy from "./gy";
    import all from "./all";
    import d1_d10 from "./d1_d10";
    import d3_d6 from "./d3_d6";
    import d7_d10 from "./d7-d10";

    export default {
        components: { lm, gy, d1_d10, d3_d6, d7_d10, all },
        props: ["activeName"],
        data() {
            return {

            }
        },
        created() {
        },
        methods: {
            /**显示赔率 */
            set_lose() {
                this.activeName == "全部" ? this.$refs.all.get_lose() : "";
                this.activeName == "两面" ? this.$refs.lm.get_lose() : "";
                this.activeName == "冠亚组合" ? this.$refs.gy.get_lose() : "";
                this.activeName == "1-10名" ? this.$refs.d1_d10.get_lose() : "";
                this.activeName == "三、四、五、六名" ? this.$refs.d3_d6.get_lose() : "";
                this.activeName == "七、八、九、十名" ? this.$refs.d7_d10.get_lose() : "";
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$parent.get_fast())
                return this.$parent.get_fast();
            },
            /**重填，重置 */
            reset() {
                this.activeName == "全部" ? this.$refs.all.reset() : "";
                this.activeName == "两面" ? this.$refs.lm.reset() : "";
                this.activeName == "冠亚组合" ? this.$refs.gy.reset() : "";
                this.activeName == "1-10名" ? this.$refs.d1_d10.reset() : "";
                this.activeName == "三、四、五、六名" ? this.$refs.d3_d6.reset() : "";
                this.activeName == "七、八、九、十名" ? this.$refs.d7_d10.reset() : "";
            },
            /**提交，下注 */
            sub_bet() {
                if (this.activeName == "两面") {
                    return this.$refs.lm.sub_bet();
                }
                if (this.activeName == "全部") {
                    return this.$refs.all.sub_bet();
                }
                if (this.activeName == "冠亚组合") {
                    return this.$refs.gy.sub_bet();
                }
                if (this.activeName == "1-10名") {
                    return this.$refs.d1_d10.sub_bet();
                }
                if (this.activeName == "三、四、五、六名") {
                    return this.$refs.d3_d6.sub_bet();
                }
                if (this.activeName == "七、八、九、十名") {
                    return this.$refs.d7_d10.sub_bet();
                }
            },
            /**重复上次下单 */
            repeat_last_bet(playing, list) {
                playing == "pk10_lmp" ? this.$refs.lm.repeat_last_bet(list) : "";
                playing == "pk10_all" ? this.$refs.all.repeat_last_bet(list) : "";
                playing == "pk10_gy" ? this.$refs.gy.repeat_last_bet(list) : "";
                playing == "pk10_d1_10" ? this.$refs.d1_d10.repeat_last_bet(list) : "";
                playing == "pk10_d3_6" ? this.$refs.d3_d6.repeat_last_bet(list) : "";
                playing == "pk10_d7_10" ? this.$refs.d7_d10.repeat_last_bet(list) : "";
            },
        },
    }
</script>

<style scoped>
</style>
