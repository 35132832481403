<template>
    <div class="bao3">
        <!-- 两面 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in bao3" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="bet-items-one" style="height:205px;">
                    <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                        <span class="bet-name betclassname_old_pcdd" :style="{'background-color':i.color}" @click.prevent="check_name(i)">{{i.name}}</span>
                        <span class="bet-lose" style="margin:0 15px;">{{i.static_bonus}}</span>
                        <el-checkbox label="" class="bao3" v-model="i.val" @change="checkbox_change" :disabled="i.disabled"></el-checkbox>
                    </el-col>
                </div>
                <div style="background-color:#fff;padding:6px 0;">
                    <span>下注金额：</span>
                    <el-input class="bao3-val" v-model="bao3_val" size="small"  v-on:focus="bao3_val=check_fast_val(bao3_val)"></el-input>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            bao3: [
                {
                    ballname: "特码包三",
                    betclass: [
                        { title: "特码包三", name: "0", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                        { title: "特码包三", name: "1", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "2", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "3", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "4", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "5", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "6", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "7", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "8", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "9", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "10", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "11", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "12", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "13", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                        { title: "特码包三", name: "14", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                        { title: "特码包三", name: "15", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "16", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "17", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "18", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "19", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "20", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "21", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "22", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "23", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "24", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#F56C6C" },
                        { title: "特码包三", name: "25", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67C23A" },
                        { title: "特码包三", name: "26", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409EFF" },
                        { title: "特码包三", name: "27", visible: false, static_bonus: "", val: false, disabled: false, subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                    ]
                }
            ],
            // 选择的个数，限制7个
            bao3_count: 0,
            /**特码包三下注金额 */
            bao3_val: "",
        }
    },
    created() {
        // this.get_lose();
    },
    methods: {
        /**显示赔率等数据 */
        get_lose() {
            let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
            let others = this.$store.state.lottery.lose_data.others;
            let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
            //两面
            for (let i = 0; i < this.bao3.length; i++) {
                for (let j = 0; j < this.bao3[i].betclass.length; j++) {
                    this.bao3[i].betclass[j].static_bonus = static_bonus[0];
                    this.bao3[i].betclass[j].straightbet_high = others[0].straightbet_high;
                    this.bao3[i].betclass[j].straightbet_low = others[0].straightbet_low;
                }
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val) {
            if (val) {
                return val;
            }
            else {
                val = this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset() {
            this.bao3_val = "";
            this.bao3_count = 0;
            for (let i = 0; i < this.bao3[0].betclass.length; i++) {
                this.bao3[0].betclass[i].val = false;
                // console.log(this.bao3[0].betclass[i].val)
                this.bao3[0].betclass[i].disabled = false;
            }
        },
        check_name(item) {
            if (item.disabled) {
                return;
            } else {
                item.val = !item.val;
                item.val ? this.bao3_count++ : this.bao3_count--;
            }
            if (this.bao3_count == 3) {
                for (let i = 0; i < this.bao3[0].betclass.length; i++) {
                    if (this.bao3[0].betclass[i].val == false) {
                        this.bao3[0].betclass[i].disabled = true
                    }
                }
            }
            else if (this.bao3_count < 4) {
                for (let i = 0; i < this.bao3[0].betclass.length; i++) {
                    if (this.bao3[0].betclass[i].val == false) {
                        this.bao3[0].betclass[i].disabled = false
                    }
                }
            }
        },
        /**多选框点击 */
        checkbox_change(v) {
            v ? this.bao3_count++ : this.bao3_count--;
            //限制最多选取7个号码
            if (this.bao3_count == 3) {
                for (let i = 0; i < this.bao3[0].betclass.length; i++) {
                    if (this.bao3[0].betclass[i].val == false) {
                        this.bao3[0].betclass[i].disabled = true
                    }
                }
            }
            else if (this.bao3_count < 4) {
                for (let i = 0; i < this.bao3[0].betclass.length; i++) {
                    if (this.bao3[0].betclass[i].val == false) {
                        this.bao3[0].betclass[i].disabled = false
                    }
                }
            }
        },
        /**提交，下注 */
        sub_bet() {
            let list = [];
            if (this.bao3_val && !isNaN(this.bao3_val * 1) && this.bao3_count > 0) {
                for (let i = 0; i < this.bao3.length; i++) {
                    for (let j = 0; j < this.bao3[i].betclass.length; j++) {
                        if (this.bao3[i].betclass[j].val) {
                            this.bao3[i].betclass[j].val = this.bao3_val;
                            list.push(this.bao3[i].betclass[j]);
                        }
                    }
                }
            }
            // console.log(list)
            let name = `${list[0].name},${list[1].name},${list[2].name}`;
            list.length = 1;
            let arr = JSON.parse(JSON.stringify(list));
            arr[0].name = name;
            return arr;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for (let i = 0; i < list.length; i++) {
                for (let j = 0; j < this.bao3.length; j++) {
                    for (let k = 0; k < this.bao3[j].betclass.length; k++) {
                        if (this.bao3[j].betclass[k].title == list[i].title && this.bao3[j].betclass[k].name == list[i].name) {
                            this.bao3[j].betclass[k].val = true;
                        }
                    }
                }
            }
        },
    },
}
</script>

<style scoped>
.betclassname_old_pcdd{
    float: none !important;
    display: inline-block;
    width: 25px !important;
    height: 25px;
    padding: 0 !important;
    border-radius: 50%;
    color: #fff;
    text-align: center !important;
    font-weight: bold;
    margin-left:20px;
    margin-bottom:3px;
    line-height: 25px !important;
}
.bao3-val{
    width: 120px !important;
    margin: 0 auto !important;
}
</style>