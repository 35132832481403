<template>
    <div class="k3-lm">
        <!-- 和值 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in sum_value" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 总和 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in sum" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name sum">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 跨度 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in kd" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name kd">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 组六 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in zl" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name kd">{{i.name=='杂六'?'杂':i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 三军 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in sj" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name kd">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { link } from 'fs';
    export default {
        data() {
            return {
                /**和值 */
                sum_value: [
                    {
                        ballname: "两面",
                        betclass: [
                            { title: "和值", name: "3", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "4", visible: false, static_bonus: "-", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "5", visible: false, static_bonus: "-", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "6", visible: false, static_bonus: "-", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "7", visible: false, static_bonus: "-", val: "", subname: "0_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "8", visible: false, static_bonus: "-", val: "", subname: "0_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "9", visible: false, static_bonus: "-", val: "", subname: "0_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "10", visible: false, static_bonus: "-", val: "", subname: "0_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "11", visible: false, static_bonus: "-", val: "", subname: "0_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "12", visible: false, static_bonus: "-", val: "", subname: "0_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "13", visible: false, static_bonus: "-", val: "", subname: "0_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "14", visible: false, static_bonus: "-", val: "", subname: "0_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "15", visible: false, static_bonus: "-", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "16", visible: false, static_bonus: "-", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "17", visible: false, static_bonus: "-", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "18", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**总和 */
                sum: [
                    {
                        ballname: "总和",
                        betclass: [
                            { title: "总和", name: "大", visible: false, static_bonus: "-", val: "", subname: "8_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "小", visible: false, static_bonus: "-", val: "", subname: "8_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "单", visible: false, static_bonus: "-", val: "", subname: "8_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "双", visible: false, static_bonus: "-", val: "", subname: "8_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "单大", visible: false, static_bonus: "-", val: "", subname: "8_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "单小", visible: false, static_bonus: "-", val: "", subname: "8_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "双大", visible: false, static_bonus: "-", val: "", subname: "8_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "双小", visible: false, static_bonus: "-", val: "", subname: "8_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**跨度 */
                kd: [
                    {
                        ballname: "跨度",
                        betclass: [
                            { title: "跨度", name: "跨度[1]", visible: false, static_bonus: "-", val: "", subname: "8_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", name: "跨度[2]", visible: false, static_bonus: "-", val: "", subname: "8_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", name: "跨度[3]", visible: false, static_bonus: "-", val: "", subname: "8_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", name: "跨度[4]", visible: false, static_bonus: "-", val: "", subname: "8_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "跨度", name: "跨度[5]", visible: false, static_bonus: "-", val: "", subname: "8_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**组六 */
                zl: [
                    {
                        ballname: "组六",
                        betclass: [
                            { title: "总和", name: "半顺", visible: false, static_bonus: "-", val: "", subname: "8_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "杂六", visible: false, static_bonus: "-", val: "", subname: "8_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "全顺", visible: false, static_bonus: "-", val: "", subname: "8_0_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "豹子通", visible: false, static_bonus: "-", val: "", subname: "8_0_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**三军 */
                sj: [
                    {
                        ballname: "三军",
                        betclass: [
                            { title: "三军", name: "1", visible: false, static_bonus: "-", val: "", subname: "8_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", name: "2", visible: false, static_bonus: "-", val: "", subname: "8_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", name: "3", visible: false, static_bonus: "-", val: "", subname: "8_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", name: "4", visible: false, static_bonus: "-", val: "", subname: "8_0_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", name: "5", visible: false, static_bonus: "-", val: "", subname: "8_0_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "三军", name: "6", visible: false, static_bonus: "-", val: "", subname: "8_0_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
            }
        },

        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sum_value[i].betclass[j].subname) {
                                this.sum_value[i].betclass[j].static_bonus = item.rate;
                                this.sum_value[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sum_value[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sum[i].betclass[j].subname) {
                                this.sum[i].betclass[j].static_bonus = item.rate;
                                this.sum[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sum[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.kd[i].betclass[j].subname) {
                                this.kd[i].betclass[j].static_bonus = item.rate;
                                this.kd[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.kd[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.zl[i].betclass[j].subname) {
                                this.zl[i].betclass[j].static_bonus = item.rate;
                                this.zl[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.zl[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sj[i].betclass[j].subname) {
                                this.sj[i].betclass[j].static_bonus = item.rate;
                                this.sj[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sj[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        this.sum_value[i].betclass[j].val = ""
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        this.sum[i].betclass[j].val = ""
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        this.kd[i].betclass[j].val = ""
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        this.zl[i].betclass[j].val = ""
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        this.sj[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //和值
                for (let i = 0; i < this.sum_value.length; i++) {
                    for (let j = 0; j < this.sum_value[i].betclass.length; j++) {
                        if (this.sum_value[i].betclass[j].val && !isNaN(this.sum_value[i].betclass[j].val * 1)) {
                            list.push(this.sum_value[i].betclass[j]);
                        }
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        if (this.sum[i].betclass[j].val && !isNaN(this.sum[i].betclass[j].val * 1)) {
                            list.push(this.sum[i].betclass[j]);
                        }
                    }
                }
                //跨度
                for (let i = 0; i < this.kd.length; i++) {
                    for (let j = 0; j < this.kd[i].betclass.length; j++) {
                        if (this.kd[i].betclass[j].val && !isNaN(this.kd[i].betclass[j].val * 1)) {
                            list.push(this.kd[i].betclass[j]);
                        }
                    }
                }
                //组六
                for (let i = 0; i < this.zl.length; i++) {
                    for (let j = 0; j < this.zl[i].betclass.length; j++) {
                        if (this.zl[i].betclass[j].val && !isNaN(this.zl[i].betclass[j].val * 1)) {
                            list.push(this.zl[i].betclass[j]);
                        }
                    }
                }
                //三军
                for (let i = 0; i < this.sj.length; i++) {
                    for (let j = 0; j < this.sj[i].betclass.length; j++) {
                        if (this.sj[i].betclass[j].val && !isNaN(this.sj[i].betclass[j].val * 1)) {
                            list.push(this.sj[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //和值
                    for (let j = 0; j < this.sum_value.length; j++) {
                        for (let k = 0; k < this.sum_value[j].betclass.length; k++) {
                            if (this.sum_value[j].betclass[k].title == list[i].title && this.sum_value[j].betclass[k].name == list[i].name) {
                                this.sum_value[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //总和
                    for (let j = 0; j < this.sum.length; j++) {
                        for (let k = 0; k < this.sum[j].betclass.length; k++) {
                            if (this.sum[j].betclass[k].title == list[i].title && this.sum[j].betclass[k].name == list[i].name) {
                                this.sum[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //跨度
                    for (let j = 0; j < this.kd.length; j++) {
                        for (let k = 0; k < this.kd[j].betclass.length; k++) {
                            if (this.kd[j].betclass[k].title == list[i].title && this.kd[j].betclass[k].name == list[i].name) {
                                this.kd[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //组六
                    for (let j = 0; j < this.zl.length; j++) {
                        for (let k = 0; k < this.zl[j].betclass.length; k++) {
                            if (this.zl[j].betclass[k].title == list[i].title && this.zl[j].betclass[k].name == list[i].name) {
                                this.zl[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //三军
                    for (let j = 0; j < this.sj.length; j++) {
                        for (let k = 0; k < this.sj[j].betclass.length; k++) {
                            if (this.sj[j].betclass[k].title == list[i].title && this.sj[j].betclass[k].name == list[i].name) {
                                this.sj[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .bet-name {
        width: 24px !important;
        height: 24px;
        text-align: center !important;
        line-height: 24px;
        background: #f56c6c;
        border-radius: 50%;
        padding: 0 !important;
        margin-left: 6px;
        font-weight: bold;
        color: #fff;
    }
    .sum {
        background: none;
        color: #666;
        width: 40px !important;
    }
    .kd {
        background: none;
        color: #666;
        width: 50px !important;
    }
</style>