<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-12 23:51:39
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-01 13:12:16
 * @FilePath: \pc\src\components\menuitems\changepass.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="changepass">
        <div class="ui-dialog-content" style="width: 614px; height: 144px;">
            <form style="width:614px">
                <div class="pBox" style="width: 600px;">
                    <table class="infoList" style="width:100%">
                        <tbody>
                            <tr>
                                <td align="right" width="111" class="tdbg1">原始密碼</td>
                                <td style="text-align: left">
                                    &nbsp;
                                    <input type="password" class="input" v-model="old_passwd" />&nbsp;&nbsp;請輸入原始密碼
                                </td>
                            </tr>
                            <tr>
                                <td align="right" class="tdbg1">新設密碼</td>
                                <td style="text-align: left">
                                    &nbsp;
                                    <input type="password" class="input" v-model="new_passwd" />&nbsp;&nbsp;8-20位,且必需同时包含大小写字母和数字！
                                </td>
                            </tr>
                            <tr>
                                <td align="right" class="tdbg1">確認密碼</td>
                                <td style="text-align: left">
                                    &nbsp;
                                    <input type="password" class="input" v-model="renew_passwd" />&nbsp;&nbsp;请您再一次输入新密码
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="text-align:center;">
                        <button class="btn theme-half-bg theme-text" @click="reset">重填</button>&nbsp;
                        <button class="btn theme-bg" @click="sub_change">確定修改</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                old_passwd: '',
                new_passwd: '',
                renew_passwd: '',
            }
        },
        created() {
            this.reset();
        },
        methods: {
            reset() {
                this.old_passwd = '';
                this.new_passwd = '';
                this.renew_passwd = '';
            },
            sub_change() {
                if (this.old_passwd == '') {
                    return this.$message.error('請輸入原始密碼');
                }
                if (this.new_passwd == '') {
                    return this.$message.error('請輸入新設密碼');
                }
                if (!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/.test(this.new_passwd)) {
                     return this.$message.error('新密码为8-20位，大小写字母和数字组合');
                }
                if (this.renew_passwd == '') {
                    return this.$message.error('请您再一次输入新密码');
                }
                if (this.renew_passwd !== this.new_passwd) {
                    return this.$message.error('两次新密码输入不一致');
                }
                this.axios.post(`/user/resetpwd`,
                    {
                        username: this.$store.state.username,
                        old_passwd: this.old_passwd,
                        new_passwd: this.new_passwd,
                        renew_passwd: this.renew_passwd,
                    }
                ).then(result => {
                    if (result.data.status === 1 && result.data.msg === "密码修改成功") {
                        this.$messageBox.alert('密码修改成功','提示', {
                            confirmButtonText: '确定',
                            callback: (action) => {
                                this.reset();
                                if (this.$route.name !== "Home") {
                                    this.$emit('f_change');
                                }
                            },
                        })
                    }
                });
            }
        },
    }
</script>

<style scoped>
    .ui-dialog-content {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        *zoom: 1;
        *display: inline;
        text-align: left;
    }
    .infoList {
        width: 100%;
        height: 24px;
        line-height: 24px;
        background-color: #ffffff;
        border-collapse: collapse;
        font-family: Verdana, "宋体", Arial, Sans;
        table-layout: fixed;
        margin-bottom: 5px;
    }
    .infoList td,
    .infoList1 td,
    .infoList3 td {
        border: 1px solid #ccdaea;
        text-align: center;
        line-height: 28px;
    }

    .btn,
    button.btn,
    button.btn:hover,
    button.btn:focus,
    button.btn:active {
        font-size: 12px;
        display: inline-block;
        padding: 4px 20px;
        border: none;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer;
        color: #fff;
    }
    .pBox .input {
        width: 175px;
        padding: 6px 2px;
        font-size: 12px;
        line-height: 12px;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        background: url("../../assets/BoxBg1.png") 0 -91px no-repeat;
        border: 1px solid #9eaec8;
        margin: 2px;
        font-family: Verdana, "宋体", Arial, Sans;
    }
</style>