<template>
<div class="cqssc-lm">
    <!-- 两面 -->
    <el-row type="flex">
        <el-col :span="24" v-for="item in lmian" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <div class="bet-items-one" style="height:85px;">
                <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                    <span class="bet-name">{{i.name}}</span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                    <!-- <div style="clear:both"></div> -->
                </el-col>
            </div>
        </el-col>
    </el-row>
    <br>
    <!-- 1-3区，波色 -->
    <el-row type="flex" justify="space-around" :gutter="10">
        <el-col :span="6" v-for="item in balls" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <div class="bet-items">
                <div v-for="i in item.betclass" :key="i.index">
                    <span class="bet-name">{{i.name}}</span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                </div>
            </div>
        </el-col>
    </el-row>
</div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            balls: [
                {
                    ballname: "一区",
                    betclass: [
                        { title: "一区", name: "大", visible: false, static_bonus: "", val: "", subname: "1_1_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "一区", name: "小", visible: false, static_bonus: "", val: "", subname: "1_1_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "一区", name: "单", visible: false, static_bonus: "", val: "", subname: "1_1_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "一区", name: "双", visible: false, static_bonus: "", val: "", subname: "1_1_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "二区",
                    betclass: [
                        { title: "二区", name: "大", visible: false, static_bonus: "", val: "", subname: "1_2_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "二区", name: "小", visible: false, static_bonus: "", val: "", subname: "1_2_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "二区", name: "单", visible: false, static_bonus: "", val: "", subname: "1_2_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "二区", name: "双", visible: false, static_bonus: "", val: "", subname: "1_2_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "三区",
                    betclass: [
                        { title: "三区", name: "大", visible: false, static_bonus: "", val: "", subname: "1_3_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "三区", name: "小", visible: false, static_bonus: "", val: "", subname: "1_3_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "三区", name: "单", visible: false, static_bonus: "", val: "", subname: "1_3_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "三区", name: "双", visible: false, static_bonus: "", val: "", subname: "1_3_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "波色",
                    betclass: [
                        { title: "两面", name: "红波", visible: false, static_bonus: "", val: "", subname: "1_0_10", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "绿波", visible: false, static_bonus: "", val: "", subname: "1_0_11", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "蓝波", visible: false, static_bonus: "", val: "", subname: "1_0_12", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
            ],
            /**两面 */
            lmian: [
                {
                    ballname: "两面",
                    betclass: [
                        { title: "两面", name: "大", visible: false, static_bonus: "", val: "", subname: "1_0_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "小", visible: false, static_bonus: "", val: "", subname: "1_0_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "单", visible: false, static_bonus: "", val: "", subname: "1_0_4", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "双", visible: false, static_bonus: "", val: "", subname: "1_0_5", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "大单", visible: false, static_bonus: "", val: "", subname: "1_0_6", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "大双", visible: false, static_bonus: "", val: "", subname: "1_0_7", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "小单", visible: false, static_bonus: "", val: "", subname: "1_0_8", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "小双", visible: false, static_bonus: "", val: "", subname: "1_0_9", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "极大", visible: false, static_bonus: "", val: "", subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "极小", visible: false, static_bonus: "", val: "", subname: "1_0_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "两面", name: "豹子", visible: false, static_bonus: "", val: "", subname: "1_0_13", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                }
            ]
        }
    },
    created(){
        // this.get_lose();
    },
    methods: {
        /**显示赔率等数据 */
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            //两面
            for(let i=0;i<this.lmian.length;i++){
                for(let j=0;j<this.lmian[i].betclass.length;j++){
                    this.lmian[i].betclass[j].static_bonus=static_bonus[j];
                    this.lmian[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.lmian[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
            }
                bonus_high=bonus_high.slice(11);
                static_bonus=static_bonus.slice(11);
                others=others.slice(11);
            //1-3区，波色
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].static_bonus=static_bonus[j];
                    this.balls[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.balls[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                bonus_high=bonus_high.slice(4);
                static_bonus=static_bonus.slice(4);
                others=others.slice(4);
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            //1-3区，波色
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].val=""
                }
            }
            //两面
            for(let i=0;i<this.lmian.length;i++){
                for(let j=0;j<this.lmian[i].betclass.length;j++){
                    this.lmian[i].betclass[j].val=""
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            //1-3区，波色
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    if(this.balls[i].betclass[j].val&&!isNaN(this.balls[i].betclass[j].val*1)){
                        list.push(this.balls[i].betclass[j]);
                    }
                }
            }
            //两面
            for(let i=0;i<this.lmian.length;i++){
                for(let j=0;j<this.lmian[i].betclass.length;j++){
                    if(this.lmian[i].betclass[j].val&&!isNaN(this.lmian[i].betclass[j].val*1)){
                        list.push(this.lmian[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                //1-3区，波色
                for(let j=0;j<this.balls.length;j++){
                    for(let k=0;k<this.balls[j].betclass.length;k++){
                        if(this.balls[j].betclass[k].title==list[i].title&&this.balls[j].betclass[k].name==list[i].name){
                            this.balls[j].betclass[k].val=list[i].val;
                        }
                    }
                }
                //两面
                for(let j=0;j<this.lmian.length;j++){
                    for(let k=0;k<this.lmian[j].betclass.length;k++){
                        if(this.lmian[j].betclass[k].title==list[i].title&&this.lmian[j].betclass[k].name==list[i].name){
                            this.lmian[j].betclass[k].val=list[i].val;
                        }
                    }
                }
            }
        },
    },
}
</script>

