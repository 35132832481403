<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-13 17:16:22
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-17 16:15:05
 * @FilePath: \pc\src\components\menuitems\history.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="history-open">
        <div style="margin-bottom:5px" v-if="showall&&from!='jqkj'">
            <el-select size="small" placeholder="请选择彩种" v-model="selectID" @change="change_lottery">
                <el-option v-for="(item,index) in lottery_list" :key="index" :label="item.gname" :value="item.id"></el-option>
            </el-select>
            <el-link type="warning" href="https://t1.1881kk.com" target="_blank" style="margin-left:20px">
                <el-tag type="warning" effect="dark">
                    <i class="el-icon-s-flag"></i> 开奖网
                </el-tag>
            </el-link>
            <el-button class="refs-btn" type="danger" size="small" @click="get_data(gid)">点击刷新</el-button>
        </div>
        <!-- 重庆时时彩 -->
        <div v-if="gid == 1||gid == 4|| gid == 7||gid == 10|| gid == 18|| gid == 38 || gid == 39|| gid == 44|| gid == 45|| gid == 46">
            <el-table :data="history.resdata.list" border size="small" header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" row-key="id" :expand-row-keys="expands" @row-click="clickRowHandle">
                <el-table-column property="qnum" label="期号" width="100" align="center"></el-table-column>
                <el-table-column label="开奖日期" width="150" align="center">
                    <template #default="scope">
                        <span>{{scope.row.lottery_open}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res" label="开出号码" align="center">
                    <template #default="scope">
                        <span v-for="item in scope.row.res" :key="item.index">
                            <span class="cqssc_nums" :style="{'background-color':gid == 4|| gid == 10?'#F56C6C':'#409eff'}">{{item}}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column property="sum" label="总和" align="center" width="50"></el-table-column>
                <el-table-column property="sum_big" label="大小" align="center" width="50">
                    <template #default="scope">
                        <span v-if="scope.row.sum_big=='大'" style="color:red">{{ scope.row.sum_big }}</span>
                        <span v-else style="color: #409EFF">{{ scope.row.sum_big }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="sum_single" label="单双" align="center" width="50">
                    <template #default="scope">
                        <span v-if="scope.row.sum_single=='雙'" style="color:red">{{ scope.row.sum_single }}</span>
                        <span v-else style="color: #409EFF">{{ scope.row.sum_single }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="dragon_tiger" label="龙虎" align="center" width="50">
                    <template #default="scope">
                        <span v-if="scope.row.dragon_tiger=='龙'" style="color:red">{{ scope.row.dragon_tiger }}</span>
                        <span v-else-if="scope.row.dragon_tiger=='和'" style="color:green">{{ scope.row.dragon_tiger }}</span>
                        <span v-else style="color: #409EFF">{{ scope.row.dragon_tiger }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="front" label="前三" align="center" width="50"></el-table-column>
                <el-table-column v-if="gid!= 4" property="in" label="中三" align="center" width="50"></el-table-column>
                <el-table-column v-if="gid!= 4" property="back" label="後三" align="center" width="50"></el-table-column>
                <el-table-column property="res_info[0]" align="center" width="1" type="expand">
                    <template #default="scope">
                        <div style="text-align:center">
                            <span v-for="item in scope.row.djm" :key="item.index" :style="{color:item.color}">{{item.text}}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 北京PK10 -->
        <div v-if="gid == 3|| gid == 19|| gid == 24|| gid == 28|| gid == 37|| gid == 40|| gid == 43|| gid == 47">
            <el-table :data="history.resdata.list" border size="small" header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" row-key="id" :expand-row-keys="expands" @row-click="clickRowHandle">
                <el-table-column property="qnum" label="期号" width="100" align="center"></el-table-column>
                <el-table-column label="开奖日期" width="150" align="center">
                    <template #default="scope">
                        <span>{{scope.row.lottery_open}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res" label="开出号码" align="center">
                    <template #default="scope">
                        <span class="lottery_num_pk10" v-for="item in scope.row.res" :key="item.index">
                            <span>
                                <span v-if="item==1" class="lottery_num_pk10_num1">1</span>
                                <span v-if="item==2" class="lottery_num_pk10_num2">2</span>
                                <span v-if="item==3" class="lottery_num_pk10_num3">3</span>
                                <span v-if="item==4" class="lottery_num_pk10_num4">4</span>
                                <span v-if="item==5" class="lottery_num_pk10_num5">5</span>
                                <span v-if="item==6" class="lottery_num_pk10_num6">6</span>
                                <span v-if="item==7" class="lottery_num_pk10_num7">7</span>
                                <span v-if="item==8" class="lottery_num_pk10_num8">8</span>
                                <span v-if="item==9" class="lottery_num_pk10_num9">9</span>
                                <span v-if="item==10" class="lottery_num_pk10_num10">10</span>
                            </span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column property="res_info[0]" label="冠亚总和" align="center" width="70"></el-table-column>
                <el-table-column property="res_info[2]" label="冠亚和单双" align="center" width="81">
                    <template #default="scope">
                        <span v-if="scope.row.res_info[2]=='双'" style="color:red">{{ scope.row.res_info[2] }}</span>
                        <span v-else style="color: #409EFF">{{ scope.row.res_info[2]}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res_info[1]" label="冠亚和大小" align="center" width="81">
                    <template #default="scope">
                        <span v-if="scope.row.res_info[1]=='大'" style="color:red">{{ scope.row.res_info[1] }}</span>
                        <span v-else style="color: #409EFF">{{ scope.row.res_info[1] }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res_info" label="1～5 龙虎" align="center" width="80">
                    <template #default="scope">
                        <div class="pk10_1_5">
                            <span v-if="scope.row.res_info[3]=='龙'" style="color:red">{{ scope.row.res_info[3] }}</span>
                            <span v-else-if="scope.row.dragon_tiger=='和'" style="color:green">{{ scope.row.res_info[3] }}</span>
                            <span v-else style="color: #409EFF">{{ scope.row.res_info[3] }}</span>

                            <span v-if="scope.row.res_info[4]=='龙'" style="color:red">{{ scope.row.res_info[4] }}</span>
                            <span v-else-if="scope.row.dragon_tiger=='和'" style="color:green">{{ scope.row.res_info[4] }}</span>
                            <span v-else style="color: #409EFF">{{ scope.row.res_info[4] }}</span>

                            <span v-if="scope.row.res_info[5]=='龙'" style="color:red">{{ scope.row.res_info[5] }}</span>
                            <span v-else-if="scope.row.dragon_tiger=='和'" style="color:green">{{ scope.row.res_info[5] }}</span>
                            <span v-else style="color: #409EFF">{{ scope.row.res_info[5] }}</span>

                            <span v-if="scope.row.res_info[6]=='龙'" style="color:red">{{ scope.row.res_info[6] }}</span>
                            <span v-else-if="scope.row.dragon_tiger=='和'" style="color:green">{{ scope.row.res_info[6] }}</span>
                            <span v-else style="color: #409EFF">{{ scope.row.res_info[6] }}</span>

                            <span v-if="scope.row.res_info[7]=='龙'" style="color:red">{{ scope.row.res_info[7] }}</span>
                            <span v-else-if="scope.row.dragon_tiger=='和'" style="color:green">{{ scope.row.res_info[7] }}</span>
                            <span v-else style="color: #409EFF">{{ scope.row.res_info[7] }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column property="res_info[0]" align="center" width="1" type="expand">
                    <template #default="scope">
                        <div style="text-align:center">
                            <span v-for="item in scope.row.djm" :key="item.index" :style="{color:item.color}">{{item.text}}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 六合彩 -->
        <div v-if="gid==20||gid==34||gid==48">
            <el-table :data="history.resdata.list" border size="small" header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" row-key="id" :expand-row-keys="expands" @row-click="clickRowHandle">
                <el-table-column property="qnum" label="期号" width="102" align="center"></el-table-column>
                <el-table-column label="开奖日期" width="160" align="center">
                    <template #default="scope">
                        <span>{{scope.row.lottery_open}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res" label="开出号码" align="center">
                    <template #default="scope">
                        <div class="lhc_nums" v-for="item in scope.row.res_s" :key="item.index">
                            <span class="num" :style="{'background-color':item.color}">{{item.num}}</span>
                            <span>{{item.sx}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="合计" width="80" align="center" property="res_info[0]"></el-table-column>
                <el-table-column label="单双" width="80" align="center" property="res_info[1]"></el-table-column>
                <el-table-column label="大小" width="80" align="center" property="res_info[2]"></el-table-column>
                <el-table-column property="djm" align="center" width="1" type="expand">
                    <template #default="scope">
                        <div class="item-others-txid">
                            <div class="tl" v-for="tl in scope.row.djm" :key="tl.index">
                                <span v-for="t in tl" :key="t.index" :style="{color:t.color}">{{t.text}}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- pc蛋蛋，加拿大28，幸运28 -->
        <div v-if="gid==22||gid==23">
            <el-table :data="history.resdata.list" border size="small" header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="qnum" label="期号" width="100" align="center"></el-table-column>
                <el-table-column label="开奖日期" width="180" align="center">
                    <template #default="scope">
                        <span>{{scope.row.lottery_open}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res" label="开出号码" align="center">
                    <!-- <template #header>
                        <el-button-group>
                            <el-button size="small" @click="showType='显示号码'">显示号码</el-button>
                            <el-button size="small" @click="showType='显示大小'">显示大小</el-button>
                            <el-button size="small" @click="showType='显示单双'">显示单双</el-button>
                        </el-button-group>
                    </template>-->
                    <template #default="scope">
                        <span>
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[0].color}">{{scope.row.res[0].num}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[1].color}">{{scope.row.res[1].num}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[2].color}">{{scope.row.res[2].num}}</span>=
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.sum.color}">{{scope.row.sum.num}}</span>
                        </span>
                        <!-- <span v-if="showType=='显示号码'">
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[0].color}">{{scope.row.res[0].num}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[1].color}">{{scope.row.res[1].num}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[2].color}">{{scope.row.res[2].num}}</span>=
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.sum.color}">{{scope.row.sum.num}}</span>
                        </span>
                        <span v-if="showType=='显示大小'">
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[0].num>=5?'#F56C6C':'#909399'}">{{scope.row.res[0].num>=5?'大':'小'}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[1].num>=5?'#F56C6C':'#909399'}">{{scope.row.res[1].num>=5?'大':'小'}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[2].num>=5?'#F56C6C':'#909399'}">{{scope.row.res[2].num>=5?'大':'小'}}</span>=
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.sum.num>=14?'#F56C6C':'#909399'}">{{scope.row.sum.num>=14?'大':'小'}}</span>
                        </span>
                        <span v-if="showType=='显示单双'">
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[0].num%2==0?'#409eff':'#909399'}">{{scope.row.res[0].num%2==0?'双':'单'}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[1].num%2==0?'#409eff':'#909399'}">{{scope.row.res[1].num%2==0?'双':'单'}}</span>+
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.res[2].num%2==0?'#409eff':'#909399'}">{{scope.row.res[2].num%2==0?'双':'单'}}</span>=
                            <span class="betclassname_old_pcdd" :style="{'background-color':scope.row.sum.num%2==0?'#409eff':'#909399'}">{{scope.row.sum.num%2==0?'双':'单'}}</span>
                        </span>-->
                    </template>
                </el-table-column>
                <el-table-column label="大小" width="70" align="center" property="sum_big"></el-table-column>
                <el-table-column label="单双" width="70" align="center" property="sum_single"></el-table-column>
                <el-table-column label="波色" width="70" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.sum.color=='#F56C6C'" :style="{'color':'#F56C6C'}">紅波</span>
                        <span v-else-if="scope.row.sum.color=='#67C23A'" :style="{'color':'#67C23A'}">绿波</span>
                        <span v-else-if="scope.row.sum.color=='#409EFF'" :style="{'color':'#409EFF'}">蓝波</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="极大小" width="70" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.sum.num<=4">极小</span>
                        <span v-else-if="scope.row.sum.num>=23">极大</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="豹子" width="66" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.res[0].num==scope.row.res[1].num&&scope.row.res[0].num==scope.row.res[2].num">豹子</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 快三 -->
        <div v-if="gid==6||gid==29||gid==30||gid==31||gid==32||gid==36">
            <el-table :data="history.resdata.list" border size="small" header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" row-key="id" :expand-row-keys="expands" @row-click="clickRowHandle">
                <el-table-column property="qnum" label="期号" width="80" align="center"></el-table-column>
                <el-table-column label="开奖日期" width="180" align="center">
                    <template #default="scope">
                        <span>{{scope.row.lottery_open}}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res" label="开出号码" align="center">
                    <template #default="scope">
                        <span v-for="item in scope.row.res" :key="item.index">
                            <span class="cqssc_nums" style="background:#F56C6C;color:#fff;">{{item}}</span>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column property="big" label="总和大小" align="center" width="100"></el-table-column>
                <el-table-column property label="总和" align="center" width="100">
                    <template #default="scope">
                        <span style="color:red">{{ scope.row.res_info[0] }}</span>
                    </template>
                </el-table-column>
                <el-table-column property label="总和单双" align="center" width="100">
                    <template #default="scope">
                        <span>{{ scope.row.res_info[1] }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="res_info[0]" align="center" width="1" type="expand">
                    <template #default="scope">
                        <div style="text-align:center">
                            <span v-for="item in scope.row.djm" :key="item.index" :style="{color:item.color}">{{item.text}}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页 -->
        <div style="margin:0 auto" v-if="showall&&from!='jqkj'">
            <el-pagination small @current-change="historyHandleCurrentChange" :current-page="page" :pager-count="5" :total="history.resdata.nums*1" layout="total,prev,pager,next,jumper" prev-text="上一页" next-text="下一页"></el-pagination>
        </div>
    </div>
</template>

<script>
    import { pcddNum } from '../../plugin/pcddNum';
    import { get_lhc_sx } from "../../plugin/sx.js";
    import { lhcNum } from "../../plugin/lhcNum";
    import { duijiangma } from "../../plugin/duijiangma";
    export default {
        props: ['from'],
        data() {
            return {
                /**彩种列表 */
                lottery_list: [],
                logs: [],
                /**历史开奖 */
                history: {
                    /**控制历史开奖dialog的显示 */
                    LotteryVisible: false,
                    /**默认选中的彩种，这里应该和主页里彩种一样 */
                    default: "",
                    /**彩种列表 */
                    list: [],
                    /**获取当前所选中的彩种的所有数据，后台接口获取 */
                    resdata: {},
                },
                /**所选种的彩种的ID */
                selectID: '',
                page: 1,
                date: {
                    start: "",
                    end: "",
                },
                showType: '显示号码',
                showall: true,
                gid: '',
                expands: [],
            }
        },
        methods: {
            clickRowHandle(row, column, event) {
                if (this.expands.includes(row.id)) {
                    this.expands = this.expands.filter(val => val !== row.id);
                } else {
                    this.expands.push(row.id);
                }
            },
            /**历史开奖彩种切换 ，e为当前所切换的彩种ID*/
            change_lottery(e) {
                this.page = 1;
                this.get_data(e);
            },
            /**历史开奖页码切换，参数val为选中的页码*/
            historyHandleCurrentChange(val) {
                this.page = val;
                this.get_data(this.gid);
            },
            get_data(e) {
                this.axios.post("/betresult/index",
                    {
                        gid: e,
                        page: this.page,
                        start_time: this.$filters.time(new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30)).getTime() / 1000),
                        end_time: this.$filters.time((new Date().getTime()) / 1000),
                        limit:20,
                    }
                ).then(result => {
                    // console.log(result.data);
                    if (result.data.status && result.data.msg === "请求成功！") {
                        this.history.resdata = result.data.res;
                        let list = result.data.res.list;
                        //处理是pc蛋蛋的结果
                        if (result.data.res.gname.id == 22 || result.data.res.gname.id == 23) {
                            for (let i = 0; i < list.length; i++) {
                                for (let j = 0; j < list[i].res.length; j++) {
                                    list[i].res[j] = pcddNum(list[i].res[j])
                                }
                                list[i].sum = pcddNum(list[i].sum);
                            }
                        }
                        //处理六合彩
                        if (result.data.res.gname.id == 20 || result.data.res.gname.id == 34 || result.data.res.gname.id == 48) {
                            for (let i = 0; i < list.length; i++) {
                                let arr = [];
                                let lottery_open = list[i].lottery_open.split(' ')[0];
                                for (let j = 0; j < list[i].res.length; j++) {
                                    arr.push(lhcNum(list[i].res[j], lottery_open))
                                }
                                list[i].res_s = get_lhc_sx("开奖", arr);
                            }
                        }
                        duijiangma(list, e);
                        this.history.resdata.list = list
                        this.gid = e;
                    }
                });
            },
            getdata(data, showall = true) {
                // console.log(data);
                this.showall = showall;
                this.lottery_list = [];
                let game_list = this.$store.state.game_list;
                for (let i = 0; i < game_list.length; i++) {
                    if (game_list[i].open_status == 1) {
                        this.lottery_list.push(game_list[i]);
                    }
                }
                this.selectID = this.$store.state.checked_gid;
                this.history.resdata = data;
                let list = this.history.resdata.list;
                //处理是pc蛋蛋的结果
                if (this.selectID == 22 || this.selectID == 23) {
                    for (let i = 0; i < list.length; i++) {
                        for (let j = 0; j < list[i].res.length; j++) {
                            list[i].res[j] = pcddNum(list[i].res[j])
                        }
                        list[i].sum = pcddNum(list[i].sum);
                    }
                }
                //处理六合彩
                if (this.selectID == 20 || this.selectID == 34 || this.selectID == 48) {
                    for (let i = 0; i < list.length; i++) {
                        let arr = [];
                        let lottery_open = list[i].lottery_open.split(' ')[0];
                        for (let j = 0; j < list[i].res.length; j++) {
                            arr.push(lhcNum(list[i].res[j], lottery_open))
                        }
                        list[i].res_s = get_lhc_sx("开奖", arr);
                    }
                }
                duijiangma(list, this.selectID);
                this.history.resdata.list = list
                this.gid = this.selectID;
            }
        },
    }
</script>

<style scoped>
    .history-open {
        overflow-y: auto;
    }
    .refs-btn {
        float: right;
    }
    .bottom-text {
        float: right;
        margin-top: 6px;
    }
    /* 分页 */
    .jumper_btn {
        background: #409eff;
        color: #fff;
    }

    .jumper_btn:hover {
        background: #66b1ff;
        border-color: #66b1ff;
        color: #fff;
    }

    /* 彩种 */
    .cqssc_nums {
        display: inline-block;
        color: #fff;
        font-size: 12px;
        line-height: 24px;
        font-weight: bold;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin: 0;
        margin-left: 2px;
        text-align: center;
    }

    .pk10_1_5 span {
        display: inline-block;
    }

    .lottery_num_pk10 span {
        display: inline-block;
        height: 24px;
        width: 24px;
        line-height: 24px;
        margin: 0 1px 0 0;
        text-align: center;
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        font-family: Arial, Helvetica, sans-serif;
        border-radius: 4px;
        text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    }

    .lottery_num_pk10_num1 {
        background: #ff0;
    }

    .lottery_num_pk10_num2 {
        background: #0089ff;
    }

    .lottery_num_pk10_num3 {
        background: #4d4d4d;
    }

    .lottery_num_pk10_num4 {
        background: #ff7300;
    }

    .lottery_num_pk10_num5 {
        background: #81ffff;
    }

    .lottery_num_pk10_num6 {
        background: #5200ff;
    }

    .lottery_num_pk10_num7 {
        background: #e3e3e3;
    }

    .lottery_num_pk10_num8 {
        background: #f00;
    }

    .lottery_num_pk10_num9 {
        background: #760000;
    }

    .lottery_num_pk10_num10 {
        background: #28c200;
    }
    .lhc_nums {
        display: inline-block;
        text-align: center;
        padding: 0px !important;
        margin: 0;
    }
    .lhc_nums .num {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        margin: 0 2px;
        text-indent: 0;
    }
    .betclassname_old_pcdd {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin: 0 6px;
        font-weight: bold;
    }
    .item-others-txid {
        word-break: break-all;
        text-align: left;
        padding: 0 5px;
    }
    .item-others-txid .tl {
        border-bottom: 1px solid #eee;
    }
    .item-others-txid .tl:last-child {
        border: 0;
    }
</style>
