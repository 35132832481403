<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-14 00:11:24
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-11 18:02:11
 * @FilePath: \pc\src\components\menuitems\gameLobby.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="game-lobby">
        <el-row :gutter="10">
            <el-col class="game-item theme-border-half" :span="12" v-for="(item,i) of gamelist" :key="item.id">
                <div class="item-img">
                    <img class="logo" :src="item.img" />
                </div>
                <div class="item-status theme-border-half">
                    <span class="status" :style="{'background-color':item.color}">{{item.is_open}}</span>
                </div>
                <div class="item-name theme-border-half" @click="choose_lottery(item)">{{item.gname}}</div>
                <div class="item-time">
                    <timeDown :ref="`timeDown${i}`" :gid="item.id" @get_item_open_status="get_item_open_status"></timeDown>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import timeDown from "./timeDown.vue";
    export default {
        components: { timeDown },
        data() {
            return {
                gamelist: [],
                themeColorLight: '',
            }
        },
        created() {
            this.themeColorLight = this.$store.state.themeColorLight;
        },
        beforeDestroy() {
            this.clear_timer();
        },
        methods: {
            /**关闭时，停止所有彩种的倒计时*/
            clear_timer() {
                for (let i = 0; i < this.gamelist.length; i++) {
                    this.$refs[`timeDown${i}`][0].clear_timer();
                }
            },
            /**选择彩种 */
            choose_lottery(game) {
                this.$emit("change_game", game.id);
                this.clear_timer();
            },
            /**获取彩种列表 */
            getlist(game_list) {
                let gamelist = game_list;
                let list = [];
                for (let i = 0; i < gamelist.length; i++) {
                    if (gamelist[i].open_status == 1) {
                        list.push(gamelist[i]);
                    }
                }
                gamelist = list;
                for (let i = 0; i < gamelist.length; i++) {
                    gamelist[i].is_open = '停';
                    gamelist[i].color = 'gray';
                    gamelist[i].img = require(`../../assets/${gamelist[i].gname}.png`);
                }
                this.gamelist = gamelist;
                this.$nextTick(() => {
                    for (let i = 0; i < this.gamelist.length; i++) {
                        this.$refs[`timeDown${i}`][0].clear = false;
                        this.$refs[`timeDown${i}`][0].get_lottery_info();
                    }
                })
            },
            get_item_open_status(text, color, gid) {
                for (let game of this.gamelist) {
                    if (game.id == gid) {
                        game.is_open = text;
                        game.color = color;
                        return;
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .game-item {
        border: 1px solid;
        border-bottom: 0;
    }
    .game-item:nth-child(4n) {
        background-color: v-bind(themeColorLight);
    }
    .game-item:nth-child(4n-1) {
        background-color: v-bind(themeColorLight);
    }
    .game-item:last-child,
    .game-item:nth-last-child(2) {
        border-bottom: 1px solid;
    }
    .item-img,
    .item-status,
    .item-name,
    .item-time {
        display: inline-block;
        height: 80px;
        line-height: 80px;
        vertical-align: middle;
        text-align: center;
    }
    .logo {
        width: 72px;
    }
    .item-img {
        width: 100px;
    }
    .item-status {
        width: 50px;
        border-left: 1px solid;
        border-right: 1px solid;
    }
    .item-name {
        width: 140px;
        border-right: 1px solid;
    }
    .item-name:hover {
        cursor: pointer;
        color: #409eff;
    }
    .item-time {
        width: 180px;
    }
    .status {
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 22px;
        color: #fff;
        border-radius: 4px;
        font-weight: bold;
    }
</style>
