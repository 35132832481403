/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-21 21:22:41
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-26 16:52:45
 * @FilePath: \pc\src\plugin\postFormData.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**处理pc蛋蛋的号码，包括加拿大28.幸运28，生成对应的颜色 */
export function postObj(list, lottery) {
    // console.log(list, lottery)
    let postObj = {
        game_id: [],//选中的彩种ID
        periods_id: [],//期号ID，stroe.state里面
        qnum: [],//所投期号
        level_two: [],//subname
        game_multiple: [],//每组金额
        place: [],//第几球，总和龙虎为1
        balance: [],
        bet_info: [],
        /**md5(game_id+"0NhGGgggiK3a7O7jEeoZ" +$two + 'periods_id' + 'qnum' +'user_id'+'place'+'bet_mode'+'game_multiple'+'list-zxfd'+'active_rebate_level'+'bet_count'+bet_infostr) */
        b_sign: [],
        //md5(万位+大+$value['place+'0NhGGgggiK3a7O7jEeoZ'+用户ID)
        c_sign: [],
    };
    let gid = lottery.id;
    let playing = lottery.activeName;
    //cqssc---------------------------------------------
    // console.log(list)
    if (gid == 1 || gid == 4 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
        for (let i = 0; i < list.length; i++) {
            postObj.game_id.push(gid);
            postObj.periods_id.push(lottery.new_qnum_id);
            postObj.qnum.push(lottery.new_qnum);
            if (playing === "第一球") {
                !isNaN(list[i].name * 1) ? list[i].subname = `1_0_${list[i].name}` : '';
                list[i].name === '大' ? list[i].subname = '0_0_0' : '';
                list[i].name === '小' ? list[i].subname = '0_0_1' : '';
                list[i].name === '单' ? list[i].subname = '0_0_2' : '';
                list[i].name === '双' ? list[i].subname = '0_0_3' : '';
            }
            if (playing === "第二球") {
                (list[i].name * 1) < 10 ? list[i].subname = `1_1_${list[i].name}` : '';
                list[i].name === '大' ? list[i].subname = '0_1_0' : '';
                list[i].name === '小' ? list[i].subname = '0_1_1' : '';
                list[i].name === '单' ? list[i].subname = '0_1_2' : '';
                list[i].name === '双' ? list[i].subname = '0_1_3' : '';
            }
            if (playing === "第三球") {
                !isNaN(list[i].name * 1) ? list[i].subname = `1_2_${list[i].name}` : '';
                list[i].name === '大' ? list[i].subname = '0_2_0' : '';
                list[i].name === '小' ? list[i].subname = '0_2_1' : '';
                list[i].name === '单' ? list[i].subname = '0_2_2' : '';
                list[i].name === '双' ? list[i].subname = '0_2_3' : '';
            }
            if (playing === "第四球") {
                !isNaN(list[i].name * 1) ? list[i].subname = `1_3_${list[i].name}` : '';
                list[i].name === '大' ? list[i].subname = '0_3_0' : '';
                list[i].name === '小' ? list[i].subname = '0_3_1' : '';
                list[i].name === '单' ? list[i].subname = '0_3_2' : '';
                list[i].name === '双' ? list[i].subname = '0_3_3' : '';
            }
            if (playing === "第五球") {
                !isNaN(list[i].name * 1) ? list[i].subname = `1_4_${list[i].name}` : '';
                list[i].name === '大' ? list[i].subname = '0_4_0' : '';
                list[i].name === '小' ? list[i].subname = '0_4_1' : '';
                list[i].name === '单' ? list[i].subname = '0_4_2' : '';
                list[i].name === '双' ? list[i].subname = '0_4_3' : '';
            }
            postObj.level_two.push(list[i].subname);
            postObj.game_multiple.push(list[i].val);
            if (list[i].name === '龙' || list[i].name === '虎' || list[i].name === '和') {
                list[i].title = list[i].bet_title;
                postObj.place.push(1);
            }
            if ((gid == 4 || gid == 10) && (list[i].title.includes('和值') || list[i].title.includes('直选') || list[i].title.includes('组三') || list[i].title.includes('组六') || list[i].title.includes('一码不定位') || list[i].title.includes('二码不定位'))) {
                postObj.place.push(1);
            }
            list[i].title === "第一球" || list[i].title === "前三" || list[i].title === "中三" || list[i].title === "后三" ? postObj.place.push(1) : "";
            list[i].title === "第二球" ? postObj.place.push(2) : "";
            list[i].title === "第三球" ? postObj.place.push(3) : "";
            list[i].title === "第四球" ? postObj.place.push(4) : "";
            list[i].title === "第五球" ? postObj.place.push(5) : "";
            list[i].title === "总和" ? postObj.place.push(1) : "";
            postObj.balance.push(list[i].val);
            postObj.bet_info.push(JSON.stringify({
                betting: [
                    {
                        title: list[i].title,
                        content: [
                            {
                                bet_name: list[i].name,
                                money: list[i].val,
                                bonus: list[i].static_bonus
                            }
                        ]
                    }
                ]
            }));
        }
    }
    // console.log(list)
    //bjpk10--------------------------------------------
    if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
        for (let i = 0; i < list.length; i++) {
            postObj.game_id.push(gid);
            postObj.periods_id.push(lottery.new_qnum_id);
            postObj.qnum.push(lottery.new_qnum);
            postObj.level_two.push(list[i].subname);
            postObj.game_multiple.push(list[i].val);
            list[i].title == "冠军" || list[i].title == "冠亚和值" ? postObj.place.push(1) : "";
            list[i].title == "亚军" ? postObj.place.push(2) : "";
            list[i].title == "第三名" ? postObj.place.push(3) : "";
            list[i].title == "第四名" ? postObj.place.push(4) : "";
            list[i].title == "第五名" ? postObj.place.push(5) : "";
            list[i].title == "第六名" ? postObj.place.push(6) : "";
            list[i].title == "第七名" ? postObj.place.push(7) : "";
            list[i].title == "第八名" ? postObj.place.push(8) : "";
            list[i].title == "第九名" ? postObj.place.push(9) : "";
            list[i].title == "第十名" ? postObj.place.push(10) : "";
            postObj.balance.push(list[i].val);
            // console.log(lottery);
            if (typeof (list[i].name * 1) == "number" && !isNaN(list[i].name * 1) && list[i].title != "冠亚和值") {
                postObj.bet_info.push(list[i].name);
            } else {
                postObj.bet_info.push(JSON.stringify({
                    betting: [
                        {
                            title: list[i].title,
                            content: [
                                {
                                    bet_name: list[i].name,
                                    money: list[i].val,
                                    bonus: list[i].static_bonus
                                }
                            ]
                        }
                    ]
                }));
            }
        }
    }
    //pcdd can28 xy28
    if (gid == 22 || gid == 23) {
        for (let i = 0; i < list.length; i++) {
            postObj.game_id.push(gid);
            postObj.periods_id.push(lottery.new_qnum_id);
            postObj.qnum.push(lottery.new_qnum);
            postObj.level_two.push(list[i].subname);
            postObj.game_multiple.push(list[i].val);
            postObj.place.push(1);
            postObj.balance.push(list[i].val);
            postObj.bet_info.push(JSON.stringify({
                betting: [
                    {
                        title: list[i].title,
                        content: [
                            {
                                bet_name: list[i].name,
                                money: list[i].val,
                                bonus: list[i].static_bonus
                            }
                        ]
                    }
                ]
            }));
        }
    }
    //lhc jslhc
    if (gid == 20 || gid == 34 || gid == 48) {
        postObj.ball_min = [];
        for (let i = 0; i < list.length; i++) {
            postObj.game_id.push(gid);
            postObj.periods_id.push(lottery.new_qnum_id);
            postObj.qnum.push(lottery.new_qnum);
            postObj.level_two.push(list[i].subname);
            postObj.game_multiple.push(list[i].val);
            switch (list[i].title) {
                case '正码': postObj.place.push(2); break;
                case '正特码一': postObj.place.push(3); break;
                case '正特码二': postObj.place.push(4); break;
                case '正特码三': postObj.place.push(5); break;
                case '正特码四': postObj.place.push(6); break;
                case '正特码五': postObj.place.push(7); break;
                case '正特码六': postObj.place.push(8); break;
                case '三中二': postObj.place.push(9); break;
                case '三全中': postObj.place.push(10); break;
                case '二全中': postObj.place.push(11); break;
                case '二中特': postObj.place.push(12); break;
                case '特串': postObj.place.push(13); break;
                case '四中一': postObj.place.push(25); break;
                case '五不中': postObj.place.push(14); break;
                case '六不中': postObj.place.push(15); break;
                case '七不中': postObj.place.push(16); break;
                case '八不中': postObj.place.push(17); break;
                case '九不中': postObj.place.push(18); break;
                case '十不中': postObj.place.push(19); break;
                case '十一不中': postObj.place.push(20); break;
                case '十二不中': postObj.place.push(21); break;
                case '特码B': postObj.place.push(24); break;
                default: postObj.place.push(1); break;
            }
            if (playing.includes('连码') || playing.includes('自选不中') || playing.includes('连肖') || playing.includes('尾连')) {
                postObj.ball_min.push(list[i].ball_min);
            }
            if (list[i].title.includes('对碰')) {
                postObj.bet_count = [];
                postObj.bet_count.push(list[i].bet_count);
                postObj.place[0] = list[i].subname == '5_2_0' ? 11 : list[i].subname == '5_3_0' ? 12 : list[i].subname == '5_4_0' ? 13 : '';
            }
            postObj.balance.push(list[i].val);
            if (playing == "色波") {
                postObj.bet_info.push(JSON.stringify({
                    betting: [
                        {
                            title: list[i].name,
                            content: [
                                {
                                    bet_name: list[i].betclass_nums.toString(),
                                    money: list[i].val,
                                    bonus: list[i].static_bonus
                                }
                            ]
                        }
                    ]
                }));
            }
            else if (playing == "特肖" || playing == "一肖" || playing == "一肖尾数") {
                let bet_name = [];
                for (let j = 0; j < list[i].betclass_nums.length; j++) {
                    bet_name.push(list[i].betclass_nums[j].num);
                }
                list[i].other = bet_name;
                postObj.bet_info.push(JSON.stringify({
                    betting: [
                        {
                            title: list[i].name,
                            content: [
                                {
                                    bet_name: bet_name.toString(),
                                    money: list[i].val,
                                    bonus: list[i].static_bonus
                                }
                            ]
                        }
                    ]
                }));
            }
            else {
                postObj.bet_info.push(JSON.stringify({
                    betting: [
                        {
                            title: list[i].title,
                            content: [
                                {
                                    bet_name: list[i].name,
                                    money: list[i].val,
                                    bonus: list[i].static_bonus
                                }
                            ]
                        }
                    ]
                }));
            }
        }
    }
    //k3
    if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
        for (let i = 0; i < list.length; i++) {
            postObj.game_id.push(gid);
            postObj.periods_id.push(lottery.new_qnum_id);
            postObj.qnum.push(lottery.new_qnum);
            postObj.level_two.push(list[i].subname);
            postObj.balance.push(list[i].val);
            // postObj.place.push(1);
            if (list[i].title == '三同号通选' || list[i].title == '三同号单选' || list[i].title == '三不同号' || list[i].title == '三连号通选' || list[i].title == '二同号复选' || list[i].title == '二同号单选' || list[i].title == '二不同号') {
                postObj.place.push('');
            } else {
                postObj.place.push(1);
            }
            if (list[i].bet_count != undefined) {
                postObj.bet_count = [];
                postObj.bet_count.push(list[i].bet_count);
                postObj.game_multiple.push(list[i].val / list[i].bet_count);
            } else {
                postObj.game_multiple.push(list[i].val);
            }
            if (list[i].title === '和值') {
                postObj.bet_info.push(list[i].title + list[i].name);
            } else if (list[i].title === '三军') {
                postObj.bet_info.push(list[i].name);
            } else if (list[i].title === '红黑码') {
                postObj.bet_info.push(list[i].name.split('[')[1].slice(0, -1));
            } else if (list[i].title == '二同号单选') {
                let nums = list[i].name.split('');
                let name = '';
                if (nums[0] == nums[1]) {
                    name = `${nums[0]}${nums[1]}|${nums[2]}`
                }
                if (nums[1] == nums[2]) {
                    name = `${nums[1]}${nums[2]}|${nums[0]}`
                }
                postObj.bet_info.push(name);
            } else if (list[i].title == '二不同号' || list[i].title == '三不同号') {
                postObj.bet_info.push(list[i].name.split('').toString());
            }
            else if (list[i].title == '三同号通选' || list[i].title == '三同号单选' || list[i].title == '三连号通选' || list[i].title == '二同号复选' || list[i].title == '二同号单选') {
                postObj.bet_info.push(list[i].name);
            } else {
                postObj.bet_info.push(JSON.stringify({
                    betting: [
                        {
                            title: list[i].title,
                            content: [
                                {
                                    bet_name: list[i].name,
                                    money: list[i].val,
                                    bonus: list[i].static_bonus
                                }
                            ]
                        }
                    ]
                }));
            }
        }
    }
    // console.log(postObj);
    return postObj;
}