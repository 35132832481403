<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-28 23:43:46
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-08 15:05:12
 * @FilePath: \pc\src\views\Login.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="login">
        <div class="loginbox">
            <form id="form_login">
                <div class="logintitle">WELCOME</div>
                <div class="shenfen">
                    <img src="../assets/member.png" />
                    <input type="hidden" />
                    <input type="hidden" />
                    <input type="hidden" />
                </div>
                <div class="usemsg">
                    <div class="user">
                        <div class="ueser1">
                            <img src="../assets/user.png" />
                        </div>
                        <div class="user2">
                            <input type="text" id="user" name="user" tabindex="1" autocomplete="off" maxlength="16" placeholder="請輸入用戶名" v-model="username" />
                        </div>
                    </div>
                    <div class="user">
                        <div class="ueser1">
                            <img src="../assets//pwd.png" />
                        </div>
                        <div class="user2">
                            <input type="password" id="pass" name="pass" tabindex="2" autocomplete="off" maxlength="16" placeholder="請輸入密碼" v-model="password" />
                        </div>
                    </div>
                    <div class="user" v-show="error_times>=3">
                        <div class="ueser1">
                            <img src="../assets/code.png" />
                        </div>
                        <div class="code1">
                            <input type="text" id="code" name="code" tabindex="3" autocomplete="off" maxlength="4" placeholder="請輸入驗證碼" v-model="checkcode" />
                        </div>
                        <div class="code2">
                            <img id="pic_code" :src="codeimg" align="middle" style="height:28px;width:85px;cursor:pointer;" title="點擊更換驗證碼" @click="getcode" />
                        </div>
                    </div>
                </div>
                <a class="loginbtn">
                    <button type="button" id="login_btn" @click="login">登 錄</button>
                </a>
            </form>
        </div>
        <el-dialog v-model="resetPass_show" top="10%" width="630px" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">您的账户为初次登录或密码已重置，安全起见，请设定新密码！</span>
            </template>
            <repass ref="changepass" @f_change="f_change" />
        </el-dialog>
    </div>
</template>

<script>
    import repass from '../components/menuitems/changepass.vue'
    export default {
        components: { repass },
        data() {
            return {
                username: "",
                password: "",
                checkcode: "",
                platform: 'pc',
                year: '',
                resetPass_show: false,
                error_times: 0,
                codeimg: '',
            }
        },
        created() {
            this.year = (new Date()).getFullYear();
            if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i))) {
                this.platform = 'mobile';
            } else {
                this.platform = 'pc';
            }
        },
        methods: {
            f_change() {
                this.resetPass_show = false;
                // console.log(1);
            },
            login() {
                if (this.username == '') {
                    return this.$message.error('請輸入您的用戶名');
                }
                if (this.password == '') {
                    return this.$message.error('請輸入您的密碼');
                }
                if (this.error_times >= 3 && this.checkcode == '') {
                    return this.$message.error('請輸入驗證碼');
                }
                this.axios.post("/login/login_post",
                    {
                        username: this.username,
                        password: this.password,
                        platform: this.platform,
                        checkcode: this.checkcode,
                        token:this.$store.state.logintoken,
                    }
                ).then(result => {
                    let userinfo = result.data;
                    // console.log(userinfo)
                    this.$store.state.userinfo = userinfo.res;
                    // console.log(this.$store.state.userinfo);
                    if (userinfo.status && userinfo.msg === "新密码首次登录,需重置密码!") {
                        // console.log(this.form.user);
                        this.$store.state.username = this.username;
                        this.$store.state.url_query_info = "user=" + result.data.res.user_id + "&token=" + result.data.res.user_id.token;
                        this.$alert("新密码首次登录，需要重置密码", "安全提示", {
                            confirmButtonText: "确定",
                            type: "success",
                            callback: action => {
                                this.resetPass_show = true;
                            }
                        });
                    } else if (userinfo.status && userinfo.msg === "登录成功") {
                        this.$store.state.username = this.username;
                        this.$store.state.isLogin = true;
                        this.$store.state.uid = userinfo.res.user_id;
                        // console.log(1111)
                        sessionStorage.setItem(
                            "islogin",
                            JSON.stringify(this.$store.state.isLogin)
                        );
                        this.$store.state.url_query_info = "user=" + this.$store.state.userinfo.user_id + "&token=" + this.$store.state.userinfo.token;
                        //获取网站名称
                        this.axios.get(`/login/get_web_info?user=${this.$store.state.userinfo.user_id}&token=${this.$store.state.userinfo.token}`).then(result => {
                            if (result.data.status == 1 && result.data.msg.web_name != '') {
                                this.$store.state.web_name = result.data.msg.web_name;
                            }
                            this.$router.replace("/notice");
                        })
                    } else if (userinfo.msg === "密码错误") {
                        this.error_times += 1;
                        if (this.error_times >= 3) {
                            this.getcode();
                        }
                    }
                    else if (userinfo.msg === "请输入正确的验证码") {
                        this.error_times = 3;
                        this.getcode();
                    }
                })
            },
            getcode() {
                this.axios.get(`/login/vcode?token=${this.$store.state.logintoken}`, {
                    responseType: "arraybuffer"
                }).then(result => {
                    if (true) {
                        const bufferUrl = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                        this.codeimg = 'data:image/png;base64,' + bufferUrl;
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .login {
        background-image: url("../assets/user_bg.png");
        background-repeat: repeat-x;
        overflow: hidden;
    }
    .loginbox {
        background: #eee;
        width: 645px;
        background: #fff;
        margin: 160px auto;
        overflow: hidden;
        border-radius: 13px;
        box-shadow: 1px 1px 20px 1px #646464;
    }
    .logintitle {
        color: #093;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        margin: 20px 0;
        overflow: hidden;
    }
    .shenfen {
        text-align: center;
    }
    .shenfen img {
        width: 68%;
    }
    .usemsg {
        width: 52%;
        margin: 0 auto;
        overflow: hidden;
    }
    .user {
        overflow: hidden;
        width: 90%;
        margin: 10px auto;
        background: #edf3eb;
        padding: 4px;
        border: 1px solid #fff;
        border-radius: 5px;
    }
    .ueser1 {
        float: left;
        border-right: 1px solid #ddd;
        width: 10%;
        text-align: center;
    }
    .ueser1 img {
        vertical-align: middle;
    }
    .user2 {
        float: left;
        width: 80%;
    }
    .user2 input {
        width: 96%;
        float: right;
        height: 28px;
        line-height: 28px;
        border: none;
        background: no-repeat;
        outline: 0;
        font-size: 16px;
        font-family: sans-serif;
    }
    .code1 {
        float: left;
        width: 54%;
    }
    .code1 input {
        width: 93%;
        float: right;
        height: 28px;
        line-height: 28px;
        border: none;
        background: 0 0;
        outline: 0;
        font-size: 16px;
        font-family: sans-serif;
    }
    .code2 {
        float: left;
        width: 35%;
        text-align: right;
    }
    .code2 img {
        width: 85px;
        height: 28px;
        vertical-align: middle;
    }
    .loginbtn {
        display: block;
        text-align: center;
        margin: 30px;
    }
    .loginbtn button {
        width: 316px;
        height: 38px;
        line-height: 38px;
        float: center;
        background: #093;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        font-family: "Microsoft YaHei";
        cursor: pointer;
        border: none;
    }
</style>