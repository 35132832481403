<template>
    <div class="zma1_6">
        <el-row type="flex" :gutter="1">
            <el-col :span="4" v-for="item in zma1" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-row>
                            <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                                <span class="bet-name" :style="{'color':i.name=='红波'?'#f56c6c':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#666'}">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="4" v-for="item in zma2" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-row>
                            <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                                <span class="bet-name" :style="{'color':i.name=='红波'?'#f56c6c':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#666'}">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="4" v-for="item in zma3" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-row>
                            <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                                <span class="bet-name" :style="{'color':i.name=='红波'?'#f56c6c':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#666'}">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="4" v-for="item in zma4" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-row>
                            <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                                <span class="bet-name" :style="{'color':i.name=='红波'?'#f56c6c':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#666'}">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="4" v-for="item in zma5" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-row>
                            <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                                <span class="bet-name" :style="{'color':i.name=='红波'?'#f56c6c':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#666'}">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="4" v-for="item in zma6" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-row>
                            <el-col :span="24" v-for="i in item.betclass" :key="i.index">
                                <span class="bet-name" :style="{'color':i.name=='红波'?'#f56c6c':i.name=='绿波'?'#67c23a':i.name=='蓝波'?'#409eff':'#666'}">{{i.name}}</span>
                                <span class="bet-lose">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                zma1: [
                    {
                        ballname: "正码1",
                        betclass: [
                            { title: "正码1", name: "大", visible: false, static_bonus: "-", val: "", subname: "16_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "小", visible: false, static_bonus: "-", val: "", subname: "16_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "单", visible: false, static_bonus: "-", val: "", subname: "16_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "双", visible: false, static_bonus: "-", val: "", subname: "16_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "合大", visible: false, static_bonus: "-", val: "", subname: "16_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "合小", visible: false, static_bonus: "-", val: "", subname: "16_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "合单", visible: false, static_bonus: "-", val: "", subname: "16_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "合双", visible: false, static_bonus: "-", val: "", subname: "16_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "16_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "16_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "红波", visible: false, static_bonus: "-", val: "", subname: "16_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "16_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码1", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "16_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                zma2: [
                    {
                        ballname: "正码2",
                        betclass: [
                            { title: "正码2", name: "大", visible: false, static_bonus: "-", val: "", subname: "16_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "小", visible: false, static_bonus: "-", val: "", subname: "16_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "单", visible: false, static_bonus: "-", val: "", subname: "16_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "双", visible: false, static_bonus: "-", val: "", subname: "16_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "合大", visible: false, static_bonus: "-", val: "", subname: "16_1_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "合小", visible: false, static_bonus: "-", val: "", subname: "16_1_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "合单", visible: false, static_bonus: "-", val: "", subname: "16_1_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "合双", visible: false, static_bonus: "-", val: "", subname: "16_1_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "16_1_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "16_1_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "红波", visible: false, static_bonus: "-", val: "", subname: "16_1_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "16_1_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码2", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "16_1_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                zma3: [
                    {
                        ballname: "正码3",
                        betclass: [
                            { title: "正码3", name: "大", visible: false, static_bonus: "-", val: "", subname: "16_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "小", visible: false, static_bonus: "-", val: "", subname: "16_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "单", visible: false, static_bonus: "-", val: "", subname: "16_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "双", visible: false, static_bonus: "-", val: "", subname: "16_2_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "合大", visible: false, static_bonus: "-", val: "", subname: "16_2_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "合小", visible: false, static_bonus: "-", val: "", subname: "16_2_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "合单", visible: false, static_bonus: "-", val: "", subname: "16_2_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "合双", visible: false, static_bonus: "-", val: "", subname: "16_2_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "16_2_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "16_2_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "红波", visible: false, static_bonus: "-", val: "", subname: "16_2_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "16_2_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码3", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "16_2_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                zma4: [
                    {
                        ballname: "正码4",
                        betclass: [
                            { title: "正码4", name: "大", visible: false, static_bonus: "-", val: "", subname: "16_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "小", visible: false, static_bonus: "-", val: "", subname: "16_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "单", visible: false, static_bonus: "-", val: "", subname: "16_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "双", visible: false, static_bonus: "-", val: "", subname: "16_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "合大", visible: false, static_bonus: "-", val: "", subname: "16_3_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "合小", visible: false, static_bonus: "-", val: "", subname: "16_3_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "合单", visible: false, static_bonus: "-", val: "", subname: "16_3_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "合双", visible: false, static_bonus: "-", val: "", subname: "16_3_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "16_3_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "16_3_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "红波", visible: false, static_bonus: "-", val: "", subname: "16_3_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "16_3_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码4", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "16_3_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                zma5: [
                    {
                        ballname: "正码5",
                        betclass: [
                            { title: "正码5", name: "大", visible: false, static_bonus: "-", val: "", subname: "16_4_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "小", visible: false, static_bonus: "-", val: "", subname: "16_4_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "单", visible: false, static_bonus: "-", val: "", subname: "16_4_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "双", visible: false, static_bonus: "-", val: "", subname: "16_4_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "合大", visible: false, static_bonus: "-", val: "", subname: "16_4_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "合小", visible: false, static_bonus: "-", val: "", subname: "16_4_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "合单", visible: false, static_bonus: "-", val: "", subname: "16_4_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "合双", visible: false, static_bonus: "-", val: "", subname: "16_4_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "16_4_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "16_4_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "红波", visible: false, static_bonus: "-", val: "", subname: "16_4_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "16_4_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码5", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "16_4_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                zma6: [
                    {
                        ballname: "正码6",
                        betclass: [
                            { title: "正码6", name: "大", visible: false, static_bonus: "-", val: "", subname: "16_5_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "小", visible: false, static_bonus: "-", val: "", subname: "16_5_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "单", visible: false, static_bonus: "-", val: "", subname: "16_5_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "双", visible: false, static_bonus: "-", val: "", subname: "16_5_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "合大", visible: false, static_bonus: "-", val: "", subname: "16_5_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "合小", visible: false, static_bonus: "-", val: "", subname: "16_5_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "合单", visible: false, static_bonus: "-", val: "", subname: "16_5_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "合双", visible: false, static_bonus: "-", val: "", subname: "16_5_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "16_5_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "16_5_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "红波", visible: false, static_bonus: "-", val: "", subname: "16_5_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "16_5_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "正码6", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "16_5_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
            }
        },
        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //1
                for (let j = 0; j < this.zma1[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.zma1[0].betclass[j].title && item.name == this.zma1[0].betclass[j].name) {
                            this.zma1[0].betclass[j].static_bonus = item.rate;
                            this.zma1[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.zma1[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //2
                for (let j = 0; j < this.zma2[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.zma2[0].betclass[j].title && item.name == this.zma2[0].betclass[j].name) {
                            this.zma2[0].betclass[j].static_bonus = item.rate;
                            this.zma2[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.zma2[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //3
                for (let j = 0; j < this.zma3[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.zma3[0].betclass[j].title && item.name == this.zma3[0].betclass[j].name) {
                            this.zma3[0].betclass[j].static_bonus = item.rate;
                            this.zma3[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.zma3[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //4
                for (let j = 0; j < this.zma4[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.zma4[0].betclass[j].title && item.name == this.zma4[0].betclass[j].name) {
                            this.zma4[0].betclass[j].static_bonus = item.rate;
                            this.zma4[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.zma4[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //5
                for (let j = 0; j < this.zma5[0].betclass.length; j++) {
                   lose_data.forEach(item => {
                        if (item.title == this.zma5[0].betclass[j].title && item.name == this.zma5[0].betclass[j].name) {
                            this.zma5[0].betclass[j].static_bonus = item.rate;
                            this.zma5[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.zma5[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //6
                for (let j = 0; j < this.zma6[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.zma6[0].betclass[j].title && item.name == this.zma6[0].betclass[j].name) {
                            this.zma6[0].betclass[j].static_bonus = item.rate;
                            this.zma6[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.zma6[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.zma1.length; i++) {
                    for (let j = 0; j < this.zma1[i].betclass.length; j++) {
                        this.zma1[i].betclass[j].val = ""
                    }
                }
                for (let i = 0; i < this.zma2.length; i++) {
                    for (let j = 0; j < this.zma2[i].betclass.length; j++) {
                        this.zma2[i].betclass[j].val = ""
                    }
                }
                for (let i = 0; i < this.zma3.length; i++) {
                    for (let j = 0; j < this.zma3[i].betclass.length; j++) {
                        this.zma3[i].betclass[j].val = ""
                    }
                }
                for (let i = 0; i < this.zma4.length; i++) {
                    for (let j = 0; j < this.zma4[i].betclass.length; j++) {
                        this.zma4[i].betclass[j].val = ""
                    }
                }
                for (let i = 0; i < this.zma5.length; i++) {
                    for (let j = 0; j < this.zma5[i].betclass.length; j++) {
                        this.zma5[i].betclass[j].val = ""
                    }
                }
                for (let i = 0; i < this.zma6.length; i++) {
                    for (let j = 0; j < this.zma6[i].betclass.length; j++) {
                        this.zma6[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.zma1.length; i++) {
                    for (let j = 0; j < this.zma1[i].betclass.length; j++) {
                        if (this.zma1[i].betclass[j].val && !isNaN(this.zma1[i].betclass[j].val * 1)) {
                            list.push(this.zma1[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.zma2.length; i++) {
                    for (let j = 0; j < this.zma2[i].betclass.length; j++) {
                        if (this.zma2[i].betclass[j].val && !isNaN(this.zma2[i].betclass[j].val * 1)) {
                            list.push(this.zma2[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.zma3.length; i++) {
                    for (let j = 0; j < this.zma3[i].betclass.length; j++) {
                        if (this.zma3[i].betclass[j].val && !isNaN(this.zma3[i].betclass[j].val * 1)) {
                            list.push(this.zma3[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.zma4.length; i++) {
                    for (let j = 0; j < this.zma4[i].betclass.length; j++) {
                        if (this.zma4[i].betclass[j].val && !isNaN(this.zma4[i].betclass[j].val * 1)) {
                            list.push(this.zma4[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.zma5.length; i++) {
                    for (let j = 0; j < this.zma5[i].betclass.length; j++) {
                        if (this.zma5[i].betclass[j].val && !isNaN(this.zma5[i].betclass[j].val * 1)) {
                            list.push(this.zma5[i].betclass[j]);
                        }
                    }
                }
                for (let i = 0; i < this.zma6.length; i++) {
                    for (let j = 0; j < this.zma6[i].betclass.length; j++) {
                        if (this.zma6[i].betclass[j].val && !isNaN(this.zma6[i].betclass[j].val * 1)) {
                            list.push(this.zma6[i].betclass[j]);
                        }
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.zma1.length; j++) {
                        for (let k = 0; k < this.zma1[j].betclass.length; k++) {
                            if (this.zma1[j].betclass[k].title == list[i].title && this.zma1[j].betclass[k].name == list[i].name) {
                                this.zma1[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.zma2.length; j++) {
                        for (let k = 0; k < this.zma2[j].betclass.length; k++) {
                            if (this.zma2[j].betclass[k].title == list[i].title && this.zma2[j].betclass[k].name == list[i].name) {
                                this.zma2[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.zma3.length; j++) {
                        for (let k = 0; k < this.zma3[j].betclass.length; k++) {
                            if (this.zma3[j].betclass[k].title == list[i].title && this.zma3[j].betclass[k].name == list[i].name) {
                                this.zma3[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.zma4.length; j++) {
                        for (let k = 0; k < this.zma4[j].betclass.length; k++) {
                            if (this.zma4[j].betclass[k].title == list[i].title && this.zma4[j].betclass[k].name == list[i].name) {
                                this.zma4[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.zma5.length; j++) {
                        for (let k = 0; k < this.zma5[j].betclass.length; k++) {
                            if (this.zma5[j].betclass[k].title == list[i].title && this.zma5[j].betclass[k].name == list[i].name) {
                                this.zma5[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    for (let j = 0; j < this.zma6.length; j++) {
                        for (let k = 0; k < this.zma6[j].betclass.length; k++) {
                            if (this.zma6[j].betclass[k].title == list[i].title && this.zma6[j].betclass[k].name == list[i].name) {
                                this.zma6[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .zma1_6 .bet-items-one .bet-name {
        width: 24px;
        text-align: center;
        padding-left: 3px;
    }
    .zma1_6 .bet-items-one .bet-lose {
        width: 50px;
        text-align: center;
    }
</style>
