<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-27 17:06:16
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-28 23:26:57
 * @FilePath: \pc\src\components\menuitems\noSettlement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="noSettlement">
        <el-table :data="openOrder.list" highlight-current-row size="small" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" :row-class-name="tableRowClassName">
            <el-table-column property="platform" label="平台" align="center" width="100"></el-table-column>
            <el-table-column property label="注单号/时间" align="center">
                <template #default="scope">
                    <span>{{ scope.row.order_id}}</span>
                    <br />
                    <span style="color:#409EFF">{{$filters.time(scope.row.posttime,'noyear')}}</span>
                </template>
            </el-table-column>
            <el-table-column property="game_name" label="下注类型" align="center">
                <template #default="scope">
                    <span>{{scope.row.game_name}}</span>
                    <br />
                    <span style="color:#67C23A">{{scope.row.qnum}}</span>
                </template>
            </el-table-column>
            <el-table-column property="game_level" label="注单明细" align="center">
                <template #default="scope">
                    <span>{{scope.row.game_level}}</span>
                    <br />
                    <span style="color:#F56C6C">{{scope.row.list_bonus}}</span>
                </template>
            </el-table-column>
            <el-table-column property="balance" label="下注金额" align="center"></el-table-column>
            <el-table-column property="balance" label="可赢金额" align="center">
                <template #default="scope">
                    <span>{{(scope.row.balance * scope.row.list_bonus).toFixed(1) }}</span>
                </template>
            </el-table-column>
            <el-table-column property="tuishui" label="退水" align="center">
                <!-- <template #default="scope">
                <span>{{(scope.row.balance * (scope.row.rebate_level *1)/100).toFixed(1)}}</span>
                </template>-->
            </el-table-column>
            <!-- <el-table-column property="" label="操作" align="center">
                <template #default="scope">
                    <el-button v-if="scope.row.order_staus==0" type="danger" icon="el-icon-delete" size="medium" @click="cancel_list(scope.row)">撤单</el-button>
                    <el-button v-else-if="scope.row.order_staus==-1" type="info" icon="el-icon-document-delete" plain size="medium" disabled>{{scope.row.remark?scope.row.remark:'已撤单'}}</el-button>
                </template>
            </el-table-column>-->
        </el-table>
        <div class="total">
            <span>当前页合计：</span>
            <span style="color:#e64100;border:0;font-weight:bold">{{openOrder.total.page_count}}</span>笔，总额：
            <span style="width:99px;font-weight:bold;color:#e64100;">{{openOrder.total.balance}}</span>
        </div>
        <div style="text-align:center">
            <el-pagination hide-on-single-page @current-change="getOpenOrder" small :current-page="openOrder.total.currentPage" :page-size="20" :pager-count="5" :total="openOrder.total.count*1" layout=" total,prev, pager, next, jumper," />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                /**未结明细 */
                openOrder: {
                    visible: false,
                    currentPage: 1, //分页默认第一页
                    list: [], //记录数据
                    total: {
                        count: 0,
                        currentPage: 1,
                    } //分页信息
                },
                name: "",
                /**撤单按钮 */
                cancel_btn: {
                    type: "danger",
                    disabled: false,

                },
            }
        },
        mounted() {
        },
        methods: {
            get_data(data) {
                if (data.length == 0) {
                    this.openOrder.list = null;
                    this.openOrder.total = {
                        count: 0,
                        currentPage: 1,
                    };
                    this.openOrder.total.page_count = 0;
                    return;
                }
                this.openOrder.list = data.list;
                this.openOrder.total = data.total;
            },
            getOpenOrder(val = 1) {
                this.axios.get("/order/open_order?page=" + val).then(result => {
                    // console.log(result.data)
                    if (result.data.status && result.data.msg === "请求成功！") {
                        if (result.data.res.length == 0) {
                            this.openOrder.list = null;
                            this.openOrder.total = {};
                            this.openOrder.total.page_count = 0;
                            return;
                        }
                        this.openOrder.list = result.data.res.list;
                        // console.log(this.openOrder.list)
                        this.openOrder.total = result.data.res.total;
                    }
                })
            },
            cancel_list(row) {
                // console.log(row);
                let post_obj = {
                    game_id: row.game_id,
                    periods_id: row.periods_id,
                    id: row.id,
                    ajax_type: 1
                }
                this.axios.post("/order/cancel_list",
                    this.qs.stringify(post_obj),
                    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }).then(result => {
                        // console.log(result.data)
                        if (result.data.status == 1) {
                            this.$message({
                                message: result.data.msg,
                                type: 'success',
                            });
                            this.getOpenOrder();
                            this.$emit('cancel_refresh');
                        }
                        else {
                            this.$message.error(result.data.msg);
                        }
                    })
            },
            tableRowClassName({ row, rowIndex }) {
                if (row.remark === '后台撤单') {
                    return 'warning-row';
                }
                return '';
            }
        },
    }
</script>

<style scoped>
    .noSettlement >>> .el-table .warning-row {
        background: linear-gradient(#fff 49%, #f56c6c 0, #f56c6c 51%, #fff 0);
    }
    .noSettlement {
        /* height: 600px; */
        overflow-y: auto;
    }

    .bottom-text {
        float: right;
        margin-top: 6px;
    }

    .right-tit {
        float: right;
    }

    .right-tit .tit-cn {
        margin-right: 10px;
        font-size: 36px;
        font-weight: bold;
        text-align: right;
    }

    .right-tit .tit-cn span {
        color: #409eff;
    }

    .right-tit .tit-en {
        margin-right: 10px;
        font-size: 26px;
        color: #ccc;
    }

    .total {
        text-align: right;
        padding: 5px 0;
    }

    .total span {
        margin: 0 6px;
    }
</style>
