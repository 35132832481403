<template>
    <div class="cqssc">
        <lm v-if="activeName=='两面'" ref="lm"></lm>
        <tema v-else-if="activeName=='特码'" ref="tema"></tema>
        <bao3 v-else ref="bao3"></bao3>
    </div>
</template>

<script>
import lm from "./lm";
import tema from "./tema";
import bao3 from "./bao3";


export default {
    components:{lm,tema,bao3},
    props:["activeName"],
    data() {
        return {

        }
    },
    methods: {
        /**显示赔率 */
        set_lose(){
            if(this.activeName=="两面"){
                this.$refs.lm.get_lose();
            }
            else if(this.activeName=="特码"){
                this.$refs.tema.get_lose();
            }
            else{
                this.$refs.bao3.get_lose(this.activeName);
            }
        },
        /**获取快捷下注金额 */
        get_fast(){
            // console.log(this.$parent.get_fast())
            return this.$parent.get_fast();
        },
        /**重填，重置 */
        reset(){
            if(this.activeName=="两面"){
                this.$refs.lm.reset();
            }
            else if(this.activeName=="特码"){
                this.$refs.tema.reset();
            }
            else{
                this.$refs.bao3.reset(this.activeName);
            }
        },
        /**提交，下注 */
        sub_bet(){
            if(this.activeName=="两面"){
                return this.$refs.lm.sub_bet();
            }
            else if(this.activeName=="特码"){
                return this.$refs.tema.sub_bet();
            }
            else{
                return this.$refs.bao3.sub_bet(this.activeName);
            }
        },
        /**重复上次下单 */
        repeat_last_bet(playing,list) {
            if(playing=="lmian"){
                this.$refs.lm.repeat_last_bet(list);
            }
            else if(playing=="tema"){
                this.$refs.tema.repeat_last_bet(list);
            }
            else{
                this.$refs.bao3.repeat_last_bet(list);
            }
        },
    },
}
</script>