<!--
 * @Author: your name
 * @Date: 2021-04-26 15:04:00
 * @LastEditTime: 2022-07-25 00:16:51
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck\src\components\betPage\k3\sbth.vue
-->
<template>
    <div class="sbth">
        <el-row type="flex">
            <el-col :span="24" v-for="item in sbth" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-col :span="8" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sbth: [
                    {
                        ballname: "三不同号",
                        betclass: [
                            { title: "三不同号", name: "123", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "124", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "125", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "126", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "134", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "135", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "136", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "145", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "146", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "156", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "234", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "235", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "236", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "245", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "246", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "256", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "345", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "346", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "356", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                            { title: "三不同号", name: "456", visible: false, static_bonus: "-", val: '', disabled: false, subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                        ]
                    }
                ],
            }
        },
        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                for (let i = 0; i < this.sbth.length; i++) {
                    for (let j = 0; j < this.sbth[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.sbth[i].betclass[j].subname) {
                                this.sbth[i].betclass[j].static_bonus = item.rate;
                                this.sbth[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sbth[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.sbth[0].betclass.length; i++) {
                    this.sbth[0].betclass[i].val = '';
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.sbth.length; i++) {
                    for (let j = 0; j < this.sbth[i].betclass.length; j++) {
                        if (this.sbth[i].betclass[j].val) {
                            this.sbth[i].betclass[j].bet_count = 1;
                            list.push(this.sbth[i].betclass[j]);
                        }
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.sbth.length; j++) {
                        for (let k = 0; k < this.sbth[j].betclass.length; k++) {
                            if (this.sbth[j].betclass[k].title == list[i].title && this.sbth[j].betclass[k].name == list[i].name) {
                                this.sbth[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>