<template>
    <div class="bjpk10-gy">
        <!-- 冠亚和 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in gy_count" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one" style="height:140px">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name" style="font-weight:bold">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <br />
        <!-- 冠亚和大小单双 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in gy_count_size" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one" style="height:30px">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <br />
        <!-- 冠军 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in one" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <!-- <span class="bet-name">{{i.name}}</span> -->
                            <span class="bet-name pk10-nums">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <div v-else>{{i.name}}</div>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <br />
        <!-- 亚军 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in two" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name pk10-nums">
                                <span v-if="i.name==1" class="pk10_num1">1</span>
                                <span v-else-if="i.name==2" class="pk10_num2">2</span>
                                <span v-else-if="i.name==3" class="pk10_num3">3</span>
                                <span v-else-if="i.name==4" class="pk10_num4">4</span>
                                <span v-else-if="i.name==5" class="pk10_num5">5</span>
                                <span v-else-if="i.name==6" class="pk10_num6">6</span>
                                <span v-else-if="i.name==7" class="pk10_num7">7</span>
                                <span v-else-if="i.name==8" class="pk10_num8">8</span>
                                <span v-else-if="i.name==9" class="pk10_num9">9</span>
                                <span v-else-if="i.name==10" class="pk10_num10">10</span>
                                <div v-else>{{i.name}}</div>
                            </span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                /**冠亚总和值*/
                gy_count: [
                    {
                        ballname: "冠亚和值",
                        betclass: [
                            { title: "冠亚和值", name: "3", visible: false, static_bonus: "-", val: "", subname: "4_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "4", visible: false, static_bonus: "-", val: "", subname: "4_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "5", visible: false, static_bonus: "-", val: "", subname: "4_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "6", visible: false, static_bonus: "-", val: "", subname: "4_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "7", visible: false, static_bonus: "-", val: "", subname: "4_3_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "8", visible: false, static_bonus: "-", val: "", subname: "4_3_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "9", visible: false, static_bonus: "-", val: "", subname: "4_3_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "10", visible: false, static_bonus: "-", val: "", subname: "4_3_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "11", visible: false, static_bonus: "-", val: "", subname: "4_3_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "12", visible: false, static_bonus: "-", val: "", subname: "4_3_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "13", visible: false, static_bonus: "-", val: "", subname: "4_3_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "14", visible: false, static_bonus: "-", val: "", subname: "4_3_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "15", visible: false, static_bonus: "-", val: "", subname: "4_3_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "16", visible: false, static_bonus: "-", val: "", subname: "4_3_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "17", visible: false, static_bonus: "-", val: "", subname: "4_3_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "18", visible: false, static_bonus: "-", val: "", subname: "4_3_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "19", visible: false, static_bonus: "-", val: "", subname: "4_3_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]

                    }
                ],
                /**冠亚总和值大小，单双*/
                gy_count_size: [
                    {
                        ballname: "冠亚和值",
                        betclass: [
                            { title: "冠亚和值", name: "大", visible: false, static_bonus: "-", val: "", subname: "4_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "小", visible: false, static_bonus: "-", val: "", subname: "4_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "单", visible: false, static_bonus: "-", val: "", subname: "4_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠亚和值", name: "双", visible: false, static_bonus: "-", val: "", subname: "4_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    }
                ],
                /**冠军 */
                one: [
                    {
                        ballname: "冠军",
                        betclass: [
                            { title: "冠军", name: "01", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "02", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "03", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "04", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "05", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "06", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "07", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "08", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "09", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "10", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "龙", visible: false, static_bonus: "-", val: "", subname: "4_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "虎", visible: false, static_bonus: "-", val: "", subname: "4_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "大", visible: false, static_bonus: "-", val: "", subname: "4_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "小", visible: false, static_bonus: "-", val: "", subname: "4_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "单", visible: false, static_bonus: "-", val: "", subname: "4_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "冠军", name: "双", visible: false, static_bonus: "-", val: "", subname: "4_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**亚军*/
                two: [
                    {
                        ballname: "亚军",
                        betclass: [
                            { title: "亚军", name: "01", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "02", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "03", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "04", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "05", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "06", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "07", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "08", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "09", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "10", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "龙", visible: false, static_bonus: "-", val: "", subname: "4_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "虎", visible: false, static_bonus: "-", val: "", subname: "4_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "大", visible: false, static_bonus: "-", val: "", subname: "4_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "小", visible: false, static_bonus: "-", val: "", subname: "4_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "单", visible: false, static_bonus: "-", val: "", subname: "4_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "亚军", name: "双", visible: false, static_bonus: "-", val: "", subname: "4_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
            }
        },
        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.gy_count[i].betclass[j].title && item.name == this.gy_count[i].betclass[j].name) {
                                this.gy_count[i].betclass[j].static_bonus = item.rate;
                                this.gy_count[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.gy_count[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.gy_count_size[i].betclass[j].title && item.name == this.gy_count_size[i].betclass[j].name) {
                                this.gy_count_size[i].betclass[j].static_bonus = item.rate;
                                this.gy_count_size[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.gy_count_size[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //冠军
                for (let i = 0; i < this.one.length; i++) {
                    for (let j = 0; j < this.one[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.one[i].betclass[j].title && item.name == this.one[i].betclass[j].name) {
                                this.one[i].betclass[j].static_bonus = item.rate;
                                this.one[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.one[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //亚军
                for (let i = 0; i < this.two.length; i++) {
                    for (let j = 0; j < this.two[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.two[i].betclass[j].title && item.name == this.two[i].betclass[j].name) {
                                this.two[i].betclass[j].static_bonus = item.rate;
                                this.two[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.two[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        this.gy_count[i].betclass[j].val = ""
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        this.gy_count_size[i].betclass[j].val = ""
                    }
                }
                //冠军
                for (let i = 0; i < this.one.length; i++) {
                    for (let j = 0; j < this.one[i].betclass.length; j++) {
                        this.one[i].betclass[j].val = ""
                    }
                }
                //亚军
                for (let i = 0; i < this.two.length; i++) {
                    for (let j = 0; j < this.two[i].betclass.length; j++) {
                        this.two[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //冠亚总和值
                for (let i = 0; i < this.gy_count.length; i++) {
                    for (let j = 0; j < this.gy_count[i].betclass.length; j++) {
                        if (this.gy_count[i].betclass[j].val && !isNaN(this.gy_count[i].betclass[j].val * 1)) {
                            list.push(this.gy_count[i].betclass[j]);
                        }
                    }
                }
                //冠亚总和值大小，单双
                for (let i = 0; i < this.gy_count_size.length; i++) {
                    for (let j = 0; j < this.gy_count_size[i].betclass.length; j++) {
                        if (this.gy_count_size[i].betclass[j].val && !isNaN(this.gy_count_size[i].betclass[j].val * 1)) {
                            list.push(this.gy_count_size[i].betclass[j]);
                        }
                    }
                }
                //冠军
                for (let i = 0; i < this.one.length; i++) {
                    for (let j = 0; j < this.one[i].betclass.length; j++) {
                        if (this.one[i].betclass[j].val && !isNaN(this.one[i].betclass[j].val * 1)) {
                            list.push(this.one[i].betclass[j]);
                        }
                    }
                }
                //亚军
                for (let i = 0; i < this.two.length; i++) {
                    for (let j = 0; j < this.two[i].betclass.length; j++) {
                        if (this.two[i].betclass[j].val && !isNaN(this.two[i].betclass[j].val * 1)) {
                            list.push(this.two[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //冠亚总和值
                    for (let j = 0; j < this.gy_count.length; j++) {
                        for (let k = 0; k < this.gy_count[j].betclass.length; k++) {
                            if (this.gy_count[j].betclass[k].title == list[i].title && this.gy_count[j].betclass[k].name == list[i].name) {
                                this.gy_count[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //冠亚总和值大小，单双
                    for (let j = 0; j < this.gy_count_size.length; j++) {
                        for (let k = 0; k < this.gy_count_size[j].betclass.length; k++) {
                            if (this.gy_count_size[j].betclass[k].title == list[i].title && this.gy_count_size[j].betclass[k].name == list[i].name) {
                                this.gy_count_size[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //冠军
                    for (let j = 0; j < this.one.length; j++) {
                        for (let k = 0; k < this.one[j].betclass.length; k++) {
                            if (this.one[j].betclass[k].title == list[i].title && this.one[j].betclass[k].name == list[i].name) {
                                this.one[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //亚军
                    for (let j = 0; j < this.two.length; j++) {
                        for (let k = 0; k < this.two[j].betclass.length; k++) {
                            if (this.two[j].betclass[k].title == list[i].title && this.two[j].betclass[k].name == list[i].name) {
                                this.two[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
</style>

