<template>
    <div class="lmian">
        <el-row type="flex">
            <el-col :span="24" v-for="item in lmian" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one" style="height:145px;">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                lmian: [
                    {
                        ballname: "两面",
                        betclass: [
                            { title: "两面", name: "特大", visible: false, static_bonus: "-", val: "", subname: "10_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "特小", visible: false, static_bonus: "-", val: "", subname: "10_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "特单", visible: false, static_bonus: "-", val: "", subname: "10_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "特双", visible: false, static_bonus: "-", val: "", subname: "10_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "合大", visible: false, static_bonus: "-", val: "", subname: "10_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "合小", visible: false, static_bonus: "-", val: "", subname: "10_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "合单", visible: false, static_bonus: "-", val: "", subname: "10_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "合双", visible: false, static_bonus: "-", val: "", subname: "10_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "总大", visible: false, static_bonus: "-", val: "", subname: "10_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "总小", visible: false, static_bonus: "-", val: "", subname: "10_0_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "总单", visible: false, static_bonus: "-", val: "", subname: "10_0_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "总双", visible: false, static_bonus: "-", val: "", subname: "10_0_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "10_0_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "10_0_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "合尾大", visible: false, static_bonus: "-", val: "", subname: "10_0_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "合尾小", visible: false, static_bonus: "-", val: "", subname: "10_0_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "家禽", visible: false, static_bonus: "-", val: "", subname: "10_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "两面", name: "野兽", visible: false, static_bonus: "-", val: "", subname: "10_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ]
            }
        },
        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //两面
                for (let j = 0; j < this.lmian[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.lmian[0].betclass[j].title && item.name == this.lmian[0].betclass[j].name) {
                            this.lmian[0].betclass[j].static_bonus = item.rate;
                            this.lmian[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.lmian[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //两面
                for (let i = 0; i < this.lmian.length; i++) {
                    for (let j = 0; j < this.lmian[i].betclass.length; j++) {
                        this.lmian[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //两面
                for (let i = 0; i < this.lmian.length; i++) {
                    for (let j = 0; j < this.lmian[i].betclass.length; j++) {
                        if (this.lmian[i].betclass[j].val && !isNaN(this.lmian[i].betclass[j].val * 1)) {
                            list.push(this.lmian[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //两面
                    for (let j = 0; j < this.lmian.length; j++) {
                        for (let k = 0; k < this.lmian[j].betclass.length; k++) {
                            if (this.lmian[j].betclass[k].title == list[i].title && this.lmian[j].betclass[k].name == list[i].name) {
                                this.lmian[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>