<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-14 16:48:18
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-14 16:50:40
 * @FilePath: \pc\src\components\menuitems\duijiangguize.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="duijiangguize">
        <div v-html="data"></div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                data:'',
            }
        },
        created() { },
        methods: {
            getdata(data){
                this.data=data;
            }
        },
    }
</script>

<style scoped>
</style>