/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-21 21:17:52
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-21 21:24:22
 * @FilePath: \pc\src\plugin\numsRanking.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/**处理当前彩种历史开奖号，生成对应的球号排行。 */
/**gid:彩种ID， */
/**history:历史开奖号数据， */
/**playing：玩法 */
/**num:选中的排行方式，第几球， */
import {pcddNum} from "./pcddNum";
export function numsRanking(gid, history, playing, num) {
    let list = []
    let ball_list = [];
    //所有取球的数据，
    for (let i = 0; i < history.length; i++) {
        list.push(history[i].res.toString().split(","))
    }
    // console.log(list)
    //重庆时时彩*********************************************************
    if (gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45|| gid == 46) {
        //两面盘，1-5球-------------------
        if (playing == "cqssc_lmp" || playing == "cqssc_d1_5") {
            //判断需要用到第几球的数组
            for (let i = 0; i < list.length; i++) {
                //1-5球
                num == 0 || num == 1 ? ball_list.push(list[i][0]) : ""
                num == 2 || num == 3 ? ball_list.push(list[i][1]) : ""
                num == 4 || num == 5 ? ball_list.push(list[i][2]) : ""
                num == 6 || num == 7 ? ball_list.push(list[i][3]) : ""
                num == 8 || num == 9 ? ball_list.push(list[i][4]) : ""
                //总和，龙虎
                if (num == 10 || num == 11) {
                    let sum = 0;
                    for (let j = 0; j < list[i].length; j++) {
                        sum += list[i][j] * 1
                    }
                    ball_list.push(sum);
                }
                //龙虎，1第个和最后一个相比较，第1个大为龙否则为虎
                if (num == 12) {
                    let sum = 0;
                    if (list[i][0] > list[i][list[i].length - 1]) {
                        sum = "龙"
                    }
                    if (list[i][0] < list[i][list[i].length - 1]) {
                        sum = "虎"
                    }
                    if (list[i][0] == list[i][list[i].length - 1]) {
                        sum = "和"
                    }
                    ball_list.push(sum)
                }
            }
            //文字转换------
            //判断是显示大小
            if (num == 0 || num == 2 || num == 4 || num == 6 || num == 8) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] > 4 ? ball_list[i] = "大" : ball_list[i] = "小";
                }
            }
            //判断是显示单双
            if (num == 1 || num == 3 || num == 5 || num == 7 || num == 9) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] % 2 == 0 ? ball_list[i] = "双" : ball_list[i] = "单";
                }
            }
            //判断是显示总和大小
            if (num == 10) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] > 45 / 2 ? ball_list[i] = "大" : ball_list[i] = "小";
                }
            }
            //判断是显示总和单双
            if (num == 11) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] % 2 == 0 ? ball_list[i] = "双" : ball_list[i] = "单";
                }
            }
            //判断是显示龙虎和
            // if (num == 12) {
            //     for (let i = 0; i < 12; i++) {
            //         list.push(new Array())
            //     }
            // }
        }
        //第1球-第2球-第3球-第4球-第5球-----------------------------
        if(playing=="cqssc_d1"||playing=="cqssc_d2"||playing=="cqssc_d3"||playing=="cqssc_d4"||playing=="cqssc_d5"){
            //判断需要用到第1球的数组
            for (let i = 0; i < list.length; i++) {
                if(num==0||num==1||num==2){
                    //1球
                    playing=="cqssc_d1"?ball_list.push(list[i][0]):"";
                    //2球
                    playing=="cqssc_d2"?ball_list.push(list[i][1]):"";
                    //3球
                    playing=="cqssc_d3"?ball_list.push(list[i][2]):"";
                    //4球
                    playing=="cqssc_d4"?ball_list.push(list[i][3]):"";
                    //5球
                    playing=="cqssc_d5"?ball_list.push(list[i][4]):"";
                }
                //总和，龙虎
                if (num == 3 || num == 4) {
                    let sum = 0;
                    for (let j = 0; j < list[i].length; j++) {
                        sum += list[i][j] * 1
                    }
                    ball_list.push(sum)
                }
                //龙虎，1第个和最后一个相比较，第1个大为龙否则为虎
                if (num == 5) {
                    let sum = 0;
                    if (list[i][0] > list[i][list[i].length - 1]) {
                        sum = "龙"
                    }
                    if (list[i][0] < list[i][list[i].length - 1]) {
                        sum = "虎"
                    }
                    if (list[i][0] == list[i][list[i].length - 1]) {
                        sum = "和"
                    }
                    ball_list.push(sum)
                }
            }
            //判断是显示大小
            if (num == 1) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] > 4 ? ball_list[i] = "大" : ball_list[i] = "小";
                }
            }
            //判断是显示单双
            if (num == 2) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] % 2 == 0 ? ball_list[i] = "双" : ball_list[i] = "单";
                }
            }
            //判断是显示总和大小
            if (num == 3) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] > 45 / 2 ? ball_list[i] = "大" : ball_list[i] = "小";
                }
            }
            //判断是显示总和单双
            if (num == 4) {
                for (let i = 0; i < ball_list.length; i++) {
                    ball_list[i] % 2 == 0 ? ball_list[i] = "双" : ball_list[i] = "单";
                }
            }
        }
    }
    //北京PK10*********************************************************
    if (gid == 3|| gid == 19|| gid == 24|| gid == 28|| gid == 37||gid == 40|| gid == 43|| gid == 47) {
        //判断需要用到冠亚和的数组
        for (let i = 0; i < list.length; i++) {
            ball_list.push(list[i][0]*1+list[i][1]*1)
        }
        // console.log(ball_list)
        //文字转换------
        //判断是显示总和大小
        if (num == 1) {
            for (let i = 0; i < ball_list.length; i++) {
                ball_list[i] > 11 ? ball_list[i] = "大" : ball_list[i] = "小";
            }
        }
        //判断是显示总和单双
        if (num == 2) {
            for (let i = 0; i < ball_list.length; i++) {
                ball_list[i] % 2 == 0 ? ball_list[i] = "双" : ball_list[i] = "单";
            }
        }
    }
    //pc蛋蛋，加拿大28，幸运28*********************************************************
    if (gid == 22||gid==23) {

        //判断需要用到特码最后一个
        for (let i = 0; i < list.length; i++) {
            ball_list.push(list[i][3]*1)
        }
        // console.log(ball_list)
        //文字转换------
        //判断是显示总和大小
        if (num == 1) {
            for (let i = 0; i < ball_list.length; i++) {
                ball_list[i] > 13 ? ball_list[i] = "大" : ball_list[i] = "小";
            }
        }
        //判断是显示总和单双
        if (num == 2) {
            for (let i = 0; i < ball_list.length; i++) {
                ball_list[i] % 2 == 0 ? ball_list[i] = "双" : ball_list[i] = "单";
            }
        }
        //判断是显示波色
        if (num == 3) {
            for (let i = 0; i < ball_list.length; i++) {
                ball_list[i]=pcddNum(ball_list[i]);
                ball_list[i].color == "#F56C6C" ? ball_list[i] = "红波" : "";
                ball_list[i].color == "#67C23A" ? ball_list[i] = "绿波" : "";
                ball_list[i].color == "#409EFF" ? ball_list[i] = "蓝波" : "";
                ball_list[i].color == "#909399" ? ball_list[i] = "-" : "";
            }
        }
    }
    //因为要竖着显示连续并且相同的球号结果
    list = []
    for (let i = 0; i < 20; i++) {
        list.push(new Array())
    }
    let str = ball_list[0];
    let index = 0
    for (let i = 0; i < ball_list.length; i++) {
        if (ball_list[i] == str) {
            list[index].push(ball_list[i])
        } else {
            index++;
            list[index].push(ball_list[i])
            str = ball_list[i]
        }
    }
    // console.log(list)
    return list
}