<template>
    <div class="gameinfo theme-border-half light-bg-fff">
        <div class="info1 theme-border-half">
            <div class="logo">
                <img class="logo-img" :src="require(`../../assets/${gname}.png`)" alt="logo" />
            </div>
            <div class="game-text">
                <div class="game-text-gname">{{gname}}</div>
                <div style="color:#6a737c">
                    <span v-if="lottery_info.qnum_time>0&&gid!=34&&gid!=4&&gid!=10">
                        <span v-if="lottery_info.qnum_time%60!==0">{{lottery_info.qnum_time}}秒一期</span>
                        <span v-else>{{lottery_info.qnum_time/60}}分钟一期</span>
                    </span>
                    <span v-else-if="gid==34||gid==4||gid==10">一天一期</span>
                    <span v-else>
                        <span v-if="lottery_info.qnum_time%60!==0">{{lottery_info.qnum_time}}秒一期</span>
                        <span>{{lottery_info.qnum_time/60}}分钟一期</span>
                    </span>
                    <span>，每天 07:00-次日06:00</span>
                </div>
                <div style="color:#6a737c">
                    <template v-if="gid==4||gid==10">
                        <span>已售</span>
                        <span class="green-color">{{lottery_info.not_open}}</span>
                        <span>期，</span>
                        <span>还剩</span>
                        <span class="green-color">{{lottery_info.is_open}}</span>
                        <span>期</span>
                    </template>
                    <template v-else>
                        <span>已售</span>
                        <span class="green-color">{{lottery_info.is_open}}</span>
                        <span>期，</span>
                        <span>还剩</span>
                        <span class="green-color">{{lottery_info.not_open}}</span>
                        <span>期</span>
                    </template>
                </div>
            </div>
        </div>
        <div class="info2">
            <div class="win-lose">今日输赢：{{$store.state.userinfo.win_and_lose}}</div>
            <div class="kai-time">
                <span>第</span>
                <span class="new-qnum">{{lottery_info.new_qnum}}</span>
                <span>期，</span>
                <span>
                    <span>{{countDownText}}：</span>
                    <span class="time-nums theme-border-half light-bg">{{countDown_h}}</span>
                    <span class="time-dw">时</span>
                    <span class="time-nums theme-border-half light-bg">{{countDown_m}}</span>
                    <span class="time-dw">分</span>
                    <span class="time-nums theme-border-half light-bg">{{countDown_s}}</span>
                    <span class="time-dw">秒</span>
                </span>
            </div>
            <div class="kai-nums">
                <span>第</span>
                <span class="green-color">{{lottery_info.old_qnum}}</span>
                <span>期开奖：</span>
                <span class="last-num" v-if="lottery_info.old_qnum">
                    <!-- 判断彩种，进行不同样式来渲染开奖号码 -->
                    <!-- 重庆时时彩 -->
                    <span v-if="gid == 1|| gid == 7|| gid == 18|| gid == 38 || gid == 39|| gid == 44|| gid == 45|| gid == 46">
                        <span class="last-nums-cqssc" v-for="item in old_res" :key="item.index">{{item}}</span>
                    </span>
                    <!-- 北京PK10 -->
                    <span v-if="gid==3||gid == 19||gid == 24||gid == 28||gid == 37||gid == 40||gid == 43||gid == 47">
                        <span class="pk10-nums" v-for="item in old_res" :key="item.index">
                            <span v-if="item==1" class="pk10_num1">1</span>
                            <span v-if="item==2" class="pk10_num2">2</span>
                            <span v-if="item==3" class="pk10_num3">3</span>
                            <span v-if="item==4" class="pk10_num4">4</span>
                            <span v-if="item==5" class="pk10_num5">5</span>
                            <span v-if="item==6" class="pk10_num6">6</span>
                            <span v-if="item==7" class="pk10_num7">7</span>
                            <span v-if="item==8" class="pk10_num8">8</span>
                            <span v-if="item==9" class="pk10_num9">9</span>
                            <span v-if="item==10" class="pk10_num10">10</span>
                        </span>
                    </span>
                    <!-- 六合彩,极速六合彩 -->
                    <span v-if="gid==20||gid==34||gid==48">
                        <span class="last-nums-lhc" v-for="item in old_res" :key="item.index">
                            <span :style="{'background-color':item.color}">{{item.num}}</span>
                            {{item.sx}}
                        </span>
                    </span>
                    <!-- pc蛋蛋，加拿大28，幸运28 -->
                    <span v-if="gid==22&&old_res||gid==23&&old_res" style="padding-top:2px">
                        <span class="betclassname_old_pcdd" :style="{'background-color':old_res[0].color}">{{old_res[0].num}}</span>＋
                        <span class="betclassname_old_pcdd" :style="{'background-color':old_res[1].color}">{{old_res[1].num}}</span>＋
                        <span class="betclassname_old_pcdd" :style="{'background-color':old_res[2].color}">{{old_res[2].num}}</span>＝
                        <span class="betclassname_old_pcdd" :style="{'background-color':old_res[3].color}">{{old_res[3].num}}</span>
                    </span>
                    <!-- 快三 -->
                    <span v-if="gid == 4||gid==6||gid == 10||gid==29||gid==30||gid==31||gid==32||gid==36">
                        <span class="nums-k3" v-for="item of old_res" :key="item.index">{{item}}</span>
                    </span>
                </span>
                <span v-else class="last-num">暂无开奖信息</span>
            </div>
        </div>
        <div class="info3">
            <div class="kjwz theme-border-half light-bg" @click="go_kjweb">开奖网站</div>
            <div class="jqkj theme-border-half light-bg" @click="get_jqkj">查看近期开奖</div>
        </div>
        <!-- <div class="jqkj-info">
            <history v-if="show_jqkj" ref="history" />
        </div>-->
    </div>
</template>

<script>
    import { get_lhc_sx } from "../../plugin/sx";
    import { lhcNum } from "../../plugin/lhcNum";
    import { pcddNum } from "../../plugin/pcddNum";
    // import history from "../menuitems/history.vue";
    export default {
        // components: { history },
        data() {
            return {
                gid: '',
                gname: '',
                lottery_info: {
                    ahead_time: "-",
                    closetime: "-",
                    game_image: "",
                    gname: "-",
                    is_open: 20,
                    new_qnum: "-",
                    new_qnum_id: "-",
                    not_open: 39,
                    now: 1562812567,
                    old_qnum: "-",
                    old_res: "",
                    opentime: "-",
                    qnum_time: 1200,
                    stoptime: "-",
                    win_and_lose: "0"
                },
                /**上一期开奖结果 */
                old_res: "",
                lottery_open: '',
                /** */
                set_oldres_sid: "",
                /**封盘时间 */
                closetime: "",
                /**当前时间 */
                now: "",
                /**倒计时 */
                countDown_d: "--",
                countDown_h: "--",
                countDown_m: "--",
                countDown_s: "--",
                /**提前封盘时间 */
                ahead_time: 0,
                /**提示文字 */
                countDownText: "距离封盘",
                /**两个倒计时的循环id */
                timer1: "",
                timer2: "",
                // loadImg: true,
                newqum: "", //最新开奖期号
                oldqnum: 0, //最新已开奖的期号
                game_list: [],
                show_jqkj: false,
            }
        },
        created() {
            this.gid = this.$store.state.checked_gid;
            this.gname = this.$store.state.checked_gname;
            this.change_game(this.gid,this.gname);
        },
        methods: {
            change_game(gid, gname) {
                this.clear();
                window.clearInterval(this.set_oldres_sid);
                this.get_lottery_info(gid);
                this.gname = gname;
                this.getRecentlyLottery(this.$store.state.checked_gid);
                this.set_oldres_sid = window.setInterval(() => {
                    this.getRecentlyLottery(this.$store.state.checked_gid);
                }, 5000);
            },
            /**获取当前彩种信息 */
            get_lottery_info(gid = '') {
                // console.log(this.selected_gid)
                this.axios.get("/main/index_header?gid=" + `${gid != '' ? gid : this.$store.state.checked_gid}`).then(result => {
                    if (result.data.status && result.data.msg === "请求成功！") {
                        this.$emit('stop_sale', false);
                        this.lottery_info = result.data.res;
                        this.closetime = result.data.res.closetime * 1;
                        this.now = result.data.res.now * 1;
                        this.ahead_time = result.data.res.ahead_time * 1;
                        this.$store.state.new_qnum = result.data.res.new_qnum;
                        this.$store.state.new_qnum_id = result.data.res.new_qnum_id;
                        // this.gameinfo_loading = false;
                        let t = this.closetime - this.now;
                        // console.log(t);
                        if (t <= this.lottery_info.qnum_time && t > this.ahead_time) {
                            let ct = this.closetime - this.ahead_time;
                            this.countdowm(ct, this.now, "start");
                            this.$emit('poll_lose_data', true);
                        } else {
                            this.countdowm_ahead(this.closetime - result.data.res.qnum_time, this.now, "stop");
                            this.$emit('poll_lose_data', false);
                        }
                    } else if (result.data.msg === '彩种停售') {
                        // this.loadImg = true;
                        this.lottery_info.new_qnum = '...';
                        this.lottery_info.old_qnum = '...';
                        this.lottery_info.is_open = '...';
                        this.lottery_info.not_open = '...';
                        this.lottery_info.qnum_time = 0;
                        this.lottery_info.opentime = '-';
                        this.lottery_info.stoptime = '-';
                        this.old_res = [];
                        this.lottery_info.gname = this.$store.state.game_list.find(item => item.id === this.$store.state.checked_gid).gname;
                        this.$emit('poll_lose_data', false);
                        this.$emit('stop_sale', true);
                        // this.gameinfo_loading = false;
                    }
                    let list = this.lottery_info.old_res.split(',');
                    if (gid == 20 || gid == 34 || gid == 48) {
                        let time = this.$filters.time(this.lottery_info.old_starttime, 'notime')
                        let arr = [];
                        for (let i = 0; i < list.length; i++) {
                            arr.push(lhcNum(list[i], time));
                        }
                        arr = get_lhc_sx("开奖", arr);
                        this.old_res = arr;
                    } else {
                        this.old_res = list;
                    }
                    this.gid = gid;
                });
            },
            /**投注倒计时 */
            countdowm(closetime, now, text) {
                window.clearInterval(this.timer1);
                this.countDownText = "距离封盘";
                let self = this;
                let t = (closetime - now) * 1000;
                this.$emit('stop_bet', false, '提交');
                this.timer1 = setInterval(function () {
                    // console.log(self.timer1 )
                    // self.loadImg = false;
                    // console.log(t);
                    t -= 1000;
                    if (t >= 0) {
                        let day = Math.floor(t / 86400000);
                        let hour = Math.floor((t / 3600000) % 24);
                        let min = Math.floor((t / 60000) % 60);
                        let sec = Math.floor((t / 1000) % 60);
                        if (day > 0) {
                            hour += day * 24;
                        }
                        hour = hour < 10 ? "0" + hour : hour;
                        min = min < 10 ? "0" + min : min;
                        sec = sec < 10 ? "0" + sec : sec;
                        // self.countDown_d = day;
                        self.countDown_h = hour;
                        self.countDown_m = min;
                        self.countDown_s = sec;
                        // console.log("距離封盤--"+min+":"+sec+" "+text)
                    } else {
                        window.clearInterval(self.timer1);
                        self.endcallback(text);
                    }
                }, 1000);
            },
            /**封盘倒计时 */
            countdowm_ahead(closetime, now, text) {
                this.$emit('stop_bet', true, '封盘');
                window.clearInterval(this.timer2);
                this.countDownText = "距离开盘"
                let self = this;
                let t = (closetime - now) * 1000;
                this.timer2 = setInterval(function () {
                    // self.loadImg = false;
                    // console.log(self.timer2)
                    t -= 1000;
                    if (t >= 0) {
                        let day = Math.floor(t / 86400000);
                        let hour = Math.floor((t / 3600000) % 24);
                        let min = Math.floor((t / 60000) % 60);
                        let sec = Math.floor((t / 1000) % 60);
                        hour = hour < 10 ? "0" + hour : hour;
                        min = min < 10 ? "0" + min : min;
                        sec = sec < 10 ? "0" + sec : sec;
                        self.countDown_h = hour;
                        self.countDown_m = min;
                        self.countDown_s = sec;
                        // console.log("距離開盤--"+min+":"+sec+" "+text)

                    } else {
                        // self.loadImg = true;
                        window.clearInterval(self.timer2);
                        self.endcallback_ahead();
                    }
                }, 1000);
            },
            endcallback() {
                // this.$emit("stopBet", "stop")
                this.countDownText = "距离开盘"
                this.get_lottery_info(this.gid)
            },
            endcallback_ahead() {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
                // this.$emit("stopBet", "start")
                this.countDownText = "距离封盘";
                this.get_lottery_info(this.gid);
                this.$emit('refersh_nera_bet');
            },
            /**获取近期开奖 ,设置第一组为最新开奖号码*/
            getRecentlyLottery(gid) {
                this.axios.get("/betresult/index?gid=" + gid + "&recent=1").then(result => {
                    // console.log(result.data);
                    if (result.data.status && result.data.msg === "请求成功！") {
                        if (result.data.res.list.length > 0) {
                            let strs = result.data.res.list[0]
                            let arr = [];
                            /**判断解析六合彩球号，颜色，生肖 */
                            if (result.data.res.gname.id == 20 || result.data.res.gname.id == 34 || result.data.res.gname.id == 48) {
                                let lottery_open = strs.lottery_open.split(' ')[0];
                                for (let i = 0; i < strs.res.length; i++) {
                                    arr.push(lhcNum(strs.res[i], lottery_open));
                                }
                                arr = get_lhc_sx("开奖", arr)
                            }
                            //pc蛋蛋，加拿大28，幸运28
                            else if (result.data.res.gname.id == 22 || result.data.res.gname.id == 23) {
                                let list = result.data.res.list;
                                for (let i = 0; i < strs.res.length; i++) {
                                    arr.push(pcddNum(strs.res[i]));
                                }
                                arr.push(pcddNum(strs.sum));
                                for (let i = 0; i < list.length; i++) {
                                    for (let j = 0; j < list[i].res.length; j++) {
                                        list[i].res[j] = pcddNum(list[i].res[j])
                                    }
                                    list[i].sum = pcddNum(list[i].sum)
                                }
                            }
                            /**其他彩种球号，cqssc,pk10,k3 */
                            else {
                                for (let i = 0; i < strs.res.length; i++) {
                                    arr.push(strs.res[i]);
                                }
                            }
                            this.old_res = arr;
                            this.lottery_info.old_qnum = strs.qnum;
                        }
                    }
                });
            },
            go_kjweb() {
                window.open('https://t1.1881kk.com');
            },
            get_jqkj() {
                this.$emit('get_jqkj');
            },
            clear() {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
            },
        },
    }
</script>

<style scoped>
    .gameinfo {
        border-top: 1px solid;
        border-bottom: 1px solid;
        height: 68px;
    }
    .info1,
    .info2,
    .info3 {
        display: inline-block;
        vertical-align: top;
    }
    .info3 {
        padding-left: 5px;
    }
    .info1 {
        width: 295px;
        border-right: 1px dotted;
    }
    .logo,
    .game-text {
        display: inline-block;
    }
    .logo {
        padding: 0px 5px;
    }
    .logo-img {
        width: 65px;
        height: 65px;
    }
    .game-text {
        padding: 5px 0;
        vertical-align: top;
    }
    .game-text-gname {
        line-height: 28px;
        font-weight: bold;
    }
    .green-color {
        color: green;
        font-weight: bold;
        padding: 0 3px;
    }
    .info2 {
        width: 600px;
        padding-left: 10px;
        padding-top: 2px;
        height: 29px;
    }
    .win-lose,
    .kai-time {
        display: inline-block;
    }
    .win-lose {
        color: #ff3300;
        font-weight: bold;
        line-height: 29px;
    }
    .kai-time {
        padding-left: 20px;
    }
    .new-qnum {
        color: #004eff;
        font-weight: bold;
    }
    .time-nums {
        display: inline-block;
        width: 26px;
        height: 22px;
        border: 1px solid;
        border-radius: 3px;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #e46100;
        line-height: 22px;
    }
    .time-dw {
        display: inline-block;
        margin: 0 2px;
    }
    .kai-nums {
        height: 30px;
        line-height: 30px;
    }
    .nums-k3 {
        font-size: 20px;
        line-height: 26px;
        height: 26px;
        width: 26px;
    }
    .pk10-nums span {
        height: 26px;
        width: 26px;
        line-height: 26px;
        font-size: 16px;
    }
    .last-nums-cqssc {
        font-size: 20px;
        line-height: 26px;
        height: 26px;
        width: 26px;
    }
    .last-nums-lhc span {
        width: 24px;
        height: 24px;
        line-height: 24px;
        font-size: 14px !important;
    }
    .last-nums-cqssc {
        display: inline-block;
        background: #409eff;
        color: #fff;
        font-size: 12px;
        line-height: 24px;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin: 0;
        margin-left: 6px;
        text-align: center;
        font-weight: bold;
    }
    .nums-k3 {
        display: inline-block;
        background-color: #f56c6c;
        color: #fff;
        line-height: 24px;
        font-weight: bold;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin-left: 6px;
        text-align: center;
    }
    .last-nums-lhc span {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin: 0;
        font-weight: bold;
    }
    .kjwz,
    .jqkj {
        height: 22px;
        border: 1px solid;
        border-bottom: 0;
        text-align: center;
        line-height: 22px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        display: inline-block;
        position: relative;
        top: 45px;
        cursor: pointer;
    }
    .kjwz:hover,
    .jqkj:hover {
        color: red;
    }
    .kjwz {
        width: 70px;
    }
    .jqkj {
        width: 100px;
        margin-left: 46px;
    }
</style>