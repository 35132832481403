<template>
    <div class="subBet">
        <div class="fast-bet">
            <div class="fast-btns">
                <span class="lable">快捷下注金额</span>
                <el-input size="small" class="fast-input" v-model="fast_input_val" clearable></el-input>
            </div>
            <div class="fast-btns">
                <el-button-group>
                    <el-button size="small" plain v-for="item in fast_list_btn" :key="item" @click="fast_input_val=item">{{item}}</el-button>
                </el-button-group>
                <el-popover trigger="click" transition="el-zoom-in-bottom" placement="right" popper-class="fast-add-pop">
                    <template #reference>
                        <el-button size="small" type="primary" style="margin-left:5px">+</el-button>
                    </template>
                    <el-input size="small" style="width:60px;" v-for="(item,i) in fast_list_btn" :key="i" v-model="fast_list_btn[i]"></el-input>
                </el-popover>
            </div>
        </div>
        <div class="sub">
            <el-button size="small" type="primary" @click="repeat_last_bet">&nbsp;重复上次下单&nbsp;</el-button>
            <el-button size="small" type="primary" @click="reset">&nbsp;&nbsp;重填&nbsp;&nbsp;</el-button>
            <el-button size="small" type="primary" :disabled="sub_btn.stop" @click="sub_bet">&nbsp;&nbsp;{{sub_btn.text}}&nbsp;&nbsp;</el-button>
        </div>
        <!-- 下注确认dialog -->
        <el-dialog v-model="sub_list_dialog" width="40%" customClass="mydialogclass" @close="close_sub" :lock-scroll="true">
            <template #header>
                <div class="mydialogtitle">确认下注</div>
            </template>
            <el-table :data="sub_list" size="small" border>
                <el-table-column label="注单" align="center">
                    <template #default="scope">
                        <span>
                            {{scope.row.title}}
                            <i class="el-icon-caret-right"></i>
                            {{scope.row.name}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column property="static_bonus" label="赔率" align="center"></el-table-column>
                <el-table-column property="val" label="下注金额" align="center">
                    <template #default="scope">
                        <el-popover popper-class="err-bet-val" placement="top-start" trigger="manual" :content="sub_err_msg" v-model="scope.row.sub_popover">
                            <template #reference>
                                <el-input size="small" style="text-align:center" v-model="scope.row.val" :disabled="scope.row.changeInput=='cant'" @input="reset_val(scope.row)"></el-input>
                            </template>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column property label="操作/删除" align="center">
                    <template #default="scope">
                        <el-button size="small" type="danger" circle plain @click="delete_bet(scope.$index)">x</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <template #footer>
                <div class="dialog-footer" style="text-align:right">
                    <span class="sub-list-sum">
                        合计注数：
                        <span v-if="sub_list[0].title.includes('对碰')">{{sub_list[0].bet_count}}</span>
                        <span v-else>{{sub_list.length}}</span>
                        合计金额：
                        <span>{{sub_list_sum}}</span>
                    </span>
                    <el-button @click="sub_list_dialog = false">取 消</el-button>
                    <el-button type="warning" @click="confirm_sub_bet" :disabled="sub_repeat_time!==0">
                        <span>确 定</span>
                        <span v-if="sub_repeat_time">({{sub_repeat_time}})</span>
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import { postObj } from "../../plugin/postFormData";
    import md5 from 'js-md5'
    export default {
        data() {
            return {
                //快捷输入框值
                fast_input_val: "",
                //快捷按钮组
                fast_list_btn: [50, 100, 200, 500, 1000],
                /**提交下注列表 */
                sub_list: [{ title: '' }],
                //下注金额总计
                sub_list_sum: 0,
                //下注金额不符合提示文字
                sub_err_msg: "",
                /**确认下注单dialog显示隐藏 */
                sub_list_dialog: false,
                sub_repeat_time: 0,
                sub_repeat_time_sid: "",
                sub_btn: {
                    stop: false,
                    text: "提交",
                }
            }
        },
        methods: {
            //六合彩快速下注
            fastsub(list) {
                this.sub_list = list;
                this.confirm_sub_bet('fast_lhc_sub');
            },
            //停止下注
            stop_bet(state, text) {
                this.sub_btn.stop = state;
                this.sub_btn.text = text;
                this.sub_list_dialog = false;
                this.fast_input_val = '';
            },
            /**dialog确认下注,最后提交 */
            confirm_sub_bet(type = '') {
                //判断是否满足最小，最大下注金额
                for (let i = 0; i < this.sub_list.length; i++) {
                    if (this.sub_list[i].title.includes('对碰')) {
                        if (this.sub_list[i].val < this.sub_list[i].straightbet_low * this.sub_list[i].bet_count) {
                            this.sub_err_msg = "✖ 错误，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low * this.sub_list[i].bet_count;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low * this.sub_list[i].bet_count)
                            return;
                        }
                        if (this.sub_list[i].val > this.sub_list[i].straightbet_high * this.sub_list[i].bet_count) {
                            this.sub_err_msg = "✖ 错误，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high * this.sub_list[i].bet_count;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high * this.sub_list[i].bet_count)
                            return;
                        }
                    } else {
                        if (this.sub_list[i].val < this.sub_list[i].straightbet_low * 1) {
                            this.sub_err_msg = "✖ 错误，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能小于最小投注金额：" + this.sub_list[i].straightbet_low)
                            return;
                        }
                        if (this.sub_list[i].val > this.sub_list[i].straightbet_high * 1) {
                            this.sub_err_msg = "✖ 错误，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high;
                            this.sub_list[i].sub_popover = true;
                            this.$message.error(this.sub_list[i].title + this.sub_list[i].name + "，下注金额不能大于最大投注金额：" + this.sub_list[i].straightbet_high)
                            return;
                        }
                    }
                }
                //格式化数据
                let checked_gid = this.$store.state.checked_gid;
                let playing = this.$store.state.playing;
                let new_qnum = this.$store.state.new_qnum;
                let new_qnum_id = this.$store.state.new_qnum_id;
                let activeName = this.$store.state.activeName;
                let uid = this.$store.state.userinfo.user_id;
                let post_data = postObj(
                    this.sub_list,
                    {
                        id: checked_gid,
                        activeName: activeName,
                        new_qnum: new_qnum,
                        new_qnum_id: new_qnum_id
                    }
                );
                // console.log(this.sub_list)
                //md5
                for (let i = 0; i < this.sub_list.length; i++) {
                    //b_sign
                    let game_multiple = this.sub_list[i].bet_count != undefined ? this.sub_list[i].val / this.sub_list[i].bet_count : this.sub_list[i].val;
                    let bet_count = this.sub_list[i].bet_count != undefined ? this.sub_list[i].bet_count : 1;
                    post_data.b_sign.push(
                        md5(`${checked_gid}0NhGGgggiK3a7O7jEeoZ${this.sub_list[i].subname}${this.$store.state.new_qnum_id}${this.$store.state.new_qnum}${uid}${post_data.place[i]}1${game_multiple}0${bet_count}`
                        )
                    )
                    //c_sign
                    //六合彩的加密字段
                    if (checked_gid == 34 || checked_gid == 20 || checked_gid == 48) {
                        // console.log(this.sub_list[i].betclass_nums)
                        // console.log(this.sub_list[i].name +this.sub_list[i].betclass_nums.toString()+ post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid)
                        if (playing == "sebo") {
                            post_data.c_sign.push(md5(this.sub_list[i].name + this.sub_list[i].betclass_nums.toString() + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                        else if (playing == "texiao" || playing == "yxiao" || playing == "yxiaobz") {
                            // console.log(this.sub_list[i].name +this.sub_list[i].other.toString()+ post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid)
                            post_data.c_sign.push(md5(this.sub_list[i].name + this.sub_list[i].other.toString() + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                        else {
                            post_data.c_sign.push(md5(this.sub_list[i].title + this.sub_list[i].name + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                    }
                    //快3 C加密
                    else if (checked_gid == 6 || checked_gid == 29 || checked_gid == 30 || checked_gid == 31 || checked_gid == 32 || checked_gid == 36) {
                        if (this.sub_list[i].title === '和值' || this.sub_list[i].title === '红黑码' || this.sub_list[i].title === '三军') {
                            post_data.c_sign.push(md5(post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        } else {
                            post_data.c_sign.push(md5(this.sub_list[i].title + this.sub_list[i].name + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                        }
                    }
                    else {
                        post_data.c_sign.push(md5(this.sub_list[i].title + this.sub_list[i].name + post_data.place[i] + "0NhGGgggiK3a7O7jEeoZ" + uid));
                    }
                    // console.log('title：' + this.sub_list[i].title + '\n name：' + this.sub_list[i].name + '\n place：' + post_data.place[i] + '\n salt：' + "0NhGGgggiK3a7O7jEeoZ" + '\n uid：' + uid)
                }
                // console.log(post_data)
                //避免重复提交
                this.sub_repeat_time = 3;
                let self = this;
                this.sub_repeat_time_sid = window.setInterval(() => {
                    self.sub_repeat_time -= 1;
                    if (self.sub_repeat_time === 0) {
                        window.clearInterval(self.sub_repeat_time_sid);
                    }
                }, 1000)
                //发送后台验证
                // console.log(post_data)
                this.axios.post("order/checkout_bet", post_data).then(result => {
                    // console.log(result.data)
                    if (result.data.status == 1 && result.data.msg == "投注成功") {
                        this.$alert(result.data.msg, "恭喜您", {
                            confirmButtonText: "确定",
                            type: "success",
                            callback: () => {
                                //关闭dialog
                                this.sub_list_dialog = false;
                                //刷新余额
                                this.$parent.get_user_balance();
                                //保存为上次投注
                                this.$store.state.last_bet.list = [];
                                for (let i = 0; i < this.sub_list.length; i++) {
                                    // console.log(this.sub_list[i].val)
                                    this.$store.state.last_bet.list.push({
                                        title: this.sub_list[i].title,
                                        name: this.sub_list[i].name,
                                        val: this.sub_list[i].val
                                    });
                                }
                                this.$store.state.last_bet.gid = checked_gid;
                                this.$store.state.last_bet.playing = playing;
                                // console.log(this.$store.state.lottery.last_bet.list)
                                this.sub_list = [{ title: '' }];
                                //清空已输入
                                this.reset();
                                if (type == 'fast_lhc_sub') {
                                    this.$emit('reset_fast_sub');
                                }
                            }
                        });
                    } else {
                        this.$alert(result.data.msg, "错误", {
                            confirmButtonText: "确定",
                            type: "error",
                        });
                    }
                    // console.log(post_data)
                })
            },

            /**dialog确认下注,修改金额 */
            reset_val(item) {
                //判断关闭/显示，最小最大限额pop
                if (item.val >= item.straightbet_low && item.val <= item.straightbet_high) {
                    item.sub_popover = false;
                } else {
                    item.sub_popover = true;
                    let errMsg = '';
                    if (item.val < item.straightbet_low) {
                        errMsg = "✖ 错误，下注金额不能小于最小投注金额：" + item.straightbet_low;
                    }
                    if (item.val > item.straightbet_high) {
                        errMsg = "✖ 错误，下注金额不能大于最大投注金额：" + item.straightbet_high;
                    }
                    if (errMsg !== '') {
                        this.sub_err_msg = errMsg;
                    }
                    // item.val < item.straightbet_low ? this.sub_err_msg = "✖ 错误，下注金额不能小于最小投注金额：" + item.straightbet_low : "";
                    // item.val > item.straightbet_high ? this.sub_err_msg = "✖ 错误，下注金额不能大于最大投注金额：" + item.straightbet_high : "";
                }
                //计算总计
                this.sub_list_sum = 0;
                for (let i = 0; i < this.sub_list.length; i++) {
                    this.sub_list_sum += this.sub_list[i].val * 1;
                }
                // console.log(this.sub_list);
            },

            /**dialog确认下注,删除注单操作 */
            delete_bet(index) {
                //如果全部删除，隐藏dialog
                if (this.sub_list.length == 1) {
                    return this.sub_list_dialog = false;
                }
                // console.log(index)
                this.sub_list.splice(index, 1);
                //计算总计
                this.sub_list_sum = 0;
                for (let i = 0; i < this.sub_list.length; i++) {
                    this.sub_list_sum += this.sub_list[i].val * 1;
                }
                // console.log(this.sub_list.length)
            },

            /**dialog确认下注,关闭 */
            close_sub() {
                //判断是否满足有未关闭的金额POP
                for (let i = 0; i < this.sub_list.length; i++) {
                    this.sub_list[i].sub_popover ? this.sub_list[i].sub_popover = false : '';
                }
                this.reset();
            },

            /**提交，下注 */
            sub_bet() {
                this.sub_list = [];
                this.sub_list = JSON.parse(JSON.stringify(this.$parent.sub_bet()));
                if (this.sub_list.type == 'error') {
                    this.$message.error(this.sub_list.msg);
                    this.sub_list = [{ title: '' }];
                    return;
                }
                let playing = this.$store.state.playing;
                if (playing === 'honghm' && this.sub_list.length === 0) { return false }
                if (this.sub_list.length === 0) {
                    this.$message.error({
                        message: '抱歉，您还没有选择下注项！',
                        type: 'error',
                        center: true
                    });
                    this.sub_list = [{ title: '' }];
                    this.reset();
                } else {
                    //计算总计
                    this.sub_list_sum = 0;
                    for (let i = 0; i < this.sub_list.length; i++) {
                        this.sub_list_sum += this.sub_list[i].val * 1;
                    }
                    if (this.sub_list_sum == 0) {
                        this.$message.error({
                            message: '抱歉，您还没有填写下注金额！',
                            type: 'error',
                            center: true
                        });
                        this.sub_list = [{ title: '' }];
                        this.reset();
                    } else {
                        this.sub_list_dialog = true;
                    }
                }
            },

            /**重复上次下单 */
            repeat_last_bet() {
                let gid = this.$store.state.checked_gid;
                let playing = this.$store.state.playing;
                let last_gid = this.$store.state.lottery.last_bet.gid;
                let last_playing = this.$store.state.lottery.last_bet.playing;
                let list = this.$store.state.lottery.last_bet.list;
                // console.log(list,gid,last_gid,playing,last_playing)
                if (list.length && gid == last_gid && playing == last_playing) {
                    this.$parent.repeat_last_bet(gid, playing, list);
                } else {
                    this.$message.error({
                        message: '抱歉，您没有下注记录！',
                        type: 'error',
                        offset: 500,
                        center: true
                    });
                }
            },
            /**获取快捷下注金额 */
            get_fast() {
                return this.fast_input_val;
            },
            /**重填 */
            reset() {
                this.fast_input_val = "";
                this.$emit('reset');
            },

        },

    }
</script>

<style scoped>
    .fast-btns {
        float: left;
        padding: 10px;
    }
    .subBet .fast-bet {
        width: 60%;
        min-width: 600px;
        display: inline-block;
    }

    .subBet .fast-bet .lable {
        margin-right: 10px;
    }

    .subBet .fast-bet .fast-input {
        width: 70px;
        margin-right: 10px;
    }

    .subBet .sub {
        float: right;
        padding: 10px;
    }
    .sub-list-sum {
        margin: 0 20px;
    }

    .sub-list-sum span {
        color: #f56c6c;
        margin-right: 10px;
        font-weight: bold;
    }
</style>
