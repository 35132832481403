<template>
    <div class="wlian">
        <!-- 两面 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in wlian" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="wlian_which_num">
                        <el-radio-group v-model="radio" fill size="small" text-color="#fff" @change="wlian_change_num">
                            <el-radio-button label="wlian2">二尾连</el-radio-button>
                            <el-radio-button label="wlian3">三尾连</el-radio-button>
                            <el-radio-button label="wlian4">四尾连</el-radio-button>
                            <el-radio-button label="wlian5">五尾连</el-radio-button>
                        </el-radio-group>
                        <el-radio-group class="type-radio" v-model="typeRadio" @change="change_typeRadio">
                            <el-radio label="0">单注/复试</el-radio>
                            <el-radio label="1">胆拖</el-radio>
                        </el-radio-group>
                        <div v-if="typeRadio==1" style="margin-top:10px">
                            <div>
                                <span style="color:#F56C6C">已选胆：</span>
                                <span>{{dan.toString()}}</span>
                            </div>
                            <div>
                                <span style="color:#F56C6C">已选拖：</span>
                                <span>{{tuo.toString()}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="bet-items-one">
                        <div v-for="i in item.betclass" :key="i.index" class="item">
                            <span class="bet-name">{{i.name}}尾</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-checkbox label class="wlian" v-model="i.val" @change="checkbox_change($event,i.name)" :disabled="i.disabled"></el-checkbox>
                            <div class="betclassname_wlian">
                                <span v-for="v in i.betclass_nums" :key="v.index">
                                    <span v-if="v.color=='red'" class="wlian_nums_red">{{v.num}}</span>
                                    <span v-if="v.color=='blue'" class="wlian_nums_blue">{{v.num}}</span>
                                    <span v-if="v.color=='green'" class="wlian_nums_green">{{v.num}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="one-bet">
                        <span>下注金额：</span>
                        <el-input class="wlian-val" v-model="wlian_val" size="small" v-on:focus="wlian_val=check_fast_val(wlian_val)"></el-input>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { isArray } from 'util';
    export default {
        data() {
            return {
                wlian: [
                    {
                        ballname: "尾连",
                        betclass: [
                            {
                                title: "尾连", name: "0", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 10, color: "blue" }, { num: 20, color: "blue" }, { num: 30, color: "red" }, { num: 40, color: "red" }]
                            },
                            {
                                title: "尾连", name: "1", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 1, color: "red" }, { num: 11, color: "green" }, { num: 21, color: "green" }, { num: 31, color: "blue" }, { num: 41, color: "blue" }]
                            },
                            {
                                title: "尾连", name: "2", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 2, color: "red" }, { num: 12, color: "red" }, { num: 22, color: "green" }, { num: 32, color: "green" }, { num: 42, color: "blue" }]
                            },
                            {
                                title: "尾连", name: "3", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 3, color: "blue" }, { num: 13, color: "red" }, { num: 23, color: "red" }, { num: 33, color: "green" }, { num: 43, color: "green" }]
                            },
                            {
                                title: "尾连", name: "4", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 4, color: "blue" }, { num: 14, color: "blue" }, { num: 24, color: "red" }, { num: 34, color: "red" }, { num: 44, color: "green" }]
                            },
                            {
                                title: "尾连", name: "5", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 5, color: "green" }, { num: 15, color: "blue" }, { num: 25, color: "blue" }, { num: 35, color: "red" }, { num: 45, color: "red" }]
                            },
                            {
                                title: "尾连", name: "6", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 6, color: "green" }, { num: 16, color: "green" }, { num: 26, color: "blue" }, { num: 36, color: "blue" }, { num: 46, color: "red" }]
                            },
                            {
                                title: "尾连", name: "7", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 7, color: "red" }, { num: 17, color: "green" }, { num: 27, color: "green" }, { num: 37, color: "blue" }, { num: 47, color: "blue" }]
                            },
                            {
                                title: "尾连", name: "8", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 8, color: "red" }, { num: 18, color: "red" }, { num: 28, color: "green" }, { num: 38, color: "green" }, { num: 48, color: "blue" }]
                            },
                            {
                                title: "尾连", name: "9", visible: false, static_bonus: "-", val: "", disabled: false, subname: "12_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff",
                                betclass_nums: [{ num: 9, color: "blue" }, { num: 19, color: "red" }, { num: 29, color: "red" }, { num: 39, color: "green" }, { num: 49, color: "green" }]
                            },
                        ]
                    }
                ],
                radio: 'wlian2',
                typeRadio: '0',
                // 选择的个数，限制7个
                wlian_count: 0,
                /**尾连下注金额 */
                wlian_val: "",
                zhengxiao: "",
                zhengxiaopl: '',
                dan: [],//选中的胆
                tuo: [],//选中的拖
            }
        },
        methods: {
            wlian_change_num(label) {
                // console.log(label)
                this.$emit('regainLoss', label)
                this.reset();
                this.get_lose();
            },
            change_typeRadio() {
                this.reset();
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //特码
                for (let j = 0; j < this.wlian[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.name.includes(this.wlian[0].betclass[j].name)) {
                            this.wlian[0].betclass[j].static_bonus = item.rate;
                            this.wlian[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.wlian[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.wlian_val = "";
                this.wlian_count = 0;
                for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                    this.wlian[0].betclass[i].val = false;
                    this.wlian[0].betclass[i].disabled = false;
                    // console.log(this.wlian[0].betclass[i].val)
                }
                this.dan = [];
                this.tuo = [];
            },
            /**多选框点击 */
            checkbox_change(v, name) {
                // console.log(v, name);
                v ? this.wlian_count++ : this.wlian_count--;
                if (this.typeRadio == '0') {
                    // console.log(this.wlian_count);
                    if (this.wlian_count >= 8) {
                        for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                            if (this.wlian[0].betclass[i].val == false) {
                                this.wlian[0].betclass[i].disabled = true;
                            }
                        }
                    } else {
                        for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                            if (this.wlian[0].betclass[i].val == false) {
                                this.wlian[0].betclass[i].disabled = false;
                            }
                        }
                    }
                } else if (this.typeRadio == '1') {
                    let length = this.radio[this.radio.length - 1] - 1;
                    if (v) {
                        if (this.dan.length < length) {
                            this.dan.push(name);
                        } else {
                            this.tuo.push(name);
                        }
                        if (this.dan.length == length && this.tuo.length > 0) {
                            this.wlian[0].betclass.forEach(item => {
                                this.dan.forEach(i => {
                                    if (item.name == i) {
                                        item.disabled = true;
                                    }
                                })
                            });
                        }
                    } else {
                        if (this.dan.length < length && this.tuo.length == 0) {
                            this.dan.splice(this.dan.findIndex(item => item == name), 1)
                        }
                        if (this.dan.length == length && this.tuo.length == 0) {
                            this.dan.splice(this.dan.findIndex(item => item == name), 1)
                        }
                        if (this.dan.length == length && this.tuo.length > 0) {
                            this.tuo.splice(this.tuo.findIndex(item => item == name), 1);
                            if (this.dan.length == length && this.tuo.length == 0) {
                                this.wlian[0].betclass.forEach(item => {
                                    this.dan.forEach(i => {
                                        if (item.name == i) {
                                            item.disabled = false;
                                        }
                                    })
                                });
                            }
                        }
                    }
                }
            },
            tuo_dan_nums(dan = [], tuo = []) {
                let list = [];
                tuo.forEach(item => {
                    list.push((dan.toString() + `,${item}`).split(','));
                })
                return list;
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                let index = 0;
                let level = '';
                if (this.radio == "wlian2") {
                    index = 2;
                    level = '12_0_0';
                }
                if (this.radio == "wlian3") {
                    index = 3;
                    level = '12_1_0';
                }
                if (this.radio == "wlian4") {
                    index = 4;
                    level = '12_2_0';
                }
                if (this.radio == "wlian5") {
                    index = 5;
                    level = '12_3_0';
                }
                if (this.wlian_count < index) {
                    this.$message.error({
                        message: '提交失败，请至少选择' + index + '个',
                        type: 'error',
                        offset: 465,
                        center: true,
                    });
                } else {
                    if (this.wlian_val && !isNaN(this.wlian_val * 1) && this.wlian_count > 0) {
                        for (let i = 0; i < this.wlian.length; i++) {
                            for (let j = 0; j < this.wlian[i].betclass.length; j++) {
                                if (this.wlian[i].betclass[j].val) {
                                    this.wlian[i].betclass[j].val = this.wlian_val;
                                    list.push(this.wlian[i].betclass[j]);
                                }
                            }
                        }
                        let choose_num = [];
                        // console.log(list)
                        for (let i = 0; i < list.length; i++) {
                            choose_num.push(list[i].name);
                        }
                        //多选号码，重新组合成注单
                        let bet_obj = list[0];
                        // console.log(bet_obj);
                        list = [];
                        let new_arr = this.typeRadio == '0' ? this.combination_arr_elem(choose_num, index) : this.tuo_dan_nums(this.dan, this.tuo);
                        for (let i = 0; i < new_arr.length; i++) {
                            list.push(
                                {
                                    title: `${index}尾连`,
                                    name: new_arr[i].toString(),
                                    visible: bet_obj.visible,
                                    static_bonus: bet_obj.static_bonus,
                                    val: bet_obj.val,
                                    subname: level,
                                    straightbet_high: bet_obj.straightbet_high,
                                    straightbet_low: bet_obj.straightbet_low,
                                    bonus_high: bet_obj.bonus_high,
                                    sub_popover: bet_obj.sub_popover,
                                    color: bet_obj.color,
                                    isChoose: bet_obj.isChoose,
                                }
                            )
                        }
                    }
                }
                //筛选最小的赔率
                // console.log(list);
                for (let i = 0; i < list.length; i++) {
                    list[i].ball_list = list[i].name.split(',');
                    list[i].ball_loss_list = [];
                    // console.log(list[i].ball_list);
                    for (let k = 0; k < list[i].ball_list.length; k++) {
                        for (let j = 0; j < this.wlian[0].betclass.length; j++) {
                            // console.log(this.wlian[0].betclass[j].name, list[i].ball_list[k]);
                            if (this.wlian[0].betclass[j].name == list[i].ball_list[k]) {
                                list[i].ball_loss_list.push({
                                    loss: this.wlian[0].betclass[j].static_bonus,
                                    ball: this.wlian[0].betclass[j].name,
                                });
                            }
                        }
                    }
                    let sitem = list[i].ball_loss_list.sort((a, b) => a.loss - b.loss)[0];
                    // console.log(list[i]);
                    list[i].ball_min = list[i].name.includes('0') ? 0 : sitem.ball;
                    list[i].static_bonus = list[i].name.includes('0') ? list[i].ball_loss_list.find(item => item.ball == 0).loss : sitem.loss;
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    if (!isArray(list[i].title)) {
                        list[i].title = list[i].title.split(",");
                    }
                    for (let j = 0; j < this.wlian.length; j++) {
                        for (let k = 0; k < this.wlian[j].betclass.length; k++) {
                            // console.log(this.wlian[j].betclass[k].name)
                            if (list[i].title.find(n => n == this.wlian[j].betclass[k].name)) {
                                this.wlian[j].betclass[k].val = true;
                            }
                        }
                    }
                }
                this.wlian_count = 0;
                for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                    if (this.wlian[0].betclass[i].val) {
                        this.wlian_count += 1;
                    }
                }
                if (this.wlian_count >= 8) {
                    for (let i = 0; i < this.wlian[0].betclass.length; i++) {
                        if (this.wlian[0].betclass[i].val == false) {
                            this.wlian[0].betclass[i].disabled = true;
                        }
                    }
                }
            },
            combination_arr_elem(nu, groupl, res) {
                let result = res ? res : [];
                let nuc = nu.slice(0);
                let item = nuc.shift();
                item = item.constructor === Array ? item : [item];
                (function func(item, nuc) {
                    let itemc;
                    let nucc = nuc.slice(0);
                    let margin = groupl - item.length
                    if (margin == 0) {
                        result.push(item);
                        return;
                    }
                    if (margin == 1) {
                        for (let j in nuc) {
                            itemc = item.slice(0);
                            itemc.push(nuc[j]);
                            result.push(itemc);
                        }
                    }
                    if (margin > 1) {
                        itemc = item.slice(0);
                        itemc.push(nucc.shift());
                        func(itemc, nucc);
                        if (item.length + nucc.length >= groupl) {
                            func(item, nucc);
                        }
                    }
                })(item, nuc);
                if (nuc.length >= groupl) {
                    return this.combination_arr_elem(nuc, groupl, result);
                } else {
                    return result;
                }
            },
        },
    }
</script>

<style scoped>
    .wlian_which_num {
        text-align: center;
        padding-bottom: 5px;
    }
    .wlian_nums_red,
    .wlian_nums_blue,
    .wlian_nums_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        font-weight: bold;
    }
    .wlian_nums_red {
        background-color: #f56c6c;
    }
    .wlian_nums_blue {
        background-color: #409eff;
    }
    .wlian_nums_green {
        background-color: #67c23a;
    }
    .wlian-val {
        width: 100px;
    }

    .wlian .bet-items-one .bet-name {
        width: 20px;
    }
    .wlian .bet-items-one .wlian-input {
        width: 100px;
    }
    .wlian .bet-items-one .bet-lose {
        text-align: center;
        width: 70px;
    }
    .type-radio {
        width: 100%;
        justify-content: center;
    }
    .betclassname_wlian {
        display: inline-block;
        text-align: left;
    }
    .item {
        width: 280px;
        margin: 0 auto;
    }
    .one-bet {
        width: 200px;
        margin: 5px auto;
    }
</style>