<template>
    <div class="pl35hz">
        <el-row justify="space-around" :gutter="3">
            <el-col :span="8" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <el-row class="bet-items">
                    <div v-for="i in item.betclass" :key="i.index" class="item-info" :style="{'float':i.name<14?'left':'right'}">
                        <span class="bet-name" style="font-weight:bold">{{i.name}}</span>
                        <span class="bet-lose">{{i.static_bonus}}</span>
                        <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                    </div>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        ballname: "前和值",
                        betclass: [
                            { title: "前和值", name: "0", visible: false, static_bonus: "", val: "", subname: "15_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "1", visible: false, static_bonus: "", val: "", subname: "15_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "2", visible: false, static_bonus: "", val: "", subname: "15_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "3", visible: false, static_bonus: "", val: "", subname: "15_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "4", visible: false, static_bonus: "", val: "", subname: "15_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "5", visible: false, static_bonus: "", val: "", subname: "15_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "6", visible: false, static_bonus: "", val: "", subname: "15_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "7", visible: false, static_bonus: "", val: "", subname: "15_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "8", visible: false, static_bonus: "", val: "", subname: "15_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "9", visible: false, static_bonus: "", val: "", subname: "15_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "10", visible: false, static_bonus: "", val: "", subname: "15_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "11", visible: false, static_bonus: "", val: "", subname: "15_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "12", visible: false, static_bonus: "", val: "", subname: "15_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "13", visible: false, static_bonus: "", val: "", subname: "15_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "14", visible: false, static_bonus: "", val: "", subname: "15_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "15", visible: false, static_bonus: "", val: "", subname: "15_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "16", visible: false, static_bonus: "", val: "", subname: "15_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "17", visible: false, static_bonus: "", val: "", subname: "15_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "18", visible: false, static_bonus: "", val: "", subname: "15_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "19", visible: false, static_bonus: "", val: "", subname: "15_0_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "20", visible: false, static_bonus: "", val: "", subname: "15_0_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "21", visible: false, static_bonus: "", val: "", subname: "15_0_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "22", visible: false, static_bonus: "", val: "", subname: "15_0_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "23", visible: false, static_bonus: "", val: "", subname: "15_0_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "24", visible: false, static_bonus: "", val: "", subname: "15_0_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "25", visible: false, static_bonus: "", val: "", subname: "15_0_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "26", visible: false, static_bonus: "", val: "", subname: "15_0_26", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前和值", name: "27", visible: false, static_bonus: "", val: "", subname: "15_0_27", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "中和值",
                        betclass: [
                            { title: "中和值", name: "0", visible: false, static_bonus: "", val: "", subname: "15_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "1", visible: false, static_bonus: "", val: "", subname: "15_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "2", visible: false, static_bonus: "", val: "", subname: "15_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "3", visible: false, static_bonus: "", val: "", subname: "15_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "4", visible: false, static_bonus: "", val: "", subname: "15_1_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "5", visible: false, static_bonus: "", val: "", subname: "15_1_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "6", visible: false, static_bonus: "", val: "", subname: "15_1_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "7", visible: false, static_bonus: "", val: "", subname: "15_1_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "8", visible: false, static_bonus: "", val: "", subname: "15_1_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "9", visible: false, static_bonus: "", val: "", subname: "15_1_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "10", visible: false, static_bonus: "", val: "", subname: "15_1_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "11", visible: false, static_bonus: "", val: "", subname: "15_1_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "12", visible: false, static_bonus: "", val: "", subname: "15_1_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "13", visible: false, static_bonus: "", val: "", subname: "15_1_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "14", visible: false, static_bonus: "", val: "", subname: "15_1_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "15", visible: false, static_bonus: "", val: "", subname: "15_1_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "16", visible: false, static_bonus: "", val: "", subname: "15_1_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "17", visible: false, static_bonus: "", val: "", subname: "15_1_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "18", visible: false, static_bonus: "", val: "", subname: "15_1_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "19", visible: false, static_bonus: "", val: "", subname: "15_1_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "20", visible: false, static_bonus: "", val: "", subname: "15_1_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "21", visible: false, static_bonus: "", val: "", subname: "15_1_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "22", visible: false, static_bonus: "", val: "", subname: "15_1_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "23", visible: false, static_bonus: "", val: "", subname: "15_1_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "24", visible: false, static_bonus: "", val: "", subname: "15_1_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "25", visible: false, static_bonus: "", val: "", subname: "15_1_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "26", visible: false, static_bonus: "", val: "", subname: "15_1_26", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中和值", name: "27", visible: false, static_bonus: "", val: "", subname: "15_1_27", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "后和值",
                        betclass: [
                            { title: "后和值", name: "0", visible: false, static_bonus: "", val: "", subname: "15_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "1", visible: false, static_bonus: "", val: "", subname: "15_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "2", visible: false, static_bonus: "", val: "", subname: "15_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "3", visible: false, static_bonus: "", val: "", subname: "15_2_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "4", visible: false, static_bonus: "", val: "", subname: "15_2_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "5", visible: false, static_bonus: "", val: "", subname: "15_2_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "6", visible: false, static_bonus: "", val: "", subname: "15_2_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "7", visible: false, static_bonus: "", val: "", subname: "15_2_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "8", visible: false, static_bonus: "", val: "", subname: "15_2_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "9", visible: false, static_bonus: "", val: "", subname: "15_2_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "10", visible: false, static_bonus: "", val: "", subname: "15_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "11", visible: false, static_bonus: "", val: "", subname: "15_2_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "12", visible: false, static_bonus: "", val: "", subname: "15_2_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "13", visible: false, static_bonus: "", val: "", subname: "15_2_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "14", visible: false, static_bonus: "", val: "", subname: "15_2_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "15", visible: false, static_bonus: "", val: "", subname: "15_2_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "16", visible: false, static_bonus: "", val: "", subname: "15_2_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "17", visible: false, static_bonus: "", val: "", subname: "15_2_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "18", visible: false, static_bonus: "", val: "", subname: "15_2_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "19", visible: false, static_bonus: "", val: "", subname: "15_2_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "20", visible: false, static_bonus: "", val: "", subname: "15_2_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "21", visible: false, static_bonus: "", val: "", subname: "15_2_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "22", visible: false, static_bonus: "", val: "", subname: "15_2_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "23", visible: false, static_bonus: "", val: "", subname: "15_2_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "24", visible: false, static_bonus: "", val: "", subname: "15_2_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "25", visible: false, static_bonus: "", val: "", subname: "15_2_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "26", visible: false, static_bonus: "", val: "", subname: "15_2_26", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后和值", name: "27", visible: false, static_bonus: "", val: "", subname: "15_2_27", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
            }
        },
        methods: {
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].static_bonus = static_bonus[j];
                        this.balls[i].betclass[j].straightbet_high = others[j].straightbet_high;
                        this.balls[i].betclass[j].straightbet_low = others[j].straightbet_low;
                    }
                    static_bonus = static_bonus.slice(28);
                    others = others.slice(28);
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].val && !isNaN(this.balls[i].betclass[j].val * 1)) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //1-5
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>
<style scoped>
    .bet-items {
        height: 402px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
		flex-wrap:wrap;
    }
    .item-info {
        width: 50%;
    }
</style>