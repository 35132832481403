<template>
    <div class="hongheima">
        <!-- 红黑 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in hhm" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <div style="text-align:center">
                            <span>请先填入下注类型金额，再选择相应个数的号码</span>
                            <el-checkbox-group class="nums" :disabled="hhm[0].betclass[0].val===''&&hhm[0].betclass[1].val===''&&hhm[0].betclass[2].val===''" v-model="selected_nums" @change="selected_nums_change">
                                <el-checkbox v-for="item of nums" :label="item" :key="item.num" :disabled="item.disabled">{{item.num}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                        <el-row>
                            <el-col :span="8">
                                <span class="bet-name">{{item.betclass[0].name}}</span>
                                <span class="bet-lose">{{item.betclass[0].static_bonus}}</span>
                                <el-input v-model="item.betclass[0].val" size="small" :disabled="item.betclass[1].val!==''||item.betclass[2].val!==''" v-on:focus="item.betclass[0].val=check_fast_val(item.betclass[0].val)"></el-input>
                            </el-col>
                            <el-col :span="8">
                                <span class="bet-name">{{item.betclass[1].name}}</span>
                                <span class="bet-lose">{{item.betclass[1].static_bonus}}</span>
                                <el-input v-model="item.betclass[1].val" size="small" :disabled="item.betclass[0].val!==''||item.betclass[2].val!==''" v-on:focus="item.betclass[1].val=check_fast_val(item.betclass[1].val)"></el-input>
                            </el-col>
                            <el-col :span="8">
                                <span class="bet-name">{{item.betclass[2].name}}</span>
                                <span class="bet-lose">{{item.betclass[2].static_bonus}}</span>
                                <el-input v-model="item.betclass[2].val" size="small" :disabled="item.betclass[1].val!==''||item.betclass[0].val!==''" v-on:focus="item.betclass[2].val=check_fast_val(item.betclass[2].val)"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { link } from 'fs';
    export default {
        data() {
            return {
                /**红黑 */
                hhm: [
                    {
                        ballname: "红黑码",
                        betclass: [
                            { title: "红黑码", name: "四码红", visible: false, static_bonus: "-", val: "", subname: "8_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑码", name: "四码黑", visible: false, static_bonus: "-", val: "", subname: "8_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑码", name: "五码黑", visible: false, static_bonus: "-", val: "", subname: "8_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                nums: [{ num: 1, disabled: false }, { num: 2, disabled: false }, { num: 3, disabled: false }, { num: 4, disabled: false }, { num: 5, disabled: false }, { num: 6, disabled: false }],
                selected_nums: [],
                selected_name: '',
            }
        },

        methods: {
            //选择号码
            selected_nums_change(v) {
                let len = v.length;
                let name = this.hhm[0].betclass.find(item => item.val !== '').name;
                let arr = this.nums.filter(item => {
                    if (!v.includes(item))
                        return item
                })
                if ((name === '四码红' || name === '四码黑')) {
                    len >= 4 ? arr.forEach(item => item.disabled = true) : arr.forEach(item => item.disabled = false);
                } else {
                    len >= 5 ? arr.forEach(item => item.disabled = true) : arr.forEach(item => item.disabled = false);
                }
                this.selected_name = name;
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.hhm[i].betclass[j].subname) {
                                this.hhm[i].betclass[j].static_bonus = item.rate;
                                this.hhm[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.hhm[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    this.nums.forEach(item => item.disabled = false);
                    this.selected_nums = [];
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //红黑
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        this.hhm[i].betclass[j].val = "";
                    }
                }
                this.nums.forEach(item => item.disabled = false);
                this.selected_nums = [];
            },
            /**提交，下注 */
            sub_bet() {
                if (this.selected_nums.length < 1) {
                    return {type:'error',msg:'请选择对应个数的号码'};
                }
                if (this.selected_name === '四码红' || this.selected_name === '四码黑') {
                    if (this.selected_nums.length !== 4) {
                        return {type:'error',msg:'请至少选择4个号码'};
                    }
                } else {
                    if (this.selected_nums.length !== 5) {
                        return {type:'error',msg:'请至少选择5个号码'};
                    }
                }
                let list = [];
                //红黑
                for (let i = 0; i < this.hhm.length; i++) {
                    for (let j = 0; j < this.hhm[i].betclass.length; j++) {
                        if (this.hhm[i].betclass[j].val && !isNaN(this.hhm[i].betclass[j].val * 1)) {
                            list.push(this.hhm[i].betclass[j]);
                        }
                    }
                }
                //提取号码并小到大排序
                let str = this.selected_nums.map(item => item.num).sort((a, b) => a - b);
                list = JSON.parse(JSON.stringify(list));
                list[0].name += `[${str.toString()}]`
                // console.log(list[0].name);
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //红黑
                    for (let j = 0; j < this.hhm.length; j++) {
                        for (let k = 0; k < this.hhm[j].betclass.length; k++) {
                            if (this.hhm[j].betclass[k].title == list[i].title && list[i].name.includes(this.hhm[j].betclass[k].name)) {
                                this.hhm[j].betclass[k].val = list[i].val;
                                this.selected_name = list[0].name;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .nums {
        margin: 10px 0 10px 0;
    }
</style>