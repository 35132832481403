<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-02 16:01:03
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-12 16:56:19
 * @FilePath: \pc\src\views\notice.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="notice" :style="{'height':$store.state.clientHeight}">
        <div class="wrap">
            <div class="proBox">
                <div class="proPic1">
                    <div class="mybkkd"></div>
                    <span class="title">用戶協議與規則</span>
                </div>
                <div class="proPic2">
                    <div class="textbox1">
                        <ul>
                            <li>
                                <span>1、</span>
                                <label>使用本公司網站的客戶，請留意閣下所在的國家或居住地的相關法律規定，如有疑問應就相關問題，尋求當地法律意見。</label>
                            </li>
                            <li>
                                <span>2、</span>
                                <label>若發生遭駭客入侵破壞行為或不可抗拒之災害導致網站故障或資料損壞、資料丟失等情況，我們將以本公司之後備資料為最後處理依據；為確保各方利益，請各會員投注後列印資料。本公司不會接受沒有列印資料的投訴。</label>
                            </li>
                            <li>
                                <span>3、</span>
                                <label>為避免糾紛，各會員在投注之後，務必進入下注狀況檢查及列印資料。若發現任何異常，請立即與代理商聯繫查證，一切投注將以本公司資料庫的資料為准，不得異議。如出现特殊网络情况或线路不稳定导致不能下注或下注失败。本公司概不负责。</label>
                            </li>
                            <li>
                                <span>4、</span>
                                <label>單一注單最高派彩上限為一百萬。</label>
                            </li>
                            <li>
                                <span>5、</span>
                                <label>開獎結果以官方公佈的結果為准。</label>
                            </li>
                            <li>
                                <span>6、</span>
                                <label>我們將竭力提供準確而可靠的開獎統計等資料，但並不保證資料絕對無誤，統計資料只供參考，並非是對客戶行為的指引，本公司也不接受關於統計數據產生錯誤而引起的相關投訴。</label>
                            </li>
                            <li>
                                <span>7、</span>
                                <label>本公司擁有一切判決及註消任何涉嫌以非正常方式下註之權利，在進行更深入調查期間將停止發放與其有關之任何彩金。客戶有責任確保自己的帳戶及密碼保密，如果客戶懷疑自己的資料被盜用，應立即通知本公司，並須更改其個人詳細資料。所有被盜用帳號之損失將由客戶自行負責。在某種特殊情況下，客人之信用額可能會出現透支。</label>
                            </li>
                            <li style="text-align:right;">管理層 敬啟</li>
                        </ul>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="proPic3">
                    <input type="button" value="不同意" class="btn1 grayBtn" @click="exit" />
                    <input type="button" value="同意" class="btn1 hotBtn" @click="agree" />
                </div>
            </div>
        </div>
        <el-dialog v-model="dialog.visible" width="500px" top="30vh" customClass="mydialogclass" destroy-on-close>
            <template #header>
                <span class="mydialogtitle">公告</span>
            </template>
            <el-row v-for="item in dialog.list" :key="item.id" class="item-info">
                <el-col :span="6" style="font-weight:bold">{{item.title}}:</el-col>
                <el-col :span="18">{{item.text}}（{{item.create_time.split(' ')[0]}}）</el-col>
            </el-row>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="small" type="warning" @click="dialog.visible=false">关闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import { set_playing } from '../plugin/set_playing'
    export default {
        components: {},
        data() {
            return {
                dialog: {
                    visible: false,
                    list: [],
                    gohome: false,
                },
                themeColor: '',
                themeColorhalf: '',
                themeColorLight: '',
            }
        },
        created() {
            this.themeColor = this.$store.state.userinfo.color;
            let strs = this.themeColor.split(',')
            let h = strs[0].match(/\d+/g)[0];
            let s = strs[1].split('%')[0];
            let l = strs[2].split('%')[0];
            if (l < 95) {
                l = 90;
            }
            if (s > 35) {
                s = 90;
            }
            this.themeColorLight = `hsl(${h},${s}%,${l}%)`;
            this.themeColorhalf = `hsl(${h},30%,75%)`;
            this.$emit('get_color', this.themeColor, this.themeColorLight, this.themeColorhalf);
            this.axios.post("/notice/notice_one", {}).then(result => {
                // console.log(result.data);
                if (result.data.status && result.data.msg === "请求成功") {
                    this.dialog.list = result.data.res;
                    this.dialog.visible = true;
                    if (this.dialog.list.length > 0) {
                    }
                }
            });
            //获取彩种
            this.axios.get("/main/game_list").then(result => {
                // console.log(result.data.res.game_list);
                if (result.data.status && result.data.msg === "请求成功！") {
                    let list = result.data.res.game_list;
                    list.sort((a, b) => a.order - b.order);
                    this.$store.state.game_list = list;
                    let game = list.find(game => game.status == 1 && game.open_status == 1)
                    if (game !== undefined) {
                        this.$store.state.checked_gid = game.id;
                        this.$store.state.checked_gname = game.gname;
                        this.$store.state.playing_list = set_playing(game.id);
                        this.$store.state.playing = this.$store.state.playing_list[0].play;
                        this.$store.state.activeName = this.$store.state.playing_list[0].text;
                        this.gohome = true;
                    }
                    // console.log(game);
                }
            });
        },
        methods: {
            exit() {
                this.$router.replace('/');
                this.axios.get(`/login/logout?user=${this.$store.state.userinfo.user_id}&token=${this.$store.state.userinfo.token}`).then(result => {
                    // console.log(result.data)
                    if (result.data.status && result.data.msg === "已退出登录！") {
                        sessionStorage.clear();
                        this.$store.state.isLogin = false;
                        this.$store.state.userinfo = "";
                        window.location.reload();
                    }
                    // console.log(this.$store.state.isLogin)
                });
            },
            agree() {
                if (this.gohome) {
                    this.$router.push('/home');
                }
            },
        },
    }
</script>

<style scoped>
    .notice {
        background-image: linear-gradient(
            v-bind(themeColor),
            v-bind(themeColorhalf)
        );
        padding: 0;
        margin: 0;
        text-align: left;
    }
    .wrap {
        width: 547px;
        margin: 0 auto;
        padding-top: 6%;
    }
    .proBox {
        width: 547px;
        margin: 0 auto;
        box-shadow: 0px 0px 10px v-bind(themeColorhalf);
        border-radius: 8px;
        background: v-bind(themeColorLight);
    }
    .proPic1 {
        font-weight: bold;
        color: #333;
        background: v-bind(themeColorLight);
        border-radius: 8px 8px 0 0;
        -webkit-border-radius: 8px 8px 0 0;
        -moz-border-radius: 8px 8px 0 0;
        border-bottom: none;
    }
    .proPic2 {
        height: 461px;
        background: v-bind(themeColorLight);
        border-top: none;
        border-bottom: none;
    }
    .proPic2 .textbox1 {
        width: 501px;
        margin: 10px;
        padding: 10px;
        float: left;
        font-family: "宋体";
        border: 2px solid v-bind(themeColor);
        background: #fff;
        border-radius: 10px;
    }
    .proPic2 .textbox1 ul {
        width: 500px;
        float: left;
        padding: 0;
        margin: 0;
    }
    .proPic2 .textbox1 li {
        width: 500px;
        line-height: 21px;
        margin-bottom: 8px;
        float: left;
        color: #343434;
        list-style: none;
        font-size: 12px;
    }
    .proPic2 .textbox1 span {
        width: 20px;
        float: left;
    }
    .proPic2 .textbox1 label {
        width: 480px;
        float: left;
    }
    .proPic3 {
        height: 53px;
        background: v-bind(themeColorLight);
        border-radius: 0 0 8px 8px;
        -webkit-border-radius: 0 0 8px 8px;
        -moz-border-radius: 0 0 8px 8px;
        border-top: none;
        text-align: center;
    }
    .grayBtn {
        font-weight: bold;
        color: #fff;
        text-shadow: 0px 1px 0px v-bind(themeColor);
        background: v-bind(themeColor);
        border: 1px solid v-bind(themeColor);
    }
    .hotBtn {
        font-weight: bold;
        color: #fff;
        text-shadow: 0px 1px 0px #d05000;
        background: #f07700;
        background-image: -webkit-linear-gradient(to top, #e36600, #ff8800);
        background-image: linear-gradient(to top, #e36600, #ff8800);
        border-bottom: 1px solid #b74c00;
    }
    .btn1 {
        text-align: center;
        font-size: 12px;
        display: inline-block;
        border: none;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer;
        width: 80px;
        height: 28px;
        line-height: 26px;
        margin: 10px 20px 0 20px;
    }
    .clear {
        clear: both;
    }
    .item-info {
        color: #000;
    }
    .mybkkd {
        display: inline-block;
        width: 60px;
        height: 60px;
        box-sizing: content-box;
        padding-top: 15px;
        position: relative;
        background: v-bind(themeColor);
        color: white;
        font-size: 11px;
        letter-spacing: 0.2em;
        text-align: center;
        text-transform: uppercase;
        margin: 0 20px 0 30px;
        vertical-align: middle;
    }
    .mybkkd:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-bottom: 20px solid v-bind(themeColorLight);
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
    }
    .title{
        font-size: 30px;
        font-weight: bold;
    }
</style>