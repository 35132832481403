<!--
 * @Author: your name
 * @Date: 2022-03-13 17:20:20
 * @LastEditTime: 2022-09-11 18:01:22
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\home\usermenu.vue
-->
<template>
    <div class="allmenu theme-half-bg">
        <div class="top_menu base-clear">
            <div class="navBox theme-border" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <div class="up-down-icon theme-text"></div>
                <span @click="click_game_list">{{ gname }}</span>
                <div class="navList theme-border light-bg" v-show="show_list">
                    <ul style="list-style-type: none;padding: 0;margin: 0;float: left;">
                        <li v-for="item in game_list" :key="item.id" @click="change_game(item.id)">
                            <span class="gname">{{ item.gname }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="nav base-clear theme-border light-bg">
                <a class="theme-border" @click="get_list">
                    <i class="icon9"></i>
                    <span>遊戲大廳</span>
                </a>
                <a class="theme-border" @click="get_info">
                    <i class="icon1"></i>
                    <span>信用資料</span>
                </a>
                <a class="theme-border" @click="get_logs">
                    <i class="icon8"></i>
                    <span>登錄日誌</span>
                </a>
                <a class="theme-border" @click="cpass=true">
                    <i class="icon2"></i>
                    <span>修改密碼</span>
                </a>
                <a class="theme-border" @click="get_norep">
                    <i class="icon3"></i>
                    <span>未結明細</span>
                </a>
                <a class="theme-border" @click="get_report">
                    <i class="icon4"></i>
                    <span>結算報表</span>
                </a>
                <a class="theme-border" @click="get_his">
                    <i class="icon5"></i>
                    <span>歷史開獎</span>
                </a>
                <a class="theme-border" @click="get_djgz">
                    <i class="icon5"></i>
                    <span>兌獎規則</span>
                </a>
                <a class="theme-border" @click="rules=true">
                    <i class="icon6"></i>
                    <span>玩法規則</span>
                </a>
                <a class="theme-border" @click="quit=true">
                    <i class="icon7"></i>
                    <span>安全退出</span>
                </a>
            </div>
        </div>
        <!-- 菜单弹窗 -->
        <el-dialog v-model="quit" width="200px" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">安全退出</span>
            </template>
            <span>您确认要退出吗？</span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="small" @click="quit=false">取消</el-button>
                    <el-button size="small" type="warning" @click="confirm_quit">确认</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="rules" width="50%" top="0" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">玩法規則</span>
            </template>
            <rule />
        </el-dialog>
        <el-dialog v-model="djgz" width="50%" top="0" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">兌獎規則</span>
            </template>
            <duijiangguize ref="duijiangguize" />
        </el-dialog>
        <el-dialog v-model="info" width="600px" top="5%" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">信用資料</span>
            </template>
            <userinfo ref="userinfo" />
        </el-dialog>
        <el-dialog v-model="logs" top="5%" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">登錄日誌</span>
            </template>
            <loginlog ref="loginlog" />
        </el-dialog>
        <el-dialog v-model="cpass" top="10%" width="630px" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">修改密碼</span>
            </template>
            <changepass ref="changepass" />
        </el-dialog>
        <el-dialog v-model="his" top="5%" width="65%" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">歷史開獎</span>
            </template>
            <history ref="history" />
        </el-dialog>
        <el-dialog v-model="games" top="5%" width="1000px" customClass="mydialogclass" @closed="$refs['gameLobby'].clear_timer()">
            <template #header>
                <span class="mydialogtitle">遊戲大廳</span>
            </template>
            <gameLobby ref="gameLobby" @change_game="change_game" />
        </el-dialog>
        <el-dialog v-model="rep" top="5%" width="1000px" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">結算報表</span>
            </template>
            <report ref="report" />
        </el-dialog>
        <el-dialog v-model="norep" top="5%" width="1000px" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">未結明細</span>
            </template>
            <noSettlement ref="noSettlement" />
        </el-dialog>
    </div>
</template>

<script>
    import rule from '../menuitems/rule.vue';
    import userinfo from '../menuitems/userinfo.vue';
    import loginlog from '../menuitems/loginlog.vue';
    import changepass from '../menuitems/changepass.vue';
    import history from '../menuitems/history.vue';
    import gameLobby from '../menuitems/gameLobby.vue';
    import report from '../menuitems/report.vue';
    import noSettlement from '../menuitems/noSettlement.vue';
    import duijiangguize from '../menuitems/duijiangguize.vue';
    export default {
        components: { rule, userinfo, loginlog, changepass, history, gameLobby, report, noSettlement, duijiangguize },
        data() {
            return {
                quit: false,
                rules: false,
                info: false,
                logs: false,
                cpass: false,
                his: false,
                games: false,
                rep: false,
                norep: false,
                game_list: [],
                gid: '',
                gname: '',
                show_list: false,
                djgz: false,
            }
        },
        created() {
            this.game_list = this.$store.state.game_list;
            if (this.$store.state.checked_gid !== '') {
                this.gid = this.$store.state.checked_gid;
                this.gname = this.$store.state.checked_gname;
            } else {
                this.gid = this.game_list[0].id;
                this.gname = this.game_list[0].gname;
            }
            // console.log(this.gid, this.gname);
        },
        methods: {
            change_game(gid) {
                this.gid = this.$store.state.checked_gid = gid;
                this.gname = this.$store.state.checked_gname = this.game_list.find(item => item.id == gid).gname;
                this.show_list = false;
                this.$emit('change_game', gid, this.gname);
                this.games = false;
            },
            click_game_list() {
                this.show_list = !this.show_list;
            },
            mouseenter(s) {
                this.show_list = true;
            },
            mouseleave(s) {
                this.show_list = false;
            },
            get_report() {
                this.axios.post("/order/close_order", { user_id: this.$store.state.userinfo.user_id }).then((result) => {
                    if (result.data.status == 1 && result.data.msg === "请求成功") {
                        this.rep = true;
                        this.$nextTick(() => {
                            this.$refs['report'].getdata(result.data.res);
                        })
                    }
                })
            },
            get_list() {
                this.axios.get("/main/game_list").then(result => {
                    // console.log(result.data);
                    if (result.data.status && result.data.msg === "请求成功！") {
                        let list = result.data.res.game_list;
                        list.sort((a, b) => a.order - b.order);
                        this.games = true;
                        this.$nextTick(() => {
                            this.$refs['gameLobby'].getlist(list);
                        })
                    }
                });
            },
            get_his() {
                this.axios.post("/betresult/index",
                    {
                        gid: this.$store.state.checked_gid,
                        page: 1,
                        start_time: this.$filters.time(new Date(new Date().setTime(new Date().getTime() - 3600 * 1000 * 24 * 30)).getTime() / 1000),
                        end_time: this.$filters.time((new Date().getTime()) / 1000),
                        limit:20
                    }
                ).then((result) => {
                    if (result.data.status == 1 && result.data.msg === "请求成功！") {
                        this.his = true;
                        this.$nextTick(() => {
                            this.$refs['history'].getdata(result.data.res);
                        })
                    }
                })
            },
            confirm_quit() {
                this.axios.post(`/login/logout`).then(result => {
                    if (result.data.status && result.data.msg === "已退出登录！") {
                        sessionStorage.clear();
                        this.$store.state.isLogin = false;
                        this.$store.state.userinfo = "";
                        this.$message({
                            type: "success",
                            message: "已退出!"
                        });
                        this.$emit('quit');
                        this.$router.replace('/');
                        window.setTimeout(() => {
                            window.location.reload()
                        }, 100);
                    }
                });
            },
            get_info() {
                this.axios.post("/user/credit_data", { gid: this.$store.state.game_list[0].id }).then((result) => {
                    if (result.data.status && result.data.msg === "请求成功") {
                        this.info = true;
                        this.$nextTick(() => {
                            this.$refs['userinfo'].get_info(result.data.res);
                        })
                    }
                })
            },
            get_logs() {
                this.axios.post("/log/login_log").then(result => {
                    if (result.status && result.data.msg === "请求成功") {
                        this.logs = true;
                        this.$nextTick(() => {
                            this.$refs['loginlog'].get_logs(result.data.res);
                        })
                    }
                });
            },
            get_norep() {
                this.axios.get("/order/open_order?page=1").then(result => {
                    // console.log(result.data)
                    if (result.data.status && result.data.msg === "请求成功！") {
                        this.norep = true;
                        this.$nextTick(() => {
                            this.$refs['noSettlement'].get_data(result.data.res);
                        })
                    }
                })
            },
            get_djgz() {
                this.axios.post("/game/rules", { user_id: this.$store.state.userinfo.user_id }).then((result) => {
                    if (result.data.status == 1) {
                        this.djgz = true;
                        this.$nextTick(() => {
                            this.$refs['duijiangguize'].getdata(result.data.res);
                        })
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .top_menu {
        height: 35px;
        width: 1325px;
        padding-left: 5px;
        padding-top: 4px;
        position: relative;
    }
    .base-clear {
        zoom: 1;
    }
    .base-clear:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }

    .nav {
        float: left;
        margin-left: 10px;
        font-size: 12px;
        line-height: 28px;
        border-radius: 3px;
        position: absolute;
        top: 4px;
        left: 164px;
        height: 28px;
        border-left: 1px solid;
    }
    .nav a {
        float: left;
        height: 28px;
        padding: 0 7px 0 5px;
        color: #000;
        border: 1px solid;
        border-left: 0;
    }
    .nav a:hover {
        cursor: pointer;
        background: #ddd;
        background-image: -webkit-linear-gradient(to top, #ddd, #fdfeff);
        background-image: linear-gradient(to top, #ddd, #fdfeff);
        color: #e64100;
    }
    a {
        border: none;
        text-decoration: none;
    }
    .nav a i {
        display: inline-block;
        width: 20px;
        height: 28px;
        vertical-align: middle;
        margin-right: 3px;
        font-style: normal;
    }
    .icon1,
    .icon2,
    .icon3,
    .icon4,
    .icon5,
    .icon6,
    .icon7,
    .icon8,
    .icon9 {
        background: url("../../assets/BoxBg.png") center no-repeat;
    }
    .icon1 {
        background-position: 0 -2px;
    }
    .icon2 {
        background-position: -90px -2px;
    }
    .icon3 {
        background-position: -180px -2px;
    }
    .icon4 {
        background-position: -270px -2px;
    }
    .icon5 {
        background-position: -360px -2px;
    }
    .icon6 {
        background-position: 0 -30px;
    }
    .icon7 {
        background-position: -90px -30px;
    }
    .icon9 {
        background-position: -359px -59px;
    }
    .icon8 {
        background-position: -180px -30px;
    }
    .gname:hover {
        background: url("../../assets/menu_a_bg.gif") no-repeat scroll 12px center
            #ffff2b;
    }

    .gname {
        background: url("../../assets/menu_a_bg.gif") no-repeat scroll 12px center
            #fff;
        border-radius: 0;
        display: inline-block;
        font-size: 12px;
        height: 28px;
        color: #000;
        line-height: 28px;
        padding-left: 26px;
        width: 138px;
        border-bottom: 1px dotted;
    }

    .navList {
        position: absolute;
        width: 100%;
        top: 100%;
        left: -1px;
        border: 1px solid;
        z-index: 4;
        border-bottom: 0;
        box-shadow: 0 3px 3px 0 #eee;
    }

    .up-down-icon {
        position: absolute;
        width: 16px;
        height: 28px;
        margin-top: 11px;
        font-size: 12px;
        top: -10px;
        right: 5px;
        background: url("../../assets/BoxBg.png") -363px -28px no-repeat;
    }

    .navBox {
        width: 150px;
        height: 28px;
        line-height: 24px;
        font-size: 14px;
        float: left;
        padding: 0 7px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        border: 1px solid;
        cursor: pointer;
        margin-right: 7px;
        position: relative;
        background: #fff;
    }
</style>