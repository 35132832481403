/*
 * @Author: your name
 * @Date: 2020-06-18 14:34:38
 * @LastEditTime: 2022-02-07 16:05:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \admin\src\plugins\lhcNum.js
 */
/**处理lhc生成对应的颜色 */
export function lhcNum(num, lottery_open) {
	let arr = [
		{ num: 1, color: "#F56C6C", sx: "",lottery_open },
		{ num: 2, color: "#F56C6C", sx: "",lottery_open },
		{ num: 3, color: "#409eff", sx: "",lottery_open },
		{ num: 4, color: "#409eff", sx: "",lottery_open },
		{ num: 5, color: "#67C23A", sx: "",lottery_open },
		{ num: 6, color: "#67C23A", sx: "",lottery_open },
		{ num: 7, color: "#F56C6C", sx: "",lottery_open },
		{ num: 8, color: "#F56C6C", sx: "",lottery_open },
		{ num: 9, color: "#409eff", sx: "",lottery_open },
		{ num: 10, color: "#409eff", sx: "",lottery_open },
		{ num: 11, color: "#67C23A", sx: "",lottery_open },
		{ num: 12, color: "#F56C6C", sx: "",lottery_open },
		{ num: 13, color: "#F56C6C", sx: "",lottery_open },
		{ num: 14, color: "#409eff", sx: "",lottery_open },
		{ num: 15, color: "#409eff", sx: "",lottery_open },
		{ num: 16, color: "#67C23A", sx: "",lottery_open },
		{ num: 17, color: "#67C23A", sx: "",lottery_open },
		{ num: 18, color: "#F56C6C", sx: "",lottery_open },
		{ num: 19, color: "#F56C6C", sx: "",lottery_open },
		{ num: 20, color: "#409eff", sx: "",lottery_open },
		{ num: 21, color: "#67C23A", sx: "",lottery_open },
		{ num: 22, color: "#67C23A", sx: "",lottery_open },
		{ num: 23, color: "#F56C6C", sx: "",lottery_open },
		{ num: 24, color: "#F56C6C", sx: "",lottery_open },
		{ num: 25, color: "#409eff", sx: "",lottery_open },
		{ num: 26, color: "#409eff", sx: "",lottery_open },
		{ num: 27, color: "#67C23A", sx: "",lottery_open },
		{ num: 28, color: "#67C23A", sx: "",lottery_open },
		{ num: 29, color: "#F56C6C", sx: "",lottery_open },
		{ num: 30, color: "#F56C6C", sx: "",lottery_open },
		{ num: 31, color: "#409eff", sx: "",lottery_open },
		{ num: 32, color: "#67C23A", sx: "",lottery_open },
		{ num: 33, color: "#67C23A", sx: "",lottery_open },
		{ num: 34, color: "#F56C6C", sx: "",lottery_open },
		{ num: 35, color: "#F56C6C", sx: "",lottery_open },
		{ num: 36, color: "#409eff", sx: "",lottery_open },
		{ num: 37, color: "#409eff", sx: "",lottery_open },
		{ num: 38, color: "#67C23A", sx: "",lottery_open },
		{ num: 39, color: "#67C23A", sx: "",lottery_open },
		{ num: 40, color: "#F56C6C", sx: "",lottery_open },
		{ num: 41, color: "#409eff", sx: "",lottery_open },
		{ num: 42, color: "#409eff", sx: "",lottery_open },
		{ num: 43, color: "#67C23A", sx: "",lottery_open },
		{ num: 44, color: "#67C23A", sx: "",lottery_open },
		{ num: 45, color: "#F56C6C", sx: "",lottery_open },
		{ num: 46, color: "#F56C6C", sx: "",lottery_open },
		{ num: 47, color: "#409eff", sx: "",lottery_open },
		{ num: 48, color: "#409eff", sx: "",lottery_open },
		{ num: 49, color: "#67C23A", sx: "",lottery_open },
		{ num: '', color: "", sx: "" },
		{ num: '红大', color: "#F56C6C" },
		{ num: '红小', color: "#F56C6C" },
		{ num: '红单', color: "#F56C6C" },
		{ num: '红双', color: "#F56C6C" },
		{ num: '绿大', color: "#67C23A" },
		{ num: '绿小', color: "#67C23A" },
		{ num: '绿单', color: "#67C23A" },
		{ num: '绿双', color: "#67C23A" },
		{ num: '蓝大', color: "#409eff" },
		{ num: '蓝小', color: "#409eff" },
		{ num: '蓝单', color: "#409eff" },
		{ num: '蓝双', color: "#409eff" },
		{ num: '红波', color: "#F56C6C" },
		{ num: '蓝波', color: "#409eff" },
		{ num: '绿波', color: "#67C23A" },
	];
	for (let i = 0; i < arr.length; i++) {
		if (num == arr[i].num) {
			num = arr[i]
		}
	}
	arr = null;
	return num
}