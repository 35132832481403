<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-14 00:11:02
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-15 15:35:31
 * @FilePath: \pc\src\components\menuitems\timeDown.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="time-down">
        <div v-if="loadImg" class="loading">
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
        </div>
        <div v-else>{{countDown_h}} : {{countDown_m}} : {{countDown_s}}</div>
    </div>
</template>

<script>
export default {
    props: ["gid"],
    data() {
        return {
            /**当前时间 */
            now: "",
            /**封盘时间 */
            closetime: "",
            /**提前封盘时间 */
            ahead_time: 0,

            /**倒计时文本 */
            countDown_h: "--",
            countDown_m: "--",
            countDown_s: "--",

            /**两个倒计时的循环id */
            timer1: "",
            timer2: "",

            loadImg: true,
            clear: false
        }
    },
    mounted() { },
    beforeDestroy() {
        this.clear_timer();
    },
    methods: {
        /**获取当前彩种信息 */
        get_lottery_info() {
            if (this.clear) {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
                return;
            }
            this.axios.post("/main/game_lobby" ,{gid:this.gid} ).then(result => {
                // console.log(result.data)
                if (result.data.status && result.data.msg === "请求成功！") {
                    let gid = result.data.res.id;
                    let now = Number(result.data.res.now);
                    let opentime = Number(result.data.res.opentime);
                    let closetime = Number(result.data.res.closetime);
                    let ahead_time = Number(result.data.res.ahead_time);
                    let is_open = result.data.res.is_open;
                    if (is_open === 1) {
                        this.$emit('get_item_open_status', '开', 'green',this.gid);
                        this.countdowm(closetime, now);
                    } else if (is_open === 2) {
                        this.$emit('get_item_open_status', '封', 'red',this.gid);
                        this.countdowm_ahead(opentime, now);
                    } else {
                        this.$emit('get_item_open_status', '停', 'gray',this.gid);;
                    }
                }
            });
        },
        /**投注倒计时 */
        countdowm(closetime, now) {
            if (this.clear) {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
                return;
            }
            let self = this;
            let t = (closetime - now) * 1000;
            this.loadImg = false;
            this.timer1 = setInterval(function () {
                // console.log(111)
                t -= 1000;
                if (t > 0) {
                    let day = Math.floor(t / 86400000);
                    let hour = Math.floor((t / 3600000) % 24);
                    let min = Math.floor((t / 60000) % 60);
                    let sec = Math.floor((t / 1000) % 60);
                    hour = hour < 10 ? "0" + hour : hour;
                    min = min < 10 ? "0" + min : min;
                    sec = sec < 10 ? "0" + sec : sec;
                    self.countDown_h = hour;
                    self.countDown_m = min;
                    self.countDown_s = sec;
                    // console.log("距離封盤--"+min+":"+sec+" "+text)
                } else {
                    window.clearInterval(self.timer1);
                    self.$emit('get_item_open_status', '封', 'red',self.gid);
                    self.countdowm_ahead();
                }
            }, 1000);
        },
        /**封盘倒计时 */
        countdowm_ahead(opentime, now) {
            if (this.clear) {
                window.clearInterval(this.timer1);
                window.clearInterval(this.timer2);
                return;
            }
            let self = this;
            let t = (opentime - now) * 1000;
            this.timer2 = setInterval(function () {
                t -= 1000;
                if (t > 0) {
                    let day = Math.floor(t / 86400000);
                    let hour = Math.floor((t / 3600000) % 24);
                    let min = Math.floor((t / 60000) % 60);
                    let sec = Math.floor((t / 1000) % 60);
                    hour = hour < 10 ? "0" + hour : hour;
                    min = min < 10 ? "0" + min : min;
                    sec = sec < 10 ? "0" + sec : sec;
                    self.countDown_h = hour;
                    self.countDown_m = min;
                    self.countDown_s = sec;
                    // console.log("距離開盤--"+min+":"+sec+" "+text)

                } else {
                    self.loadImg = true;
                    window.clearInterval(self.timer2);
                    self.endcallback_ahead();
                }
            }, 1000);
        },
        /**倒计时结束回调 */
        endcallback() {
            this.get_lottery_info();
        },
        endcallback_ahead() {
            this.get_lottery_info();
        },
        /**清除两个倒计时的循环 */
        clear_timer() {
            window.clearInterval(this.timer1);
            window.clearInterval(this.timer2);
            this.clear = true;
        },
    },
}
</script>

<style scoped>
.time-down {
    width: 100%;
}

.time-down .loading {
    margin: 0 auto;
    margin-top: 35px;
    width: 3.5em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.time-down .loading .item {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: #FFE53B;
    background-image: linear-gradient(147deg, rgb(252, 243, 184) 0%, rgb(250, 84, 84) 74%);

    animation: fade 0.8s ease-in-out alternate infinite;
}

.time-down .loading .item:nth-of-type(1) {
    animation-delay: -0.4s;
}

.time-down .loading .item:nth-of-type(2) {
    animation-delay: -0.2s;
}

@keyframes fade {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
</style>
