<!--
 * @Author: your name
 * @Date: 2022-03-11 16:27:32
 * @LastEditTime: 2022-09-02 03:06:15
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\App.vue
-->
<template>
    <router-view @get_color="get_color" />
</template>

<script>
    export default {
        data() {
            return {
                themeColor: '#267cb9',
                themeColorhalf: '#a1c1fb',
                themeColorLight: '#e4ecfb',
            }
        },
        created() {
            // 在页面加载时读取sessionStorage里的状态信息
            if (sessionStorage.getItem("store")) {
                this.$store.replaceState(
                    Object.assign({},
                        this.$store.state,
                        JSON.parse(sessionStorage.getItem("store"))
                    )
                );
            }
            //在页面刷新时将vuex里的信息保存到sessionStorage里
            window.addEventListener("beforeunload", () => {
                sessionStorage.setItem("store", JSON.stringify(this.$store.state));
            });
            // 获取网站名称
            this.axios.get(`/login/get_web_info?url=${window.location.host}`).then(result => {
                if (result.data.status == 1 && result.data.msg.web_name != '') {
                    this.$store.state.web_name = result.data.msg.web_name;
                    this.$store.state.logintoken = result.data.msg.token;
                }
            });
            this.$store.state.clientHeight = document.documentElement.clientHeight + 'px';
            this.$store.state.clientWidth = document.documentElement.clientWidth + 'px';
        },
        methods: {
            get_color(themeColor, themeColorLight, themeColorhalf) {
                this.themeColor = this.$store.state.themeColor = themeColor;
                this.themeColorhalf = this.$store.state.themeColorhalf = themeColorhalf;
                this.themeColorLight = this.$store.state.themeColorLight = themeColorLight;
                // console.log(this.themeColor, this.themeColorhalf, this.themeColorLight);
                this.axios.post("user/user_colors", { color: this.themeColor }).then(result => {
                    if (result.data.status == 200) {
                        // this.$message.success(result.data.shortMessage);
                    }
                })
            }
        },
    }

</script>
<style>
    body {
        margin: 0;
        background-color: #fff;
    }
    * {
        font-size: 12px;
        -webkit-text-size-adjust: none !important;
    }
    #app {
        font-family: Verdana, "宋体", Arial, Sans !important;
        -webkit-font-smoothing: antialiased !;
        -moz-osx-font-smoothing: grayscale !;
        font-size: 12px;
    }
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    input,
    textarea,
    p,
    blockquote,
    th,
    td,
    hr,
    button,
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        margin: 0;
        padding: 0;
    }
    :root {
        --el-color-primary: v-bind(themeColor) !important;
    }
    .white-text {
        color: #fff;
        font-weight: bold;
    }
    .black-text {
        color: #000;
    }
    .theme-text {
        color: v-bind(themeColor);
    }
    .theme-text-half {
        color: v-bind(themeColor);
    }
    .theme-border {
        border-color: v-bind(themeColor) !important;
    }
    .theme-border-lhc-jszd-ykpl {
        text-align: center;
        line-height: 20px;
        border-bottom: 2px solid v-bind(themeColor) !important;
        cursor: pointer;
    }

    .theme-border-half {
        border-color: v-bind(themeColorhalf) !important;
    }

    .theme-bg {
        background-color: v-bind(themeColor);
        border-color: v-bind(themeColor) !important;
    }
    .theme-half-bg {
        background-color: v-bind(themeColorhalf);
    }
    .theme-half-bg-light {
        background-image: linear-gradient(
            to top,
            v-bind(themeColorhalf),
            v-bind(themeColorLight)
        );
    }
    .light-bg {
        background-color: v-bind(themeColorLight);
    }
    .light-bg-fff {
        background-image: linear-gradient(to top, #ffffff, v-bind(themeColorLight));
    }
    .themeColorhalf-bg {
        background-color: v-bind(themeColorhalf);
        border-color: v-bind(themeColorhalf) !important;
    }

    .light-text {
        color: v-bind(themeColorLight);
    }
    /* .pl_popver */
    .loseinfo .el-divider__text {
        background-color: v-bind(themeColorLight) !important;
        font-weight: bold;
        font-size: 16px;
        color: v-bind(themeColor);
    }
    .pl_popver {
        position: absolute;
        width: 150px;
        z-index: 3;
        background-color: v-bind(themeColorLight);
        border: 1px solid v-bind(themeColor);
    }
    .pl_popver_title {
        background-color: v-bind(themeColor);
        color: #fff;
        text-align: center;
        line-height: 25px;
    }
    /* el-popver */
    .my_popver_pl {
        /* --el-popover-padding: 0 !important; */
        position: relative;
        z-index: 9999;
    }
    /* .el-message-box */
    .el-message-box {
        width: 200px !important;
        background-color: hsl(87deg, 35%, 95%) !important;
        padding-bottom: 5px !important;
        border: 2px solid hsl(87deg, 97%, 28%) !important;
    }
    .el-message-box__header {
        padding: 5px !important;
    }
    .el-message-box__title span {
        background: url("./assets/title_icon.gif") 5px center no-repeat;
        font-weight: bold;
        padding-left: 25px;
        font-size: 12px;
    }
    .el-message-box__headerbtn {
        top: 5px !important;
    }
    .el-message-box__content {
        background-color: #fff;
        border-top: 1px solid hsl(87deg, 30%, 75%) !important;
        border-bottom: 1px solid hsl(87deg, 30%, 75%) !important;
    }
    .el-message-box__btns {
        padding: 5px !important;
        padding-bottom: 0 !important;
    }
    .el-message-box__btns .el-button--default {
        width: 50px !important;
        height: 24px !important;
    }
    .el-message-box__btns .el-button--primary {
        width: 50px !important;
        height: 24px !important;
        background-color: #f07700 !important;
        border-color: #f07700 !important;
    }
    /* mydialogclass */
    .mydialogclass {
        background-color: v-bind(themeColorLight) !important;
        border: 2px solid v-bind(themeColor);
        text-align: left;
    }
    .mydialogclass .el-dialog__header {
        padding: 10px;
    }
    .mydialogclass .el-dialog__body {
        background-color: #fff !important;
        border-top: 1px solid v-bind(themeColorhalf);
        border-bottom: 1px solid v-bind(themeColorhalf);
        padding: 10px;
    }
    .mydialogclass .el-dialog__footer {
        padding: 3px;
        text-align: center;
    }
    .mydialogclass .el-dialog__headerbtn {
        width: 35px;
        height: 35px;
        top: 0px;
        color: v-bind(themeColorhalf) !important;
    }
    .mydialogtitle {
        background: url("./assets/title_icon.gif") 5px center no-repeat;
        font-weight: bold;
        padding-left: 25px;
        color: #000;
    }
    /* myform */
    .myform {
        border: 1px solid v-bind(themeColorhalf);
        margin-top: 3px;
        line-height: 28px;
    }

    .myform .myform-item:hover .el-col {
        background-color: #ffff2b !important;
    }

    .myform-item-lable {
        text-align: right;
        padding: 0 10px;
        background-color: v-bind(themeColorLight);
        border-right: 1px solid v-bind(themeColorhalf);
        border-bottom: 1px solid v-bind(themeColorhalf);
    }

    .myform-item-value {
        padding: 0 10px;
        text-align: left;
        border-bottom: 1px solid v-bind(themeColorhalf);
        background-color: #fff;
    }

    .myform:nth-last-child(1),
    .myform:nth-last-child(2) {
        border-bottom: 0;
    }

    /* el-select */
    .home .el-select {
        --el-select-input-focus-border-color: #e6a23c;
    }

    /* el-input */
    .home .el-input {
        --el-input-focus-border-color: #e6a23c;
    }

    .home .el-textarea__inner:focus {
        box-shadow: 0 0 0 1px #e6a23c;
    }

    /* el-checkbox */
    .home .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: v-bind(themeColor);
    }

    /* el-button */
    #app .el-button--primary {
        --el-button-text-color: v-bind(themeColorLight) !important;
        --el-button-bg-color: v-bind(themeColor) !important;
        --el-button-border-color: v-bind(themeColor) !important;
        --el-button-hover-text-color: v-bind(themeColorLight) !important;
        --el-button-hover-bg-color: v-bind(themeColor) !important;
        --el-button-hover-border-color: v-bind(themeColorLight) !important;
    }
    #app .el-button.is-disabled,
    #app .el-button.is-disabled:focus,
    #app .el-button.is-disabled:hover {
        color: #666;
        cursor: not-allowed;
        background-image: none;
        background-color: #eee;
        border-color: #eee;
    }
    .home .top {
        background-color: v-bind(themeColor);
        border: 1px solid v-bind(themeColor);
        border-bottom-color: #aaa;
    }

    .home .el-button--small {
        --el-button-size: 22px;
        height: var(--el-button-size);
        padding: 5px;
        font-size: 12px;
        border-radius: calc(var(--el-border-radius-base) - 1px);
    }

    .home .el-link {
        font-size: 12px;
        color: v-bind(themeColor);
    }

    .home .el-link:hover {
        color: red;
    }

    .home .el-radio__input.is-checked .el-radio__inner {
        background-color: v-bind(themeColor);
    }

    .home .el-radio__input.is-checked + .el-radio__label {
        color: v-bind(themeColor);
    }

    .home .el-input-group__append,
    .home .el-input-group__prepend {
        background-color: v-bind(themeColorLight);
    }

    .home .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
        background-color: v-bind(themeColor);
        color: v-bind(themeColorLight);
    }

    /* el-table */
    .home .el-table {
        font-size: 12px;
        --el-table-border-color: v-bind(themeColorhalf);
        --el-table-border: 1px solid v-bind(themeColorhalf);
        --el-table-text-color: var(--el-text-color-regular);
        --el-table-header-text-color: #333;
        --el-table-row-hover-bg-color: #ffff2b;
        --el-table-current-row-bg-color: #ffff6d;
        --el-table-header-bg-color: v-bind(themeColorLight);
        --el-table-fixed-box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
        --el-table-bg-color: var(--el-fill-color-blank);
        --el-table-tr-bg-color: #fff;
        --el-table-expanded-cell-bg-color: var(--el-fill-color-blank);
        --el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0, 0, 0, 0.15);
        --el-table-fixed-right-column: inset -10px 0 10px -10px rgba(0, 0, 0, 0.15);
        color: #000;
    }

    .home .el-table tr:hover {
        background-color: #ffff2b;
    }

    .home .el-table tr:nth-child(even) {
        background-color: v-bind(themeColorLight);
    }

    .home .instant .el-table tr:nth-child(even) {
        background-color: rgba(255, 255, 255, 0);
    }

    .home .instant .el-table tr > td:first-child {
        background-color: v-bind(themeColorLight);
    }

    .home
        .el-table--striped
        .el-table__body
        tr.el-table__row--striped
        td.el-table__cell
        :hover {
        background: #ffff2b;
    }

    .home .el-table .el-table__cell {
        padding: 3px 3px;
        word-break: break-all;
    }

    .home .el-table .cell {
        padding: 0;
        line-height: 22px;
    }

    .home .el-table .table-header-cell {
        color: #333;
        line-height: 22px;
        background-image: linear-gradient(#fff, v-bind(themeColorLight));
    }

    .home .el-table--border .el-table__cell:first-child .cell {
        padding-left: 0;
    }

    .el-table__footer {
        font-weight: bold;
    }

    .el-table__footer-wrapper tbody td {
        background-color: #fff !important;
    }

    /* .el-radio-button */
    .home .el-radio-button {
        --el-radio-button-checked-bg-color: v-bind(themeColor);
        --el-radio-button-checked-text-color: #fff;
        --el-radio-button-checked-border-color: v-bind(themeColor);
    }

    .home .el-radio-button__inner {
        color: #000;
    }

    .el-radio-button--small .el-radio-button__inner {
        padding: 6px 10px !important;
    }

    /* el-check-tag */
    .home .el-check-tag.is-checked {
        background-color: v-bind(themeColor);
        color: #fff;
    }

    .home .el-check-tag.is-checked:hover {
        background-color: v-bind(themeColorLight);
        color: #333;
    }

    .home .el-check-tag {
        background-color: transparent;
        color: #000;
        font-size: 12px;
        line-height: var(--el-font-size-base);
        padding: 3px;
        transition: var(--el-transition-all);
        font-weight: normal;
    }

    .el-icon {
        vertical-align: middle;
    }

    /* 日期 */
    .el-date-editor.el-input--small.el-input--suffix .el-input__inner {
        padding-right: 0;
        font-weight: bold;
    }

    .my-date-picker .el-date-picker {
        width: 215px;
    }

    .my-date-picker .el-date-picker .el-date-table tr td {
        width: 27px !important;
        height: 22px !important;
        padding: 0px !important;
    }

    .my-date-picker .el-date-picker .el-picker-panel__content {
        width: 215px;
        margin: 0px;
    }

    .my-date-picker .el-date-picker .el-date-picker__header {
        margin: 3px;
    }

    /* 分页 */
    .paging {
        height: 24px;
        position: relative;
        top: calc(50% - 50px);
        left: calc(50% - 150px);
    }

    #app .el-pagination {
        --el-pagination-bg-color: none;
    }

    .el-pagination button:disabled {
        background: none !important;
    }

    .el-pager li {
        background: none;
    }

    .el-pagination .btn-next,
    .el-pagination .btn-prev {
        color: #000;
        background: none;
    }

    .home .router-view-main {
        padding: 3px 0;
        overflow: hidden;
    }

    .home .panel .panel-body {
        padding: 3px;
    }

    .home .panel .panel-title {
        height: 28px;
        line-height: 28px;
        padding: 0 3px;
        text-align: left;
        background-image: linear-gradient(#fff, v-bind(themeColorLight));
        border-bottom: 1px solid v-bind(themeColorhalf);
    }

    .home .panel .panel-title h2 {
        font-size: 12px;
        font-weight: bold;
        background: url("./assets/title_icon.gif") no-repeat 0 8px;
        padding: 0 0 0 18px;
        margin: 0 0 0 5px;
        float: left;
    }

    .home .panel {
        border: 1px solid v-bind(themeColorLight);
        box-shadow: 0 2px 5px #ccc;
        background-image: linear-gradient(#fff, 90%, v-bind(themeColorLight));
        padding-bottom: 10px;
        margin: 0 auto;
    }

    .home .sub-menu {
        background-image: linear-gradient(#fff, v-bind(themeColorLight));
    }

    body .myConfirmButtonClass {
        background-color: v-bind(themeColor);
    }

    .redcolor {
        color: red;
    }

    .blackcolor {
        color: #000;
    }

    /* a-icon */
    a:hover {
        color: red;
    }

    .a-icon {
        height: 18px;
        line-height: 18px;
        background-repeat: no-repeat;
        background-image: url("./assets/iconlist.gif");
        float: left;
        padding-left: 19px;
        margin-right: 5px;
        vertical-align: middle;
        white-space: nowrap;
        text-decoration: none;
    }

    .i-rizhi {
        background-position: 0 1px;
    }

    .i-three {
        background-position: 0 -36px;
    }

    .i-six {
        background-position: 0 -90px;
    }

    .i-changeLog {
        background-position: 0 -72px;
    }

    .i-topUp {
        background: url("./assets/chongzhi.gif") no-repeat 2px;
    }

    .i-cashOut {
        background: url("./assets/tixian.png") no-repeat 2px;
    }

    .i-cashFlow {
        background: url("./assets/xianjinliu.png") no-repeat 2px;
    }

    .i-tuishui {
        background: url("./assets/tuishui.png") no-repeat 2px;
    }

    .i-Edit {
        background-position: 0 -54px;
    }
    fieldset {
        padding: 0px 0px 5px 0px;
        border: 1px solid;
        background-color: v-bind(themeColorLight) !important;
        border-color: v-bind(themeColorhalf) !important;
    }
    legend {
        color: #333333;
        padding: 2px 6px;
        margin: 2px 0 0px 7px;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: v-bind(themeColorLight) !important;
        background-color: v-bind(themeColorhalf) !important;
        font-weight: bold;
    }
    .pk10-nums span {
        display: inline-block;
        height: 24px;
        width: 24px;
        line-height: 24px;
        margin: 0 6px 0 0;
        text-align: center;
        font-size: 14px;
        color: #fff;
        font-weight: bold;
        border-radius: 4px;
        text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    }
    .pk10_num1 {
        background: #fff000;
    }

    .pk10_num2 {
        background: #0089ff;
    }

    .pk10_num3 {
        background: #4d4d4d;
    }

    .pk10_num4 {
        background: #ff7300;
    }

    .pk10_num5 {
        background: #81ffff;
    }

    .pk10_num6 {
        background: #5200ff;
    }

    .pk10_num7 {
        background: #e3e3e3;
    }

    .pk10_num8 {
        background: #f00;
    }

    .pk10_num9 {
        background: #760000;
    }

    .pk10_num10 {
        background: #28c200;
    }
</style>
