<template>
    <div class="top">
        <div class="top_bar base-clear">
            <div class="logo" style="padding-left: 85px;line-height:36px">{{$store.state.web_name}}</div>
            <div class="user_info_wrap base-clear">
                <span class="usdt">{{usdt}}</span>
                <div class="user_pic"></div>
                <div class="user_info">
                    <p class="user_name">{{$store.state.username}}</p>
                    <p v-if="is_kuaicai==1">
                        彩票余额：
                        <span id="user_money">{{balance}}</span>
                    </p>
                    <!-- <p v-if="is_liuhe==1">
                        六合余额：
                        <span id="user_money_partner">{{balance}}</span>
                    </p> -->
                </div>
                <el-color-picker v-model="color" :predefine="predefineColors" size="small" color-format="hsl" @change="change_color" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                usdt:'',
                balance: '0',
                kuaicai_balance: '0',
                is_kuaicai: 0,
                is_liuhe: 0,
                color: '',
                predefineColors: [
                    '#409EFF',
                    '#67C23A',
                    '#E6A23C',
                    '#F56C6C',
                    '#606266',
                    '#508f02',
                    '#7a64b2',
                    '#8d5f4f',
                    '#267db9',
                ],
                sid:'',
            }
        },
        created() {
            this.color = this.$store.state.userinfo.color;
            this.change_color(this.color);
            this.get_user_info();
            this.sid=window.setInterval(()=>{
                this.get_user_info();
            },10000)
        },
        methods: {
            change_color(color) {
                // console.log(color);
                if (color == null) {
                    return;
                }
                this.$emit('change_color', color);
            },
            get_user_info() {
                this.axios.post("/user/get_member_credit", { uid: this.$store.state.userinfo.id }).then(result => {
                    // console.log(result);
                    if (result.data.status == 1 && result.data.msg == "请求成功") {
                        this.$store.state.userinfo.balance = result.data.res.user_data[0].balance * 1;
                        this.$store.state.userinfo.kuaicai_balance = result.data.res.user_data[0].kuaicai_balance * 1;
                        this.$store.state.userinfo.win_and_lose = result.data.res.win_and_lose;
                        this.balance = result.data.res.user_data[0].credit_balance * 1;
                        this.kuaicai_balance = result.data.res.user_data[0].kuaicai_balance * 1;
                        this.is_kuaicai = result.data.res.user_data[0].is_kuaicai;
                        this.is_liuhe = result.data.res.user_data[0].is_liuhe;
                        this.usdt = result.data.res.usdt;
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .top_bar {
        height: 52px;
    }
    .base-clear {
        zoom: 1;
    }
    .base-clear:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
    .logo {
        float: left;
        width: 171px;
        height: 50px;
        overflow: hidden;
        color: #fff;
        line-height: 30px;
        font-size: 25px;
        font-family: "Microsoft YaHei" !important;
        font-weight: 550;
        background: url("../../assets/logo.png") no-repeat;
        padding-top: 2px;
        padding-left: 79px;
    }
    .user_info_wrap {
        float: right;
        padding-top: 4px;
        padding-right: 30px;
        position: relative;
    }
    .user_pic {
        background: url("../../assets/micon.png") -1px -202px no-repeat;
    }
    .user_pic {
        display: inline-block;
        /* float: left; */
        width: 39px;
        height: 39px;
        margin-top: 3px;
        vertical-align: middle;
    }
    .user_info {
        /* float: left; */
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        vertical-align: middle;
    }
    .user_info p {
        color: #e2f3ff;
        margin: 0;
        padding: 0;
    }
    .user_info p {
        line-height: 15px;
        font-family: Verdana;
    }
    .user_info p.user_name {
        color: #fffc00;
    }
    .user_info p.user_name {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
    }
    .user_info p span {
        margin-right: 4px;
    }
    .usdt{
        margin: 0 20px;
        color: #fff;
    }
</style>