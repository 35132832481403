<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-12 02:20:50
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-12 02:31:22
 * @FilePath: \pc\src\components\menuitems\loginlog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="loginlog">
        <el-table size="small" :data="logs" border header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
            <el-table-column property="id" label="ID" align="center"></el-table-column>
            <el-table-column property="posttime" label="登录时间" align="center"></el-table-column>
            <el-table-column property="login_ip" label="IP地址" align="center"></el-table-column>
            <el-table-column property="address" label="IP归属" align="center"></el-table-column>
        </el-table>
        <div style="text-align:center;padding-top:6px">注：只记录最近20条</div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                logs: [],
            }
        },
        created() { },
        methods: {
            get_logs(data) {
                this.logs = data;
            }
        },
    }
</script>

<style scoped>
</style>