/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-18 23:02:34
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-09 15:53:36
 * @FilePath: \pc\src\plugin\set_playing.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function set_playing(gid) {
    let playing_list = [];
    //重庆时时彩,排列35
    if (gid == 1 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
        playing_list = [
            { text: "全部", play: "ssc_all" },
            { text: "两面", play: "cqssc_lmp" },
            { text: "单球1-5", play: "cqssc_d1_5" },
            { text: "第一球", play: "cqssc_d1" },
            { text: "第二球", play: "cqssc_d2" },
            { text: "第三球", play: "cqssc_d3" },
            { text: "第四球", play: "cqssc_d4" },
            { text: "第五球", play: "cqssc_d5" }
        ]
    }
    //排列35
    if (gid == 10) {
        playing_list = [
            { text: "两面", play: "cqssc_lmp" },
            { text: "和值", play: "cqssc_hz" },
            { text: "直选", play: "pl_zhixuan" },
            { text: "组选", play: "pl_zuxuan" },
            { text: "不定位", play: "ftpl_bdw" },
            { text: "单球1-5", play: "cqssc_d1_5" },
            { text: "第一球", play: "cqssc_d1" },
            { text: "第二球", play: "cqssc_d2" },
            { text: "第三球", play: "cqssc_d3" },
            { text: "第四球", play: "cqssc_d4" },
            { text: "第五球", play: "cqssc_d5" }
        ]
    }
    //福彩3D
    if (gid == 4) {
        playing_list = [
            { text: "两面", play: "cqssc_lmp" },
            { text: "和值", play: "cqssc_hz" },
            { text: "直选", play: "ft_zhixuan" },
            { text: "组选", play: "ft_zuxuan" },
            { text: "不定位", play: "ftpl_bdw" },
            { text: "单球1-3", play: "cqssc_d1_5" },
            { text: "第一球", play: "cqssc_d1" },
            { text: "第二球", play: "cqssc_d2" },
            { text: "第三球", play: "cqssc_d3" }
        ]
    }
    //北京PK10
    if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
        playing_list = [
            { text: "全部", play: "pk10_all" },
            { text: "两面", play: "pk10_lmp" },
            { text: "冠亚组合", play: "pk10_gy" },
            { text: "1-10名", play: "pk10_d1_10" },
            { text: "三、四、五、六名", play: "pk10_d3_6" },
            { text: "七、八、九、十名", play: "pk10_d7_10" }
        ]
    }
    //六合彩（20），极速六合彩（34
    if (gid == 20 || gid == 34 || gid == 48) {
        playing_list = [
            { text: "特码", play: "tema" },
            { text: "特码B", play: "temab" },
            { text: "色波", play: "sebo" },
            { text: "特肖", play: "texiao" },
            { text: "正码", play: "zma" },
            { text: "正码1-6", play: "zma1_6" },
            { text: "正特", play: "zte" },
            { text: "连码", play: "lma2" },
            { text: "一肖尾数", play: "yxiao" },
            { text: "自选不中", play: "zxbz" },
            { text: "连肖", play: "lxiao" },
            { text: "尾连", play: "wlian2" },
            { text: "两面", play: "lmian" },
        ]
    }
    //pc蛋蛋，加拿大28（22），幸运28（28）
    if (gid == 22 || gid == 23) {
        playing_list = [
            { text: "两面", play: "lmian" },
            { text: "特码", play: "tema" },
            { text: "特码包三", play: "tmbs" }
        ]
    }
    //快3，江苏快3（6），北京快3（29）
    if (gid == 6 || gid == 29 || gid == 31 || gid == 30 || gid == 32 || gid == 36) {
        playing_list = [
            { text: "两面", play: "lmian" },
            { text: "红黑", play: "hongh" },
            { text: "红黑码", play: "honghm" },
            { text: "二同号单选", play: "ethdx" },
            { text: "二同号复选", play: "ethfx" },
            { text: "二不同号", play: "ebth" },
            { text: "三同号单选", play: "sthdx" },
            { text: "三不同号", play: "sbth" },
        ]
    }
    return playing_list
}