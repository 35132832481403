/*
 * @Author: your name
 * @Date: 2022-03-11 21:09:56
 * @LastEditTime: 2022-07-23 00:27:58
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\store\store.js
 */
import { createStore } from 'vuex'

export default createStore({
    state: {
        themeColor: '',
        themeColorhalf: '',
        themeColorLight: '',
        game_list: [],
        checked_gid: '',
        checked_gname: '',
        playing_list: [],
        lose_data: [],
        new_qnum: '',
        new_qnum_id: '',
        last_bet: {
            list: [],
            gid: '',
            playing: '',
        },
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
})
