/*
 * @Author: your name
 * @Date: 2022-03-11 16:28:34
 * @LastEditTime: 2022-06-09 15:26:56
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\router\index.js
 */
import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: '/',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/notice.vue'),
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    const isLogin = sessionStorage.getItem("islogin") ? "true" : "false"
    if (to.path == '/' || to.path == '/login') {
        next()
    } else {
        if (isLogin) {
            next()
        } else {
            next({ name: 'login' });
        }
    }
})

export default router
