/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-11 21:55:58
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-17 16:09:49
 * @FilePath: \pc\src\plugin\duijiangma.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
let pk10wpp = function (num) {
    if (num == 1 || num == 6) {
        return 'a';
    }
    if (num == 2 || num == 7) {
        return 'b';
    }
    if (num == 3 || num == 8) {
        return 'c';
    }
    if (num == 4 || num == 9) {
        return 'd';
    }
    if (num == 5 || num == 10) {
        return 'e';
    }
}
export function duijiangma(list, gid) {
    //ssc
    if (gid == 1 || gid == 4 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
        list.forEach(item => {
            item.djm=[];
            let tlist = item.txid.split('');
            tlist.reverse();
            tlist.forEach(t => {
                item.djm.push({
                    text: t, color: '',
                })
            });
            item.res.forEach(num => {
                item.djm.some(t => {
                    if (t.text == num && t.color == '') {
                        return t.color = 'red';
                    }
                })
            });
            item.djm.reverse();
        });
    }
    //pk10
    if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
        list.forEach(item => {
            item.djm = [];
            let tlist = item.txid.split('');
            tlist.forEach(t => {
                item.djm.push({
                    text: t, color: '',
                })
            });
            item.res.forEach(num => {
                item.djm.some(t => {
                    if ((t.text == num * 1 || t.text + 10 == num * 1) && t.color == '') {
                        return t.color = 'red';
                    }
                })
            });
            let rednums = item.djm.filter(item => item.color == 'red');
            // console.log(rednums);
            if (rednums.length == 8) {
                let wpp8 = '';
                let wpp9 = '';
                wpp8 = pk10wpp(item.res[8] * 1);
                wpp9 = pk10wpp(item.res[9] * 1);
                item.djm[item.djm.findIndex(djm => djm.text == wpp8 && djm.color == '')].color = 'red';
                item.djm[item.djm.findIndex(djm => djm.text == wpp9 && djm.color == '')].color = 'red';
            }
            if (rednums.length == 9) {
                item.djm.push(
                    {
                        text:item.res[9]*1==10?0:item.res[9]*1,
                        color:'#409eff',
                    }
                )
            }
        });
    }
    //lhc
    if (gid == 20 || gid == 34 || gid == 48) {
        list.forEach(item => {
            item.djm=[[],[],[],[],[],];
            let tlist = item.txid.split(',');
            // console.log(tlist);
            tlist.forEach((tl,index) => {
                tl = tl.split('');
                tl.forEach(t=>{
                    item.djm[index].push({
                        text: t, color: '',
                    })
                })
            });
            let sevenlist = [];
            for (let index = 0; index < item.djm[0].length; index++) {
                if (item.djm[0][index].text !== 'f') {
                    item.djm[0][index].color = 'red';
                    sevenlist.push(
                        {
                            t: item.djm[0][index].text,
                            djm: '',
                        }
                    );
                    if (sevenlist.length == 7) {
                        break;
                    }
                }
            }
            sevenlist.forEach(seven => {
                if (seven.t == '1' || seven.t == '6' || seven.t == 'a') {
                    seven.djm = item.djm[0];
                    seven.index = 0;
                }
                if (seven.t == '2' || seven.t == '7' || seven.t == 'b') {
                    seven.djm = item.djm[1];
                    seven.index = 1;
                }
                if (seven.t == '3' || seven.t == '8' || seven.t == 'c') {
                    seven.djm = item.djm[2];
                    seven.index = 2;
                }
                if (seven.t == '4' || seven.t == '9' || seven.t == 'd') {
                    seven.djm = item.djm[3];
                    seven.index = 3;
                }
                if (seven.t == '5' || seven.t == '0' || seven.t == 'e') {
                    seven.djm = item.djm[4];
                    seven.index = 4;
                }
            })
            let openlist = [];
            item.res.forEach(num => {
                openlist.push((num / 10).toString()[2] || '0');
            })
            for (let index = 0; index < 7; index++) {
                sevenlist[index].open = openlist[index];
            }
            sevenlist.forEach(seven => {
                if (seven.djm.find(djm => djm.text == seven.open && djm.color == '') != undefined) {
                    seven.djm.some(djm => {
                        if (djm.text == seven.open && djm.color == '') {
                            return djm.color = 'red';
                        }
                    })
                } else {
                    let open = pk10wpp(seven.open);
                    seven.djm.some(djm => {
                        if (djm.text == open && djm.color == '') {
                            return djm.color = 'red';
                        }
                    })
                }
                // console.log(sevenlist);
                item.djm.forEach((djm, index) => {
                    if (index == seven.index) {
                        djm.forEach(djm=>{
                            seven.djm.forEach(sdjm=>{
                                if (djm.text==sdjm.text&&sdjm.color=='red'&&djm.color=='') {
                                    djm.colo = 'red';
                                }
                            })
                        })
                    }
                })
            });
        });
    }
    //k3
    if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
        list.forEach(item => {
            item.djm=[];
            let tlist = item.txid.split('');
            tlist.forEach(t => {
                item.djm.push({
                    text: t, color: '',
                })
            });
            item.res.forEach(num => {
                item.djm.some(t => {
                    if (t.text == num && t.color == '') {
                        return t.color = 'red';
                    }
                })
            });
        });
    }
    //xy28
    if (gid == 22 || gid == 23) {
    }
    // console.log(list);
    return list
}