<template>
    <div class="cqssc-dds">
        <el-row type="flex">
            <el-col :span="24" v-for="item in balls" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 大小单双 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in size_odd_even" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items bet-items-size">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 总和、龙虎 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in sum" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items bet-items-sum">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <!-- 前三，中三，后三 -->
        <el-row type="flex" justify="space-around" :gutter="10">
            <el-col :span="8" v-for="item in front" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items">
                        <div v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </div>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="8" v-for="item in center" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items">
                        <div v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </div>
                    </div>
                </fieldset>
            </el-col>
            <el-col :span="8" v-for="item in back" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items">
                        <div v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </div>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        ballname: "第一球",
                        betclass: [
                            { title: "第一球", name: "0", visible: false, static_bonus: "-", val: "", subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "1", visible: false, static_bonus: "-", val: "", subname: "1_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "2", visible: false, static_bonus: "-", val: "", subname: "1_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "3", visible: false, static_bonus: "-", val: "", subname: "1_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "4", visible: false, static_bonus: "-", val: "", subname: "1_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "5", visible: false, static_bonus: "-", val: "", subname: "1_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "6", visible: false, static_bonus: "-", val: "", subname: "1_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "7", visible: false, static_bonus: "-", val: "", subname: "1_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "8", visible: false, static_bonus: "-", val: "", subname: "1_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "9", visible: false, static_bonus: "-", val: "", subname: "1_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                //大小单双
                size_odd_even: [
                    {
                        ballname: "大小、单双",
                        betclass: [
                            { title: "第一球", name: "大", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "小", visible: false, static_bonus: "-", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "单", visible: false, static_bonus: "-", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "双", visible: false, static_bonus: "-", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false }
                        ]
                    }
                ],
                /**总和，龙虎 */
                sum: [
                    {
                        ballname: "总和",
                        betclass: [
                            { title: "总和", name: "总和大", visible: false, static_bonus: "-", val: "", subname: "0_5_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "总和小", visible: false, static_bonus: "-", val: "", subname: "0_5_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "总和单", visible: false, static_bonus: "-", val: "", subname: "0_5_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "总和", name: "总和双", visible: false, static_bonus: "-", val: "", subname: "0_5_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            // { title: "总和龙虎", name: "龙", visible: false, static_bonus: "-", val: "" ,subname:"0_5_4", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                            // { title: "总和龙虎", name: "虎", visible: false, static_bonus: "-", val: "" ,subname:"0_5_5", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                            // { title: "总和龙虎", name: "和", visible: false, static_bonus: "-", val: "" ,subname:"0_5_6", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false},
                        ]
                    },
                ],
                /**前三 */
                front: [
                    {
                        ballname: "前三",
                        betclass: [
                            { title: "前三", name: "豹子", visible: false, static_bonus: "-", val: "", subname: "2_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", name: "顺子", visible: false, static_bonus: "-", val: "", subname: "2_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", name: "对子", visible: false, static_bonus: "-", val: "", subname: "2_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", name: "半顺", visible: false, static_bonus: "-", val: "", subname: "2_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "前三", name: "杂六", visible: false, static_bonus: "-", val: "", subname: "2_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                /**中三 */
                center: [
                    {
                        ballname: "中三",
                        betclass: [
                            { title: "中三", name: "豹子", visible: false, static_bonus: "-", val: "", subname: "3_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", name: "顺子", visible: false, static_bonus: "-", val: "", subname: "3_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", name: "对子", visible: false, static_bonus: "-", val: "", subname: "3_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", name: "半顺", visible: false, static_bonus: "-", val: "", subname: "3_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "中三", name: "杂六", visible: false, static_bonus: "-", val: "", subname: "3_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
                /**后三 */
                back: [
                    {
                        ballname: "后三",
                        betclass: [
                            { title: "后三", name: "豹子", visible: false, static_bonus: "-", val: "", subname: "4_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", name: "顺子", visible: false, static_bonus: "-", val: "", subname: "4_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", name: "对子", visible: false, static_bonus: "-", val: "", subname: "4_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", name: "半顺", visible: false, static_bonus: "-", val: "", subname: "4_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "后三", name: "杂六", visible: false, static_bonus: "-", val: "", subname: "4_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ]
            }
        },
        methods: {
            //赔率
            get_lose(tit) {
                let lose_data = this.$store.state.lose_data;
                //球号
                for (let i = 0; i < this.balls.length; i++) {
                    this.balls[i].ballname = tit;
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].title=tit;
                        lose_data.forEach(item => {
                            if (item.title == this.balls[i].betclass[j].title && item.name == this.balls[i].betclass[j].name) {
                                this.balls[i].betclass[j].static_bonus = item.rate;
                                this.balls[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.balls[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //大小单双
                for (let i = 0; i < this.size_odd_even.length; i++) {
                    for (let j = 0; j < this.size_odd_even[i].betclass.length; j++) {
                        this.size_odd_even[i].betclass[j].title=tit;
                        lose_data.forEach(item => {
                            if (item.title == this.size_odd_even[i].betclass[j].title && item.name == this.size_odd_even[i].betclass[j].name) {
                                this.size_odd_even[i].betclass[j].static_bonus = item.rate;
                                this.size_odd_even[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.size_odd_even[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //总和龙虎
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.sum[i].betclass[j].title && item.name == this.sum[i].betclass[j].name) {
                                this.sum[i].betclass[j].static_bonus = item.rate;
                                this.sum[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.sum[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.front[i].betclass[j].title && item.name == this.front[i].betclass[j].name) {
                                this.front[i].betclass[j].static_bonus = item.rate;
                                this.front[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.front[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.center[i].betclass[j].title && item.name == this.center[i].betclass[j].name) {
                                this.center[i].betclass[j].static_bonus = item.rate;
                                this.center[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.center[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.back[i].betclass[j].title && item.name == this.back[i].betclass[j].name) {
                                this.back[i].betclass[j].static_bonus = item.rate;
                                this.back[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.back[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
                // console.log(this.size_odd_even)
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //球号
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = ""
                    }
                }
                //大小单双
                for (let i = 0; i < this.size_odd_even.length; i++) {
                    for (let j = 0; j < this.size_odd_even[i].betclass.length; j++) {
                        this.size_odd_even[i].betclass[j].val = ""
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        this.sum[i].betclass[j].val = ""
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        this.front[i].betclass[j].val = ""
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        this.center[i].betclass[j].val = ""
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        this.back[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //球号
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].val && !isNaN(this.balls[i].betclass[j].val * 1)) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                //大小单双
                for (let i = 0; i < this.size_odd_even.length; i++) {
                    for (let j = 0; j < this.size_odd_even[i].betclass.length; j++) {
                        if (this.size_odd_even[i].betclass[j].val && !isNaN(this.size_odd_even[i].betclass[j].val * 1)) {
                            list.push(this.size_odd_even[i].betclass[j]);
                        }
                    }
                }
                //总和
                for (let i = 0; i < this.sum.length; i++) {
                    for (let j = 0; j < this.sum[i].betclass.length; j++) {
                        if (this.sum[i].betclass[j].val && !isNaN(this.sum[i].betclass[j].val * 1)) {
                            list.push(this.sum[i].betclass[j]);
                        }
                    }
                }
                //前三
                for (let i = 0; i < this.front.length; i++) {
                    for (let j = 0; j < this.front[i].betclass.length; j++) {
                        if (this.front[i].betclass[j].val && !isNaN(this.front[i].betclass[j].val * 1)) {
                            list.push(this.front[i].betclass[j]);
                        }
                    }
                }
                //中三
                for (let i = 0; i < this.center.length; i++) {
                    for (let j = 0; j < this.center[i].betclass.length; j++) {
                        if (this.center[i].betclass[j].val && !isNaN(this.center[i].betclass[j].val * 1)) {
                            list.push(this.center[i].betclass[j]);
                        }
                    }
                }
                //后三
                for (let i = 0; i < this.back.length; i++) {
                    for (let j = 0; j < this.back[i].betclass.length; j++) {
                        if (this.back[i].betclass[j].val && !isNaN(this.back[i].betclass[j].val * 1)) {
                            list.push(this.back[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //球号
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //大小单双
                    for (let j = 0; j < this.size_odd_even.length; j++) {
                        for (let k = 0; k < this.size_odd_even[j].betclass.length; k++) {
                            if (this.size_odd_even[j].betclass[k].title == list[i].title && this.size_odd_even[j].betclass[k].name == list[i].name) {
                                this.size_odd_even[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //总和
                    for (let j = 0; j < this.sum.length; j++) {
                        for (let k = 0; k < this.sum[j].betclass.length; k++) {
                            if (this.sum[j].betclass[k].title == list[i].title && this.sum[j].betclass[k].name == list[i].name) {
                                this.sum[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //前三
                    for (let j = 0; j < this.front.length; j++) {
                        for (let k = 0; k < this.front[j].betclass.length; k++) {
                            if (this.front[j].betclass[k].title == list[i].title && this.front[j].betclass[k].name == list[i].name) {
                                this.front[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //中三
                    for (let j = 0; j < this.center.length; j++) {
                        for (let k = 0; k < this.center[j].betclass.length; k++) {
                            if (this.center[j].betclass[k].title == list[i].title && this.center[j].betclass[k].name == list[i].name) {
                                this.center[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //后三
                    for (let j = 0; j < this.back.length; j++) {
                        for (let k = 0; k < this.back[j].betclass.length; k++) {
                            if (this.back[j].betclass[k].title == list[i].title && this.back[j].betclass[k].name == list[i].name) {
                                this.back[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .cqssc-dds .bet-items-one .bet-name {
        width: 24px;
        height: 24px !important;
        line-height: 24px !important;
        background: #409eff;
        border-radius: 50%;
        padding: 0;
        margin-left: 5px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        font-size: 16px;
    }
    .cqssc-dds .bet-items-one .bet-lose {
        margin: 0;
    }
    .cqssc-dds .bet-items-one .el-input--mini {
        margin-right: 6px;
    }
    .cqssc-dds .bet-items-sum .bet-name {
        width: 40px;
    }
</style>
