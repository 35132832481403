<!--
 * @Author: your name
 * @Date: 2022-03-11 16:28:34
 * @LastEditTime: 2022-08-04 16:49:15
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\views\Home.vue
-->
<template>
    <div class="home">
        <top ref="top" @change_color="change_color" />
        <usermenu ref="usermenu" @change_game="change_game" @quit="quit" />
        <gameinfo ref="gameinfo" @stop_bet="stop_bet" @stop_sale="stop_sale" @refersh_nera_bet="refersh_nera_bet" @poll_lose_data="poll_lose_data" @get_user_balance="get_user_balance" @get_jqkj="get_jqkj" />
        <div class="jqkj-info" v-if="show_jqkj">
            <history ref="history" from="jqkj" />
        </div>
        <gamePlaying ref="gamePlaying" @get_lose_data="get_lose_data" />
        <div v-if="is_stop_sale" class="stop-sale light-bg" :style="{height:stop_sale_h}">
            <div class="stop-sale-text">{{$store.state.checked_gname}} 當前未開盤</div>
            <div class="container">
                <div class="square"></div>
                <div class="square"></div>
                <div class="square"></div>
                <div class="square"></div>
            </div>
        </div>
        <div class="notice theme-half-bg">
            <div class="notice_box">
                <div class="notice_p base-clear">
                    <p>
                        <marquee id="noticeLink" behavior="scroll" scrollamount="2" direction="left" onmouseout="this.setAttribute('scrollamount', 2, 0);" onmouseover="this.setAttribute('scrollamount', 0, 0);">
                            <a v-for="item in noticedialog.list" :key="item.index">
                                <span>{{item.title}}:{{item.text}}</span>
                                <span>{{item.create_time}}</span>
                            </a>
                        </marquee>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import top from '../components/homePublic/top.vue'
    import usermenu from '../components/homePublic/usermenu.vue'
    import gameinfo from '../components/homePublic/gameinfo.vue'
    import gamePlaying from '../components/homePublic/gamePlaying.vue'
    import { set_playing } from '../plugin/set_playing'
    import history from "../components/menuitems/history.vue";
    export default {
        name: 'Home',
        components: {
            top, usermenu, gameinfo, gamePlaying, history
        },
        data() {
            return {
                activated: '',
                game_list: [],
                show_jqkj: false,
                is_stop_bet: false,
                is_stop_sale: false,
                poll_data_id: '',
                noticedialog: {
                    visible: false,
                    title: "站内公告",
                    list: []
                },
                themeColor: '',
                themeColorhalf: '',
                rleft: '0px',
                rtop: '0px',
                stop_sale_h: '',
            }
        },
        created() {
            this.get_playing();
            this.get_notice();
        },
        methods: {
            quit() {
                window.clearInterval(this.poll_data_id);
                this.$refs['gameinfo'].clear();
            },
            get_playing() {
                let gid = this.$store.state.checked_gid;
                this.$store.state.playing_list = set_playing(gid);
                this.$store.state.playing = this.$store.state.playing_list[0].play;
                this.$store.state.activeName = this.$store.state.playing_list[0].text;
            },
            //彩种停售
            stop_sale(state) {
                this.is_stop_sale = state;
                if (state) {
                    this.stop_sale_h = document.documentElement.clientHeight+ 'px'
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            },
            /**下注成功后刷新用余额 */
            get_user_balance() {
                this.$refs.top.get_user_info();
            },
            //当期投注结束后刷新近期下注
            refersh_nera_bet() {
                this.$refs.gamePlaying.cancel_refresh();
            },
            /**判断是否轮询赔率 */
            poll_lose_data(s) {
                // console.log(s);
                window.clearInterval(this.poll_data_id);
                if (s) {
                    this.get_lose_data();
                    this.poll_data_id = setInterval(() => {
                        let qnum = this.$store.state.new_qnum;
                        if (qnum) {
                            this.get_lose_data();
                        }
                    }, 10000);
                }
            },
            //停止下注
            stop_bet(state, text) {
                if (this.$store.state.lose_data.length > 0) {
                    // console.log(this.$store.state.lose_data);
                    this.$store.state.lose_data.forEach(item => {
                        item.rate = '-';
                    });
                }
                this.$nextTick(() => {
                    this.$refs['gamePlaying'].set_lose();
                    this.$refs['gamePlaying'].stop_bet(state, text);
                });
                this.is_stop_bet = state;
            },
            /**轮询获取当前彩种赔率等数据 */
            get_lose_data() {
                return new Promise((resolve, reject) => {
                    let gid = this.$store.state.checked_gid;
                    let uid = this.$store.state.userinfo.user_id;
                    let qnum = this.$store.state.new_qnum;
                    let playing = this.$store.state.playing;
                    this.axios.post("/main/index_body_new",
                        {
                            gid: gid,
                            playing: playing,
                            uid: uid,
                            qnum: qnum,
                        }
                    ).then(result => {
                        // console.log(result.data)
                        if (result.data.status && result.data.msg === "请求成功！" && result.data.res != null && result.data.res.length > 0) {
                            let res = result.data.res;
                            this.$store.state.lose_data = res || [];
                            //设置赔率
                            this.$nextTick(() => {
                                this.$refs['gamePlaying'].set_lose();
                            });
                            resolve();
                        }
                    });
                })
            },
            change_game(gid, gname) {
                this.is_stop_sale = false;
                this.show_jqkj = false;
                this.$store.state.playing_list = set_playing(gid);
                this.$store.state.playing = this.$store.state.playing_list[0].play;
                this.$store.state.activeName = this.$store.state.playing_list[0].text;
                this.$refs['gameinfo'].change_game(gid, gname);
                this.$refs['gamePlaying'].choose_lottery();
            },
            change_submenu(name, params) {
                let menu = this.$refs['usermenu'].submenu.find(item => item.title == name);
                this.$refs['usermenu'].click_sub_menu(menu, params);
            },
            change_color(color) {
                let strs = color.split(',')
                let h = strs[0].match(/\d+/g)[0];
                let s = strs[1].split('%')[0];
                let l = strs[2].split('%')[0];
                if (l < 95) {
                    l = 95;
                }
                if (s > 35) {
                    s = 35;
                }
                let themeColorLight = `hsl(${h},${s}%,${l}%)`;
                let themeColorhalf = `hsl(${h},30%,75%)`;
                this.$emit('get_color', color, themeColorLight, themeColorhalf);
                this.themeColor = color;
                this.themeColorhalf = themeColorhalf;
            },
            get_activated(activated_text) {
                this.activated = activated_text;
            },
            get_jqkj() {
                if (this.show_jqkj) {
                    this.show_jqkj = false;
                } else {
                    this.axios.get("/betresult/index?gid=" + this.$store.state.checked_gid + "&recent=1").then(result => {
                        // console.log(result.data);
                        if (result.data.status && result.data.msg === "请求成功！") {
                            this.show_jqkj = true;
                            this.$nextTick(() => {
                                this.$refs['history'].getdata(result.data.res, false);
                            })
                        }
                    });
                }
            },
            get_notice() {
                this.axios.get("/notice/notice_one").then(result => {
                    // console.log(result.data);
                    if (result.data.status && result.data.msg === "请求成功") {
                        this.noticedialog.list = result.data.res;
                    }
                });
            },
        },
    }
</script>
<style>
    .home {
        min-width: 1366px;
        position: relative;
    }
    .jqkj-info {
        width: 1100px;
        max-height: 185px;
        overflow: hidden;
        margin-left: 5px;
    }
    .notice {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 24px;
        overflow: hidden;
        zoom: 1;
        color: #000;
        background: #fcf5f3 url("../assets/BoxBg1.png") 0px -131px no-repeat;
        z-index: 2;
    }
    .notice_box {
        height: 24px;
        margin-left: 24px;
        position: relative;
        text-align: right;
        overflow: hidden;
    }
    .notice_box .notice_p {
        position: absolute;
        width: 100%;
        text-align: left;
        overflow: hidden;
    }
    .notice_box p {
        width: 100%;
        text-align: left;
        margin-right: 10px;
        height: 24px;
        line-height: 24px;
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: keep-all;
    }
    .notice_box p a {
        text-decoration: none;
        color: #000;
    }
    .notice_box p a span {
        margin-right: 10px;
    }
    .stop-sale {
        width: 100%;
        position: absolute;
        top: 92px;
        z-index: 1;
        overflow: hidden;
    }
    .stop-sale-text {
        font-size: 40px;
        padding: 40px;
        color: v-bind(themeColor);
        font-weight: bold;
        position: absolute;
        z-index: 2;
    }
    .container {
        position: relative;
        top: -124px;
    }
    .square {
        position: absolute;
    }
    .square:nth-child(1) {
        width: 400px;
        height: 400px;
        background: v-bind(themeColorhalf);
        animation: cornerrot1 4s ease infinite;
        opacity: 0.3;
        transform: rotate(45deg);
    }
    .square:nth-child(2) {
        width: 400px;
        height: 400px;
        background: v-bind(themeColorhalf);
        animation: cornerrot2 6s ease infinite;
        opacity: 0.3;
        transform: rotate(45deg);
    }
    .square:nth-child(3) {
        width: 300px;
        height: 300px;
        background: v-bind(themeColorhalf);
        animation: cornerrot3 8s ease infinite;
        opacity: 0.3;
        transform: rotate(45deg);
    }
    .square:nth-child(4) {
        width: 400px;
        height: 400px;
        background: v-bind(themeColorhalf);
        animation: cornerrot4 10s ease infinite;
        opacity: 0.3;
        transform: rotate(45deg);
    }
    @keyframes cornerrot1 {
        0% {
            top: -100px;
            left: 200px;
        }
        50% {
            top: 0px;
            left: 0px;
        }
        100% {
            top: -100px;
            left: 200px;
        }
    }
    @keyframes cornerrot2 {
        0% {
            top: -200px;
            left: -100px;
        }
        50% {
            top: 0px;
            right: 0px;
        }
        100% {
            top: -200px;
            left: -100px;
        }
    }
    @keyframes cornerrot3 {
        0% {
            top: -0px;
            left: 500px;
        }
        50% {
            top: 0px;
            left: 0px;
        }
        100% {
            top: -0px;
            left: 500px;
        }
    }
    @keyframes cornerrot4 {
        0% {
            top: -200px;
            left: 600px;
        }
        50% {
            top: 0px;
            left: 0px;
        }
        100% {
            top: -200px;
            left: 600px;
        }
    }
</style>