<template>
<div class="cqssc-lm">
    <!-- 两面 -->
    <el-row type="flex">
        <el-col :span="24" v-for="item in tema" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <div class="bet-items-one" style="height:195px;">
                <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                    <span class="bet-name betclassname_old_pcdd" :style="{'background-color':i.color}">{{i.name}}</span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                    <!-- <div style="clear:both"></div> -->
                </el-col>
            </div>
        </el-col>
    </el-row>
</div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            tema: [
                {
                    ballname: "特码",
                    betclass: [
                        { title: "特码", name: "0", visible: false, static_bonus: "", val: "" ,subname:"0_0_0", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                        { title: "特码", name: "1", visible: false, static_bonus: "", val: "" ,subname:"0_0_1", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "2", visible: false, static_bonus: "", val: "" ,subname:"0_0_2", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "3", visible: false, static_bonus: "", val: "" ,subname:"0_0_3", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "4", visible: false, static_bonus: "", val: "" ,subname:"0_0_4", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "5", visible: false, static_bonus: "", val: "" ,subname:"0_0_5", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "6", visible: false, static_bonus: "", val: "" ,subname:"0_0_6", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "7", visible: false, static_bonus: "", val: "" ,subname:"0_0_7", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "8", visible: false, static_bonus: "", val: "" ,subname:"0_0_8", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "9", visible: false, static_bonus: "", val: "" ,subname:"0_0_9", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "10", visible: false, static_bonus: "", val: "" ,subname:"0_0_10", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "11", visible: false, static_bonus: "", val: "" ,subname:"0_0_11", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "12", visible: false, static_bonus: "", val: "" ,subname:"0_0_12", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "13", visible: false, static_bonus: "", val: "" ,subname:"0_0_13", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                        { title: "特码", name: "14", visible: false, static_bonus: "", val: "" ,subname:"0_0_14", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                        { title: "特码", name: "15", visible: false, static_bonus: "", val: "" ,subname:"0_0_15", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "16", visible: false, static_bonus: "", val: "" ,subname:"0_0_16", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "17", visible: false, static_bonus: "", val: "" ,subname:"0_0_17", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "18", visible: false, static_bonus: "", val: "" ,subname:"0_0_18", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "19", visible: false, static_bonus: "", val: "" ,subname:"0_0_19", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "20", visible: false, static_bonus: "", val: "" ,subname:"0_0_20", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "21", visible: false, static_bonus: "", val: "" ,subname:"0_0_21", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "22", visible: false, static_bonus: "", val: "" ,subname:"0_0_22", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "23", visible: false, static_bonus: "", val: "" ,subname:"0_0_23", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "24", visible: false, static_bonus: "", val: "" ,subname:"0_0_24", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#F56C6C"},
                        { title: "特码", name: "25", visible: false, static_bonus: "", val: "" ,subname:"0_0_25", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#67C23A"},
                        { title: "特码", name: "26", visible: false, static_bonus: "", val: "" ,subname:"0_0_26", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#409EFF"},
                        { title: "特码", name: "27", visible: false, static_bonus: "", val: "" ,subname:"0_0_27", straightbet_high: "", straightbet_low: "" , bonus_high:"", sub_popover:false, color:"#909399"},
                    ]
                }
            ]
        }
    },
    created(){
        // this.get_lose();
    },
    methods: {
        /**显示赔率等数据 */
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            //两面
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    this.tema[i].betclass[j].static_bonus=static_bonus[j];
                    this.tema[i].betclass[j].straightbet_high=others[0].straightbet_high;
                    this.tema[i].betclass[j].straightbet_low=others[0].straightbet_low;
                }
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            //两面
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    this.tema[i].betclass[j].val=""
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            //两面
            for(let i=0;i<this.tema.length;i++){
                for(let j=0;j<this.tema[i].betclass.length;j++){
                    if(this.tema[i].betclass[j].val&&!isNaN(this.tema[i].betclass[j].val*1)){
                        list.push(this.tema[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                //两面
                for(let j=0;j<this.tema.length;j++){
                    for(let k=0;k<this.tema[j].betclass.length;k++){
                        if(this.tema[j].betclass[k].title==list[i].title&&this.tema[j].betclass[k].name==list[i].name){
                            this.tema[j].betclass[k].val=list[i].val;
                        }
                    }
                }
            }
        },
    },
}
</script>

<style scoped>
.betclassname_old_pcdd{
    display: inline-block;
    width: 25px !important;
    height: 25px;
    padding: 0 !important;
    border-radius: 50%;
    color: #fff;
    text-align: center !important;
    font-weight: bold;
    margin-left:20px;
    margin-bottom:3px;
    line-height: 25px !important;
}
</style>


