<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-21 21:17:24
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-12 17:16:51
 * @FilePath: \pc\src\components\homePublic\gamePlaying.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="game-playing">
        <div class="btns theme-border-half">
            <button :class="`btn theme-border-half ${item.bg_class}`" v-for="item in playing_list" :key="item.play" @click="change_play(item)">{{item.text}}</button>
        </div>
        <betpage ref="betPage" :activeName="activeName" @get_user_balance="get_user_balance" @regainLoss="regainLoss"></betpage>
        <lmcl ref="lmcl"></lmcl>
        <historyChart ref="historyChart"></historyChart>
    </div>
</template>

<script>
    import betpage from "../betPage/betPage.vue";
    import lmcl from "./lmcl.vue"
    import historyChart from "./historyChart.vue"
    export default {
        components: { betpage, lmcl, historyChart },
        data() {
            return {
                playing: "",
                activeName: "",
                playing_list: [],
            };
        },
        created() {
            this.choose_lottery();
        },
        methods: {
            //六合彩正特，连码，自选不中，根据小玩法重新获取赔率
            regainLoss(playStr) {
                this.$store.state.playing = playStr;
                this.playing = playStr;
                this.$parent.get_lose_data();
            },
            setplay() {
                this.playing = this.$store.state.playing;
                this.activeName = this.$store.state.activeName;
                this.playing_list = this.$store.state.playing_list;
                // console.log(this.playing_list,this.activeName);
                this.set_lose();
            },
            //停止下注
            stop_bet(state, text) {
                this.$refs.betPage.stop_bet(state, text);
            },
            /**切换彩种 */
            choose_lottery() {
                this.playing = this.$store.state.playing;
                this.playing_list = this.$store.state.playing_list;
                this.activeName = this.$store.state.activeName;
                this.playing_list.forEach(item => {
                    if (this.activeName == item.text) {
                        item.bg_class = 'theme-bg white-text';
                    } else {
                        item.bg_class = 'light-bg-fff';
                    }
                })
                this.$nextTick(() => {
                    this.$refs.betPage.choose_lottery();
                    this.$refs.historyChart.set_chart();
                    this.set_lose();
                    this.$refs.lmcl.get_data();
                })
                // console.log(this.playing_list);
            },
            /**玩法切换 */
            change_play(item) {
                this.$store.state.playing = item.play;
                let activeName = this.$store.state.activeName = item.text;
                this.$refs.betPage.reset();
                this.$refs.historyChart.set_chart();
                this.activeName = activeName;
                this.playing_list.forEach(item => {
                    if (this.activeName == item.text) {
                        item.bg_class = 'theme-bg white-text';
                    } else {
                        item.bg_class = 'light-bg-fff';
                    }
                })
                this.$emit('get_lose_data')
            },
            /**设置赔率,历史记录 */
            set_lose() {
                this.$refs.betPage.set_lose();
                // console.log(this.$refs.betPage);
            },
            /**下注成功后刷新用余额 */
            get_user_balance() {
                this.$parent.get_user_balance();
                this.$refs.lmcl.get_data();
            },
            //撤单刷新近期下注
            cancel_refresh() {
                this.$refs.lmcl.get_data();
            }
        }
    };
</script>

<style scoped>
    .game-playing {
        padding: 5px 0px 24px 10px;
        position: relative;
    }
    .btns {
        border-left: 1px solid;
    }
    .btn {
        height: 24px;
        padding: 5px 13px;
        line-height: 10px;
        border-radius: 0;
        border: 1px solid;
        border-left: 0;
        border-bottom: 0;
    }
</style>
