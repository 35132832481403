<template>
    <div class="jslhc">
        <div class="fast-sub" v-show="activeName=='特码'||activeName=='特码B'||activeName=='正码'||activeName=='正特'">
            <el-button class="fast-sub-btn" size="small" type="primary" @click="open_fastsub">快速投注</el-button>
        </div>
        <el-dialog v-model="fastsub.visible" width="1200px" customClass="mydialogclass">
            <template #header>
                <span class="mydialogtitle">快速投注</span>
            </template>
            <fastsubcp ref="fastsubcp" :nums="fastsub.nums" />
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="close_fastsubcp">取 消</el-button>
                    <el-button type="primary" @click="subBet">提 交</el-button>
                </span>
            </template>
        </el-dialog>

        <lmian v-show="activeName=='两面'" ref="lm"></lmian>
        <tema v-show="activeName=='特码'" ref="tema"></tema>
        <temab v-show="activeName=='特码B'" ref="temab"></temab>
        <sebo v-show="activeName=='色波'" ref="sebo"></sebo>
        <texiao v-show="activeName=='特肖'" ref="texiao"></texiao>
        <zma v-show="activeName=='正码'" ref="zma"></zma>
        <zma1_6 v-show="activeName=='正码1-6'" ref="zma1_6"></zma1_6>
        <zte v-show="activeName=='正特'" ref="zte" @regainLoss="regainLoss"></zte>
        <lma v-show="activeName=='连码'" ref="lma" @regainLoss="regainLoss"></lma>
        <yxiao v-show="activeName=='一肖尾数'" ref="yxiao"></yxiao>
        <zxbz v-show="activeName=='自选不中'" ref="zxbz" @regainLoss="regainLoss"></zxbz>
        <lxiao v-show="activeName=='连肖'" ref="lxiao" @regainLoss="regainLoss"></lxiao>
        <wlian v-show="activeName=='尾连'" ref="wlian" @regainLoss="regainLoss"></wlian>
    </div>
</template>

<script>
    import lmian from "./lmian";
    import lma from "./lma";
    import lxiao from "./lxiao";
    import sebo from "./sebo";
    import tema from "./tema";
    import temab from "./temab";
    import texiao from "./texiao";
    import yxiao from "./yxiao";
    import zma from "./zma";
    import zma1_6 from "./zma1_6";
    import zte from "./zte";
    import zxbz from "./zxbz";
    import wlian from "./wlian";
    import fastsubcp from "./fastsubcp";
    export default {
        components: {
            lmian,
            tema,
            temab,
            sebo,
            texiao,
            zma,
            zma1_6,
            zte,
            lma,
            yxiao,
            zxbz,
            lxiao,
            wlian,
            fastsubcp,
        },
        props: ["activeName"],
        data() {
            return {
                fastsub: {
                    visible: false,
                    nums: [],
                }
            }
        },
        methods: {
            close_fastsubcp() {
                this.$refs['fastsubcp'].reset_fast_sub();
                this.fastsub.visible = false;
            },
            reset_fast_sub() {
                this.$refs['fastsubcp'].reset_fast_sub();
            },
            //快速投注提交
            subBet() {
                let list = this.$refs['fastsubcp'].subList;
                if (list.length > 0) {
                    this.$emit('fastsub', list);
                }
            },
            //打开快速投注
            open_fastsub() {
                if (this.activeName == "特码") {
                    this.fastsub.nums = this.$refs['tema'].tema[0].betclass;
                } else if (this.activeName == "特码B") {
                    this.fastsub.nums = this.$refs['temab'].temab[0].betclass;
                } else if (this.activeName == "正码") {
                    this.fastsub.nums = this.$refs['zma'].zma[0].betclass;
                } else if (this.activeName == "正特") {
                    this.fastsub.nums = this.$refs['zte'].zte[0].betclass;
                }
                this.fastsub.visible = true;
            },
            //六合彩正特，连码，自选不中，根据小玩法重新获取赔率
            regainLoss(playStr) {
                this.$emit('regainLoss', playStr)
            },
            /**显示赔率 */
            set_lose() {
                if (this.activeName == "两面") { this.$refs.lm.get_lose(); }
                if (this.activeName == "特码") { this.$refs.tema.get_lose(); }
                if (this.activeName == "特码B") { this.$refs.temab.get_lose(); }
                if (this.activeName == "色波") { this.$refs.sebo.get_lose(); }
                if (this.activeName == "特肖") { this.$refs.texiao.get_lose(); }
                if (this.activeName == "正码") { this.$refs.zma.get_lose(); }
                if (this.activeName == "正码1-6") { this.$refs.zma1_6.get_lose(); }
                if (this.activeName == "正特") { this.$refs.zte.get_lose(); }
                if (this.activeName == "连码") { this.$refs.lma.get_lose(); }
                if (this.activeName == "一肖尾数") { this.$refs.yxiao.get_lose(); }
                if (this.activeName == "自选不中") { this.$refs.zxbz.get_lose(); }
                if (this.activeName == "连肖") { this.$refs.lxiao.get_lose(); }
                if (this.activeName == "尾连") { this.$refs.wlian.get_lose(); }
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$parent.get_fast())
                return this.$parent.get_fast();
            },
            /**重填，重置 */
            reset() {
                if (this.activeName == "两面") { this.$refs.lm.reset(); }
                if (this.activeName == "特码") { this.$refs.tema.reset(); }
                if (this.activeName == "特码B") { this.$refs.temab.reset(); }
                if (this.activeName == "色波") { this.$refs.sebo.reset(); }
                if (this.activeName == "特肖") { this.$refs.texiao.reset(); }
                if (this.activeName == "正码") { this.$refs.zma.reset(); }
                if (this.activeName == "正码1-6") { this.$refs.zma1_6.reset(); }
                if (this.activeName == "正特") { this.$refs.zte.reset(); }
                if (this.activeName == "连码") { this.$refs.lma.reset(); }
                if (this.activeName == "一肖尾数") { this.$refs.yxiao.reset(); }
                if (this.activeName == "自选不中") { this.$refs.zxbz.reset(); }
                if (this.activeName == "连肖") { this.$refs.lxiao.reset(); }
                if (this.activeName == "尾连") { this.$refs.wlian.reset(); }
            },
            /**提交，下注 */
            sub_bet() {
                // console.log(this.activeName);
                if (this.activeName == "两面") { return this.$refs.lm.sub_bet(); }
                if (this.activeName == "特码") { return this.$refs.tema.sub_bet(); }
                if (this.activeName == "特码B") { return this.$refs.temab.sub_bet(); }
                if (this.activeName == "色波") { return this.$refs.sebo.sub_bet(); }
                if (this.activeName == "特肖") { return this.$refs.texiao.sub_bet(); }
                if (this.activeName == "正码") { return this.$refs.zma.sub_bet(); }
                if (this.activeName == "正码1-6") { return this.$refs.zma1_6.sub_bet(); }
                if (this.activeName == "正特") { return this.$refs.zte.sub_bet(); }
                if (this.activeName == "连码") { return this.$refs.lma.sub_bet(); }
                if (this.activeName == "一肖尾数") { return this.$refs.yxiao.sub_bet(); }
                if (this.activeName == "自选不中") { return this.$refs.zxbz.sub_bet(); }
                if (this.activeName == "连肖") { return this.$refs.lxiao.sub_bet(); }
                if (this.activeName == "尾连") { return this.$refs.wlian.sub_bet(); }
            },
            /**重复上次下单 */
            repeat_last_bet(playing, list) {
                if (playing == "lmian") { this.$refs.lm.repeat_last_bet(list); }
                if (playing == "tema") { this.$refs.tema.repeat_last_bet(list); }
                if (playing == "temab") { this.$refs.temab.repeat_last_bet(list); }
                if (playing == "sebo") { this.$refs.sebo.repeat_last_bet(list); }
                if (playing == "texiao") { this.$refs.texiao.repeat_last_bet(list); }
                if (playing == "zma") { this.$refs.zma.repeat_last_bet(list); }
                if (playing == "zma1-zma6") { this.$refs.zma1_6.repeat_last_bet(list); }
                if (playing == "zte") { this.$refs.zte.repeat_last_bet(list); }
                if (playing == "lma") { this.$refs.lma.repeat_last_bet(list); }
                if (playing == "yxiao") { this.$refs.yxiao.repeat_last_bet(list); }
                if (playing == "zxbz") { this.$refs.zxbz.repeat_last_bet(list); }
                if (playing == "lxiao") { this.$refs.lxiao.repeat_last_bet(list); }
                if (playing == "wlian") { this.$refs.wlian.repeat_last_bet(list); }
            },
        },
    }
</script>

<style scoped>
    .fast-sub {
        position: absolute;
        top: -26px;
        left: 830px;
        z-index: 1;
    }
    .fast-sub-btn {
        height: 24px;
    }
</style>
