<template>
    <div class="cqssc-d1-5">
        <!-- 1-5 -->
        <div class="flex5">
            <div class="flex5-item" v-for="item in balls" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items">
                        <div v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        ballname: "第一球",
                        betclass: [
                            { title: "第一球", name: "0", visible: false, static_bonus: "-", val: "", subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "1", visible: false, static_bonus: "-", val: "", subname: "1_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "2", visible: false, static_bonus: "-", val: "", subname: "1_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "3", visible: false, static_bonus: "-", val: "", subname: "1_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "4", visible: false, static_bonus: "-", val: "", subname: "1_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "5", visible: false, static_bonus: "-", val: "", subname: "1_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "6", visible: false, static_bonus: "-", val: "", subname: "1_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "7", visible: false, static_bonus: "-", val: "", subname: "1_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "8", visible: false, static_bonus: "-", val: "", subname: "1_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "9", visible: false, static_bonus: "-", val: "", subname: "1_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第二球",
                        betclass: [
                            { title: "第二球", name: "0", visible: false, static_bonus: "-", val: "", subname: "1_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "1", visible: false, static_bonus: "-", val: "", subname: "1_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "2", visible: false, static_bonus: "-", val: "", subname: "1_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "3", visible: false, static_bonus: "-", val: "", subname: "1_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "4", visible: false, static_bonus: "-", val: "", subname: "1_1_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "5", visible: false, static_bonus: "-", val: "", subname: "1_1_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "6", visible: false, static_bonus: "-", val: "", subname: "1_1_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "7", visible: false, static_bonus: "-", val: "", subname: "1_1_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "8", visible: false, static_bonus: "-", val: "", subname: "1_1_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "9", visible: false, static_bonus: "-", val: "", subname: "1_1_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第三球",
                        betclass: [
                            { title: "第三球", name: "0", visible: false, static_bonus: "-", val: "", subname: "1_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "1", visible: false, static_bonus: "-", val: "", subname: "1_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "2", visible: false, static_bonus: "-", val: "", subname: "1_2_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "3", visible: false, static_bonus: "-", val: "", subname: "1_2_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "4", visible: false, static_bonus: "-", val: "", subname: "1_2_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "5", visible: false, static_bonus: "-", val: "", subname: "1_2_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "6", visible: false, static_bonus: "-", val: "", subname: "1_2_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "7", visible: false, static_bonus: "-", val: "", subname: "1_2_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "8", visible: false, static_bonus: "-", val: "", subname: "1_2_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "9", visible: false, static_bonus: "-", val: "", subname: "1_2_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第四球",
                        betclass: [
                            { title: "第四球", name: "0", visible: false, static_bonus: "-", val: "", subname: "1_3_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "1", visible: false, static_bonus: "-", val: "", subname: "1_3_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "2", visible: false, static_bonus: "-", val: "", subname: "1_3_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "3", visible: false, static_bonus: "-", val: "", subname: "1_3_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "4", visible: false, static_bonus: "-", val: "", subname: "1_3_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "5", visible: false, static_bonus: "-", val: "", subname: "1_3_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "6", visible: false, static_bonus: "-", val: "", subname: "1_3_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "7", visible: false, static_bonus: "-", val: "", subname: "1_3_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "8", visible: false, static_bonus: "-", val: "", subname: "1_3_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第四球", name: "9", visible: false, static_bonus: "-", val: "", subname: "1_3_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        ballname: "第五球",
                        betclass: [
                            { title: "第五球", name: "0", visible: false, static_bonus: "-", val: "", subname: "1_4_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "1", visible: false, static_bonus: "-", val: "", subname: "1_4_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "2", visible: false, static_bonus: "-", val: "", subname: "1_4_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "3", visible: false, static_bonus: "-", val: "", subname: "1_4_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "4", visible: false, static_bonus: "-", val: "", subname: "1_4_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "5", visible: false, static_bonus: "-", val: "", subname: "1_4_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "6", visible: false, static_bonus: "-", val: "", subname: "1_4_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "7", visible: false, static_bonus: "-", val: "", subname: "1_4_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "8", visible: false, static_bonus: "-", val: "", subname: "1_4_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第五球", name: "9", visible: false, static_bonus: "-", val: "", subname: "1_4_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
            }
        },
        methods: {
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.title == this.balls[i].betclass[j].title && item.name == this.balls[i].betclass[j].name) {
                                this.balls[i].betclass[j].static_bonus = item.rate;
                                this.balls[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.balls[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //1-5
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].val && !isNaN(this.balls[i].betclass[j].val * 1)) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //1-5
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .cqssc-d1-5 .bet-name {
        width: 24px !important;
        height: 24px;
        line-height: 24px !important;
        background: #409eff;
        border-radius: 50%;
        padding: 0 !important;
        margin-left: 5px;
        font-weight: bold;
        color: #fff;
        font-size: 16px;
    }
</style>
