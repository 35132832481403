/**六合彩生肖计算 */
// type：生成那种情况下的生肖
//arr，每个元素带有num,sx属性
export function get_lhc_sx(type, arr) {
	let idx = 0;
	if (type == "开奖") {
		for (let i = 0; i < arr.length; i++) {
			idx = arr[i].num;
			//计算生肖排序
			let arr_sx = [ '猪','鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗',]
			let time=arr[i].lottery_open.split('-');
			let move = get_nongli(time[0]*1,time[1]*1,time[2]*1)[0] - 2018;
			// console.log(move);
			for (let k = 0; k < move; k++) {
				let dele_value = arr_sx.shift();
				arr_sx.push(dele_value);
			}
			switch (Number(idx)) {
				case 12: case 24: case 36: case 48:
					arr[i].sx = (arr_sx[0]);
					break;
				case 11: case 23: case 35: case 47:
					arr[i].sx = (arr_sx[1]);
					break;
				case 10: case 22: case 34: case 46:
					arr[i].sx = (arr_sx[2]);
					break;
				case 9: case 21: case 33: case 45:
					arr[i].sx = (arr_sx[3]);
					break;
				case 8: case 20: case 32: case 44:
					arr[i].sx = (arr_sx[4]);
					break;
				case 7: case 19: case 31: case 43:
					arr[i].sx = (arr_sx[5]);
					break;
				case 6: case 18: case 30: case 42:
					arr[i].sx = (arr_sx[6]);
					break;
				case 5: case 17: case 29: case 41:
					arr[i].sx = (arr_sx[7]);
					break;
				case 4: case 16: case 28: case 40:
					arr[i].sx = (arr_sx[8]);
					break;
				case 3: case 15: case 27: case 39:
					arr[i].sx = (arr_sx[9]);
					break;
				case 2: case 14: case 26: case 38:
					arr[i].sx = (arr_sx[10]);
					break;
				case 1: case 13: case 25: case 37: case 49:
					arr[i].sx = (arr_sx[11]);
					break;
			}
			// console.log(arr[i].sx)
			// console.log(arr)
		}
	}
	if (type == "初始化") {
		// console.log(arr)
		for (let i = 0; i < arr.length; i++) {
			for (let j = 0; j < arr[i].betclass.length; j++) {
				for (let l = 0; l < arr[i].betclass[j].betclass_nums.length; l++) {
					idx = arr[i].betclass[j].betclass_nums[l].num;
					//计算生肖排序//鼠、牛、虎、兔、龙、蛇、马、羊、猴、鸡、狗、猪
					let arr_sx = [ '猪','鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗',];
					let move = get_nongli(new Date().getFullYear(),new Date().getMonth()+1,new Date().getMonth())[0] - 2018;
					for (let k = 0; k < move; k++) {
						let dele_value = arr_sx.shift();
						arr_sx.push(dele_value);
					}
					switch (idx) {
						case 12: case 24: case 36: case 48:
							arr[i].betclass[j].name = (arr_sx[0]);
							break;
						case 11: case 23: case 35: case 47:
							arr[i].betclass[j].name = (arr_sx[1]);
							break;
						case 10: case 22: case 34: case 46:
							arr[i].betclass[j].name = (arr_sx[2]);
							break;
						case 9: case 21: case 33: case 45:
							arr[i].betclass[j].name = (arr_sx[3]);
							break;
						case 8: case 20: case 32: case 44:
							arr[i].betclass[j].name = (arr_sx[4]);
							break;
						case 7: case 19: case 31: case 43:
							arr[i].betclass[j].name = (arr_sx[5]);
							break;
						case 6: case 18: case 30: case 42:
							arr[i].betclass[j].name = (arr_sx[6]);
							break;
						case 5: case 17: case 29: case 41:
							arr[i].betclass[j].name = (arr_sx[7]);
							break;
						case 4: case 16: case 28: case 40:
							arr[i].betclass[j].name = (arr_sx[8]);
							break;
						case 3: case 15: case 27: case 39:
							arr[i].betclass[j].name = (arr_sx[9]);
							break;
						case 2: case 14: case 26: case 38:
							arr[i].betclass[j].name = (arr_sx[10]);
							break;
						case 1: case 13: case 25: case 37: case 49:
							arr[i].betclass[j].name = (arr_sx[11]);
							break;
					}
				}
			}
		}

	}
	if (type == "快速下注初始化") {
		// console.log(arr)
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].title == '生肖') {
				for (let j = 0; j < arr[i].nums.length; j++) {
					idx = arr[i].nums[j];
					//计算生肖排序
					let arr_sx = [ '猪','鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗',]
					let move = get_nongli(new Date().getFullYear(),new Date().getMonth()+1,new Date().getMonth())[0] - 2018;
					for (let k = 0; k < move; k++) {
						let dele_value = arr_sx.shift();
						arr_sx.push(dele_value);
					}
					switch (idx) {
						case 12: case 24: case 36: case 48:
							arr[i].name = (arr_sx[0]);
							break;
						case 11: case 23: case 35: case 47:
							arr[i].name = (arr_sx[1]);
							break;
						case 10: case 22: case 34: case 46:
							arr[i].name = (arr_sx[2]);
							break;
						case 9: case 21: case 33: case 45:
							arr[i].name = (arr_sx[3]);
							break;
						case 8: case 20: case 32: case 44:
							arr[i].name = (arr_sx[4]);
							break;
						case 7: case 19: case 31: case 43:
							arr[i].name = (arr_sx[5]);
							break;
						case 6: case 18: case 30: case 42:
							arr[i].name = (arr_sx[6]);
							break;
						case 5: case 17: case 29: case 41:
							arr[i].name = (arr_sx[7]);
							break;
						case 4: case 16: case 28: case 40:
							arr[i].name = (arr_sx[8]);
							break;
						case 3: case 15: case 27: case 39:
							arr[i].name = (arr_sx[9]);
							break;
						case 2: case 14: case 26: case 38:
							arr[i].name = (arr_sx[10]);
							break;
						case 1: case 13: case 25: case 37: case 49:
							arr[i].name = (arr_sx[11]);
							break;
					}
				}
			}
		}
	}
	// console.log(arr);
	return arr;
	//获取当前年农历日期
	function get_nongli(year,month,date) {
		// console.log(year,month,date);
		let CalendarData = new Array(100);
		let madd = new Array(12);
		let cYear, cMonth, cDay, TheDate;
		CalendarData = new Array(0xA4B, 0x5164B, 0x6A5, 0x6D4, 0x415B5, 0x2B6, 0x957, 0x2092F, 0x497, 0x60C96, 0xD4A, 0xEA5, 0x50DA9, 0x5AD, 0x2B6, 0x3126E, 0x92E, 0x7192D, 0xC95, 0xD4A, 0x61B4A, 0xB55, 0x56A, 0x4155B, 0x25D, 0x92D, 0x2192B, 0xA95, 0x71695, 0x6CA, 0xB55, 0x50AB5, 0x4DA, 0xA5B, 0x30A57, 0x52B, 0x8152A, 0xE95, 0x6AA, 0x615AA, 0xAB5, 0x4B6, 0x414AE, 0xA57, 0x526, 0x31D26, 0xD95, 0x70B55, 0x56A, 0x96D, 0x5095D, 0x4AD, 0xA4D, 0x41A4D, 0xD25, 0x81AA5, 0xB54, 0xB6A, 0x612DA, 0x95B, 0x49B, 0x41497, 0xA4B, 0xA164B, 0x6A5, 0x6D4, 0x615B4, 0xAB6, 0x957, 0x5092F, 0x497, 0x64B, 0x30D4A, 0xEA5, 0x80D65, 0x5AC, 0xAB6, 0x5126D, 0x92E, 0xC96, 0x41A95, 0xD4A, 0xDA5, 0x20B55, 0x56A, 0x7155B, 0x25D, 0x92D, 0x5192B, 0xA95, 0xB4A, 0x416AA, 0xAD5, 0x90AB5, 0x4BA, 0xA5B, 0x60A57, 0x52B, 0xA93, 0x40E95);
		madd[0] = 0;
		madd[1] = 31;
		madd[2] = 59;
		madd[3] = 90;
		madd[4] = 120;
		madd[5] = 151;
		madd[6] = 181;
		madd[7] = 212;
		madd[8] = 243;
		madd[9] = 273;
		madd[10] = 304;
		madd[11] = 334;

		function GetBit(m, n) {
			return (m >> n) & 1;
		}
		function e2c(year,month,date) {
			// console.log(arguments);
			TheDate = (arguments.length != 3) ? new Date() : new Date(arguments[0], arguments[1], arguments[2]);
			let total, m, n, k;
			let isEnd = false;
			let tmp = TheDate.getYear();
			if (tmp < 1900) {
				tmp += 1900;
			}
			total = (tmp - 1921) * 365 + Math.floor((tmp - 1921) / 4) + madd[TheDate.getMonth()] + TheDate.getDate() - 38;

			if (TheDate.getYear() % 4 == 0 && TheDate.getMonth() > 1) {
				total++;
			}
			for (m = 0; ; m++) {
				k = (CalendarData[m] < 0xfff) ? 11 : 12;
				for (n = k; n >= 0; n--) {
					if (total <= 29 + GetBit(CalendarData[m], n)) {
						isEnd = true; break;
					}
					total = total - 29 - GetBit(CalendarData[m], n);
				}
				if (isEnd) break;
			}
			cYear = 1921 + m;
			cMonth = k - n + 1;
			cDay = total;
			if (k == 12) {
				if (cMonth == Math.floor(CalendarData[m] / 0x10000) + 1) {
					cMonth = 1 - cMonth;
				}
				if (cMonth > Math.floor(CalendarData[m] / 0x10000) + 1) {
					cMonth--;
				}
			}
			return new Array(cYear, cMonth, cDay);
		}
		return e2c(year,month,date);
	}
}