/*
 * @Author: your name
 * @Date: 2022-03-12 14:03:01
 * @LastEditTime: 2022-09-08 21:49:25
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\axios\index.js
 */
import axios from 'axios'; // 引入axios
import qs from 'qs'; // 引入qs模块，用来序列化post类型的数据
// import { Dialog } from 'vant';
import store from '../store/store'
// import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus';

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/';
} else if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_CURRENTMODE === 'test') {
    // axios.defaults.baseURL = 'http://pc.dist.com';
    axios.defaults.baseURL = 'https://api.1688center.com';
} else {
    axios.defaults.baseURL = 'https://api.dj5568.com';
}
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/**修改配置信息，跨域保存session值 */
axios.defaults.withCredentials = true;

// 设置了请求拦截器，处理下post方法传的参数格式问题
axios.interceptors.request.use(
    config => {
        // 利用qs转换了一下data的格式，让post方法能够正确识别传参
        config.data = config.method == 'post' ? qs.stringify(config.data) : config.data;
        if (config.url !== '/login/login_post'&&!config.url.includes('login/vcode')&&!config.url.includes('login/get_web_info')) {
            // console.log(store.state.url_query_info);
            config.url = `${config.url}${config.url.includes('?') ? '&' : '?'}${store.state.url_query_info}`;
        }
        return config;
    },
    err => Promise.reject(console.log(err))
)

axios.interceptors.response.use(
    res => {
        // console.log(res);
        if (res.data.status === 0 && (res.data.msg === '请输入用户名' || res.data.msg === '您已被登出，请重新登录！' || res.data.msg === '您的账号已在其他pc端登录！'|| res.data.msg === '您的账号已在其他mobile端登录！')) {
            ElMessageBox.alert(res.data.msg, "错误提示", {
                confirmButtonText: "确定",
                type: "error",
                callback: action => {
                    window.location.replace('/');
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }
            });
        }else if (res.data.status === 0) {
            ElMessageBox.confirm(res.data.msg, "提示信息", {
                customClass:'myElMessageBox',
                confirmButtonText: "确定",
                type: "error",
                callback: action => {}
            });
        }
        return res;
    },
    error => {// 对响应错误做处理
        return Promise.reject(error);
    }
);
export default axios;