<template>
    <div class="tema">
        <el-row type="flex">
            <el-col :span="24" v-for="item in tema" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one">
                        <div class="flex5">
                            <div class="flex5-item" v-for="i in item.betclass" :key="i.index">
                                <span v-if="i.color=='red'" class="lhc_tema_red">{{i.name}}</span>
                                <span v-if="i.color=='green'" class="lhc_tema_green">{{i.name}}</span>
                                <span v-if="i.color=='blue'" class="lhc_tema_blue">{{i.name}}</span>
                                <span class="bet-lose" style=" min-width: 50px;">{{i.static_bonus}}</span>
                                <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
        <el-row type="flex" :gutter="10">
            <el-col :span="24" v-for="item in dxdssb" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one ">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name" :style="{'color':i.color}">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tema: [
                    {
                        ballname: "特码",
                        betclass: [
                            { title: "特码", name: "1", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "2", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "3", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "4", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "5", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "6", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "7", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "8", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "9", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "10", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "11", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "12", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "13", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "14", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "15", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "16", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "17", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "18", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "19", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "20", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "21", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "22", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "23", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "24", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "25", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "26", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "27", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "28", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "29", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "30", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "31", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "32", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "33", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "34", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "35", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "36", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "37", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "38", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "39", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "40", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "41", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "42", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "43", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "44", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "特码", name: "45", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "46", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "特码", name: "47", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "48", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "特码", name: "49", visible: false, static_bonus: "-", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                        ]
                    },
                ],
                dxdssb: [
                    {
                        ballname: '大小单双、色波',
                        betclass: [
                            { title: "两面", name: "特大", visible: false, static_bonus: "-", val: "", subname: "10_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "特小", visible: false, static_bonus: "-", val: "", subname: "10_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "特单", visible: false, static_bonus: "-", val: "", subname: "10_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "特双", visible: false, static_bonus: "-", val: "", subname: "10_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "合单", visible: false, static_bonus: "-", val: "", subname: "10_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "合双", visible: false, static_bonus: "-", val: "", subname: "10_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "尾大", visible: false, static_bonus: "-", val: "", subname: "10_0_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "两面", name: "尾小", visible: false, static_bonus: "-", val: "", subname: "10_0_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "" },
                            { title: "色波", name: "红波", visible: false, static_bonus: "-", val: "", subname: "1_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#f56c6c" },
                            { title: "色波", name: "蓝波", visible: false, static_bonus: "-", val: "", subname: "1_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#409eff" },
                            { title: "色波", name: "绿波", visible: false, static_bonus: "-", val: "", subname: "1_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#67c23a" },
                        ]
                    }
                ]
            }
        },
        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                //特码
                for (let j = 0; j < this.tema[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.tema[0].betclass[j].title && item.name == this.tema[0].betclass[j].name) {
                            this.tema[0].betclass[j].static_bonus = item.rate;
                            this.tema[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.tema[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
                //大小单双、色波
                for (let j = 0; j < this.dxdssb[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        if (item.title == this.dxdssb[0].betclass[j].title && item.name == this.dxdssb[0].betclass[j].name) {
                            this.dxdssb[0].betclass[j].static_bonus = item.rate;
                            this.dxdssb[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.dxdssb[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //特码
                for (let i = 0; i < this.tema.length; i++) {
                    for (let j = 0; j < this.tema[i].betclass.length; j++) {
                        this.tema[i].betclass[j].val = ""
                    }
                }
                //大小单双、色波
                for (let i = 0; i < this.dxdssb.length; i++) {
                    for (let j = 0; j < this.dxdssb[i].betclass.length; j++) {
                        this.dxdssb[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //特码
                for (let i = 0; i < this.tema.length; i++) {
                    for (let j = 0; j < this.tema[i].betclass.length; j++) {
                        if (this.tema[i].betclass[j].val && !isNaN(this.tema[i].betclass[j].val * 1)) {
                            list.push(this.tema[i].betclass[j]);
                        }
                    }
                }
                //大小单双、色波
                for (let i = 0; i < this.dxdssb.length; i++) {
                    for (let j = 0; j < this.dxdssb[i].betclass.length; j++) {
                        if (this.dxdssb[i].betclass[j].val && !isNaN(this.dxdssb[i].betclass[j].val * 1)) {
                            list.push(this.dxdssb[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //特码
                    for (let j = 0; j < this.tema.length; j++) {
                        for (let k = 0; k < this.tema[j].betclass.length; k++) {
                            if (this.tema[j].betclass[k].title == list[i].title && this.tema[j].betclass[k].name == list[i].name) {
                                this.tema[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                    //大小单双、色波
                    for (let j = 0; j < this.dxdssb.length; j++) {
                        for (let k = 0; k < this.dxdssb[j].betclass.length; k++) {
                            if (this.dxdssb[j].betclass[k].title == list[i].title && this.dxdssb[j].betclass[k].name == list[i].name) {
                                this.dxdssb[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .bet-page .flex5 {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .bet-page .flex5-item {
        width: 20%;
    }
    .lhc_tema_red,
    .lhc_tema_blue,
    .lhc_tema_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 2px;
        margin-bottom: 0px;
        font-weight: bold;
    }
    .lhc_tema_red {
        background-color: #f56c6c;
    }
    .lhc_tema_blue {
        background-color: #0089ff;
    }
    .lhc_tema_green {
        background-color: #67c23a;
    }
</style>