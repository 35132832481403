<template>
    <div class="zhixuan">
        <el-row justify="space-around" :gutter="1">
            <el-col :span="24" style="background-color:#fff;padding:6px 0;">
                <el-radio-group v-model="type" size="" fill="#E6A23C" @change="type_change">
                    <el-radio-button label="前三组选"></el-radio-button>
                    <el-radio-button label="中三组选"></el-radio-button>
                    <el-radio-button label="后三组选"></el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="12">
                <div class="bet-title">
                    <span>组三</span>
                </div>
                <div class="bet-items" v-for="item in balls" :key="item.index">
                    <el-checkbox-group v-model="item.checked_list" size="small">
                        <el-checkbox-button v-for="i in item.betclass" :key="i.index" :label="i.name"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
                <div style="background-color:#fff;padding:6px 0;">
                    赔率：<span style="color:#f56c6c;margin-right:10px;">{{this.balls[0].betclass[0].static_bonus}}</span>
                    <span>下注金额：</span>
                    <el-input class="lma-val" v-model="zhixuan3_val" size="small"  v-on:focus="zhixuan3_val=check_fast_val(zhixuan3_val)"></el-input>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="bet-title">
                    <span>组六</span>
                </div>
                <div class="bet-items bet-items1" v-for="item in balls6" :key="item.index">
                    <el-checkbox-group v-model="item.checked_list" size="small">
                        <el-checkbox-button v-for="i in item.betclass" :key="i.index" :label="i.name"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
                <div style="background-color:#fff;padding:6px 0;">
                    赔率：<span style="color:#f56c6c;margin-right:10px;">{{this.balls6[0].betclass[0].static_bonus}}</span>
                    <span>下注金额：</span>
                    <el-input class="lma-val" v-model="zhixuan6_val" size="small"  v-on:focus="zhixuan6_val=check_fast_val(zhixuan6_val)"></el-input>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        checked_list: [],
                        ballname: "第一球",
                        betclass: [
                            { title: "第一球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        checked_list: [],
                        ballname: "第二球",
                        betclass: [
                            { title: "第二球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_2_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                balls6: [
                    {
                        checked_list: [],
                        ballname: "第一球",
                        betclass: [
                            { title: "第一球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        checked_list: [],
                        ballname: "第二球",
                        betclass: [
                            { title: "第二球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        checked_list: [],
                        ballname: "第三球",
                        betclass: [
                            { title: "第三球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_2_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                zhixuan3_val: '',
                zhixuan6_val: '',
                type: '前三组选',
            }
        },
        methods: {
            type_change(type) {
                this.get_lose();
                // console.log(this.type);
            },
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                let index = 0;
                if (this.type == '前三组选') {
                    index = 0;
                }
                if (this.type == '中三组选') {
                    index = 2;
                }
                if (this.type == '后三组选') {
                    index = 4;
                }
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].static_bonus = static_bonus[index];
                        this.balls[i].betclass[j].straightbet_high = others[index].straightbet_high;
                        this.balls[i].betclass[j].straightbet_low = others[index].straightbet_low;
                    }
                }
                for (let i = 0; i < this.balls6.length; i++) {
                    for (let j = 0; j < this.balls6[i].betclass.length; j++) {
                        this.balls6[i].betclass[j].static_bonus = static_bonus[index + 1];
                        this.balls6[i].betclass[j].straightbet_high = others[index + 1].straightbet_high;
                        this.balls6[i].betclass[j].straightbet_low = others[index + 1].straightbet_low;
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                } else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.balls[0].checked_list = [];
                this.balls[1].checked_list = [];
                this.balls6[0].checked_list = [];
                this.balls6[1].checked_list = [];
                this.balls6[2].checked_list = [];
                this.zhixuan3_val = '';
                this.zhixuan6_val = '';
            },
            /**提交，下注 */
            sub_bet() {
				console.log(this.type);
                let list = [];
                let zu3 = [[]];
                let zu6 = [[]];
                if (this.balls[0].checked_list.length < 1 && this.balls[1].checked_list.length < 1 && this.balls6[0].checked_list.length < 1 && this.balls6[1].checked_list.length < 1 && this.balls6[2].checked_list.length < 1) {
                    return { type: 'error', msg: '请选需要投注的号码' }
                }
                if ((this.balls[0].checked_list.length > 0 || this.balls[1].checked_list.length > 0) && (this.balls[0].checked_list.length + this.balls[1].checked_list.length < 2)) {
                    return { type: 'error', msg: '组三至少选择2个号码' }
                } else {
                    for (let i = 0; i < this.balls.length; i++) {
                        this.balls[i].checked_list = this.balls[i].checked_list.sort((a, b) => a - b);
                    }
                    //进行组合
                    let arr = [this.balls[0].checked_list, this.balls[1].checked_list];
                    for (let i = 0; i < arr.length; i++) {
                        let tarr = [];
                        for (let j = 0; j < zu3.length; j++)
                            for (let k = 0; k < arr[i].length; k++)
                                tarr.push(zu3[j].concat(arr[i][k]));
                        zu3 = tarr;
                    }
                }
                if ((this.balls6[0].checked_list.length > 0 || this.balls6[1].checked_list.length > 0 || this.balls6[2].checked_list.length > 0) && (this.balls6[0].checked_list.length + this.balls6[1].checked_list.length + this.balls6[2].checked_list.length < 3)) {
                    return { type: 'error', msg: '组六至少选择3个号码' }
                } else {
                    for (let i = 0; i < this.balls6.length; i++) {
                        this.balls6[i].checked_list = this.balls6[i].checked_list.sort((a, b) => a - b);
                    }
                    //进行组合
                    let arr = [this.balls6[0].checked_list, this.balls6[1].checked_list, this.balls6[2].checked_list];
                    for (let i = 0; i < arr.length; i++) {
                        let tarr = [];
                        for (let j = 0; j < zu6.length; j++)
                            for (let k = 0; k < arr[i].length; k++)
                                tarr.push(zu6[j].concat(arr[i][k]));
                        zu6 = tarr;
                    }
                }
                if ((this.zhixuan3_val == '' || this.zhixuan3_val < 0) && (this.zhixuan6_val == '' || this.zhixuan6_val < 0)) {
                    return { type: 'error', msg: '请输入下注额' }
                }
                //生成注单
                for (let i = 0; i < zu3.length; i++) {
                    list.push({
                        title: this.type + "组三",
                        name: zu3[i].join(''),
                        visible: false,
                        static_bonus: this.balls[0].betclass[0].static_bonus,
                        val: this.zhixuan3_val,
                        subname: this.type == '前三组选' ? "2_2_0" : this.type == '中三组选' ? '3_2_0' : '4_2_0',
                        straightbet_high: this.balls[0].betclass[0].straightbet_high,
                        straightbet_low: this.balls[0].betclass[0].straightbet_low,
                        bonus_high: "",
                        sub_popover: false
                    })
                }
                for (let i = 0; i < zu6.length; i++) {
                    list.push({
                        title: this.type + "组六",
                        name: zu6[i].join(''),
                        visible: false,
                        static_bonus: this.balls[0].betclass[0].static_bonus,
                        val: this.zhixuan6_val,
                        subname: this.type == '前三组选' ? "2_2_1" : this.type == '中三组选' ? '3_2_1' : '4_2_1',
                        straightbet_high: this.balls[0].betclass[0].straightbet_high,
                        straightbet_low: this.balls[0].betclass[0].straightbet_low,
                        bonus_high: "",
                        sub_popover: false
                    })
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .zhixuan >>> .el-checkbox-button--mini .el-checkbox-button__inner {
        padding: 7px 10px;
        font-size: 20px;
        font-weight: bold;
    }
    .bet-items {
        padding: 24px 0 !important;
    }
    .bet-items1 {
        padding: 10px 0 !important;
    }
    .lma-val {
        width: 120px !important;
        margin: 0 auto !important;
    }
</style>
