<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-21 21:17:31
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-09-03 23:23:08
 * @FilePath: \pc\src\components\homePublic\historyChart.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="history-chart" v-show="chart_list.length>0">
        <div class="btn-list light-bg ">
            <div :class="item.class" v-for="(item,i) in chart_list" :key="i" :style="{'width':((100/chart_list.length)+1+'%')}" @click="check_item(i)">{{item.text}}</div>
        </div>
        <div class="chart">
            <div class="chart-list">
                <div class="rank-col" v-for="item in chart_data" :key="item.index">
                    <div class="rank-value" v-for="val in item" :key="val.index">{{val}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { numsRanking } from "../../plugin/numsRanking";
    export default {
        data() {
            return {
                activeChart: 0,
                /**玩法列表，根据选取的彩种匹配 */
                chart_list: [],
                /**处理后的排列数据 */
                chart_data: [],
                sid: '',
            }
        },
        mounted() {
            // this.set_chart();

        },
        methods: {
            check_item(index) {
                this.activeChart = index;
                this.get_history(index);
                for (const item of this.chart_list) {
                    item.class = 'btn-item theme-border black-text';
                }
                this.chart_list[index].class = 'btn-item theme-border theme-bg white-text';
            },

            /**初始化彩种对应的历史图表 */
            set_chart() {
				window.clearInterval(this.sid);
                this.chart_list = [];
                this.activeChart = 0;
                let gid = this.$store.state.checked_gid;
                let playing = this.$store.state.playing;
                //重庆时时彩-------
                if (gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
                    //两面，1-5
                    if (playing == "cqssc_lmp" || playing == "cqssc_d1_5"|| playing == "ssc_all") {
                        this.chart_list = [
                            { id: "0", text: "第1球大小", class:'' }, { id: "2", text: "第1球单双", class:'' }, { id: "3", text: "第2球大小", class:'' }, { id: "4", text: "第2球单双", class:'' },
                            { id: "5", text: "第3球大小", class:'' }, { id: "6", text: "第3球单双", class:'' }, { id: "7", text: "第4球大小", class:'' }, { id: "8", text: "第4球单双", class:'' },
                            { id: "9", text: "第5球大小", class:'' }, { id: "10", text: "第5球单双", class:'' }, { id: "11", text: "总和大小", class:'' }, { id: "12", text: "总和单双", class:'' }, { id: "13", text: "龙虎", class:'' }
                        ];
                    }
                    //第1球
                    if (playing == "cqssc_d1") {
                        this.chart_list = [{ id: "1", text: "第1球", class:'' }, { id: "2", text: "第1球大小", class:'' }, { id: "3", text: "第1球单双", class:'' }, { id: "4", text: "总和大小", class:'' }, { id: "5", text: "总和单双", class:'' }, { id: "6", text: "龙虎", class:'' }];
                    }
                    //第2球
                    if (playing == "cqssc_d2") {
                        this.chart_list = [{ id: "1", text: "第2球", class:'' }, { id: "2", text: "第2球大小", class:'' }, { id: "3", text: "第2球单双", class:'' }, { id: "4", text: "总和大小", class:'' }, { id: "5", text: "总和单双", class:'' }, { id: "6", text: "龙虎", class:'' }];
                    }
                    //第3球
                    if (playing == "cqssc_d3") {
                        this.chart_list = [{ id: "1", text: "第3球", class:'' }, { id: "2", text: "第3球大小", class:'' }, { id: "3", text: "第3球单双", class:'' }, { id: "4", text: "总和大小", class:'' }, { id: "5", text: "总和单双", class:'' }, { id: "6", text: "龙虎", class:'' }];
                    }
                    //第4球
                    if (playing == "cqssc_d4") {
                        this.chart_list = [{ id: "1", text: "第4球", class:'' }, { id: "2", text: "第4球大小", class:'' }, { id: "3", text: "第4球单双", class:'' }, { id: "4", text: "总和大小", class:'' }, { id: "5", text: "总和单双", class:'' }, { id: "6", text: "龙虎", class:'' }];
                    }
                    //第5球
                    if (playing == "cqssc_d5") {
                        this.chart_list = [{ id: "1", text: "第5球", class:'' }, { id: "2", text: "第5球大小", class:'' }, { id: "3", text: "第5球单双", class:'' }, { id: "4", text: "总和大小", class:'' }, { id: "5", text: "总和单双", class:'' }, { id: "6", text: "龙虎", class:'' }];
                    }
                }
                //北京PK10-------
                if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
                    this.chart_list = [{ id: "1", text: "冠、亚军和", class:'' }, { id: "2", text: "冠、亚军和大小", class:'' }, { id: "3", text: "冠、亚军和单双", class:'' }];
                }
                //pc蛋蛋，加拿大28，幸运28-------
                if (gid == 22 || gid == 23) {
                    this.chart_list = [{ id: "1", text: "特码", class:'' }, { id: "2", text: "大小", class:'' }, { id: "3", text: "单双", class:'' }, { id: "4", text: "波色", class:'' }];
                }
                if (this.chart_list.length > 0) {
                    for (const item of this.chart_list) {
                        item.class = 'btn-item theme-border black-text';
                    }
                    this.chart_list[0].class = 'btn-item theme-border theme-bg white-text';
                }
                this.get_history(this.activeChart);
				this.sid = setInterval(() => {
                    this.get_history(this.activeChart);
                }, 20000);
            },
            get_history(index=0) {
                let gid = this.$store.state.checked_gid;
                let playing = this.$store.state.playing;
                this.axios.get('/main/index_footer?gid=' + gid).then(result => {
                    if (result.data.status === 1) {
                        let history = result.data.res.history;
                        if (Array.isArray(history) && history.length > 0) {
                            // 截取最新20期
                            if (history.length > 20) {
                                history = history.splice(-20, 20);
                            }
                            this.chart_data = numsRanking(gid, history, playing, index);
                            // console.log(this.chart_data)
                        }
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .history-chart {
        width: 892px;
        margin-top: 5px;
    }
    .history-chart .chart {
        background-color: #fff;
    }
    .history-chart .chart-list {
        border-bottom: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        background: linear-gradient(90deg, #ddd 1px, transparent 0),
            linear-gradient(#ddd 1px, transparent 0);
        background-size: 39px 24px;
        background-color: #fff;
        text-align: left;
    }
    .history-chart .chart-list::after {
        display: block;
        content: "";
        clear: both;
    }
    .rank-col {
        display: inline-block;
        text-align: center;
        width: 39px;
        vertical-align: top;
    }
    .rank-value {
        height: 24px;
        line-height: 24px;
    }
    .btn-list {
        display: flex;
    }
    .btn-item {
        padding: 3px;
        border: 1px solid ;
        border-top: 2px solid ;
        text-align: center;
    }
    /* .btn-item:first-child {
        border-left: 0;
    } */
    .btn-item:hover {
        cursor: pointer;
    }
</style>
