<!--
 * @Author: your name
 * @Date: 2020-06-18 14:35:52
 * @LastEditTime: 2022-08-03 17:16:50
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \luck\src\components\betPage\cqssc\cqssc.vue
-->
<template>
    <div class="cqssc">
        <all v-show="activeName=='全部'" ref="all"></all>
        <lm v-show="activeName=='两面'" ref="lm"></lm>
        <d1_5 v-show="activeName=='单球1-5'" ref="d1_5"></d1_5>
        <dds  v-show="activeName=='第五球'||activeName=='第四球'||activeName=='第三球'||activeName=='第二球'||activeName=='第一球'" ref="dds"></dds>
    </div>
</template>

<script>
    import lm from "./lm";
    import all from "./all";
    import d1_5 from "./d1_5";
    import dds from "./dds";


    export default {
        components: { lm, d1_5, dds, all },
        props: ["activeName"],
        data() {
            return {

            }
        },
        methods: {
            /**显示赔率 */
            set_lose() {
                if (this.activeName == "全部") {
                    this.$refs.all.get_lose();
                } else if (this.activeName == "两面") {
                    this.$refs.lm.get_lose();
                }
                else if (this.activeName == "单球1-5") {
                    this.$refs.d1_5.get_lose();
                }
                else {
                    this.$refs.dds.get_lose(this.activeName);
                }
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$parent.get_fast())
                return this.$parent.get_fast();
            },
            /**重填，重置 */
            reset() {
                if (this.activeName == "两面") {
                    this.$refs.lm.reset();
                }
                else if (this.activeName == "全部") {
                    this.$refs.all.reset();
                }
                else if (this.activeName == "单球1-5") {
                    this.$refs.d1_5.reset();
                }
                else {
                    this.$refs.dds.reset(this.activeName);
                }
            },
            /**提交，下注 */
            sub_bet() {
                if (this.activeName == "两面") {
                    return this.$refs.lm.sub_bet();
                }
                else if (this.activeName == "全部") {
                    return this.$refs.all.sub_bet();
                }
                else if (this.activeName == "单球1-5") {
                    return this.$refs.d1_5.sub_bet();
                }
                else {
                    return this.$refs.dds.sub_bet(this.activeName);
                }
            },
            /**重复上次下单 */
            repeat_last_bet(playing, list) {
                if (playing == "cqssc_lmp") {
                    this.$refs.lm.repeat_last_bet(list);
                }
                else if (playing == "ssc_all") {
                    this.$refs.all.repeat_last_bet(list);
                }
                else if (playing == "cqssc_d1_5") {
                    this.$refs.d1_5.repeat_last_bet(list);
                }
                else {
                    this.$refs.dds.repeat_last_bet(list);
                }
            },
        },
    }
</script>