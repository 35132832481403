<template>
    <div class="honghei">
        <!-- 红黑 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in hh" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="bet-items-one k3-one">
                        <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                            <span class="bet-name">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { link } from 'fs';
    export default {
        data() {
            return {
                /**红黑 */
                hh: [
                    {
                        ballname: "红黑",
                        betclass: [
                            { title: "红黑", name: "黑号", visible: false, static_bonus: "-", val: "", subname: "8_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "红号", visible: false, static_bonus: "-", val: "", subname: "8_1_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "大红", visible: false, static_bonus: "-", val: "", subname: "8_1_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "小红", visible: false, static_bonus: "-", val: "", subname: "8_1_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "大黑", visible: false, static_bonus: "-", val: "", subname: "8_1_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "小黑", visible: false, static_bonus: "-", val: "", subname: "8_1_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "红双", visible: false, static_bonus: "-", val: "", subname: "8_1_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "红单", visible: false, static_bonus: "-", val: "", subname: "8_1_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "黑双", visible: false, static_bonus: "-", val: "", subname: "8_1_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "红黑", name: "黑单", visible: false, static_bonus: "-", val: "", subname: "8_1_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    }
                ],
            }
        },

        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                lose_data.forEach(item => {
                    item.subname = `${item.level_two}_${item.level_three}_${item.level_four}`
                })
                //红黑
                // console.log(static_bonus)
                for (let i = 0; i < this.hh.length; i++) {
                    for (let j = 0; j < this.hh[i].betclass.length; j++) {
                        lose_data.forEach(item => {
                            if (item.subname == this.hh[i].betclass[j].subname) {
                                this.hh[i].betclass[j].static_bonus = item.rate;
                                this.hh[i].betclass[j].straightbet_high = item.other.straightbet_high;
                                this.hh[i].betclass[j].straightbet_low = item.other.straightbet_low;
                            }
                        });
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                //红黑
                for (let i = 0; i < this.hh.length; i++) {
                    for (let j = 0; j < this.hh[i].betclass.length; j++) {
                        this.hh[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                //红黑
                for (let i = 0; i < this.hh.length; i++) {
                    for (let j = 0; j < this.hh[i].betclass.length; j++) {
                        if (this.hh[i].betclass[j].val && !isNaN(this.hh[i].betclass[j].val * 1)) {
                            list.push(this.hh[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    //红黑
                    for (let j = 0; j < this.hh.length; j++) {
                        for (let k = 0; k < this.hh[j].betclass.length; k++) {
                            if (this.hh[j].betclass[k].title == list[i].title && this.hh[j].betclass[k].name == list[i].name) {
                                this.hh[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>