<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-07-08 16:39:01
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-07-08 19:25:50
 * @FilePath: \pc\src\components\menuitems\ruls.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="ruleText">
        <el-select size="small" placeholder="请选择彩种" v-model="lottery.default">
            <el-option v-for="item in lottery.list" :key="item.id" :label="item.gname" :value="item.gname"></el-option>
        </el-select>
        <div class="content" style="margin-top:10px">
            <h3 class="title">重要聲明:</h3>
            <ol>
                <li>如果客護懷疑自己的資料被盜用，應立即通知本公司，並更改詳細資料，以前的使用者名稱及密碼將全部無效。</li>
                <li>客護有責任確保自己的帳護及登入資料的保密性。以使用者名稱及密碼進行的任何網上投註將被視為有效。</li>
                <li>公佈賠率時出現的任何打字錯誤或非故意人為失誤，本公司保留改正錯誤和按正確賠率結算投註的權力。您居住所在地的法律有可能規定網路博奕不合法；若此情況屬實，本公司將不會批準您使用付帳卡進行交易</li>
                <li>每次登入時客護都應該核對自己的帳護結餘額。如對餘額有任何疑問，請在第一時間內通知本公司。</li>
                <li>一旦投註被接受，則不得取消或修改。</li>
                <li>所有號碼賠率將不時浮動，派彩時的賠率將以確認投註時之賠率為準。</li>
                <li>每註最高投註金額按不同[場次]及[投註項目]及[會員帳號]設定浮動。如投註金額超過上述設定，本公司有權取消超過之投註金額。</li>
                <li>所有投註都必須在開獎前時間內進行否則投註無效。</li>
                <li>所有投註派彩彩金皆含本金。</li>
            </ol>
        </div>
        <!-- 重庆时时彩 -->
        <div v-if="lottery.default==='重庆时时彩'||
            lottery.default==='新疆时时彩'||
            lottery.default==='极速分分彩'||
            lottery.default==='幸运时时彩'||
            lottery.default==='AG时时彩'||
            lottery.default==='加州快5'||
            lottery.default==='极速时时彩'||
            lottery.default==='澳洲幸运5'||
            lottery.default==='排列三、五'
            ">
            <div class="content">
                <h3 class="title">{{lottery.default}}規則說明:</h3>
                <!-- <p>該遊戲的投註時間、開獎時間和開獎號碼與{{lottery.default}}完全同步，北京時間（GMT+8）每天白天從上午07：10開到晚上23：50，夜場從00:30至淩晨03：10點,每20分鐘開一次獎，夜場每20分鐘開一次獎,每天開獎59期(白天50期,夜間9期)。</p> -->
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 第一球~第五球</h4>
                <ul>
                    <li><b>第一球特~第五球特</b>：第一球特、第二球特、第三球特、第四球特、第五球特：指下註的每一球特與開出之號碼其開獎順序及開獎號碼相同，視為中獎，如第一球開出號碼8，下註第一球為8者視為中獎，其餘情形視為不中獎。</li>
                    <li><b>單雙大小</b>：根據相應單項投註第一球特 ~ 第五球特開出的球號，判斷勝負。<br><b>單雙</b>：根據相應單項投註的第一球特 ~ 第五球特開出的球號為雙數叫特雙，如2、6；特碼為單數叫特單，如1、3 <br><b>大小</b>：根據相應單項投註的第一球特 ~ 第五球特開出的球號大於或等於5為特碼大，小於或等於4為特碼小。</li>
                    <li><b>總和單雙大小</b>：<br><b>單雙</b>：根據相應單項投註的第一球特 ~ 第五球特開出的球號數字總和值是雙數為總和雙，數字總和值是單數為總和單。<br><b>大小</b>：根據相應單項投註的第一球特 ~ 第五球特開出的球號數字總和值是23-45為總和大，數字總和值是0-22為總和小。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 前三特殊玩法： 豹子 > 順子 > 對子 > 半順 > 雜六</h4>
                <ul>
                    <li><b>豹子</b>：中獎號碼的個位十位百位數字都相同。----如中獎號碼為000、111、999等，中獎號碼的個位十位百位數字相同，則投註豹子者視為中獎，其它視為不中獎。</li>
                    <li><b>順子</b>：中獎號碼的個位十位百位數字都相連，不分順序。（數字9、0、1相連）----如中獎號碼為123、901、321、546等，中獎號碼個位十位百位數字相連，則投註順子者視為中獎，其它視為不中獎。</li>
                    <li><b>對子</b>：中獎號碼的個位十位百位任意兩位數字相同。（不包括豹子）----如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。如果開獎號碼為豹子,則對子視為不中獎。<br>如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。</li>
                    <li><b>半順</b>：中獎號碼的個位十位百位任意兩位數字相連，不分順序。（不包括順子、對子。）----如中獎號碼為125、540、390、706，中獎號碼有兩位數字相連，則投註半順者視為中獎，其它視為不中獎。如果開獎號碼為順子、對子,則半順視為不中獎。--如中獎號碼為123、901、556、233，視為不中獎。</li>
                    <li><b>雜六</b>：不包括豹子、對子、順子、半順的所有中獎號碼。----如中獎號碼為157，中獎號碼位數之間無關聯性，則投註雜六者視為中獎，其它視為不中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 中三特殊玩法： 豹子 > 順子 > 對子 > 半順 > 雜六</h4>
                <ul>
                    <li><b>豹子</b>：中獎號碼的十位百位千位數字都相同。----如中獎號碼為000、111、999等，中獎號碼的十位百位千位數字相同，則投註豹子者視為中獎，其它視為不中獎。</li>
                    <li><b>順子</b>：中獎號碼的十位百位千位數字都相連，不分順序。（數字9、0、1相連）----如中獎號碼為123、901、321、546等，中獎號碼十位百位千位數字相連，則投註順子者視為中獎，其它視為不中獎。</li>
                    <li><b>對子</b>：中獎號碼的十位百位千位任意兩位數字相同。（不包括豹子）----如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎如果開獎號碼為豹子,則對子視為不中獎。<br>如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。</li>
                    <li><b>半順</b>：中獎號碼的十位百位千位任意兩位數字相連，不分順序。（不包括順子、對子，數字9、0、1相連）----如中獎號碼為125、540、390、706，中獎號碼有兩位數字相連，則投註半順者視為中獎，其它視為不中獎。如果開獎號碼為順子、對子,則半順視為不中獎。--如中獎號碼為123、901、556、233，視為不中獎。</li>
                    <li><b>雜六</b>：不包括豹子、對子、順子、半順的所有中獎號碼。----如中獎十位百位千位號碼為157，中獎號碼位數之間無關聯性，則投註雜六者視為中獎，其它視為不中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 後三特殊玩法： 豹子 > 順子 > 對子 > 半順 > 雜六</h4>
                <ul>
                    <li><b>豹子</b>：中獎號碼的百位千位萬位數字都相同。----如中獎號碼為000、111、999等，中獎號碼的百位千位萬位數字相同，則投註豹子者視為中獎，其它視為不中獎。</li>
                    <li><b>順子</b>：中獎號碼的百位千位萬位數字都相連，不分順序。（數字9、0、1相連）----如中獎號碼為123、901、321、546等，中獎號碼百位千位萬位數字相連，則投註順子者視為中獎，其它視為不中獎。</li>
                    <li><b>對子</b>：中獎號碼的百位千位萬位任意兩位數字相同。（不包括豹子）----如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。如果開獎號碼為豹子,則對子視為不中獎。<br>如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。</li>
                    <li><b>半順</b>：中獎號碼的百位千位萬位任意兩位數字相連，不分順序。（不包括順子、對子，數字9、0、1相連）----如中獎號碼為125、540、390、706，中獎號碼有兩位數字相連，則投註半順者視為中獎，其它視為不中獎。如果開獎號碼為順子、對子,則半順視為不中獎。--如中獎號碼為123、901、556、233，視為不中獎。</li>
                    <li><b>雜六</b>：不包括豹子、對子、順子、半順的所有中獎號碼。----如中獎百位千位萬位號碼為157，中獎號碼位數之間無關聯性，則投註雜六者視為中獎，其它視為不中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 龍虎和特殊玩法：龍 > 虎 > 和 （0為最小，9為最大)</h4>
                <ul>
                    <li><b>龍</b>：开奖第一球【万】的号码大于第五球【个】的号码，视为中奖，其余情形即视为不中奖。如：投注龙虎：［龙］，开奖号码为：6***2、8***6、5***1…，皆视为中奖。 虎：开奖第一球【万】的号码小于第五球【个】的号码，视为中奖，其余情形即视为不中奖。</li>
                    <li><b>和</b>：开奖第一球【万】的号码等于第五球【个】的号码，视为中奖，投注龙或虎，皆视为不中奖。如：投注龙虎：［和］，开奖号码为：2***2、6***6、8***8…，皆视为中奖。 ※若龙虎玩法开出结果为和，不论投注龙或虎，皆视为不中奖。</li>
                    <li><b>虎</b>：开奖第一球【万】的号码小于第五球【个】的号码，视为中奖，其余情形即视为不中奖。如：投注龙虎：［虎］，开奖号码为：2***6、6***8、1***5…，皆视为中奖。 和：开奖第一球【万】的号码等于第五球【个】的号码，视为中奖，其他情形即为不中奖。</li>
                </ul>
            </div>
            <template v-if="lottery.default==='排列三、五'">
                <div class="content">
                    <h4 class="title_sm  theme-text">▶ 和值</h4>
                    <ul>
                        <li><b>前三</b>：前三位(万位、千位、百位)之和与所投注和值相同即中奖，如：投注前三和值2，开奖号码为002**、020**、200**、110**、101**、011**，即中奖。</li>
                        <li><b>中三</b>：中三位(千位、百位、十位)之和与所投注和值相同即中奖，如：投注中三和值2，开奖号码为*002*、*020*、*200*、*110*、*101*、*011*，即中奖。</li>
                        <li><b>后三</b>：后三位(百位、十位、个位)之和与所投注和值相同即中奖，如：投注后三和值2，开奖号码为**002、**020、**200、**110、**101、**011，即中奖。</li>
                    </ul>
                </div>
                <div class="content">
                    <h4 class="title_sm  theme-text">▶ 直选</h4>
                    <ul>
                        <li><b>前三 直选</b>：任意选择三个数，开奖号码前三码(万位、千位、百位)相同且顺序一致即为中奖。投注三码(例：3、6、8)，开奖号码368**，与开奖号码前三码相同且顺序一致，即中奖。</li>
                        <li><b>中三 直选</b>：任意选择三个数，开奖号码中三码(千位、百位、十位)相同且顺序一致即为中奖。投注三码(例：3、6、8)，开奖号码*368*，与开奖号码中三码相同且顺序一致，即中奖。</li>
                        <li><b>后三 直选</b>：任意选择三个数，开奖号码后三码(百位、十位、个位)相同且顺序一致即为中奖。投注三码(例：3、6、8)，开奖号码**368，与开奖号码后三码相同且顺序一致，即中奖。</li>
                    </ul>
                </div>
                <div class="content">
                    <h4 class="title_sm  theme-text">▶ 组三</h4>
                    <ul>
                        <li><b>前三 组三</b>：所选号码，万位、千位、百位，顺序不限，且开奖号码中的 前三码 有对子并且对应有任意两位，即为中奖。投注两码(例：3、6)，开奖号码336**、633**、663**、366**等，即中奖。</li>
                        <li><b>中三 组三</b>：所选号码，千位、百位、十位，顺序不限，且开奖号码中的 中三码 有对子并且对应有任意两位，即为中奖。投注两码(例：3、6)，开奖号码*336*、*633*、*663*、*366*等，即中奖。</li>
                        <li><b>后三 组三</b>：所选号码，百位、十位、个位，顺序不限，且开奖号码中的 后三码 有对子并且对应有任意两位，即为中奖。投注两码(例：3、6)，开奖号码**336、**633、**663、**366等，即中奖。</li>
                    </ul>
                </div>
                <div class="content">
                    <h4 class="title_sm  theme-text">▶ 组六</h4>
                    <ul>
                        <li><b>前三 组六</b>：所选号码，万位、千位、百位，顺序不限，且开奖号码中的 前三码 各不相同，即为中奖。投注三码(例：3、6、8)，开奖号码368**、386**、683**、638**、863**、836**，与开奖号码前三码相同，即中奖。</li>
                        <li><b>中三 组六</b>：所选号码，千位、百位、十位，顺序不限，且开奖号码中的 中三码 各不相同，即为中奖。投注三码(例：3、6、8)，开奖号码*368*、*386*、*683*、*638*、*863*、*836*，与开奖号码中三码相同，即中奖。</li>
                        <li><b>后三 组六</b>：所选号码，百位、十位、个位，顺序不限，且开奖号码中的 后三码 各不相同，即为中奖。投注三码(例：3、6、8)，开奖号码**368、**386、**683、**638、**863、**836，与开奖号码后三码相同，即中奖。</li>
                    </ul>
                </div>
                <div class="content">
                    <h4 class="title_sm  theme-text">▶ 一码不定位</h4>
                    <ul>
                        <li><b>前三 一码不定位</b>：投注1个号码,在万位、千位、百位的位置上3个开奖号码包含所选号码，不限位置,即为中奖。投注一码(例：6)，开奖号码6****、*6***、**6**，与开奖号码前三码中任一码相同，即中奖。</li>
                        <li><b>中三 一码不定位</b>：投注1个号码,在千位、百位、十位的位置上3个开奖号码包含所选号码，不限位置,即为中奖。投注一码(例：6)，开奖号码*6***、**6**、***6*，与开奖号码中三码中任一码相同，即中奖。</li>
                        <li><b>后三 一码不定位</b>：投注1个号码,在百位、十位、个位的位置上3个开奖号码包含所选号码，不限位置,即为中奖。投注一码(例：6)，开奖号码**6**、***6*、****6，与开奖号码后三码中任一码相同，即中奖。</li>
                    </ul>
                </div>
                <div class="content">
                    <h4 class="title_sm  theme-text">▶ 二码不定位</h4>
                    <ul>
                        <li><b>前三 二码不定位</b>：投注2个号码,在万位、千位、百位的位置上3个开奖号码包含2个所选号码，不限位置,即为中奖。投注两码(例：4、7)，开奖号码*47**、47***、4*7**，与开奖号码中前三码任两码相同，即中奖。</li>
                        <li><b>中三 二码不定位</b>：投注2个号码,在千位、百位、十位的位置上3个开奖号码包含2个所选号码，不限位置,即为中奖。投注两码(例：4、7)，开奖号码**47*、*47**、*4*7*，与开奖号码中中三码任两码相同，即中奖。</li>
                        <li><b>后三 二码不定位</b>：投注2个号码,在百位、十位、个位的位置上3个开奖号码包含2个所选号码，不限位置,即为中奖。投注两码(例：4、7)，开奖号码***47、**47*、**4*7，与开奖号码中后三码任两码相同，即中奖。</li>
                    </ul>
                </div>
            </template>
        </div>
        <!-- 福彩3D -->
        <div v-if="lottery.default==='福彩3D'">
            <div class="content">
                <h3 class="title">{{lottery.default}}規則說明:</h3>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 第一球~第三球</h4>
                <ul>
                    <li><b>第一球特~第三球特</b>：第一球特、第二球特、第三球特：指下註的每一球特與開出之號碼其開獎順序及開獎號碼相同，視為中獎，如第一球開出號碼8，下註第一球為8者視為中獎，其餘情形視為不中獎。</li>
                    <li><b>單雙大小</b>：根據相應單項投註第一球特 ~ 第三球特開出的球號，判斷勝負。<br><b>單雙</b>：根據相應單項投註的第一球特 ~ 第三球特開出的球號為雙數叫特雙，如2、6；特碼為單數叫特單，如1、3 <br><b>大小</b>：根據相應單項投註的第一球特 ~ 第三球特開出的球號大於或等於5為特碼大，小於或等於4為特碼小。</li>
                    <li><b>總和單雙大小</b>：<br><b>單雙</b>：根據相應單項投註的第一球特 ~ 第三球特開出的球號數字總和值是雙數為總和雙，數字總和值是單數為總和單。<br><b>大小</b>：根據相應單項投註的第一球特 ~ 第三球特開出的球號數字總和值是14~27為總和大，數字總和值是0~13為總和小。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 前三特殊玩法： 豹子 > 順子 > 對子 > 半順 > 雜六</h4>
                <ul>
                    <li><b>豹子</b>：中獎號碼的個位十位百位數字都相同。----如中獎號碼為000、111、999等，中獎號碼的個位十位百位數字相同，則投註豹子者視為中獎，其它視為不中獎。</li>
                    <li><b>順子</b>：中獎號碼的個位十位百位數字都相連，不分順序。（數字9、0、1相連）----如中獎號碼為123、901、321、546等，中獎號碼個位十位百位數字相連，則投註順子者視為中獎，其它視為不中獎。</li>
                    <li><b>對子</b>：中獎號碼的個位十位百位任意兩位數字相同。（不包括豹子）----如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。如果開獎號碼為豹子,則對子視為不中獎。<br>如中獎號碼為001，112、696，中獎號碼有兩位數字相同，則投註對子者視為中獎，其它視為不中獎。</li>
                    <li><b>半順</b>：中獎號碼的個位十位百位任意兩位數字相連，不分順序。（不包括順子、對子。）----如中獎號碼為125、540、390、706，中獎號碼有兩位數字相連，則投註半順者視為中獎，其它視為不中獎。如果開獎號碼為順子、對子,則半順視為不中獎。--如中獎號碼為123、901、556、233，視為不中獎。</li>
                    <li><b>雜六</b>：不包括豹子、對子、順子、半順的所有中獎號碼。----如中獎號碼為157，中獎號碼位數之間無關聯性，則投註雜六者視為中獎，其它視為不中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 龍虎和特殊玩法：龍 > 虎 > 和 （0為最小，9為最大)</h4>
                <ul>
                    <li><b>龍</b>：开奖第一球【百】的号码大于第三球【个】的号码，视为中奖，其余情形即视为不中奖。如：投注龙虎：［龙］，开奖号码为：6*2、8*6、5*1…，皆视为中奖。 虎：开奖第一球【百】的号码小于第三球【个】的号码，视为中奖，其余情形即视为不中奖。</li>
                    <li><b>和</b>：开奖第一球【百】的号码等于第三球【个】的号码，视为中奖，投注龙或虎，皆视为不中奖。如：投注龙虎：［和］，开奖号码为：2*2、6*6、8*8…，皆视为中奖。 ※若龙虎玩法开出结果为和，不论投注龙或虎，皆视为不中奖。</li>
                    <li><b>虎</b>开奖第一球【百】的号码小于第三球【个】的号码，视为中奖，其余情形即视为不中奖。如：投注龙虎：［虎］，开奖号码为：2*6、6*8、1*5…，皆视为中奖。 和：开奖第一球【百】的号码等于第三球【个】的号码，视为中奖，其他情形即为不中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 和值</h4>
                <ul>
                    <li>三位之和与所投注和值相同即中奖，如：投注和值2，开奖号码为002、020、200、110、101、011，即中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 直选</h4>
                <ul>
                    <li>任意选择三个数，开奖号码三码相同且顺序一致即为中奖。投注三码(例：3、6、8)，开奖号码368，与开奖号码三码相同且顺序一致，即中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 组选</h4>
                <ul>
                    <li><b>组三</b>：所选号码，百位、十位和个位，顺序不限，且开奖号码中有对子并且对应的三位号码有任意两位，即为中奖。投注两码(例：3、6)，开奖号码336、633、663、366等，即中奖。</li>
                    <li><b>组六</b>：所选号码，百位、十位和个位，顺序不限，且开奖号码中对应的三位号码各不相同，即为中奖。投注三码(例：3、6、8)，开奖号码368、386、683、638、863、836，与开奖号码后三码相同，即中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 不定位</h4>
                <ul>
                    <li><b>一码不定位</b>：投注1个号码,在百位，十位和个位的位置上3个开奖号码包含所选号码，不限位置,即为中奖。投注一码(例：6)，开奖号码6**、*6*、**6，与开奖号码三码中任一码相同，即中奖。</li>
                    <li><b>二码不定位</b>：投注2个号码,在百位，十位和个位的位置上3个开奖号码包含2个所选号码，不限位置,即为中奖。投注两码(例：4、7)，开奖号码*47、47*、4*7，与开奖号码中任两码相同，即中奖。</li>
                </ul>
            </div>
        </div>
        <!-- 北京PK10 -->
        <div v-if="lottery.default==='北京PK10'||
            lottery.default==='加州F1赛车'||
            lottery.default==='AG时速'||
            lottery.default==='幸运赛车'||
            lottery.default==='极速赛车'||
            lottery.default==='幸运飞艇'||
            lottery.default==='澳洲幸运10'||
            lottery.default==='极速飞艇'">
            <div class="content">
                <h3 class="title">{{lottery.default}}規則說明:</h3>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 1～10 兩面：指 單、雙，大、小</h4>
                <ul>
                    <li><b>單、雙</b>：號碼為雙數叫雙，如4、8；號碼為單數叫單，如5、9。</li>
                    <li><b>大、小</b>：開出之號碼大於或等於6為大，小於或等於5為小。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 第一名～第十名 車號指定</h4>
                <ul>
                    <li>每一個車號為一投註組合，開獎結果“投註車號”對應所投名次視為中獎，其余情形視為不中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 1～5龍虎</h4>
                <ul>
                    <li><b>冠軍 龍/虎</b>：“第一名”車號大於“第十名”車號視為【龍】中獎、反之小於視為【虎】中獎，其余情形視為不中獎。</li>
                    <li><b>亞軍 龍/虎</b>：“第二名”車號大於“第九名”車號視為【龍】中獎、反之小於視為【虎】中獎，其余情形視為不中獎。</li>
                    <li><b>第三名 龍/虎</b>：“第三名”車號大於“第八名”車號視為【龍】中獎、反之小於視為【虎】中獎，其余情形視為不中獎。</li>
                    <li><b>第四名 龍/虎</b>：“第四名”車號大於“第七名”車號視為【龍】中獎、反之小於視為【虎】中獎，其余情形視為不中獎。</li>
                    <li><b>第五名 龍/虎</b>：“第五名”車號大於“第六名”車號視為【龍】中獎、反之小於視為【虎】中獎，其余情形視為不中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 冠軍車號＋亞軍車號＝冠亞和值</h4>
                <ul>
                    <li><b>冠亞和單雙</b>：“冠亞和值”為單視為投註“單”的註單視為中獎，為雙視為投註“雙”的註單視為中獎，其余視為不中獎。</li>
                    <li><b>冠亞和大小</b>：“冠亞和值”大於11時投註“大”的註單視為中獎，小於或等於11時投註“小”的註單視為中獎，其余視為不中獎。</li>
                    <li><b>冠亞和指定</b>：“冠亞和值”可能出現的結果為3～19， 投中對應“冠亞和值”數字的視為中獎，其余視為不中獎。</li>
                </ul>
            </div>
        </div>
        <!-- 六合彩，极速六合彩 -->
        <div v-if="lottery.default==='六合彩'||lottery.default==='澳门六合彩'||lottery.default==='极速六合彩'">
            <div class="content">
                <h3 v-if="lottery.default=='六合彩'" class="title">六合彩規則說明:</h3>
                <h3 v-if="lottery.default=='澳门六合彩'" class="title">澳门六合彩規則說明:</h3>
                <h3 v-if="lottery.default=='极速六合彩'" class="title">极速六合彩規則說明:</h3>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 特码</h4>
                <ul>
                    <li v-if="lottery.default=='六合彩'">六合彩公司当期开出的最后一码为特码，假如投注号码为开出之特码，视为中奖，其馀情形视为不中奖</li>
                    <li v-if="lottery.default=='澳门六合彩'">澳门六合彩公司当期开出的最后一码为特码，假如投注号码为开出之特码，视为中奖，其馀情形视为不中奖</li>
                    <li><b>特码色波</b><br>
                        49 个号码球分别有红 、 蓝 、 绿三种颜色，以特码开出的颜色和投注的颜色相同视为中奖，颜色代表如下：<br>
                        红波 ： 01、02、07、08、12、13、18、19、23、24、29、30、34、35、40、45、46。<br>
                        蓝波 ： 03、04、09、10、14、15、20、25、26、31、36、37、41、42、47、48。<br>
                        绿波 ： 05、06、11、16、17、21、22、27、28、32、33、38, 39、43、44、49。
                    </li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 色波</h4>
                <ul>
                    <li>以特码的球色下注，开奖的球色与下注的颜色相同，视为中奖</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 特肖</h4>
                <ul>
                    <li>以特码的生肖下注，若当期特码落在下注生肖范围内，则视为中奖</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 正码</h4>
                <ul>
                    <li>当期开出前6个号码叫正码。每一个号码为一投注组合，假如投注号码为开奖号码之正码，视为中奖，其馀情形视为不中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 正码1-6</h4>
                <ul>
                    <li>正码1、正码2、正码3、正码4、正码5、正码6的大小单双、合数单双、合数大小、尾数大小、波色和特码的规则相同，如正码1为25，则正码1为大，为单，为蓝波；正码2为2，则正码2为小，为双，为红波；号码为49时，则大小单双、合数单双、合数大小、尾数大小为和，波色为绿波。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 正特</h4>
                <ul>
                    <li>正特一、正特二、正特三、正特四、正特五、正特六∶指下注的正码特与开出之正码其开奖顺序及开奖号码相同，视为中奖，如正码一开出号码8，下注正特一为8者视为中奖，其馀情形视为不中奖。。每一个号码为一投注组合，假如投注号码为开奖号码之正码，视为中奖，其馀情形视为不中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 连码</h4>
                <ul>
                    <li><b>三中二</b>：所投注的每三个号码为一组合，若其中2个是开奖号码中的正码，即为三中 二，视为中奖；若3个都是开奖号码中的正码，即为三中二之中三，其馀情形视为不中奖，如06、07、08为一组合，开奖号码中有06、07两个正码，没有 08，即为三中二，按三中二赔付；如开奖 号码中有06、07、08三个正码，即为三中二之中三，按中三赔付；如出现1个或没有，视为不中奖。</li>
                    <li><b>三全中</b>：所投注的每三个号码为一组合，若三个号码都是开奖号码之正码，视为中奖，其馀情形视为不中奖。如06、07、08三个都是开奖号码之正码，视为中奖，如两个正码加上一个特码 码视为不中奖。</li>
                    <li><b>二全中</b>：所投注的每二个号码为一组合，二个号码都是开奖号码之正码，视为中奖，其馀情形（含一个正码加一个特码）视为不中奖。</li>
                    <li><b>二中特</b>：所投注的每二个号码为一组合，二个号码都是开奖号码之正码，叫二中特之中二；若其中一个是正码，一个是特码，叫二中特之中特；其馀情形视为不中奖。</li>
                    <li><b>特串</b>：所投注的每二个号码为一组合，其中一个是正码，一个是特码，视为中奖，其馀情形（含二个号码都是正码）视为不中奖。</li>
                    <li><b>四中一</b>：在中奖的情况下，一组中有多个开奖的号码，派彩都跟中一个号码一洋！●选选择投注号码每四个号码为一组〈号码需四个号以上，少于四个号码不成立〉，每注的四个号码中如果有当期开奖的所有7个号码中的一个或多个号码，视为中奖，其他情形视为不中奖。 例：例如：1、2、3、4是一注，开奖的号码是5、6、7、8、9、10、11，那就是不中奖！再例如2、3、4、5是一注，开奖的号码是4、5、6、7、 8、9、10，那就是中奖！</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 一肖</h4>
                <ul>
                    <li>指开奖正码及特码７个号码当中含有所下注生肖内的一个或多个号码则视为中奖，但派彩只派一次，即不论同生肖号码出现一个或多个号码都只派彩一次。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 一肖不中</h4>
                <ul>
                    <li>指开出的７个开奖号码中不含有所下注生肖，即视为中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 自选不中</h4>
                <ul>
                    <li><b>五不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>六不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>七不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,13,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>八不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,13,14,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>九不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,13,14,15,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>十不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,13,14,15,16,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>十一不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,13,14,15,16,17,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                    <li><b>十二不中</b>：所投注的每个号码为一个组合,其中包含7个号码如会员下注号码为07,08,09,10,11,12,13,14,15,16,17,18,为一个组合.开奖结果为01,02.03.04.05.06.49,没出现组合选择中的号码,视为中奖(请注意49亦算输赢，不为和)</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 连肖</h4>
                <ul>
                    <li>依选择的连肖类别挑选 2 ～ 5 个生肖（排列如同生肖）为一个组合，当期的开奖号码 （六个正码及特码）座落于所勾选之生肖组合所属号码内，且所勾选之生肖皆至少有中一个号码，则视为中奖；其馀情形一律视为不中奖；若选择的生肖组合内有包含本年生肖则称之为正肖，没有包含本年生肖则称之为平肖，正肖与平肖的中奖赔率不同(请注意49亦算输赢，不为和)。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 两面</h4>
                <ul>
                    <li><b>两面</b>：指单、双；大、小；大单、大双、小单、小双；特尾大、特尾小；合单、合双；合尾大、合尾小；家禽、野兽。</li>
                    <li><b>特码单、双</b>：特码为双数叫特双，如8、16；特码为单数叫特单，如21、35；49则均为和，打和不计算输赢。</li>
                    <li><b>特码大、小</b>：开出之特码大于或等于25为特码大，小于或等于24为特码小；49则均为和，打和不计算输赢。</li>
                    <li><b>特码合单双</b>：以特码个位和十位数字之和来判断胜负，如01、12、29、32、47为合单；02、11、33、48为合双；49则均为和，打和不计算输赢。</li>
                    <li><b>特码合大小</b>：以特码个位和十位数字之和来判断胜负，介于1-6为合小；介于7-12为合大；13则为和。如01、24、33为合小；07、34、48为合大；49则为和，打和不计算输赢。</li>
                    <li><b>总和单双</b>：所有七个开奖号码的分数总和是单数叫（总分单），如分数总和是115、183；分数总和是双数叫(总分双)，如分数总和是108、162。假如投注组合符合中奖结果，视为中奖，其馀情形视为不中奖。</li>
                    <li><b>总和大小</b>：所有七个开奖号码的分数总和大于或等于175为总分大；分数总和小于或等于174为总分小。如开奖号码为02、08、17、28、39、46、25，分数总和是165，则总分小。假如投注组合符合 中奖结果，视为中奖，其馀情形视为不中奖。</li>
                    <li><b>特尾大、小</b>：开出之特码取出尾数大于或等于5为特尾大，小于或等于4为特尾小；49则均为和，打和不计算输赢。</li>
                    <li><b>合尾大、小</b>：以特码个位和十位数字之和取出尾数来判断胜负，尾数大于或等于5为合尾大，如14、24、42；尾数小于或等于4为合尾小，如19、37、49；若特码开出25则为和局，打和不计算输赢。</li>
                    <li><b>家禽、野兽</b>：开出的特码属于十二生肖中的牛、马、羊、鸡、狗、猪号码为家禽，开出的特码属于十二生肖中的鼠、虎、龙、蛇、兔、猴号码为野兽；开出49则视为和局，打和不计算输赢。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 尾数</h4>
                <ul>
                    <li>指开奖 7 个号码中含有所属尾数的一个或多个号码，但派彩只派一次，即不论同尾数号码出现一个或多个号码都只派彩一次。</li>
                    <li><b>"1"尾</b>：01、11、21、31、41</li>
                    <li><b>"2"尾</b>：02、12、22、32、42</li>
                    <li><b>"3"尾</b>：03、13、23、33、43</li>
                    <li><b>"4"尾</b>：04、14、24、34、44</li>
                    <li><b>"5"尾</b>：05、15、25、35、45</li>
                    <li><b>"6"尾</b>：06、16、26、36、46</li>
                    <li><b>"7"尾</b>：07、17、27、37、47</li>
                    <li><b>"8"尾</b>：08、18、28、38、48</li>
                    <li><b>"9"尾</b>：09、19、29、39、49</li>
                    <li><b>"0"尾</b>：10、20、30、40</li>
                    <li>例如：开奖结果正码是23、33、32、24、35、32特别号码是41 则"2"尾"3"尾"4"尾"5"尾都为中奖，其他尾数都不中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 尾数连</h4>
                <ul>
                    <li>注：在中奖的情况下，一个尾数有多个开奖的号码，派彩都跟中一个号码一洋！</li>
                    <li><b>"五尾连</b>：以每五个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其 中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2、尾3、尾4、尾5，是一注，开奖的号码是：2、3、4、5、6、7、8，那就是不中奖；再例如 ：尾1、尾2、尾3、尾4、尾5，是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖。</li>
                    <li><b>四尾连</b>：以每四个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其 中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2、尾3、尾4，是一注，开奖的号码是：2、3、4、5、6、7、8，那就是不中奖；再例如 ：尾1、尾2、尾3、尾4，是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖</li>
                    <li><b>三尾连</b>：以每三个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为 中奖。如果其中一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2、尾3，是一注，开奖的号码是：2、3、4、5、6、7、8，那就是不中奖； 再例如：尾1、尾2、尾3，是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖。</li>
                    <li><b>二尾连</b>：以每二个尾数为一注，每注的每个尾数中如果都有当期开奖的所有7个号码中对应的尾数，则视为中奖。如果其中 一个或多个尾数没有当期开奖的所有7个号码对应的尾数，则视为不中奖。例如：尾1、尾2，是一注，开奖的号码是：2、3、4、5、6、7、8，那就是不中奖；再例如：尾1、尾2， 是一注，开奖的号码是：1、2、3、4、5、6、7，那就是中奖。</li>
                </ul>
            </div>
        </div>
        <!-- pc蛋蛋，幸运28，加拿大28-->
        <div v-if="lottery.default==='幸运28'||lottery.default==='加拿大28'">
            <div class="content">
                <h3 class="title">PC蛋蛋規則說明:</h3>
                <ul>
                    <li>日場盤（09:00~23:55）：投註時間、開獎時間和開獎號碼與“北京快樂8”完全同步，北京時間（GMT+8）每天白天從上午09:00開到晚上23:55，每5分鐘開一次獎、每天開獎179期。</li>
                    <li>
                        日場北京快樂8每期開獎共開出20個數字，PC蛋蛋將這20個開獎號碼按照由小到大的順序依次排列；取其1-6位開獎號碼相加， 和值的末位數作為PC蛋蛋開獎第壹區數值； 取其7-12位開獎號碼相加，和值的末位數作為PC蛋蛋開獎第二區數值，取其13-18位開獎號碼相加， 和值的末位數作為PC蛋蛋開獎第三區數值；三個區數值相加即為PC蛋蛋最終的開獎結果。
                    </li>
                </ul>
                <!-- <img src="../../../../public/img/other/pcdd_explain.png" alt="pcdd_explain" style="width:100%;"> -->
            </div>
            <div class="content">
                <h3 v-if="lottery.default=='幸运28'" class="title">加拿大28具體遊戲規則:</h3>
                <h3 v-if="lottery.default=='加拿大28'" class="title">幸运28具體遊戲規則:</h3>
                <span>以下所有投註皆含本金。</span>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 特碼</h4>
                <ul>
                    <li>0~27投註號碼，投註的1個號碼與當期開出的28個號碼中的任1個號碼相同，則中獎。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 大小</h4>
                <ul>
                    <li>0~13為小；14~27為大。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 單雙</h4>
                <ul>
                    <li>開出的號碼為雙數（0,2,4,6,8,10,12,14,16,18,20,22,24,26)為雙。</li>
                    <li>開出的號碼為單數（1,3,5,7,9,11,13,15,17,19,21,23,25,27)為單。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 大單雙，小單雙：</h4>
                <ul>
                    <li>大單雙：開出的特碼為雙數並且大於14為大雙，如：16、24；特碼為單数並且大於14為大單，如：15、27；<b style="color:red">13、14則均為和。</b></li>
                    <li>小單雙：開出的特碼為雙數並且小於13為小雙，如：8、10；特碼為單数並且小於13為小單，如：1、11；<b style="color:red">13、14則均為和。</b></li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 極值</h4>
                <ul>
                    <li>開出的號碼小於或等於4，即開出號碼為0~4,則為極小。</li>
                    <li>開出的號碼大於或等於23，即開出號碼為23~27,則為極大。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 波色</h4>
                <ul>
                    <li><b>綠波</b>：開出的號碼為1,4,7,10,16,19,22,25。</li>
                    <li><b>藍波</b>：開出的號碼為2,5,8,11,17,20,23,26。</li>
                    <li><b>紅波</b>：開出的號碼為3,6,9,12,15,18,21,24。</li>
                    <li><b>注意：若特码为00、13、14或27，所购买的波色则均为打和，退还本金。</b></li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 豹子</h4>
                <ul>
                    <li>（北京快樂8）開獎的3個和值未位數相同，則為豹子。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 壹、二、三區大小單雙</h4>
                <span>（北京快樂8）開獎的1~6開獎號碼和值未位為第壹區號碼，7~12和值未位為第二區號碼，13~18和值未位為第三區號碼;</span><br>
                <span>（加拿大快樂8）開獎的2、5、8、11、14、17開獎號碼和值未位為第壹區號碼，3、6、9、12、15、18和值未位為第二區號碼，4、7、10、13、16、19和值未位為第三區號碼;每個號碼在0~9。</span>
                <ul>
                    <li><b>大</b>：每個區的號碼大於等於5（即5~9);</li>
                    <li><b>小</b>：每個區的號碼小於等於4（即0~4);</li>
                    <li><b>單</b>：每個區的號碼為單數;</li>
                    <li><b>雙</b>：每個區的號碼為雙數</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 特碼包三</h4>
                <ul>
                    <li>壹次下註三個號碼，中任何壹個即為中獎。</li>
                </ul>
            </div>
        </div>
        <!-- 快3-->
        <div v-if="lottery.default==='江苏快三'||lottery.default==='北京快三'||lottery.default==='广西快三'||lottery.default==='韩国快三'||lottery.default==='极速快三'||lottery.default==='吉林快三'||lottery.default==='首尔快三'">
            <div class="content">
                <h3 class="title">快三規則說明:</h3>
                <div class="content">
                    <span>以下所有投註皆含本金。</span>
                </div>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 总和</h4>
                <ul>
                    <li>和值：开奖结果佰十个三位数的总和值与投注佰十个数字的总和值相同时，即为中奖。</li>
                    <li>和值大、小：3~10为小，11~18为大。</li>
                    <li>和值单、双：3、5、7、9、11、13、15、17为单；4、6、8、10、12、14、16、18为双。</li>
                    <li>和值大单、大双：11，13，15，17为大单；12，14，16，18为大双</li>
                    <li>和值小单、小双：3，5，7，9为小单；4，6，8，10为小双</li>
                    <li>半顺：半顺三个数字只有2个数字相邻，如124、145等即为中奖。「半顺红号不中奖」。</li>
                    <li>杂六：杂六三个数字各不相邻或不含连号，如135、246等即为中奖。</li>
                    <li>跨度：跨度从1-5中选择1个号码视为1注，若奖号中三个号码的最大差值为选号视为中奖。</li>
                    <li>三军：三军根据开奖的三个骰子点数来判断，只要开出一个选定的点数便中奖，且如果开出两个相同的选定点数，奖金翻两倍，三个翻三倍。「若开出豹子则不给予豹子赔率」。</li>
                    <li>全顺(三连号通选)：所有三连号进行投注，开奖号码为任意三连号(123、234、345、456) 即中奖。</li>
                    <li>豹子(三同号通选)：对所有三同号进行投注，开奖号码为任意三同号(111、222、333、444、555、666)即中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 红黑</h4>
                <ul>
                    <li>红号 黑号：红号开奖号码若为二同号即中奖，若开出豹子不中奖；黑号开奖号码若为三不同号即中奖。</li>
                    <li>红号单双：红号单双开奖号码为二同号且和值为单数即红号单；开奖号码为二同号且和值为双即为红号双，若开出豹子则不中奖。</li>
                    <li>红号大小：红号大小开奖号码为二同号且和值为4~10即红号小；开奖号码为二同号且和值为11~17即为红号大，若开出豹子则不中奖。</li>
                    <li>黑号单双：黑号单双开奖号码为三不同号且和值为单数即黑号单；开奖号码为三不同号且和值为双数即黑号双。</li>
                    <li>黑号大小：黑号大小开奖号码为三不同号且和值为4~10即黑号小；开奖号码为三不同号且和值为11~15即黑号大。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 红黑码</h4>
                <ul>
                    <li>四码红四码=押四个号码，开奖号码为二同号，开奖号码中包含押的个号码即为中奖。</li>
                    <li>四码黑，五码黑四码=押四个号码，五码=押五个号码，开奖号码为三不同号，开奖号码中包含押的个号码即为中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 二同号单选</h4>
                <ul>
                    <li>开奖号码中的二同号数字(即对子号码)，和不同号的数字。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 二同号复选</h4>
                <ul>
                    <li>开奖号码中的二同号数字(即对子号码)，非对子不中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 二不同号</h4>
                <ul>
                    <li>选择两个号码进行投注，开奖号码中包含这两个号码即中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 三同号单选</h4>
                <ul>
                    <li>从三同号(111、222、333、444、555、666)中任选一个进行投注，开奖号码为此三同号即中奖。</li>
                </ul>
            </div>
            <div class="content">
                <h4 class="title_sm  theme-text">▶ 三不同号</h4>
                <ul>
                    <li>三不同号是指开奖号码的三个数子都不相同，竟猜这三个数字，猜对即中奖。</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RuleText",
        data() {
            return {
                lottery: {
                    list: this.$store.state.game_list,
                    default: this.$store.state.game_list[0].gname,
                },
            }
        },
    };
</script>

<style scoped>
    .ruleText {
        max-height: 100vh;
        overflow-y: auto;
        padding: 0 9px 5px 3px;
    }

    .content {
        padding: 0px 6px;
        text-align: justify;
    }
    .right-tit,
    .rule-logo {
        float: right;
    }
    .right-tit .tit-cn {
        margin-right: 10px;
        font-size: 36px;
        font-weight: bold;
    }
    .right-tit .tit-en {
        margin-right: 10px;
        font-size: 24px;
        color: #ccc;
    }
    .content .title {
        margin-top: 0;
    }

    .content .title_sm {
        margin-top: 0;
        margin-bottom: 0px;
    }

    .content li {
        margin-top: 3px;
        padding-left: 3px;
        color: #555;
        font-size: 13px;
    }
    .content li b {
        color: #444;
    }
</style>
