<!--
 * @Author: your name
 * @Date: 2021-04-26 15:04:00
 * @LastEditTime: 2022-08-31 01:35:12
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \luck\src\components\betPage\k3\sthtx.vue
-->
<template>
    <div class="sthtx">
        <el-row type="flex" style="background-color:#fff">
            <el-col :span="24">
                <div class="bet-title">
                    <span>{{sthtx[0].ballname}}</span>
                </div>
                <div class="bet-items-one">
                    <el-row>
                        <el-col :span="8" :offset="8" v-for="i in sthtx[0].betclass" :key="i.index" style="padding:10px">
                            <span style="font-weight:bold;margin-right:20px">{{i.name}}</span>
                            <span class="bet-lose">{{i.static_bonus}}</span>
                            <el-input v-model="i.val" size="small" v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sthtx: [
                    {
                        ballname: "三同号通选",
                        betclass: [
                            { title: "三同号通选", name: "三同号通选", visible: false, static_bonus: "", val: '', disabled: false, subname: "1_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "#909399" },
                        ]
                    },
                ],
            }
        },
        methods: {
            /**显示赔率等数据 */
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                for (let i = 0; i < this.sthtx.length; i++) {
                    for (let j = 0; j < this.sthtx[i].betclass.length; j++) {
                        this.sthtx[i].betclass[j].static_bonus = static_bonus[0];
                        this.sthtx[i].betclass[j].straightbet_high = others[0].straightbet_high;
                        this.sthtx[i].betclass[j].straightbet_low = others[0].straightbet_low;
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.sthtx.length; i++) {
                    for (let j = 0; j < this.sthtx[i].betclass.length; j++) {
                        this.sthtx[i].betclass[j].val = '';
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.sthtx[0].betclass.length; i++) {
                    if (this.sthtx[0].betclass[i].val !== '' && !Number.isNaN(this.sthtx[0].betclass[i].val * 1)) {
                        list.push(this.sthtx[0].betclass[i]);
                    }
                }
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.sthtx.length; j++) {
                        for (let k = 0; k < this.sthtx[j].betclass.length; k++) {
                            if (this.sthtx[j].betclass[k].title == list[i].title && this.sthtx[j].betclass[k].name == list[i].name) {
                                this.sthtx[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>