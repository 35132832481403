<template>
    <div class="zhixuan">
        <el-row justify="space-around" :gutter="1">
            <el-col :span="24" style="background-color:#fff;padding:6px 0;">
                <el-radio-group v-model="type" size="" fill="#E6A23C" @change="type_change">
                    <el-radio-button label="前三直选"></el-radio-button>
                    <el-radio-button label="中三直选"></el-radio-button>
                    <el-radio-button label="后三直选"></el-radio-button>
                </el-radio-group>
            </el-col>
            <el-col :span="8" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="bet-items">
                    <el-checkbox-group v-model="item.checked_list" size="small">
                        <el-checkbox-button v-for="i in item.betclass" :key="i.index" :label="i.name"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
            </el-col>
        </el-row>
        <div style="background-color:#fff;padding:6px 0;">
            赔率：<span style="color:#f56c6c;margin-right:10px;">{{this.balls[0].betclass[0].static_bonus}}</span>
            <span>下注金额：</span>
            <el-input class="lma-val" v-model="zhixuan_val" size="small"  v-on:focus="zhixuan_val=check_fast_val(zhixuan_val)"></el-input>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        checked_list: [],
                        ballname: "第一球",
                        betclass: [
                            { title: "第一球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第一球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        checked_list: [],
                        ballname: "第二球",
                        betclass: [
                            { title: "第二球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第二球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                    {
                        checked_list: [],
                        ballname: "第三球",
                        betclass: [
                            { title: "第三球", name: "0", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "1", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "2", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "3", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "4", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "5", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "6", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "7", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "8", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "第三球", name: "9", visible: false, static_bonus: "", val: false, subname: "2_1_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ],
                zhixuan_val: '',
                type: '前三直选',
            }
        },
        methods: {
            type_change(type) {
                this.get_lose();
            },
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                let index = 0;
                let level = '2_1_0';
                if (this.type == '前三直选') {
                    index = 0;
                    level = '2_1_0';
                }
                if (this.type == '中三直选') {
                    index = 1;
                    level = '3_1_0';
                }
                if (this.type == '后三直选') {
                    index = 2;
                    level = '4_1_0';
                }
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].static_bonus = static_bonus[index];
                        this.balls[i].betclass[j].straightbet_high = others[index].straightbet_high;
                        this.balls[i].betclass[j].straightbet_low = others[index].straightbet_low;
                        this.balls[i].betclass[j].subname = level;
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.balls[0].checked_list = [];
                this.balls[1].checked_list = [];
                this.balls[2].checked_list = [];
                this.zhixuan_val = '';
            },
            /**提交，下注 */
            sub_bet() {
                let list = []
                if (this.balls[0].checked_list.length < 1) {
                    return { type: 'error', msg: '请选择第一球' }
                }
                if (this.balls[1].checked_list.length < 1) {
                    return { type: 'error', msg: '请选择第二球' }
                }
                if (this.balls[2].checked_list.length < 1) {
                    return { type: 'error', msg: '请选择第三球' }
                }
                if (this.zhixuan_val == '' || this.zhixuan_val < 0) {
                    return { type: 'error', msg: '请输入下注额' }
                }
                //排序
                for (let i = 0; i < this.balls.length; i++) {
                    this.balls[i].checked_list = this.balls[i].checked_list.sort((a, b) => a - b);
                }
                //进行组合
                let arr = [[]];
                let arr1 = [this.balls[0].checked_list, this.balls[1].checked_list, this.balls[2].checked_list];
                for (let i = 0; i < arr1.length; i++) {
                    let tarr = [];
                    for (let j = 0; j < arr.length; j++)
                        for (let k = 0; k < arr1[i].length; k++)
                            tarr.push(arr[j].concat(arr1[i][k]));
                    arr = tarr;
                }
                //生成注单
                for (let i = 0; i < arr.length; i++) {
                    list.push({
                        title: this.type,
                        name: arr[i].join(''),
                        visible: false,
                        static_bonus: this.balls[0].betclass[0].static_bonus,
                        val: this.zhixuan_val,
                        subname: this.balls[0].betclass[0].subname,
                        straightbet_high: this.balls[0].betclass[0].straightbet_high,
                        straightbet_low: this.balls[0].betclass[0].straightbet_low,
                        bonus_high: "",
                        sub_popover: false
                    })
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>

<style scoped>
    .zhixuan >>> .el-checkbox-button--mini .el-checkbox-button__inner {
        padding: 7px 10px;
        font-size: 20px;
        font-weight: bold;
    }
    .bet-items {
        padding: 40px 0 !important;
    }
    .lma-val {
        width: 120px !important;
        margin: 0 auto !important;
    }
</style>
