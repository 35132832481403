<template>
    <div class="fc3dhz">
        <!-- fc3dhz -->
        <el-row style="background-color: #fff;">
            <el-col :span="24" v-for="item in balls" :key="item.index">
                <div class="bet-title">
                    <span>{{item.ballname}}</span>
                </div>
                <div class="bet-items hz">
                    <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                        <span class="bet-name" style="font-weight:bold">{{i.name}}</span>
                        <span class="bet-lose">{{i.static_bonus}}</span>
                        <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                    </el-col>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                balls: [
                    {
                        ballname: "和值",
                        betclass: [
                            { title: "和值", name: "0", visible: false, static_bonus: "", val: "", subname: "15_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "1", visible: false, static_bonus: "", val: "", subname: "15_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "2", visible: false, static_bonus: "", val: "", subname: "15_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "3", visible: false, static_bonus: "", val: "", subname: "15_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "4", visible: false, static_bonus: "", val: "", subname: "15_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "5", visible: false, static_bonus: "", val: "", subname: "15_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "6", visible: false, static_bonus: "", val: "", subname: "15_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "7", visible: false, static_bonus: "", val: "", subname: "15_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "8", visible: false, static_bonus: "", val: "", subname: "15_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "9", visible: false, static_bonus: "", val: "", subname: "15_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "10", visible: false, static_bonus: "", val: "", subname: "15_0_10", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "11", visible: false, static_bonus: "", val: "", subname: "15_0_11", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "12", visible: false, static_bonus: "", val: "", subname: "15_0_12", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "13", visible: false, static_bonus: "", val: "", subname: "15_0_13", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "14", visible: false, static_bonus: "", val: "", subname: "15_0_14", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "15", visible: false, static_bonus: "", val: "", subname: "15_0_15", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "16", visible: false, static_bonus: "", val: "", subname: "15_0_16", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "17", visible: false, static_bonus: "", val: "", subname: "15_0_17", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "18", visible: false, static_bonus: "", val: "", subname: "15_0_18", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "19", visible: false, static_bonus: "", val: "", subname: "15_0_19", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "20", visible: false, static_bonus: "", val: "", subname: "15_0_20", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "21", visible: false, static_bonus: "", val: "", subname: "15_0_21", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "22", visible: false, static_bonus: "", val: "", subname: "15_0_22", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "23", visible: false, static_bonus: "", val: "", subname: "15_0_23", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "24", visible: false, static_bonus: "", val: "", subname: "15_0_24", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "25", visible: false, static_bonus: "", val: "", subname: "15_0_25", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "26", visible: false, static_bonus: "", val: "", subname: "15_0_26", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                            { title: "和值", name: "27", visible: false, static_bonus: "", val: "", subname: "15_0_27", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false },
                        ]
                    },
                ]
            }
        },
        methods: {
            get_lose() {
                let bonus_high = this.$store.state.lottery.lose_data.bonus_high;
                let others = this.$store.state.lottery.lose_data.others;
                let static_bonus = this.$store.state.lottery.lose_data.static_bonus;
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].static_bonus = static_bonus[j];
                        this.balls[i].betclass[j].straightbet_high = others[j].straightbet_high;
                        this.balls[i].betclass[j].straightbet_low = others[j].straightbet_low;
                    }
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        this.balls[i].betclass[j].val = ""
                    }
                }
            },
            /**提交，下注 */
            sub_bet() {
                let list = [];
                for (let i = 0; i < this.balls.length; i++) {
                    for (let j = 0; j < this.balls[i].betclass.length; j++) {
                        if (this.balls[i].betclass[j].val && !isNaN(this.balls[i].betclass[j].val * 1)) {
                            list.push(this.balls[i].betclass[j]);
                        }
                    }
                }
                // console.log(list)
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < this.balls.length; j++) {
                        for (let k = 0; k < this.balls[j].betclass.length; k++) {
                            if (this.balls[j].betclass[k].title == list[i].title && this.balls[j].betclass[k].name == list[i].name) {
                                this.balls[j].betclass[k].val = list[i].val;
                            }
                        }
                    }
                }
            },
        },
    }
</script>
<style scoped>
    .hz {
		background-color: #fff;
    }
</style>