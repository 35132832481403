<template>
    <div class="lma">
        <!-- 两面 -->
        <el-row type="flex">
            <el-col :span="24" v-for="item in lma" :key="item.index">
                <fieldset>
                    <legend>{{item.ballname}}</legend>
                    <div class="lma_which_num">
                        <el-radio-group v-model="radio" fill size="small" text-color="#fff" @change="lma_change_num">
                            <el-radio-button label="lma2">三全中</el-radio-button>
                            <el-radio-button label="lma">三中二</el-radio-button>
                            <el-radio-button label="lma3">二全中</el-radio-button>
                            <el-radio-button label="lma4">二中特</el-radio-button>
                            <el-radio-button label="lma5">特串</el-radio-button>
                            <el-radio-button label="lma6">四中一</el-radio-button>
                        </el-radio-group>
                        <el-radio-group v-show="showType" class="type-radio" v-model="typeRadio" @change="change_typeRadio">
                            <el-radio label="0">单注/复式</el-radio>
                            <el-radio label="1">胆拖</el-radio>
                            <el-radio v-if="radio=='lma3'||radio=='lma4'||radio=='lma5'" label="2">生肖对碰</el-radio>
                            <el-radio v-if="radio=='lma3'||radio=='lma4'||radio=='lma5'" label="3">尾数对碰</el-radio>
                            <el-radio v-if="radio=='lma3'||radio=='lma4'||radio=='lma5'" label="4">混合对碰</el-radio>
                        </el-radio-group>
                        <div v-if="typeRadio==1&&showType" style="margin-top:10px">
                            <div>
                                <span style="color:#F56C6C">已选胆：</span>
                                <span>{{dan.toString()}}</span>
                            </div>
                            <div>
                                <span style="color:#F56C6C">已选拖：</span>
                                <span>{{tuo.toString()}}</span>
                            </div>
                        </div>
                    </div>
                    <!-- 生肖对碰 -->
                    <el-row>
                        <el-col :span="typeRadio==2?24:12" class="list" v-if="(radio=='lma3'||radio=='lma4'||radio=='lma5')&&(typeRadio==2||typeRadio==4)">
                            <div v-for="i in sxdp[0].betclass" :key="i.index" class="item">
                                <el-checkbox label class="sxdp" v-model="i.val" @change="checkbox_change" :disabled="i.disabled"></el-checkbox>
                                <span class="bet-name">{{i.name}}</span>
                                <div class="item-info">
                                    <span class="betclassname_sxdp" v-for="v in i.betclass_nums" :key="v.index">
                                        <span v-if="v.color=='red'" class="sxdp_nums_red">{{v.num}}</span>
                                        <span v-if="v.color=='blue'" class="sxdp_nums_blue">{{v.num}}</span>
                                        <span v-if="v.color=='green'" class="sxdp_nums_green">{{v.num}}</span>
                                    </span>
                                </div>
                            </div>
                        </el-col>
                        <!-- 尾数对碰 -->
                        <el-col :span="typeRadio==3?24:12" class="list" v-if="(radio=='lma3'||radio=='lma4'||radio=='lma5')&&(typeRadio==3||typeRadio==4)">
                            <div v-for="i in wsdp[0].betclass" :key="i.index" class="item">
                                <el-checkbox label class="sxdp" v-model="i.val" @change="checkbox_change" :disabled="i.disabled"></el-checkbox>
                                <span class="bet-name" style="float:none">{{i.name}}</span>
                                <div class="item-info">
                                    <span class="betclassname_sxdp" v-for="v in i.betclass_nums" :key="v.index">
                                        <span v-if="v.color=='red'" class="sxdp_nums_red">{{v.num}}</span>
                                        <span v-if="v.color=='blue'" class="sxdp_nums_blue">{{v.num}}</span>
                                        <span v-if="v.color=='green'" class="sxdp_nums_green">{{v.num}}</span>
                                    </span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="list" v-if="typeRadio==0||typeRadio==1">
                        <div class="flex5">
                            <div class="flex5-item" v-for="i in item.betclass" :key="i.index">
                                <span v-if="i.color=='red'" class="lhc_lma_red">{{i.name}}</span>
                                <span v-if="i.color=='green'" class="lhc_lma_green">{{i.name}}</span>
                                <span v-if="i.color=='blue'" class="lhc_lma_blue">{{i.name}}</span>
                                <span class="lm-bet-lose" style=" min-width: 50px;">{{i.static_bonus}}</span>
                                <el-checkbox label class="lma" v-model="i.val" @change="checkbox_change($event,i.name)" :disabled="i.disabled"></el-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="one-bet">
                        <span>下注金额：</span>
                        <el-input class="lma-val" v-model="lma_val" size="small" v-on:focus="lma_val=check_fast_val(lma_val)"></el-input>
                    </div>
                </fieldset>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { get_lhc_sx } from "../../../plugin/sx"
    export default {
        props: ['balls', 'bhList', "is_manlet"],
        data() {
            return {
                lma: [
                    {
                        ballname: "连码",
                        betclass: [
                            { title: "连码", name: "1", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "2", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "3", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "4", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "5", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "6", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "7", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "8", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "9", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "10", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "11", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "12", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "13", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "14", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "15", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "16", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "17", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "18", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "19", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "20", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "21", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "22", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "23", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "24", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "25", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "26", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "27", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "28", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "29", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "30", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "31", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "32", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "33", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "34", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "35", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "36", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "37", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "38", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "39", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "40", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "41", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "42", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "43", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "44", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                            { title: "连码", name: "45", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "46", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red" },
                            { title: "连码", name: "47", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "48", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue" },
                            { title: "连码", name: "49", visible: false, static_bonus: "-", val: false, subname: "5_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green" },
                        ]
                    }
                ],
                sxdp: [
                    {
                        ballname: "生肖对碰",
                        betclass: [
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 2, color: "red" }, { num: 14, color: "blue" }, { num: 26, color: "blue" }, { num: 38, color: "green" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 1, color: "red" }, { num: 13, color: "red" }, { num: 25, color: "blue" }, { num: 37, color: "blue" }, { num: 49, color: "green" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 12, color: "red" }, { num: 24, color: "red" }, { num: 36, color: "blue" }, { num: 48, color: "blue" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 11, color: "green" }, { num: 23, color: "red" }, { num: 35, color: "red" }, { num: 47, color: "blue" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 10, color: "blue" }, { num: 22, color: "green" }, { num: 34, color: "red" }, { num: 46, color: "red" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 9, color: "blue" }, { num: 21, color: "green" }, { num: 33, color: "green" }, { num: 45, color: "red" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 8, color: "red" }, { num: 20, color: "blue" }, { num: 32, color: "green" }, { num: 44, color: "green" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "green", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 7, color: "red" }, { num: 19, color: "red" }, { num: 31, color: "blue" }, { num: 43, color: "green" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 6, color: "green" }, { num: 18, color: "red" }, { num: 30, color: "red" }, { num: 42, color: "blue" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "red", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 5, color: "green" }, { num: 17, color: "green" }, { num: 29, color: "red" }, { num: 41, color: "blue" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 4, color: "blue" }, { num: 16, color: "green" }, { num: 28, color: "green" }, { num: 40, color: "red" }] },
                            { title: "生肖对碰", name: "", visible: false, static_bonus: "-", val: "", disabled: false, subname: "9_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, color: "blue", isChoose: false, bgcolor: "#fff", betclass_nums: [{ num: 3, color: "blue" }, { num: 15, color: "blue" }, { num: 27, color: "green" }, { num: 39, color: "green" }] },
                        ]
                    }
                ],
                wsdp: [
                    {
                        ballname: '尾数对碰',
                        betclass: [
                            { title: "尾数对碰", name: "1尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_1", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 1, color: "red" }, { num: 11, color: "green" }, { num: 21, color: "green" }, { num: 31, color: "blue" }, { num: 41, color: "blue" }] },
                            { title: "尾数对碰", name: "2尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_2", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 2, color: "red" }, { num: 12, color: "red" }, { num: 22, color: "green" }, { num: 32, color: "green" }, { num: 42, color: "blue" }] },
                            { title: "尾数对碰", name: "3尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_3", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 3, color: "blue" }, { num: 13, color: "red" }, { num: 23, color: "red" }, { num: 33, color: "green" }, { num: 43, color: "green" }] },
                            { title: "尾数对碰", name: "4尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_4", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 4, color: "blue" }, { num: 14, color: "blue" }, { num: 24, color: "red" }, { num: 34, color: "red" }, { num: 44, color: "green" }] },
                            { title: "尾数对碰", name: "5尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_5", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 5, color: "green" }, { num: 15, color: "blue" }, { num: 25, color: "blue" }, { num: 35, color: "red" }, { num: 45, color: "red" }] },
                            { title: "尾数对碰", name: "6尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_6", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 6, color: "green" }, { num: 16, color: "green" }, { num: 26, color: "blue" }, { num: 36, color: "blue" }, { num: 46, color: "red" }] },
                            { title: "尾数对碰", name: "7尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_7", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 7, color: "red" }, { num: 17, color: "green" }, { num: 27, color: "green" }, { num: 37, color: "blue" }, { num: 47, color: "blue" }] },
                            { title: "尾数对碰", name: "8尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_8", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 8, color: "red" }, { num: 18, color: "red" }, { num: 28, color: "green" }, { num: 38, color: "green" }, { num: 48, color: "blue" }] },
                            { title: "尾数对碰", name: "9尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_9", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 9, color: "blue" }, { num: 19, color: "red" }, { num: 29, color: "red" }, { num: 39, color: "green" }, { num: 49, color: "green" }] },
                            { title: "尾数对碰", name: "0尾", visible: false, static_bonus: "-", val: "", disabled: false, subname: "11_0_0", straightbet_high: "", straightbet_low: "", bonus_high: "", sub_popover: false, isChoose: false, betclass_nums: [{ num: 10, color: "blue" }, { num: 20, color: "blue" }, { num: 30, color: "red" }, { num: 40, color: "red" }] },
                        ]
                    }
                ],
                radio: 'lma2',
                typeRadio: '0',//0单注复式，1胆拖
                // 选择的个数，限制7个
                lma_count: 0,
                /**连码下注金额 */
                lma_val: "",
                dan: [],//选中的胆
                tuo: [],//选中的拖
                showType: true,
            }
        },
        created() {
            this.sxdp = get_lhc_sx("初始化", this.sxdp);
        },
        methods: {
            change_typeRadio(value) {
                this.reset();
                // if (value == 2 || value == 3 || value == 4) {
                this.get_lose();
                // }
            },
            lma_change_num(label) {
                // console.log(label,this.radio)
                if (label == 'lma6') {
                    this.typeRadio = '0';
                    this.showType = false;
                } else {
                    this.typeRadio = '0';
                    this.showType = true;
                }
                this.reset();
                this.get_lose();
                this.$emit('regainLoss', label);
            },
            /**显示赔率等数据 */
            get_lose() {
                let lose_data = this.$store.state.lose_data;
                for (let j = 0; j < this.lma[0].betclass.length; j++) {
                    if (this.radio == "lma") {
                        this.lma[0].betclass[j].title = "三中二";
                        this.lma[0].betclass[j].subname = "5_0_0";
                    }
                    if (this.radio == "lma2") {
                        this.lma[0].betclass[j].title = "三全中";
                        this.lma[0].betclass[j].subname = "5_1_0";
                    }
                    if (this.radio == "lma3") {
                        this.lma[0].betclass[j].title = "二全中";
                        this.lma[0].betclass[j].subname = "5_2_0";
                    }
                    if (this.radio == "lma4") {
                        this.lma[0].betclass[j].title = "二中特";
                        this.lma[0].betclass[j].subname = "5_3_0";
                    }
                    if (this.radio == "lma5") {
                        this.lma[0].betclass[j].title = "特串";
                        this.lma[0].betclass[j].subname = "5_4_0";
                    }
                    if (this.radio == "lma6") {
                        this.lma[0].betclass[j].title = "四中一";
                        this.lma[0].betclass[j].subname = "5_5_0";
                    }
                }
                for (let j = 0; j < this.lma[0].betclass.length; j++) {
                    lose_data.forEach(item => {
                        // console.log(item.other);
                        if (item.title == this.lma[0].betclass[j].title && item.name == this.lma[0].betclass[j].name) {
                            this.lma[0].betclass[j].static_bonus = item.rate;
                            this.lma[0].betclass[j].straightbet_high = item.other.straightbet_high;
                            this.lma[0].betclass[j].straightbet_low = item.other.straightbet_low;
                        }
                    });
                }
            },
            /**检查是否满足快捷输入 */
            check_fast_val(val) {
                if (val) {
                    return val;
                }
                else {
                    val = this.$parent.get_fast();
                    return val;
                }
            },
            /**重填，重置 */
            reset() {
                this.lma_val = "";
                this.lma_count = 0;
                for (let i = 0; i < this.lma[0].betclass.length; i++) {
                    this.lma[0].betclass[i].val = false;
                    this.lma[0].betclass[i].disabled = false;
                    this.lma[0].betclass[i].static_bonus = '';
                }
                for (let i = 0; i < this.sxdp[0].betclass.length; i++) {
                    this.sxdp[0].betclass[i].val = false;
                    this.sxdp[0].betclass[i].disabled = false;
                    this.sxdp[0].betclass[i].static_bonus = '';
                }
                for (let i = 0; i < this.wsdp[0].betclass.length; i++) {
                    this.wsdp[0].betclass[i].val = false;
                    this.wsdp[0].betclass[i].disabled = false;
                    this.wsdp[0].betclass[i].static_bonus = '';
                }
                this.dan = [];
                this.tuo = [];
            },
            /**多选框点击 */
            checkbox_change(v, name) {
                // console.log(v, name);
                v ? this.lma_count++ : this.lma_count--;
                if (this.typeRadio == '0') {
                    // console.log(this.lma_count);
                    if (this.lma_count >= 8) {
                        for (let i = 0; i < this.lma[0].betclass.length; i++) {
                            if (this.lma[0].betclass[i].val == false) {
                                this.lma[0].betclass[i].disabled = true;
                            }
                        }
                    } else {
                        for (let i = 0; i < this.lma[0].betclass.length; i++) {
                            if (this.lma[0].betclass[i].val == false) {
                                this.lma[0].betclass[i].disabled = false;
                            }
                        }
                    }
                } else if (this.typeRadio == '1') {
                    let length = this.radio == 'lma' || this.radio == 'lma2' ? 2 : 1;
                    // console.log(length);
                    if (v) {
                        if (this.dan.length < length) {
                            this.dan.push(name);
                        } else {
                            this.tuo.push(name);
                        }
                        if (this.dan.length == length && this.tuo.length > 0) {
                            this.lma[0].betclass.forEach(item => {
                                this.dan.forEach(i => {
                                    if (item.name == i) {
                                        item.disabled = true;
                                    }
                                })
                            });
                        }
                    } else {
                        if (this.dan.length < length && this.tuo.length == 0) {
                            this.dan.splice(this.dan.findIndex(item => item == name), 1)
                        }
                        if (this.dan.length == length && this.tuo.length == 0) {
                            this.dan.splice(this.dan.findIndex(item => item == name), 1)
                        }
                        if (this.dan.length == length && this.tuo.length > 0) {
                            this.tuo.splice(this.tuo.findIndex(item => item == name), 1);
                            if (this.dan.length == length && this.tuo.length == 0) {
                                this.lma[0].betclass.forEach(item => {
                                    this.dan.forEach(i => {
                                        if (item.name == i) {
                                            item.disabled = false;
                                        }
                                    })
                                });
                            }
                        }
                    }
                } else {
                    if (this.lma_count >= 2) {
                        for (let i = 0; i < this.sxdp[0].betclass.length; i++) {
                            if (this.sxdp[0].betclass[i].val == false) {
                                this.sxdp[0].betclass[i].disabled = true;
                            }
                        }
                        for (let i = 0; i < this.wsdp[0].betclass.length; i++) {
                            if (this.wsdp[0].betclass[i].val == false) {
                                this.wsdp[0].betclass[i].disabled = true;
                            }
                        }
                    } else {
                        for (let i = 0; i < this.sxdp[0].betclass.length; i++) {
                            if (this.sxdp[0].betclass[i].val == false) {
                                this.sxdp[0].betclass[i].disabled = false;
                            }
                        }
                        for (let i = 0; i < this.wsdp[0].betclass.length; i++) {
                            if (this.wsdp[0].betclass[i].val == false) {
                                this.wsdp[0].betclass[i].disabled = false;
                            }
                        }
                    }
                }
            },
            tuo_dan_nums(dan = [], tuo = []) {
                let list = [];
                tuo.forEach(item => {
                    list.push((dan.toString() + `,${item}`).split(','));
                })
                // console.log(list);
                return list;
            },
            /**提交，下注 */
            sub_bet() {
                let rm = true;
                let list = [];
                if (this.radio == "lma6") {
                    if (this.lma_count < 4) {
                        this.$message.error({
                            message: '提交失败，请至少选择4个',
                            type: 'error',
                            offset: 465,
                            center: true,
                        });
                    } else {
                        if (this.lma_val && !isNaN(this.lma_val * 1) && this.lma_count > 0) {
                            for (let i = 0; i < this.lma.length; i++) {
                                for (let j = 0; j < this.lma[i].betclass.length; j++) {
                                    if (this.lma[i].betclass[j].val) {
                                        // console.log(this.lma[i].betclass[j]);
                                        this.lma[i].betclass[j].val = this.lma_val;
                                        list.push(this.lma[i].betclass[j]);
                                    }
                                }
                            }
                            let choose_num = [];
                            for (let i = 0; i < list.length; i++) {
                                choose_num.push(list[i].name)
                            }
                            //多选号码，重新组合成注单
                            let bet_obj = list[0]
                            // console.log(bet_obj)
                            list = []
                            let new_arr = this.typeRadio == '0' ? this.combination_arr_elem(choose_num, 4) : this.tuo_dan_nums(this.dan, this.tuo);
                            // console.log(new_arr);
                            for (let i = 0; i < new_arr.length; i++) {
                                list.push(
                                    {
                                        title: bet_obj.title, name: new_arr[i].toString(), visible: bet_obj.visible,
                                        static_bonus: bet_obj.static_bonus, val: bet_obj.val, subname: bet_obj.subname,
                                        straightbet_high: bet_obj.straightbet_high, straightbet_low: bet_obj.straightbet_low, bonus_high: bet_obj.bonus_high,
                                        sub_popover: bet_obj.sub_popover,
                                        color: bet_obj.color, isChoose: bet_obj.isChoose,
                                    }
                                )
                            }
                        }
                    }
                }
                if (this.radio == "lma" || this.radio == "lma2") {
                    if (this.lma_count < 3) {
                        this.$message.error({
                            message: '提交失败，请至少选择3个',
                            type: 'error',
                            offset: 465,
                            center: true,
                        });
                    } else {
                        if (this.lma_val && !isNaN(this.lma_val * 1) && this.lma_count > 0) {
                            for (let i = 0; i < this.lma.length; i++) {
                                for (let j = 0; j < this.lma[i].betclass.length; j++) {
                                    if (this.lma[i].betclass[j].val) {
                                        // console.log(this.lma[i].betclass[j]);
                                        this.lma[i].betclass[j].val = this.lma_val;
                                        list.push(this.lma[i].betclass[j]);
                                    }
                                }
                            }
                            let choose_num = [];
                            for (let i = 0; i < list.length; i++) {
                                choose_num.push(list[i].name)
                            }
                            //多选号码，重新组合成注单
                            let bet_obj = list[0]
                            // console.log(bet_obj)
                            list = []
                            let new_arr = this.typeRadio == '0' ? this.combination_arr_elem(choose_num, 3) : this.tuo_dan_nums(this.dan, this.tuo);
                            // console.log(new_arr);
                            for (let i = 0; i < new_arr.length; i++) {
                                list.push(
                                    {
                                        title: bet_obj.title, name: new_arr[i].toString(), visible: bet_obj.visible,
                                        static_bonus: bet_obj.static_bonus, val: bet_obj.val, subname: bet_obj.subname,
                                        straightbet_high: bet_obj.straightbet_high, straightbet_low: bet_obj.straightbet_low, bonus_high: bet_obj.bonus_high,
                                        sub_popover: bet_obj.sub_popover,
                                        color: bet_obj.color, isChoose: bet_obj.isChoose,
                                    }
                                )
                            }
                        }
                    }
                }
                if (this.radio == "lma3" || this.radio == "lma4" || this.radio == "lma5") {
                    if (this.lma_count < 2) {
                        this.$message.error({
                            message: '提交失败，请至少选择2个',
                            type: 'error',
                            offset: 465,
                            center: true,
                        });
                    } else {
                        if (this.lma_val && !isNaN(this.lma_val * 1) && this.lma_count > 0) {
                            if (this.typeRadio == '0' || this.typeRadio == '1') {
                                for (let i = 0; i < this.lma.length; i++) {
                                    for (let j = 0; j < this.lma[i].betclass.length; j++) {
                                        if (this.lma[i].betclass[j].val) {
                                            this.lma[i].betclass[j].val = this.lma_val;
                                            list.push(this.lma[i].betclass[j]);
                                        }
                                    }
                                }
                                let choose_num = []
                                for (let i = 0; i < list.length; i++) {
                                    choose_num.push(list[i].name)
                                }
                                //多选号码，重新组合成注单
                                let bet_obj = list[0]
                                // console.log(bet_obj)
                                list = []
                                let new_arr = this.typeRadio == '0' ? this.combination_arr_elem(choose_num, 2) : this.tuo_dan_nums(this.dan, this.tuo);
                                for (let i = 0; i < new_arr.length; i++) {
                                    list.push(
                                        {
                                            title: bet_obj.title, name: new_arr[i].toString(), visible: bet_obj.visible,
                                            static_bonus: bet_obj.static_bonus, val: bet_obj.val, subname: bet_obj.subname,
                                            straightbet_high: bet_obj.straightbet_high, straightbet_low: bet_obj.straightbet_low, bonus_high: bet_obj.bonus_high,
                                            sub_popover: bet_obj.sub_popover,
                                            color: bet_obj.color, isChoose: bet_obj.isChoose,
                                        }
                                    )
                                    // this.suballcout += this.allinputval[i].val * 1;
                                }
                            } else {
                                for (let i = 0; i < this.sxdp[0].betclass.length; i++) {
                                    if (this.sxdp[0].betclass[i].val) {
                                        this.sxdp[0].betclass[i].val = this.lma_val;
                                        list.push(this.sxdp[0].betclass[i]);
                                    }
                                }
                                for (let i = 0; i < this.wsdp[0].betclass.length; i++) {
                                    if (this.wsdp[0].betclass[i].val) {
                                        this.wsdp[0].betclass[i].val = this.lma_val;
                                        list.push(this.wsdp[0].betclass[i]);
                                    }
                                }
                                // console.log(list);
                                let choose_num = []
                                let twoarr = [];
                                for (let i = 0; i < list.length; i++) {
                                    let arr = [];
                                    for (let j = 0; j < list[i].betclass_nums.length; j++) {
                                        choose_num.push(list[i].betclass_nums[j].num);
                                        arr.push(list[i].betclass_nums[j].num);
                                    }
                                    twoarr.push(arr)
                                }
                                //对碰组合
                                let arr1 = twoarr[0];
                                let arr2 = twoarr[1];
                                let arr3 = [];
                                for (let i = 0; i < arr1.length; i++) {
                                    for (let j = 0; j < arr2.length; j++) {
                                        if (arr1[i] != arr2[j]) {
                                            arr3.push(`${arr1[i]},${arr2[j]}`);
                                        }
                                    }
                                }
                                let count = arr3.length;
                                // console.log(count);
                                let bet_obj = list[0];
                                let title = this.typeRadio == 2 ? '生肖对碰' : this.typeRadio == 3 ? '尾数对碰' : '混合对碰';
                                let subname = '';
                                if (this.radio == "lma3") {
                                    subname = '5_2_0';
                                }
                                if (this.radio == "lma4") {
                                    subname = '5_3_0';
                                }
                                if (this.radio == "lma5") {
                                    subname = '5_4_0';
                                }
                                list = [{
                                    title: title, name: twoarr[0].toString() + "|" + twoarr[1].toString(), visible: bet_obj.visible,
                                    static_bonus: bet_obj.static_bonus, val: bet_obj.val * count, subname: subname, bet_count: count,
                                    straightbet_high: bet_obj.straightbet_high, straightbet_low: bet_obj.straightbet_low, bonus_high: bet_obj.bonus_high,
                                    sub_popover: bet_obj.sub_popover,
                                    color: bet_obj.color, isChoose: bet_obj.isChoose,
                                    changeInput: 'cant',
                                }]
                                let ball_loss_list = [];
                                for (let i = 0; i < this.lma[0].betclass.length; i++) {
                                    for (let j = 0; j < choose_num.length; j++) {
                                        if (this.lma[0].betclass[i].name == choose_num[j]) {
                                            // if (this.radio == "lma" || this.radio == "lma4") {
                                            //     ball_loss_list.push({
                                            //         loss: this.lma[0].betclass[i].static_bonus.split('|')[0],
                                            //         ball: this.lma[0].betclass[i].name,
                                            //     });
                                            // } else {
                                            //     ball_loss_list.push({
                                            //         loss: this.lma[0].betclass[i].static_bonus,
                                            //         ball: this.lma[0].betclass[i].name,
                                            //     });
                                            // }
                                            ball_loss_list.push({
                                                loss: this.lma[0].betclass[i].static_bonus,
                                                ball: this.lma[0].betclass[i].name,
                                            });
                                        }
                                    }
                                }
                                let sitem = ball_loss_list.sort((a, b) => a.loss - b.loss)[0];
                                list[0].ball_min = sitem.ball;
                                list[0].static_bonus = sitem.loss;
                                rm = false;
                            }
                        }
                    }
                }
                // 筛选最小的赔率
                if (rm) {
                    for (let i = 0; i < list.length; i++) {
                        list[i].ball_list = list[i].name.split(',');
                        list[i].ball_loss_list = [];
                        for (let k = 0; k < list[i].ball_list.length; k++) {
                            for (let j = 0; j < this.lma[0].betclass.length; j++) {
                                if (this.lma[0].betclass[j].name == list[i].ball_list[k]) {
                                    // if (this.radio == "lma" || this.radio == "lma4") {
                                    //     list[i].ball_loss_list.push({
                                    //         loss: this.lma[0].betclass[j].static_bonus.split('|')[0],
                                    //         ball: this.lma[0].betclass[j].name,
                                    //     });
                                    // } else {
                                    //     list[i].ball_loss_list.push({
                                    //         loss: this.lma[0].betclass[j].static_bonus,
                                    //         ball: this.lma[0].betclass[j].name,
                                    //     });
                                    // }
                                    list[i].ball_loss_list.push({
                                        loss: this.lma[0].betclass[j].static_bonus,
                                        ball: this.lma[0].betclass[j].name,
                                    });
                                }
                            }
                        }
                        let sitem = list[i].ball_loss_list.sort((a, b) => a.loss - b.loss)[0];
                        list[i].ball_min = sitem.ball;
                        list[i].static_bonus = sitem.loss;
                    }
                }
                // console.log(list);
                return list;
            },
            /**重复上次下单 */
            repeat_last_bet(list) {
                // console.log(list)
                // console.log(list[0].name.split(","))
                for (let i = 0; i < list.length; i++) {
                    if (!isArray(list[i].name)) {
                        list[i].name = list[i].name.split(",");
                    }
                    for (let j = 0; j < this.lma.length; j++) {
                        for (let k = 0; k < this.lma[j].betclass.length; k++) {
                            if (this.lma[j].betclass[k].title == list[i].title && list[i].name.find(n => n == this.lma[j].betclass[k].name)) {
                                this.lma[j].betclass[k].val = true;
                            }
                        }
                    }
                }
                this.lma_count = 0;
                for (let i = 0; i < this.lma[0].betclass.length; i++) {
                    if (this.lma[0].betclass[i].val) {
                        this.lma_count += 1;
                    }
                }
                if (this.lma_count >= 8) {
                    for (let i = 0; i < this.lma[0].betclass.length; i++) {
                        if (this.lma[0].betclass[i].val == false) {
                            this.lma[0].betclass[i].disabled = true;
                        }
                    }
                }
            },
            combination_arr_elem(nu, groupl, res) {
                let result = res ? res : [];
                let nuc = nu.slice(0);
                let item = nuc.shift();
                item = item.constructor === Array ? item : [item];
                (function func(item, nuc) {
                    let itemc;
                    let nucc = nuc.slice(0);
                    let margin = groupl - item.length
                    if (margin == 0) {
                        result.push(item);
                        return;
                    }
                    if (margin == 1) {
                        for (let j in nuc) {
                            itemc = item.slice(0);
                            itemc.push(nuc[j]);
                            result.push(itemc);
                        }
                    }
                    if (margin > 1) {
                        itemc = item.slice(0);
                        itemc.push(nucc.shift());
                        func(itemc, nucc);

                        if (item.length + nucc.length >= groupl) {
                            func(item, nucc);
                        }
                    }
                })(item, nuc);
                if (nuc.length >= groupl) {
                    return this.combination_arr_elem(nuc, groupl, result);
                } else {
                    return result;
                }
            },
        },
    }
</script>

<style scoped>
    .zhengte_which_num {
        text-align: center;
        padding-bottom: 10px;
    }
    .bet-page .flex5 {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .bet-page .flex5-item {
        width: 20%;
    }
    .item-info {
        display: inline-block;
        text-align: left;
    }
    .lma_which_num {
        text-align: center;
        padding-bottom: 5px;
    }
    .type-radio {
        width: 100%;
        justify-content: center;
    }
    .lhc_lma_red,
    .lhc_lma_blue,
    .lhc_lma_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        margin-bottom: 3px;
        font-weight: bold;
    }
    .lhc_lma_red {
        background-color: #f56c6c;
    }
    .lhc_lma_blue {
        background-color: #0089ff;
    }
    .lhc_lma_green {
        background-color: #67c23a;
    }
    .lma-val {
        width: 100px;
    }
    .lm-bet-lose {
        display: inline-block;
        color: #f56c6c;
        font-weight: bold;
        width: 70px;
        text-align: center;
    }
    .item {
        width: 220px;
        text-align: left;
        margin: 0 auto;
    }
    .item .bet-name {
        display: inline-block;
        width: 50px;
        text-align: center;
    }
    .sxdp_nums_red,
    .sxdp_nums_blue,
    .sxdp_nums_green {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        margin-left: 5px;
        font-weight: bold;
    }
    .sxdp_nums_red {
        background-color: #f56c6c;
    }
    .sxdp_nums_blue {
        background-color: #409eff;
    }
    .sxdp_nums_green {
        background-color: #67c23a;
    }
    .one-bet {
        width: 220px;
        margin: 10px auto;
    }
</style>