<!--
 * @Author: your name
 * @Date: 2021-12-29 15:21:07
 * @LastEditTime: 2022-01-13 16:21:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \luck\src\components\betPage\pl35\pl35.vue
-->
<template>
    <div class="cqssc">
        <lm v-if="activeName=='两面'" ref="lm"></lm>
        <d1_5 v-else-if="activeName=='单球1-5'" ref="d1_5"></d1_5>
        <hz v-else-if="activeName=='和值'" ref="hz"></hz>
        <zhixuan v-else-if="activeName=='直选'" ref="zhixuan"></zhixuan>
        <zuxuan v-else-if="activeName=='组选'" ref="zuxuan"></zuxuan>
        <bdw v-else-if="activeName=='不定位'" ref="bdw"></bdw>
        <dds v-else ref="dds"></dds>
    </div>
</template>

<script>
    import lm from "./lm";
    import d1_5 from "./d1_5";
    import dds from "./dds";
    import hz from "./hz";
    import zhixuan from "./zhixuan";
    import zuxuan from "./zuxuan";
    import bdw from "./bdw";

    export default {
        components: { lm, d1_5, dds, hz, zhixuan, zuxuan, bdw },
        props: ["activeName"],
        data() {
            return {

            }
        },
        methods: {
            /**显示赔率 */
            set_lose() {
                if (this.activeName == "两面") {
                    this.$refs.lm.get_lose();
                }
                else if (this.activeName == "单球1-5") {
                    this.$refs.d1_5.get_lose();
                }
                else if (this.activeName == "和值") {
                    this.$refs.hz.get_lose();
                }
                else if (this.activeName == "直选") {
                    this.$refs.zhixuan.get_lose();
                }
                else if (this.activeName == "组选") {
                    this.$refs.zuxuan.get_lose();
                }
                else if (this.activeName == "不定位") {
                    this.$refs.bdw.get_lose();
                }
                else {
                    this.$refs.dds.get_lose(this.activeName);
                }
            },
            /**获取快捷下注金额 */
            get_fast() {
                // console.log(this.$parent.get_fast())
                return this.$parent.get_fast();
            },
            /**重填，重置 */
            reset() {
                if (this.activeName == "两面") {
                    this.$refs.lm.reset();
                }
                else if (this.activeName == "单球1-5") {
                    this.$refs.d1_5.reset();
                }
                else if (this.activeName == "和值") {
                    this.$refs.hz.reset();
                }
                else if (this.activeName == "直选") {
                    this.$refs.zhixuan.reset();
                }
                else if (this.activeName == "组选") {
                    this.$refs.zuxuan.reset();
                }
                else if (this.activeName == "不定位") {
                    this.$refs.bdw.reset();
                }
                else {
                    this.$refs.dds.reset(this.activeName);
                }
            },
            /**提交，下注 */
            sub_bet() {
                if (this.activeName == "两面") {
                    return this.$refs.lm.sub_bet();
                }
                else if (this.activeName == "单球1-5") {
                    return this.$refs.d1_5.sub_bet();
                }
                else if (this.activeName == "和值") {
                    return this.$refs.hz.sub_bet();
                }
                else if (this.activeName == "直选") {
                    return this.$refs.zhixuan.sub_bet();
                }
                else if (this.activeName == "组选") {
                    return this.$refs.zuxuan.sub_bet();
                }
                else if (this.activeName == "不定位") {
                    return this.$refs.bdw.sub_bet();
                }
                else {
                    return this.$refs.dds.sub_bet(this.activeName);
                }
            },
            /**重复上次下单 */
            repeat_last_bet(playing, list) {
                if (playing == "cqssc_lmp") {
                    this.$refs.lm.repeat_last_bet(list);
                }
                else if (playing == "cqssc_d1_5") {
                    this.$refs.d1_5.repeat_last_bet(list);
                }
                else if (playing == "hz") {
                    this.$refs.hz.repeat_last_bet(list);
                }
                else if (playing == "pl_zhixuan") {
                    this.$refs.zhixuan.repeat_last_bet(list);
                }
                else if (playing == "pl_zuxuan") {
                    this.$refs.zuxuan.repeat_last_bet(list);
                }
                else if (playing == "ftpl_bdw") {
                    this.$refs.bdw.repeat_last_bet(list);
                }
                else {
                    this.$refs.dds.repeat_last_bet(list);
                }
            },
        },
    }
</script>