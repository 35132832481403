<template>
<div class="cqssc-lm">
    <!-- 1-5 -->
    <div class="flex5">
        <div  class="flex5-item" v-for="item in balls" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <div class="bet-items">
                <div v-for="i in item.betclass" :key="i.index">
                    <span class="bet-name">{{i.name}}</span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                </div>
            </div>
        </div>
    </div>
    <br>
    <!-- 总和 -->
    <el-row type="flex">
        <el-col :span="24" v-for="item in sum" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <div class="bet-items-one" style="height:30px">
                <el-col :span="6" v-for="i in item.betclass" :key="i.index">
                    <span class="bet-name">{{i.name}}</span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                    <!-- <div style="clear:both"></div> -->
                </el-col>
            </div>
        </el-col>
    </el-row>
    <br>
    <!-- 龙虎 -->
    <div class="flex5">
        <div  class="flex5-item" v-for="item in long_hu" :key="item.index">
            <div class="bet-title">
                <span>{{item.ballname}}</span>
            </div>
            <div class="bet-items" style="margin-bottom:6px">
                <div v-for="i in item.betclass" :key="i.index">
                    <span class="bet-name">{{i.name}}</span>
                    <span class="bet-lose">{{i.static_bonus}}</span>
                    <el-input v-model="i.val" size="small"  v-on:focus="i.val=check_fast_val(i.val)"></el-input>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { link } from 'fs';
export default {
    data() {
        return {
            balls: [
                {
                    ballname: "第一球",
                    betclass: [
                        { title: "第一球", name: "大", visible: false, static_bonus: "", val: "", subname: "0_0_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第一球", name: "小", visible: false, static_bonus: "", val: "", subname: "0_0_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第一球", name: "单", visible: false, static_bonus: "", val: "", subname: "0_0_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第一球", name: "双", visible: false, static_bonus: "", val: "", subname: "0_0_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "第二球",
                    betclass: [
                        { title: "第二球", name: "大", visible: false, static_bonus: "", val: "", subname: "0_1_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第二球", name: "小", visible: false, static_bonus: "", val: "", subname: "0_1_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第二球", name: "单", visible: false, static_bonus: "", val: "", subname: "0_1_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第二球", name: "双", visible: false, static_bonus: "", val: "", subname: "0_1_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "第三球",
                    betclass: [
                        { title: "第三球", name: "大", visible: false, static_bonus: "", val: "", subname: "0_2_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第三球", name: "小", visible: false, static_bonus: "", val: "", subname: "0_2_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第三球", name: "单", visible: false, static_bonus: "", val: "", subname: "0_2_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第三球", name: "双", visible: false, static_bonus: "", val: "", subname: "0_2_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "第四球",
                    betclass: [
                        { title: "第四球", name: "大", visible: false, static_bonus: "", val: "", subname: "0_3_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第四球", name: "小", visible: false, static_bonus: "", val: "", subname: "0_3_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第四球", name: "单", visible: false, static_bonus: "", val: "", subname: "0_3_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第四球", name: "双", visible: false, static_bonus: "", val: "", subname: "0_3_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
                {
                    ballname: "第五球",
                    betclass: [
                        { title: "第五球", name: "大", visible: false, static_bonus: "", val: "", subname: "0_4_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第五球", name: "小", visible: false, static_bonus: "", val: "", subname: "0_4_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第五球", name: "单", visible: false, static_bonus: "", val: "", subname: "0_4_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "第五球", name: "双", visible: false, static_bonus: "", val: "", subname: "0_4_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false}
                    ]
                },
            ],
            /**总和，龙虎 */
            sum: [
                {
                    ballname: "总和",
                    betclass: [
                        { title: "总和", name: "总和大", visible: false, static_bonus: "", val: "", subname: "0_5_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "总和", name: "总和小", visible: false, static_bonus: "", val: "", subname: "0_5_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "总和", name: "总和单", visible: false, static_bonus: "", val: "", subname: "0_5_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "总和", name: "总和双", visible: false, static_bonus: "", val: "", subname: "0_5_3", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        // { title: "总和龙虎", name: "龙", visible: false, static_bonus: "", val: "", subname: "0_5_4", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        // { title: "总和龙虎", name: "虎", visible: false, static_bonus: "", val: "", subname: "0_5_5", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        // { title: "总和龙虎", name: "和", visible: false, static_bonus: "", val: "", subname: "0_5_6", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                }
            ],
            long_hu: [
                {
                    ballname: "龙虎1vs2",
                    betclass: [
                        { title: "龙虎1vs2",bet_title:'万千', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_0_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs2",bet_title:'万千', name: "和", visible: false, static_bonus: "", val: "", subname: "14_0_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs2",bet_title:'万千', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_0_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎1vs3",
                    betclass: [
                        { title: "龙虎1vs3",bet_title:'万百', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_1_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs3",bet_title:'万百', name: "和", visible: false, static_bonus: "", val: "", subname: "14_1_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs3",bet_title:'万百', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_1_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎1vs4",
                    betclass: [
                        { title: "龙虎1vs4",bet_title:'万十', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_2_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs4",bet_title:'万十', name: "和", visible: false, static_bonus: "", val: "", subname: "14_2_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs4",bet_title:'万十', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_2_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎1vs5",
                    betclass: [
                        { title: "龙虎1vs5",bet_title:'万个', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_3_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs5",bet_title:'万个', name: "和", visible: false, static_bonus: "", val: "", subname: "14_3_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎1vs5",bet_title:'万个', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_3_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎2vs3",
                    betclass: [
                        { title: "龙虎2vs3",bet_title:'千百', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_4_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs3",bet_title:'千百', name: "和", visible: false, static_bonus: "", val: "", subname: "14_4_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs3",bet_title:'千百', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_4_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎2vs4",
                    betclass: [
                        { title: "龙虎2vs4",bet_title:'千十', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_5_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs4",bet_title:'千十', name: "和", visible: false, static_bonus: "", val: "", subname: "14_5_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs4",bet_title:'千十', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_5_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎2vs5",
                    betclass: [
                        { title: "龙虎2vs5",bet_title:'千个', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_6_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs5",bet_title:'千个', name: "和", visible: false, static_bonus: "", val: "", subname: "14_6_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎2vs5",bet_title:'千个', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_6_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎3vs4",
                    betclass: [
                        { title: "龙虎3vs4",bet_title:'百十', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_7_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎3vs4",bet_title:'百十', name: "和", visible: false, static_bonus: "", val: "", subname: "14_7_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎3vs4",bet_title:'百十', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_7_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎3vs5",
                    betclass: [
                        { title: "龙虎3vs5",bet_title:'百个', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_8_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎3vs5",bet_title:'百个', name: "和", visible: false, static_bonus: "", val: "", subname: "14_8_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎3vs5",bet_title:'百个', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_8_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
                {
                    ballname: "龙虎4vs5",
                    betclass: [
                        { title: "龙虎4vs5",bet_title:'十个', name: "龙", visible: false, static_bonus: "", val: "", subname: "14_9_0", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎4vs5",bet_title:'十个', name: "和", visible: false, static_bonus: "", val: "", subname: "14_9_1", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                        { title: "龙虎4vs5",bet_title:'十个', name: "虎", visible: false, static_bonus: "", val: "", subname: "14_9_2", straightbet_high: "", straightbet_low: "", bonus_high:"", sub_popover:false},
                    ]
                },
            ]
        }
    },
    created(){
        // this.get_lose();
    },
    methods: {
        /**显示赔率等数据 */
        get_lose(){
            let bonus_high=this.$store.state.lottery.lose_data.bonus_high;
            let others=this.$store.state.lottery.lose_data.others;
            let static_bonus=this.$store.state.lottery.lose_data.static_bonus;
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].static_bonus=static_bonus[j];
                    this.balls[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.balls[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(4);
                others=others.slice(4);
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    this.sum[i].betclass[j].static_bonus=static_bonus[j];
                    this.sum[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.sum[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(4);
                others=others.slice(4);
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    this.long_hu[i].betclass[j].static_bonus=static_bonus[j];
                    this.long_hu[i].betclass[j].straightbet_high=others[j].straightbet_high;
                    this.long_hu[i].betclass[j].straightbet_low=others[j].straightbet_low;
                }
                static_bonus=static_bonus.slice(3);
                others=others.slice(3);
            }
        },
        /**检查是否满足快捷输入 */
        check_fast_val(val){
            if(val){
                return val;
            }
            else{
                val=this.$parent.get_fast();
                return val;
            }
        },
        /**重填，重置 */
        reset(){
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    this.balls[i].betclass[j].val=""
                }
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    this.sum[i].betclass[j].val=""
                }
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    this.long_hu[i].betclass[j].val=""
                }
            }
        },
        /**提交，下注 */
        sub_bet(){
            let list=[];
            //1-5
            for(let i=0;i<this.balls.length;i++){
                for(let j=0;j<this.balls[i].betclass.length;j++){
                    if(this.balls[i].betclass[j].val&&!isNaN(this.balls[i].betclass[j].val*1)){
                        list.push(this.balls[i].betclass[j]);
                    }
                }
            }
            //总和
            for(let i=0;i<this.sum.length;i++){
                for(let j=0;j<this.sum[i].betclass.length;j++){
                    if(this.sum[i].betclass[j].val&&!isNaN(this.sum[i].betclass[j].val*1)){
                        list.push(this.sum[i].betclass[j]);
                    }
                }
            }
            //龙虎
            for(let i=0;i<this.long_hu.length;i++){
                for(let j=0;j<this.long_hu[i].betclass.length;j++){
                    if(this.long_hu[i].betclass[j].val&&!isNaN(this.long_hu[i].betclass[j].val*1)){
                        list.push(this.long_hu[i].betclass[j]);
                    }
                }
            }
            // console.log(list)
            return list;
        },
        /**重复上次下单 */
        repeat_last_bet(list) {
            // console.log(list)
            for(let i=0;i<list.length;i++){
                //1-5
                for(let j=0;j<this.balls.length;j++){
                    for(let k=0;k<this.balls[j].betclass.length;k++){
                        if(this.balls[j].betclass[k].title==list[i].title&&this.balls[j].betclass[k].name==list[i].name){
                            this.balls[j].betclass[k].val=list[i].val;
                        }
                    }
                }
                //总和
                for(let j=0;j<this.sum.length;j++){
                    for(let k=0;k<this.sum[j].betclass.length;k++){
                        if(this.sum[j].betclass[k].title==list[i].title&&this.sum[j].betclass[k].name==list[i].name){
                            this.sum[j].betclass[k].val=list[i].val;
                        }
                    }
                }
                //龙虎
                for(let j=0;j<this.long_hu.length;j++){
                    for(let k=0;k<this.long_hu[j].betclass.length;k++){
                        if(this.long_hu[j].betclass[k].title==list[i].title&&this.long_hu[j].betclass[k].name==list[i].name){
                            this.long_hu[j].betclass[k].val=list[i].val;
                        }
                    }
                }
            }
        },
    },
}
</script>

